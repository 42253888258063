import { FC, useEffect, Fragment, useState } from "react";

import {
  Box,
  Button,
  Row,
  Column,
  SectionHeading,
  Text,
  ButtonGroup,
  PlusIcon,
  NumberInput,
  IconButton,
  DeleteIcon,
  MenuItem,
  MenuActionsButton,
  MenuList,
  DescriptionAddIcon,
  Menu,
  MultiSelect,
  useToast,
  Switch,
  InformationIcon,
  Tooltip,
  Select,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import noop from "lodash/noop";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useOutletContext } from "src/router";
import { v4 as uuidv4 } from "uuid";

import { ActionBar } from "src/components/action-bar";
import { colors } from "src/components/explore/visual/colors";
import { AndOrToggleButton } from "src/components/explore/visual/condition-buttons";
import { GroupIndicatorBar } from "src/components/explore/visual/group-indicator-bar";
import { useUpdateIdentityResolutionGraphMutation } from "src/graphql";

import { OutletContext } from ".";
import {
  BlockRule,
  MergeRule,
  transformationOptionsByIdentifier,
  operatorOptions,
  allTransformationOptions,
  SupportedSource,
} from "src/pages/identity-resolution/types";
import {
  BucketingConfig,
  FUZZY_RULE_OPERATORS,
} from "@hightouch/lib/idr/types";
import isEqual from "lodash/isEqual";
import { ordinalSuffix } from "src/utils/numbers";
import { MergeRulesReorder } from "src/components/reorder";

const defaultOuterType = "and";
const defaultInnerType = "or";

type MergeRuleSet = {
  identifier: string;
  type: "and" | "or";
  conditions: MergeRuleCondition[];
};

type MergeRuleCondition = {
  type: "and" | "or";
  rules: MergeRule[];
};

type FormState = {
  merge_rules: MergeRuleSet[];
  block_rules: BlockRule[];
  resolution_rules: any[];
};

const getDefaultMergeRule = (): MergeRule => ({
  identifier: "",
  operator: "eq",
  transformations: [],
});

const getDefaultMergeRuleSet = (): MergeRuleSet => ({
  identifier: uuidv4(),
  type: defaultOuterType,
  conditions: [
    {
      type: defaultInnerType,
      rules: [
        {
          identifier: "",
          operator: "eq",
          transformations: [],
        },
      ],
    },
  ],
});

const getDefaultBlockRule = (): BlockRule => ({
  identifier: "",
  limit: 1,
});

const getDefaultValues = (graph: OutletContext["graph"]): FormState => {
  // We modified merge_rules to be an array of MergeRules objects, we need to handle the old
  // structure for backwards compatibility.
  const mergeRules =
    graph.merge_rules && !Array.isArray(graph.merge_rules)
      ? [graph.merge_rules]
      : graph.merge_rules;
  return {
    merge_rules: mergeRules ?? [getDefaultMergeRuleSet()],
    block_rules: graph.block_rules ?? [],
    resolution_rules: graph.resolution_rules ?? [],
  };
};
export const Rules: FC = () => {
  const { graph } = useOutletContext<OutletContext>();
  const { toast } = useToast();

  const form = useForm<FormState>({
    defaultValues: getDefaultValues(graph),
  });

  const updateMutation = useUpdateIdentityResolutionGraphMutation();

  const { isDirty } = form.formState;

  const submit: SubmitHandler<FormState> = async (data) => {
    try {
      await updateMutation.mutateAsync({
        id: graph.id,
        input: {
          merge_rules: data.merge_rules,
          block_rules: data.block_rules,
          resolution_rules: data.resolution_rules,
        },
      });
      toast({
        id: "rules-update",
        title: "Rules updated",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "rules-update",
        title: "Rules failed to update",
        message: "Please try again.",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    form.reset(getDefaultValues(graph));
  }, [graph]);

  return (
    <FormProvider {...form}>
      <Column gap={6} pb={32}>
        <MergeRules />
        <BlockRules />
      </Column>
      <ActionBar>
        <ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            isDisabled={!isDirty}
            isLoading={updateMutation.isLoading}
            onClick={() => {
              form.handleSubmit(submit)();
            }}
          >
            Save changes
          </Button>
          <Button
            size="lg"
            isDisabled={!isDirty}
            onClick={() => {
              form.reset();
            }}
          >
            Discard changes
          </Button>
        </ButtonGroup>
      </ActionBar>
    </FormProvider>
  );
};

const MergeRules: FC = () => {
  const { enableFuzzySearchingIdr } = useFlags();
  const { watch, setValue } = useFormContext<FormState>();
  const { append, remove } = useFieldArray<FormState>({
    name: "merge_rules",
  });
  const mergeRuleSets = watch("merge_rules");

  const hasAnyFuzzyRules =
    enableFuzzySearchingIdr &&
    mergeRuleSets.some((set) =>
      set.conditions.some((c) =>
        c.rules.some((r) => FUZZY_RULE_OPERATORS.includes(r.operator)),
      ),
    );

  const hasAnyFastFuzzyRules =
    enableFuzzySearchingIdr &&
    mergeRuleSets.some((set) =>
      set.conditions.some((c) => c.rules.some((r) => Boolean(r.bucketing))),
    );

  const [isFastFuzzyMatchingEnabled, setIsFastFuzzyMatchingEnabled] =
    useState<boolean>(
      enableFuzzySearchingIdr && (!hasAnyFuzzyRules || hasAnyFastFuzzyRules),
    );

  useEffect(() => {
    if (enableFuzzySearchingIdr && !hasAnyFuzzyRules) {
      // Reset the default if the user removes all fuzzy rules (this is what
      // will happen on page refresh anyway, so match that).
      setIsFastFuzzyMatchingEnabled(true);
    }
  }, [enableFuzzySearchingIdr, hasAnyFuzzyRules]);

  // Make sure the form state's fuzzy match settings are what they should be.
  // Only makes state changes if the form's state is not as it should be, so
  // should at most trigger one additional re-render.
  // Note that 'newValue' is not an optional argument and should never be
  // omitted - only use undefined as a value here intentionally.
  const ensureFuzzyMatchSettings = (newValue: BucketingConfig | undefined) => {
    mergeRuleSets.map((set, set_index) => {
      set.conditions.map((condition, condition_index) => {
        condition.rules.map((rule, rule_index) => {
          if (
            enableFuzzySearchingIdr &&
            FUZZY_RULE_OPERATORS.includes(rule.operator)
          ) {
            if (!isEqual(rule.bucketing, newValue)) {
              setValue(
                `merge_rules.${set_index}.conditions.${condition_index}.rules.${rule_index}.bucketing`,
                newValue,
                {
                  shouldDirty: true,
                },
              );
            }
          } else {
            if (rule.bucketing != undefined) {
              setValue(
                `merge_rules.${set_index}.conditions.${condition_index}.rules.${rule_index}.bucketing`,
                undefined,
                {
                  shouldDirty: true,
                },
              );
            }
          }
        });
      });
    });
  };

  // Do this on every re-render (theoretically tied to changes in the form
  // state) to intercept changes to operators within the editor, and make sure
  // their fuzzy match settings match the graph's current ones. Note that this
  // is also how the toggle button takes effect (see toggleFastFuzzyMatching).
  const currentFuzzySettings: BucketingConfig | undefined =
    enableFuzzySearchingIdr && isFastFuzzyMatchingEnabled
      ? {
          scheme: "first-n-chars",
          config: {
            numChars: 3,
          },
        }
      : undefined;
  ensureFuzzyMatchSettings(currentFuzzySettings);

  // This will trigger the above code to make the form state consistent with the
  // new value for the switch.
  const toggleFastFuzzyMatching = () => {
    setIsFastFuzzyMatchingEnabled((oldValue) => !oldValue);
  };

  // Ensure that all rule sets have an identifier before rendering the reorder group.
  // This backfills any existing merge rules that were created before the waterfalls feature.
  useEffect(() => {
    mergeRuleSets.forEach((ruleSet, index) => {
      if (!ruleSet.identifier) {
        const newIdentifier = uuidv4();
        ruleSet.identifier = newIdentifier;
        setValue(`merge_rules.${index}.identifier`, newIdentifier);
      }
    });
  }, [mergeRuleSets]);

  return (
    <Column align="flex-start" gap={4}>
      <Column>
        <SectionHeading> Merge records when:</SectionHeading>
        <Text>
          Define what identifiers to use to merge profiles and associate events
          with each profile.
        </Text>
      </Column>

      <Column w="100%" gap={4}>
        <MergeRulesReorder
          items={mergeRuleSets}
          onChange={(newRules) => {
            setValue("merge_rules", newRules, { shouldDirty: true });
          }}
        >
          {mergeRuleSets.map((set, index) => (
            <MergeRuleSet
              key={set.identifier}
              mergeRuleIndex={index}
              removeSet={() => {
                remove(index);
              }}
            />
          ))}
        </MergeRulesReorder>
      </Column>

      <Button icon={PlusIcon} onClick={() => append(getDefaultMergeRuleSet())}>
        Rule set
      </Button>

      {hasAnyFuzzyRules && (
        <Row gap={3} align="center">
          <Switch
            isChecked={enableFuzzySearchingIdr && isFastFuzzyMatchingEnabled}
            onChange={enableFuzzySearchingIdr ? toggleFastFuzzyMatching : noop}
          />
          <Text>
            Optimize non-exact matching
            <Tooltip message="Significantly improve runtime by grouping records before applying non-exact matching rules. May reduce match rate.">
              <Text ml={1} size="lg" color="text.secondary">
                <InformationIcon />
              </Text>
            </Tooltip>
          </Text>
        </Row>
      )}
    </Column>
  );
};

const MergeRuleSet: FC<
  Readonly<{ mergeRuleIndex: number; removeSet: () => void }>
> = ({ mergeRuleIndex, removeSet }) => {
  const { watch } = useFormContext<FormState>();
  const { fields, append, remove } = useFieldArray<FormState>({
    name: `merge_rules.${mergeRuleIndex}.conditions`,
  });
  const outerType = watch(`merge_rules.${mergeRuleIndex}.type`);
  const innerType = outerType === "and" ? "or" : "and";

  const mergeRulesLength = watch("merge_rules").length;
  const conditions = watch(`merge_rules.${mergeRuleIndex}.conditions`);

  return (
    <Column gap={4} w="100%">
      <Column gap={4}>
        <Text fontWeight="medium">
          {mergeRuleIndex + 1}
          {ordinalSuffix(mergeRuleIndex + 1)} set
        </Text>

        {fields.map((field, index) => {
          return (
            <MergeRuleGroup
              key={field.id}
              index={index}
              outerType={outerType}
              mergeRuleIndex={mergeRuleIndex}
              removeGroup={() => {
                remove(index);
                // @TODO make sure set removal is working properly
                if (conditions.length === 1 && mergeRulesLength > 1) {
                  removeSet();
                }
              }}
            />
          );
        })}

        <AddIdentifierButton
          type={fields.length ? outerType : defaultOuterType}
          onClick={() =>
            append({ type: innerType, rules: [getDefaultMergeRule()] })
          }
        />
      </Column>
    </Column>
  );
};

type IdentifierButtonProps = {
  /**
   * The type of the button, which will determine the color of the button
   * @default "and"
   */
  type: "and" | "or";
  onClick: () => void;
};

const AddIdentifierButton: FC<Readonly<IdentifierButtonProps>> = ({
  type,
  onClick,
}) => {
  return (
    <Box
      sx={
        type
          ? {
              button: {
                bg: colors.base[type],
                border: "none",
                _hover: {
                  bg: colors.hover[type],
                },
                _active: {
                  bg: colors.hover[type],
                },
                svg: { color: "text.primary" },
              },
            }
          : {}
      }
    >
      <Button icon={PlusIcon} onClick={onClick}>
        Identifier
      </Button>
    </Box>
  );
};

const MergeRuleGroup: FC<{
  index: number;
  outerType: "and" | "or";
  mergeRuleIndex: number;
  removeGroup: () => void;
}> = ({ index, outerType, mergeRuleIndex, removeGroup }) => {
  const { identifiers } = useOutletContext<OutletContext>();
  const { watch, setValue } = useFormContext<FormState>();
  const conditions = watch(`merge_rules.${mergeRuleIndex}.conditions`);
  const rules = watch(
    `merge_rules.${mergeRuleIndex}.conditions.${index}.rules`,
  );
  const innerType = outerType === "and" ? "or" : "and";
  const { fields, remove, append } = useFieldArray<FormState>({
    name: `merge_rules.${mergeRuleIndex}.conditions.${index}.rules`,
  });
  const nested = fields.length > 1;

  const ungroup = () => {
    const newConditions = conditions.filter((_, i) => i !== index);
    rules.forEach((rule) => {
      newConditions.push({
        type: innerType,
        rules: [rule],
      });
    });
    setValue(`merge_rules.${mergeRuleIndex}.conditions`, newConditions, {
      shouldDirty: true,
    });
  };

  const toggleTypes = () => {
    setValue(`merge_rules.${mergeRuleIndex}.type`, innerType, {
      shouldDirty: true,
    });
    conditions.map((_, i) => {
      setValue(
        `merge_rules.${mergeRuleIndex}.conditions.${i}.type`,
        outerType,
        { shouldDirty: true },
      );
    });
  };

  const ruleElements = fields.map((field, nestedIndex) =>
    nested ? (
      <Fragment key={field.id}>
        <Row>
          <GroupIndicatorBar conditionType={innerType as any} />
          <MergeRule
            name={`merge_rules.${mergeRuleIndex}.conditions.${index}.rules.${nestedIndex}`}
            identifiers={identifiers}
            type={innerType}
            remove={() => {
              if (fields.length === 1) {
                removeGroup();
              } else {
                remove(nestedIndex);
              }
            }}
          />
        </Row>
        <Row>
          <AndOrToggleButton
            conditionType={innerType as any}
            onClick={toggleTypes}
          />
          <Box sx={{ button: { color: "text.secondary" } }}>
            <Button variant="tertiary" onClick={ungroup}>
              Ungroup
            </Button>
          </Box>
        </Row>
        {nestedIndex === fields.length - 1 && (
          <AddIdentifierButton
            type={innerType}
            onClick={() => {
              append(getDefaultMergeRule());
            }}
          />
        )}
      </Fragment>
    ) : (
      <MergeRule
        key={field.id}
        name={`merge_rules.${mergeRuleIndex}.conditions.${index}.rules.${nestedIndex}`}
        type={innerType}
        identifiers={identifiers}
        append={() => append(getDefaultMergeRule())}
        remove={() => {
          if (fields.length === 1) {
            removeGroup();
          } else {
            remove(nestedIndex);
          }
        }}
      />
    ),
  );

  return (
    <Column gap={4} width="100%">
      {fields.length === 1 ? (
        <Row width="100%">
          <GroupIndicatorBar conditionType={outerType as any} />
          {ruleElements}
        </Row>
      ) : (
        <Row gap={4} width="100%">
          <GroupIndicatorBar conditionType={outerType as any} />
          <Column gap={4} width="100%">
            {ruleElements}
          </Column>
        </Row>
      )}

      <AndOrToggleButton
        conditionType={outerType as any}
        onClick={toggleTypes}
      />
    </Column>
  );
};

const MergeRule: FC<
  Readonly<{
    append?: () => void;
    remove: () => void;
    type: string;
    name: string;
    identifiers: string[];
  }>
> = ({ append, remove, type, name, identifiers }) => {
  const { enableFuzzySearchingIdr } = useFlags();
  const identifier = useWatch({ name: `${name}.identifier` });
  const { graph } = useOutletContext<OutletContext>();
  const form = useFormContext();

  const operatorName = `${name}.operator`;

  const sourceType = graph.source.type;
  const filteredOperatorOptionsBySource = operatorOptions.filter((operator) => {
    if (!operator.sources) {
      return true;
    }
    return operator.sources.includes(sourceType as unknown as SupportedSource);
  });

  useEffect(() => {
    if (!enableFuzzySearchingIdr) {
      // Auto set the operator
      form.setValue(`${name}.operator`, "eq");
    }
  }, [enableFuzzySearchingIdr]);

  return (
    <Row
      flex={1}
      px={4}
      py={2}
      align="center"
      gap={4}
      bg="white"
      border="1px"
      borderLeft="none"
      borderColor="base.border"
      borderTopRightRadius="md"
      borderBottomRightRadius="md"
    >
      <Row align="start" justify="space-between" gap={4} flex={1}>
        <Row align="center" gap={enableFuzzySearchingIdr ? 4 : 2} wrap="wrap">
          <Row align="center" gap={enableFuzzySearchingIdr ? 4 : 2}>
            <Controller
              name={`${name}.identifier`}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  isInvalid={Boolean(error)}
                  placeholder="Identifier..."
                  width="auto"
                  variant="heavy"
                  options={identifiers}
                  optionValue={(o) => o}
                  optionLabel={(o) => o}
                />
              )}
            />

            <Text color="text.secondary">
              {enableFuzzySearchingIdr ? "is matching" : "matches exactly"}
            </Text>
          </Row>

          {enableFuzzySearchingIdr && (
            <Controller
              name={operatorName}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  isInvalid={Boolean(error)}
                  // Decreasing width to 4xs or setting width to auto
                  // will cause a react virtual bug and crash the page when clicked
                  // https://carryinternal.slack.com/archives/C05LP5ND10U/p1710345621776769
                  width="3xs"
                  variant="heavy"
                  options={filteredOperatorOptionsBySource}
                />
              )}
            />
          )}

          {identifier && (
            <Controller
              name={`${name}.transformations`}
              render={({ field }) => {
                const identifierTransformationOptions =
                  transformationOptionsByIdentifier[identifier];
                if (identifierTransformationOptions?.length === 0) {
                  return <></>;
                }
                return (
                  <>
                    {field.value?.length > 0 && (
                      <Text
                        color="text.secondary"
                        ml={enableFuzzySearchingIdr ? undefined : -0.5}
                      >
                        and
                      </Text>
                    )}
                    <MultiSelect
                      {...field}
                      width="auto"
                      variant="heavy"
                      options={
                        identifierTransformationOptions ??
                        allTransformationOptions
                      }
                      placeholder="+ option"
                    />
                  </>
                );
              }}
            />
          )}
        </Row>

        <Row align="center">
          {append && (
            <Menu>
              <MenuActionsButton />
              <MenuList>
                <Box
                  as={MenuItem}
                  color="text.secondary"
                  icon={DescriptionAddIcon}
                  sx={{
                    svg: {
                      height: "24px",
                      width: "24px",
                    },
                  }}
                  onClick={append}
                >
                  <Column>
                    <Text fontWeight="medium">Add group</Text>
                    <Text color="text.secondary">
                      Create a nested {type} group from this filter
                    </Text>
                  </Column>
                </Box>
              </MenuList>
            </Menu>
          )}
          <Box sx={{ svg: { color: "danger.base" } }}>
            <IconButton
              aria-label="Remove"
              icon={DeleteIcon}
              variant="tertiary"
              onClick={remove}
            />
          </Box>
        </Row>
      </Row>
    </Row>
  );
};

const BlockRules: FC = () => {
  const { identifiers } = useOutletContext<OutletContext>();
  const { fields, append, remove } = useFieldArray<FormState>({
    name: "block_rules",
  });

  return (
    <Column align="flex-start" gap={4}>
      <Column>
        <SectionHeading>Limit merging when:</SectionHeading>
        <Text>
          Define the limits of allowed identifiers per profile record. If these
          limits are exceeded, these merges will be flagged as conflicts, to be
          resolved in the next step.
        </Text>
      </Column>
      {fields.map((field, index) => {
        return (
          <Row
            key={field.id}
            gap={4}
            align="center"
            px={4}
            py={2}
            border="1px"
            borderColor="base.border"
            borderRadius="md"
            bg="white"
            width="100%"
            justify="space-between"
          >
            <Row align="center" gap={2}>
              <Text color="text.secondary">Limit of</Text>
              <Controller
                name={`block_rules.${index}.limit`}
                render={({ field, fieldState: { error } }) => (
                  <Box width="50px">
                    <NumberInput {...field} isInvalid={Boolean(error)} />
                  </Box>
                )}
              />
              <Controller
                name={`block_rules.${index}.identifier`}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    placeholder="Identifier..."
                    width="4xs"
                    variant="heavy"
                    isInvalid={Boolean(error)}
                    options={identifiers}
                    optionValue={(o) => o}
                    optionLabel={(o) => o}
                  />
                )}
              />
              <Text color="text.secondary">per record</Text>
            </Row>
            <Box sx={{ svg: { color: "danger.base" } }}>
              <IconButton
                aria-label="Remove"
                icon={DeleteIcon}
                variant="tertiary"
                onClick={() => {
                  remove(index);
                }}
              />
            </Box>
          </Row>
        );
      })}
      <Button
        icon={PlusIcon}
        onClick={() => {
          append(getDefaultBlockRule());
        }}
      >
        Limit
      </Button>
    </Column>
  );
};
