import { FC, ReactNode } from "react";

import {
  Box,
  ChevronRightIcon,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  Pill,
  Text,
} from "@hightouchio/ui";
import noop from "lodash/noop";

interface Props {
  children: ReactNode;
  label: string;
  labelFontSize?: "sm" | "md" | "lg";
  count?: number;
  onChange?: () => void;
}

export const AccordionSection: FC<Readonly<Props>> = ({
  children,
  label,
  labelFontSize = "md",
  count,
  onChange = noop,
}) => (
  <ChakraAccordion allowToggle onChange={onChange}>
    <ChakraAccordionItem border="none">
      {({ isExpanded }) => {
        return (
          <>
            <ChakraAccordionButton
              cursor="pointer"
              outline="none !important"
              pl={2}
              borderRadius="md"
            >
              <Box
                as={ChevronRightIcon}
                fontSize="18px"
                transform={isExpanded ? "rotate(90deg)" : ""}
                transition="transform 150ms ease-in-out"
              />
              <Text ml={2} size={labelFontSize} fontWeight="medium">
                {label}
              </Text>
              {count && <Pill ml={2}>{count}</Pill>}
            </ChakraAccordionButton>
            <ChakraAccordionPanel>{children}</ChakraAccordionPanel>
          </>
        );
      }}
    </ChakraAccordionItem>
  </ChakraAccordion>
);
