import { createContext, FC, ReactNode, useMemo } from "react";

import { useParams } from "src/router";
import { isPresent } from "ts-extras";

import { ColumnOption } from "src/components/explore/visual/utils";
import { Form } from "src/components/form";
import { ChartDefinition, useSavedAnalyticsChartQuery } from "src/graphql";
import { chartTypeToGraphType } from "src/pages/copilot/constants";
import { PageSpinner } from "src/components/loading";

import { AnalyticsContextType, GroupByColumn } from "src/pages/analytics/types";
import { defaultActions, defaultState, TimeOptions } from "./constants";
import { useAnalyticsState } from "./use-analytics-state";
import { useUrlState } from "./use-url-state";

type Props = {
  children: ReactNode;
  columns?: ColumnOption[];
};

const defaultContextValue: AnalyticsContextType = {
  ...defaultState,
  ...defaultActions,
};

export const AnalyticsContext =
  createContext<AnalyticsContextType>(defaultContextValue);

export const AnalyticsUrlProvider: FC<Readonly<Props>> = ({ children }) => {
  // use url state for dynamic charts
  const urlValues = useUrlState();

  return (
    <AnalyticsContext.Provider value={urlValues}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsFormStateProvider: FC<Readonly<Props>> = ({
  children,
}) => {
  const { id } = useParams<{ id: string }>();

  const savedAnalyticsChartQuery = useSavedAnalyticsChartQuery(
    { id: id ?? "" },
    {
      enabled: Boolean(id),
      select: (data) => data.saved_analytics_charts_by_pk,
    },
  );

  const savedChart = savedAnalyticsChartQuery.data;

  // use local state for saved charts
  const { form, value } = useAnalyticsState({
    chart: savedChart
      ? {
          name: savedChart.name,
          description: savedChart.description,
          frequency: savedChart.frequency,
          chartType: savedChart.chart_type,
          lookbackWindow: savedChart.lookback_window,
          groupBy: savedChart.group_by,
          parentModelId: savedChart.parent_model?.id ?? "",
          goals: savedChart.goals,
          cohorts: savedChart.cohorts,
          funnel_stages: savedChart.funnel_stages,
        }
      : null,
  });

  return (
    <AnalyticsContext.Provider value={value}>
      {savedAnalyticsChartQuery.isLoading ? (
        <PageSpinner />
      ) : (
        <Form form={form}>{children}</Form>
      )}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsCopilotProvider: FC<
  Readonly<{ chartDefinition: ChartDefinition; children: ReactNode }>
> = ({ chartDefinition, children }) => {
  const { parentModelId } = chartDefinition;

  const goals = useMemo(
    () => [
      ...(chartDefinition.chartVariables.metricIds ?? []).map((id) => ({
        goal: { id },
      })),
      ...chartDefinition.chartVariables.metricDefinitions.map((definition) => ({
        goal_definition: {
          id: definition?.config?.relationshipId,
          ...definition,
        },
      })),
    ],
    [
      chartDefinition.chartVariables.metricIds,
      chartDefinition.chartVariables.metricDefinitions,
    ],
  );

  const cohorts = useMemo(
    () => [
      ...(chartDefinition.chartVariables.cohortIds ?? []).map(({ id }) => ({
        cohort: { id },
      })),
      ...chartDefinition.chartVariables.cohortDefinitions.map((definition) => ({
        cohort_definition: definition,
      })),
    ],
    [
      chartDefinition.chartVariables.cohortIds,
      chartDefinition.chartVariables.cohortDefinitions,
    ],
  );

  const groupBy = useMemo(
    () =>
      chartDefinition.chartVariables.groupByColumns
        .map((column: GroupByColumn) => {
          if (column.type === "raw") {
            return {
              ...column,
              // Backend returns strings here
              modelId: Number(column.modelId),
            };
          }

          if (column.type === "related" && column.column.type === "raw") {
            return {
              ...column,
              column: {
                ...column.column,
                // Backend returns strings here
                modelId: Number(column.column.modelId),
              },
            };
          }

          return null;
        })
        .filter(isPresent),
    [chartDefinition.chartVariables.groupByColumns],
  );

  const lookbackWindow = useMemo(
    () => ({
      lookback:
        chartDefinition.chartVariables.lookbackWindow ?? TimeOptions.SevenDays,
    }),
    [chartDefinition.chartVariables.lookbackWindow],
  );

  const { form, value } = useAnalyticsState({
    chart: {
      name: chartDefinition.chartName,
      description: null,
      frequency: chartDefinition.chartVariables.frequency,
      chartType: chartTypeToGraphType[chartDefinition.chartType] as string,
      lookbackWindow,
      groupBy,
      parentModelId,
      goals,
      cohorts,
      funnel_stages: [],
    },
  });

  return (
    <AnalyticsContext.Provider value={value}>
      <Form form={form}>{children}</Form>
    </AnalyticsContext.Provider>
  );
};
