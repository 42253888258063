import { useEffect, useMemo, useState, FC, useCallback } from "react";

import {
  Column,
  Row,
  Text,
  Button,
  Badge,
  SparkleIcon,
  Dialog,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "lodash";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { Query } from "src/components/models/query";
import { ResourceSelect } from "src/components/resource-select";
import { useDraft } from "src/contexts/draft-context";
import {
  ModelsAndAudiencesQuery,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useModelsAndAudiencesQuery,
  useSubsetAccessForUserQuery,
} from "src/graphql";
import { QueryType } from "src/types/models";
import { TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { QueryTypeBadge } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";
import { useUser } from "src/contexts/user-context";

enum SortKeys {
  Name = "name",
  UpdatedAt = "updated_at",
  QueryType = "query_type",
}

type Props = {
  onSelect: (model: any) => void;
  filter?: SegmentsBoolExp[];
  isOptionAvailable?: (id: string) => boolean;
};

export const ModelSelect: FC<Readonly<Props>> = ({
  onSelect,
  filter = [
    {
      is_schema: { _eq: false },
    },
  ],
  isOptionAvailable,
}) => {
  const [search, setSearch] = useState("");
  const [previewModel, setPreviewModel] =
    useState<ModelsAndAudiencesQuery["segments"][0]>();
  const { setEditingDraft } = useDraft();

  const { user } = useUser();
  const { appDisableSyncCreationForAudiences, appSubsetsEnabled } = useFlags();

  const { limit, offset, orderBy, page, setPage, onSort } =
    useTableConfig<SegmentsOrderBy>({
      defaultSortKey: "updated_at",
      limit: 200,
      sortOptions: Object.values(SortKeys),
    });

  const filters: SegmentsBoolExp = useMemo(() => {
    const newFilters: SegmentsBoolExp = {
      _and: [...filter],
    };
    if (search) {
      newFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }
    return newFilters;
  }, [search, filter]);

  const {
    data,
    error,
    isFetching: loading,
  } = useModelsAndAudiencesQuery(
    {
      offset,
      limit,
      filters,
      orderBy,
    },
    { keepPreviousData: true },
  );

  // Check which subsets the user has access to
  const { data: subsetGrants, isLoading: subsetGrantsLoading } =
    useSubsetAccessForUserQuery(
      {
        userId: String(user?.id) ?? "",
      },
      {
        enabled: Boolean(
          appSubsetsEnabled && user?.id && !user?.permissions_v2_enabled,
        ),
        keepPreviousData: true,
        select: (data) =>
          data.audience_subset_grants.map((g) => g.subset_value_id),
      },
    );

  // If a user doesn't have access to ALL subsets in an audience, disable the row
  const hasAccessToSubsets = useCallback(
    (modelOrAudience: ModelsAndAudiencesQuery["segments"][0]) => {
      if (!appSubsetsEnabled || user?.permissions_v2_enabled) {
        return true;
      }

      if (!subsetGrants) {
        return false;
      }

      if (modelOrAudience.subsets?.length) {
        return modelOrAudience.subsets.every((s) =>
          subsetGrants.includes(s.subset_value_id),
        );
      }

      return true;
    },
    [subsetGrants],
  );

  const models = data?.segments.filter((model) => {
    if (appDisableSyncCreationForAudiences) {
      return !model.visual_query_filter;
    }

    return true;
  });
  const count = data?.segments_aggregate?.aggregate?.count;

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({
          name,
          connection: source,
          query_type,
          query_runs,
          matchboosting_enabled,
          parent,
        }) => {
          return (
            <Row gap={3} overflow="hidden" width="100%" align="center">
              <IntegrationIcon
                src={source?.definition?.icon}
                name={source?.definition?.name}
              />
              <Column gap={1} overflow="hidden">
                <Text isTruncated fontWeight="medium">
                  {name || "Private model"}
                </Text>
                <Row gap={2}>
                  <QueryTypeBadge type={query_type}>
                    {query_runs?.[0]
                      ? `${abbreviateNumber(query_runs?.[0]?.size)} rows`
                      : "Unknown size"}
                  </QueryTypeBadge>
                  {(matchboosting_enabled || parent?.matchboosting_enabled) && (
                    <Badge size="sm" icon={SparkleIcon} iconColor="warning.400">
                      Boosted
                    </Badge>
                  )}
                </Row>
              </Column>
            </Row>
          );
        },
      },
      {
        name: "Labels",
        max: ".5fr",
        cell: ({ tags }) => {
          if (isEmpty(tags)) {
            return "--";
          }
          return <Labels labels={tags} zIndex={undefined} />;
        },
        breakpoint: "md",
      },
      {
        ...LastUpdatedColumn,
        max: "max-content",
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
        breakpoint: "md",
      },
      {
        name: "",
        max: "max-content",
        cell: (model) =>
          model.query_type !== QueryType.Visual ? (
            <Button
              size="sm"
              onClick={(event) => {
                event.stopPropagation();
                setPreviewModel(model);
              }}
            >
              View query
            </Button>
          ) : null,
      },
    ],
    [models],
  );

  useEffect(() => {
    setEditingDraft(true);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <ResourceSelect
        columns={columns}
        data={models}
        error={Boolean(error)}
        label="model"
        loading={loading || (appSubsetsEnabled ? subsetGrantsLoading : false)}
        disabled={(row) =>
          !hasAccessToSubsets(row) ||
          Boolean(isOptionAvailable && !isOptionAvailable(row.id))
        }
        pagination={{ count, page, setPage, limit }}
        search={search}
        onSearch={setSearch}
        onSelect={onSelect}
      />
      <Dialog
        isOpen={Boolean(previewModel)}
        variant="info"
        width="xl"
        title={previewModel?.name ?? "Query"}
        actions={
          <Button onClick={() => setPreviewModel(undefined)}>Close</Button>
        }
        onClose={() => setPreviewModel(undefined)}
      >
        <Query
          model={previewModel ?? null}
          source={previewModel?.connection ?? null}
        />
      </Dialog>
    </>
  );
};
