import {
  FormulaTraitConfig,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types/trait-definitions";
import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { TraitQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { useModelRun } from "src/utils/models";

export const usePreviewTrait = ({
  parentModel,
  trait,
}: {
  parentModel: NonNullable<TraitQuery["trait_definitions_by_pk"]>["config"];
  trait: {
    type: TraitType;
    config: TraitConfig;
    relationshipId?: string | null;
  };
}) => {
  const { toast } = useToast();

  const { runQuery, columns, rows, loading, error } = useModelRun(
    {
      query_type: QueryType.Visual,
      visual_query_parent_id: parentModel?.id,
      connection: parentModel?.connection,
      visual_query_filter: {
        conditions: [],
        additionalColumns: [
          {
            alias: "trait_preview",
            column:
              trait.type !== TraitType.Formula && trait.relationshipId
                ? {
                    type: "related",
                    column: {
                      type: "inline_trait",
                      traitType: trait.type,
                      traitConfig: trait.config,
                      conditions: [],
                      relationshipId: trait.relationshipId,
                    },
                    path: [trait.relationshipId],
                  }
                : {
                    type: "transformed",
                    column: {
                      type: "inline_trait",
                      // Formula traits do not have relationship ids
                      traitType: trait.type as TraitType.Formula,
                      traitConfig: trait.config as FormulaTraitConfig,
                    },
                  },
          },
        ],
      },
    },
    {
      columns: parentModel?.columns,
      useDefaultSubsets: true,
      includeTraitDependencies: true,
      onCompleted: (_data, error) => {
        if (error) {
          toast({
            id: "trait-preview",
            title: "Trait preview failed",
            variant: "error",
          });

          Sentry.captureException(error);
          return;
        }
      },
    },
  );

  const previewTrait = async () => {
    await runQuery({ limit: true, disableRowCounter: true });
  };

  return {
    previewTrait,
    isLoading: loading,
    data: { rows, columns },
    error,
  };
};
