import { useEffect } from "react";

import { HtEventsBrowser } from "@ht-sdks/events-sdk-js-browser";
import { useParams } from "src/router";
import { v4 as uuidv4 } from "uuid";

const HT_WRITE_KEY =
  import.meta.env.VITE_HT_EVENTS_WRITE_KEY?.toString() ??
  "cd86aa44fb2a6d18f759a8281a43e6b97a6e3bd71fa371fe96facd534f68135e";
const HT_EVENTS_API = "us-east-1.hightouch-events.com";
const htevents = new HtEventsBrowser();
htevents.load(
  { writeKey: HT_WRITE_KEY },
  { apiHost: HT_EVENTS_API, batching: false },
);

type Data = {
  [key in string]?:
    | string
    | number
    | boolean
    | undefined
    | null
    | Data
    | Data[];
};

export const track = (event: string, properties: Data = {}) => {
  const context = { migrationId: uuidv4() };
  htevents.track(event, properties, context);
};

export const identify = (userId: string, traits: Data) => {
  const context = { migrationId: uuidv4() };
  htevents.identify(userId, traits, context);

  const { email, ...properties } = traits;
  if (window.ko && email) {
    const userProperties = { ...properties, userId };
    window.ko.identify(email, userProperties);
  }
};

export const group = (groupId: string) => {
  const context = { migrationId: uuidv4() };
  htevents.group(groupId, context);
};

export const TrackView = ({ name }: { name: string }) => {
  const params = useParams();

  useEffect(() => {
    // send data to analytics when route renders
    const { id, ...otherParams } = params ?? {};

    const dataToSend = {
      ...otherParams,
    };

    // 'id' is not an allowed key for analytics
    if (id) {
      dataToSend["resource_id"] = id;
    }

    track(`${name} Viewed`, dataToSend);
  }, []);

  return null;
};
