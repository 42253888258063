import { FC, useState } from "react";

import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import capitalize from "lodash/capitalize";
import { useNavigate } from "src/router";

import { TraitQuery, useDeleteTraitMutation } from "src/graphql";
import { PermissionedButton } from "src/components/permission";

type Props = {
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>;
};

export const DeleteTrait: FC<Readonly<Props>> = ({ trait }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const deleteTrait = useDeleteTraitMutation();

  const traitType = trait.is_template ? "template" : "trait";

  const handleDeleteTrait = async () => {
    try {
      await deleteTrait.mutateAsync({ id: trait.id });
      navigate("/traits");

      toast({
        id: "delete-trait",
        title: `${capitalize(traitType)} was deleted successfully`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "delete-trait",
        title: `${capitalize(traitType)} failed to be deleted`,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <PermissionedButton
        permission={{
          v1: { resource: "audience_schema", grant: "delete" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: trait.parent_model.id,
          },
        }}
        variant="warning"
        onClick={() => setShowConfirmation(true)}
      >
        Delete
      </PermissionedButton>

      <ConfirmationDialog
        isOpen={showConfirmation}
        confirmButtonText="Delete"
        title={`Delete ${traitType}: ${trait.name}`}
        variant="danger"
        onCancel={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDeleteTrait}
      >
        <Paragraph>
          Deletion of a trait is not reversible, you will not be able to restore
          it afterwards.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
