import { FC } from "react";

import { Text, Code, Paragraph } from "@hightouchio/ui";
import { Link } from "src/router";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";

interface Props extends ReactMarkdownOptions {
  disableParagraphs?: boolean;
  useParagraphMargins?: boolean;
}

export const Markdown: FC<Readonly<Props>> = ({
  disableParagraphs = false,
  useParagraphMargins = false,
  children,
  ...props
}) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ children }) => {
          if (disableParagraphs) {
            return <>{children}</>;
          }
          return (
            <Paragraph my={useParagraphMargins ? 4 : 0}>{children}</Paragraph>
          );
        },
        strong: ({ children }) => {
          return <Text fontWeight="semibold">{children}</Text>;
        },
        a: ({ href, children }) => (
          <Link href={href ?? "#"} color="var(--chakra-colors-link-default)">
            {children}
          </Link>
        ),
        code: ({ children }) => {
          return <Code>{children}</Code>;
        },
      }}
      {...props}
    >
      {children}
    </ReactMarkdown>
  );
};
