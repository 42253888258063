import { Button, Column, Combobox, Dialog, FormField } from "@hightouchio/ui";
import { useNavigate } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import * as yup from "yup";

import { Form, SaveButton, useHightouchForm } from "src/components/form";
import {
  useCreateDecisionEngineMutation,
  useMinimalParentModelsQuery,
} from "src/graphql";
import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";

const schema = yup.object().shape({
  segment_id: yup.string().required("Parent model is required"),
});

export const CreateDecisionEngine = () => {
  const navigate = useNavigate();

  const mutation = useCreateDecisionEngineMutation();

  const parentQuery = useMinimalParentModelsQuery(undefined, {
    select: (data) => data.segments,
  });

  const form = useHightouchForm({
    resolver: yupResolver(schema),
    onSubmit: async ({ segment_id }) => {
      await mutation.mutateAsync({
        input: {
          name: "Default",
          segment_id: String(segment_id),
          enabled: true,
          status: DecisionEngineStatus.PENDING,
        },
      });
      navigate(`/ai/flows`);
    },
    defaultValues: {
      segment_id: "",
    },
  });

  const close = () => {
    navigate("/ai");
  };

  return (
    <Form form={form}>
      <Dialog
        isOpen
        title="Setup"
        variant="form"
        actions={
          <>
            <Button onClick={close}>Cancel</Button>
            <SaveButton size="md">Save</SaveButton>
          </>
        }
        onClose={close}
      >
        <Column gap={4}>
          <Controller
            name="segment_id"
            render={({ field, fieldState: { error } }) => (
              <FormField
                label="Parent model"
                description="AI Decisioning is based off a parent model that defines the customers you want to target."
                error={error?.message}
              >
                <Combobox
                  {...field}
                  isInvalid={Boolean(error)}
                  isLoading={parentQuery.isLoading}
                  options={parentQuery.data ?? []}
                  placeholder="Select a parent model..."
                  optionLabel={(option) => option.name}
                  optionValue={(option) => option.id}
                />
              </FormField>
            )}
          />
        </Column>
      </Dialog>
    </Form>
  );
};
