import type { ColumnType } from "../query/visual/types";
import type { RawSchemaName } from "../sync/planner/in-warehouse/adapter-types";

export type OrderByDirection = "asc" | "desc";
export type ModelType = "profile" | "event";
export type Operator =
  | "eq"
  | "fuzzy"
  | "soundex"
  | "levenstein_90"
  | "levenstein_70"
  | "jaro_winkler_90"
  | "jaro_winkler_70";
export type Transformation =
  | "case-insensitive"
  | "normalize-whitespace"
  | "extract-number";
export type BucketingScheme = "first-3-chars" | "first-n-chars";

// List of merge rule types that are considered "fuzzy". We use this to separate
// rules, since we evaluate fuzzy ones differently.
export const FUZZY_RULE_OPERATORS = [
  "levenstein_90",
  "levenstein_70",
  "jaro_winkler_90",
  "jaro_winkler_70",
];

export interface MappedColumn {
  identifier: string;
  columnType: ColumnType;
  columnName: string;
  modelSlug: string;
}

export interface BucketingConfig {
  scheme: BucketingScheme;
  config?: {
    numChars: number;
  };
}

export interface MergeRule {
  identifier: string;
  operator: Operator;
  transformations?: Transformation[];
  bucketing?: BucketingConfig;
}

export interface MergeRuleCondition {
  type: "and" | "or";
  rules: MergeRule[];
}

export interface MergeRules {
  type: "and" | "or";
  conditions: MergeRuleCondition[];
}

export interface BlockRule {
  identifier: string;
  limit: number;
}

export interface Model {
  query: string;
  slug: string;
  id: string;
  columns: string[];
  pk: string;
  orderByColumn: string;
  orderByDirection: OrderByDirection;
  orderByColumnType: ColumnType;
  modelType: ModelType;
}

export interface StateHashInput {
  outputTable: string;
  outputSchema: RawSchemaName;
  mappedColumns: MappedColumn[];
  models: Model[];
  mergeRules: MergeRules[];
  blockRules: BlockRule[];
}

export type IDRInput = StateHashInput & {
  isIncremental: boolean;
  idrRunId: string;
  idrProjectId: string;
  checkpoint: CheckpointContext;
};

export interface CheckpointContext {
  checkpoint: number;
  maxHtIdInEdges?: number;
  subcheckpoint?: number;
  iteration?: number;
  // The last 2 properties are not really part of the checkpoint itself. Instead, they are used to
  // verify that the checkpoint configuration hasn't changed underneatch us.
  configVersion?: number;
  materializedSteps?:
    | {
        checkpoint: number;
        subcheckpoint: string;
        numIterations: number;
      }[]
    | undefined;
}

// snake_case for stats keys because we return the stats transparently to the GQL client.
export interface Stats {
  profiles: StatsForModelType;
  events: StatsForModelType;
  num_ht_ids: number;
}

// Statistics for a given model type, e.g. events or profiles
export interface StatsForModelType {
  new: number;
  num_ht_ids: number;
  source_rows: number;
  models: ModelStats[];
}

// Statistics for a specific model
export interface ModelStats {
  id: string;
  source_rows: number;
  num_ht_ids: number;
}
