export interface HightouchRegionConfig {
  friendlyName: string;
  apiEndpoint: string;
  ipAddresses: string[];
  events: {
    enabled: boolean;
    endpoint: string;
  };
  tunnels: {
    enabled: boolean;
    reverseTunnelAddress: string;
    useCentralReverseSSHD: boolean;
  };
}

export enum HightouchRegionOptions {
  AWS_US_EAST_1 = "aws-us-east-1",
  AWS_EU_WEST_1 = "aws-eu-west-1",
  GCP_US_EAST_4 = "gcp-us-east4",
  AWS_AP_SOUTH_1 = "aws-ap-south-1",
}

export const HightouchRegions: Record<string, HightouchRegionConfig> = {
  [HightouchRegionOptions.AWS_US_EAST_1]: {
    friendlyName: "United States (aws-us-east-1)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_AWS_US_EAST_1 as string,
    ipAddresses: [
      "54.196.30.169",
      "52.72.201.213",
      "18.213.226.96",
      "3.224.126.197",
      "3.217.26.199",
    ],
    events: {
      enabled: true,
      endpoint: "us-east-1.hightouch-events.com",
    },
    tunnels: {
      enabled: true,
      reverseTunnelAddress: "tunnel.aws-us-east-1.hightouch.com",
      useCentralReverseSSHD: true,
    },
  },

  [HightouchRegionOptions.AWS_EU_WEST_1]: {
    friendlyName: "European Union (aws-eu-west-1)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_AWS_EU_WEST_1 as string,
    ipAddresses: ["34.255.226.212", "52.16.84.199", "52.211.114.138"],
    events: {
      enabled: true,
      endpoint: "eu-west-1.hightouch-events.com",
    },
    tunnels: {
      enabled: true,
      reverseTunnelAddress: "tunnel.aws-eu-west-1.hightouch.com",
      useCentralReverseSSHD: true,
    },
  },

  [HightouchRegionOptions.GCP_US_EAST_4]: {
    friendlyName: "United States (gcp-us-east4)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_GCP_US_EAST4 as string,
    ipAddresses: ["34.145.155.148", "35.245.150.71", "35.245.58.91"],
    events: {
      enabled: false,
      endpoint: "us-east4.hightouch-events.com",
    },
    tunnels: {
      enabled: true,
      reverseTunnelAddress: "tunnel.gcp-us-east4.production.hightouch.com",
      useCentralReverseSSHD: true,
    },
  },

  [HightouchRegionOptions.AWS_AP_SOUTH_1]: {
    friendlyName: "Asia Pacific (aws-ap-south-1)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_AWS_AP_SOUTH_1 as string,
    ipAddresses: ["13.126.190.7", "65.1.90.146"],
    events: {
      enabled: true,
      endpoint: "ap-south-1.hightouch-events.com",
    },
    tunnels: {
      enabled: true,
      reverseTunnelAddress: "tunnel.aws-ap-south-1.hightouch.com",
      useCentralReverseSSHD: true,
    },
  },
};

export function getHightouchRegionConfig(
  region: string,
): HightouchRegionConfig {
  const regionConfig = HightouchRegions[region];
  if (!regionConfig) {
    throw new Error("Invalid Hightouch region: " + region);
  }
  return regionConfig;
}
