import { FC, useState, Fragment, useEffect } from "react";

import {
  Text,
  Column,
  Combobox,
  Heading,
  Radio,
  RadioGroup,
  Row,
  SectionHeading,
  Spinner,
  FormField,
  Box,
  ArrowRightIcon,
  useToast,
  CloseIcon,
  IconButton,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { merge, uniq } from "lodash";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useNavigate, useOutletContext } from "src/router";
import * as yup from "yup";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { ModelSelect } from "src/components/models/model-select";
import {
  useCreateIdentityResolutionModelMutation,
  useModelQuery,
} from "src/graphql";
import { FieldError } from "src/components/field-error";
import { Wizard, Step } from "src/components/wizard";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { OutletContext } from ".";
import { defaultIdentifiers } from "../types";

type FormState = {
  type: "profile" | "event";
  order_by: { column: string; order: "asc" | "desc" };
  mappings: { column: string; identifier: string }[];
  identifiers: string[];
};

export const CreateIdentityResolutionModel: FC = () => {
  const { graph, identifiers } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [selectedModelId, setSelectedModelId] = useState<string>("");
  const [step, setStep] = useWizardStepper(0);
  const navigate = useNavigate();

  const form = useForm<FormState>({
    defaultValues: {
      identifiers: uniq([...defaultIdentifiers, ...identifiers]),
      type: "profile",
      order_by: { column: "", order: "asc" },
      mappings: [{ column: "", identifier: "" }],
    },
    resolver: validationResolver,
  });

  const type = form.watch("type");

  const { data: model } = useModelQuery(
    {
      id: selectedModelId,
    },
    {
      enabled: Boolean(selectedModelId),
      select: (data) => data.segments_by_pk,
    },
  );

  const createMutation = useCreateIdentityResolutionModelMutation();

  const submit: SubmitHandler<FormState> = async (data) => {
    try {
      await createMutation.mutateAsync({
        input: {
          type: data.type,
          order_by: data.type === "event" ? data.order_by : null,
          mappings: {
            data: data.mappings.map((mapping) => ({
              column: mapping.column,
              identifier: mapping.identifier,
              model_id: String(selectedModelId),
            })),
          },
          rank: graph.models.length + 1,
          idr_id: graph.id,
          segment_id: String(selectedModelId),
        },
      });

      toast({
        id: "create-idr-model",
        title: `Identity model "${model?.name}" was created`,
        variant: "success",
      });

      navigate(-1);
    } catch (error) {
      captureException(error);
      toast({
        id: "create-idr-model-error",
        title: `There was a problem creating your identity model.`,
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Select model",
      continue: "Click on a model to continue",
      header: <Heading>Select a model</Heading>,
      render: () => (
        <ModelSelect
          onSelect={(selection) => {
            setSelectedModelId(selection.id);
            setStep((step) => step + 1);
          }}
          filter={[
            { visual_query_parent_id: { _is_null: true } },
            { is_schema: { _eq: false } },
            { connection_id: { _eq: graph.source.id } },
            { id: { _nin: graph.models.map(({ model }) => model.id) } },
          ]}
        />
      ),
    },
    {
      title: "Configure mappings",
      header: <Heading>Configure mappings</Heading>,
      render: () => {
        if (model) {
          return (
            <Column gap={8} maxW="576px" width="100%">
              <Column gap={4}>
                <SectionHeading>Input model</SectionHeading>
                <Row
                  border="1px"
                  borderColor="base.border"
                  p={4}
                  borderRadius="md"
                  gap={3}
                >
                  <IntegrationIcon
                    src={model.connection?.definition.icon}
                    name={model.connection?.definition.name}
                  />
                  <Text fontWeight="medium">{model.name}</Text>
                </Row>
              </Column>
              <Column gap={4}>
                <SectionHeading>
                  What do records in this model represent?
                </SectionHeading>
                <Controller
                  name="type"
                  render={({ field }) => (
                    <RadioGroup orientation="vertical" {...field}>
                      <Radio
                        label="Profiles"
                        value="profile"
                        description="e.g. users, contacts, accounts"
                      />
                      <Radio
                        label="Events"
                        value="event"
                        description="e.g. transactions, clicks"
                      />
                    </RadioGroup>
                  )}
                />
              </Column>
              {type === "event" ? (
                <OrderByField columns={model.columns} type={type} />
              ) : null}
              <MapperField columns={model.columns} />
            </Column>
          );
        }
        return <Spinner m="auto" size="lg" />;
      },
    },
  ];

  useEffect(() => {
    form.reset();
  }, [selectedModelId]);

  return (
    <FormProvider {...form}>
      <Wizard
        step={step}
        setStep={setStep}
        title="New input model"
        steps={steps}
        onCancel={() => {
          navigate(-1);
        }}
        onSubmit={() => form.handleSubmit(submit)()}
      />
    </FormProvider>
  );
};

const profileValidationSchema = yup.object().shape({
  identifiers: yup.array().of(yup.string()),
  type: yup.string().required(),
  mappings: yup.array().of(
    yup.object().shape({
      column: yup.string(),
      identifier: yup.string(),
    }),
  ),
});

const eventValidationSchema = yup.object().shape({
  identifiers: yup.array().of(yup.string()),
  type: yup.string().required(),
  order_by: yup.object().shape({
    column: yup.string().required("A column is required"),
    order: yup.string().required(),
  }),
  mappings: yup.array().of(
    yup.object().shape({
      column: yup.string(),
      identifier: yup.string(),
    }),
  ),
});

export const validationResolver = async (data, context, options) => {
  const filteredData = {
    ...data,
    mappings: data.mappings.filter((j) => j.column && j.identifier),
  };

  const schema =
    data.type === "event" ? eventValidationSchema : profileValidationSchema;

  const mappingErrors: any = {};

  if (filteredData.mappings.length < 1) {
    mappingErrors.mappings = {
      message: "At least one identifier is required",
    };
  }

  const { values, errors } = await yupResolver(schema)(
    filteredData,
    context,
    options,
  );

  return {
    values: Object.keys(mappingErrors).length > 0 ? {} : values,
    errors: merge(errors, mappingErrors),
  };
};

export const OrderByField: FC<
  Readonly<{
    columns: { name: string; alias: string | null }[];
    type: "profile" | "event";
  }>
> = ({ columns, type }) => {
  return (
    <Column gap={4} width="100%">
      <SectionHeading>
        In what order should the rows in this model be processed?
      </SectionHeading>
      <Row gap={4} width="100%">
        <Controller
          name="order_by.column"
          render={({ field, fieldState: { error } }) => (
            <FormField
              label={type === "event" ? "Timestamp" : "Column"}
              error={error?.message}
            >
              <Combobox
                {...field}
                isInvalid={Boolean(error)}
                width="100%"
                placeholder="Select a column..."
                options={columns}
                optionLabel={(o) => o.alias ?? o.name}
                optionValue={(o) => o.name}
              />
            </FormField>
          )}
        />
        <Controller
          name="order_by.order"
          render={({ field }) => (
            <FormField label="Order">
              <Combobox
                {...field}
                width="100%"
                placeholder="Select an order..."
                options={[
                  { label: "Ascending", value: "asc" },
                  { label: "Descending", value: "desc" },
                ]}
              />
            </FormField>
          )}
        />
      </Row>
    </Column>
  );
};

export const MapperField: FC<
  Readonly<{ columns: { name: string; alias: string | null }[] }>
> = ({ columns }) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormState>();
  const identifiers = watch("identifiers");
  const mappings = watch("mappings");
  const { fields, remove, append } = useFieldArray({ name: "mappings" });

  return (
    <Column gap={4} width="100%">
      <Column>
        <SectionHeading>
          Which identifiers does this model contain?
        </SectionHeading>
        <Text color="text.secondary">
          Assigning identifiers will allow you to create rules to resolve the
          identity for a given row. Create a new identifier by typing in the
          desired name.
        </Text>
      </Column>

      <Box
        display="grid"
        gridTemplateColumns="1fr max-content 1fr max-content"
        gridGap={4}
        width="100%"
        alignItems="start"
      >
        <Text
          color="text.secondary"
          textTransform="uppercase"
          fontWeight="semibold"
        >
          Column name
        </Text>
        <Box />
        <Text
          color="text.secondary"
          textTransform="uppercase"
          fontWeight="semibold"
        >
          Identifier
        </Text>
        <Box />
        {fields.map((field, index) => {
          const shouldAppend = index === fields.length - 1;
          const otherMappings = mappings.filter((_, i) => i !== index);
          const availableColumns = columns.filter(
            (column) =>
              !otherMappings.find((mapping) => mapping.column === column.name),
          );
          const availableIdentifiers = identifiers.filter(
            (identifier) =>
              !otherMappings.find(
                (mapping) => mapping.identifier === identifier,
              ),
          );

          return (
            <Fragment key={`${field.id}-${identifiers.length}`}>
              <Controller
                name={`mappings.${index}.column`}
                render={({ field, fieldState: { error } }) => (
                  <Column>
                    <Combobox
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        if (shouldAppend) {
                          append({ column: "", identifier: "" });
                        }
                      }}
                      width="100%"
                      isInvalid={Boolean(error || errors.mappings)}
                      placeholder="Select a column..."
                      options={availableColumns}
                      emptyOptionsMessage="No columns"
                      optionLabel={(o) => o.alias ?? o.name}
                      optionValue={(o) => o.name}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Row px={0} color="text.placeholder" fontSize="3xl" mt={1}>
                <ArrowRightIcon />
              </Row>
              <Controller
                name={`mappings.${index}.identifier`}
                render={({ field, fieldState: { error } }) => (
                  <Column>
                    <Combobox
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        if (shouldAppend) {
                          append({ column: "", identifier: "" });
                        }
                      }}
                      width="100%"
                      isInvalid={Boolean(error || errors.mappings)}
                      supportsCreatableOptions
                      onCreateOption={(value) => {
                        if (value === "Forbidden") {
                          return;
                        }
                        setValue("identifiers", [...identifiers, value]);
                        field.onChange(value);
                        if (shouldAppend) {
                          append({ column: "", identifier: "" });
                        }
                      }}
                      placeholder="Select or create an identifier..."
                      options={availableIdentifiers}
                      optionLabel={(o) => o}
                      optionValue={(o) => o}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Box
                visibility={
                  fields.length === 1 || index === fields.length - 1
                    ? "hidden"
                    : "visible"
                }
              >
                <IconButton
                  icon={CloseIcon}
                  aria-label="Remove mapping"
                  onClick={() => remove(index)}
                />
              </Box>
            </Fragment>
          );
        })}
        <FieldError error={errors.mappings?.message?.toString()} />
      </Box>
    </Column>
  );
};
