import { Column, FormField, TextInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "src/router";

import { Form, useHightouchForm } from "src/components/form";
import { useCreateDecisionEngineFlowOutcomeMutation } from "src/graphql";
import { WeightField } from "./components/weight-field";
import { WizardDrawer } from "src/components/drawer";
import { CollectionSelect } from "./components/collection-select";
import { EventModelSelect } from "./components/event-model-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as y from "yup";
import { ClassificationField } from "./components/classification-field";
import { OutletContext } from "src/pages/decision-engines";
import { useEffect } from "react";

const schema = y.object().shape({
  name: y.string().required("Name is required"),
  model: y.object(),
  weight: y.object(),
});

export const AddOutcome = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const onClose = () => {
    navigate(`/ai/flows/${flowId}`);
  };

  const mutation = useCreateDecisionEngineFlowOutcomeMutation();

  const form = useHightouchForm({
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      await mutation.mutateAsync({
        input: {
          decision_engine_flow_id: flowId,
          outcome: {
            data: {
              decision_engine_id: engine.id,
              name: data.name,
              segment_id: data.model.id,
              attribution: {},
              weight: data.weight,
            },
          },
        },
      });
      onClose();
    },
    defaultValues: {
      name: "",
      model: {} as { id: string; name: string },
      weight: {
        type: "positive",
        priority: 0.5,
      },
    },
  });

  const { watch } = form;
  const model = watch("model");

  useEffect(() => {
    form.setValue("name", model.name);
  }, [model.name]);

  return (
    <Form form={form}>
      <WizardDrawer
        isOpen
        size="xl"
        steps={[
          {
            label: "Configure",
            isDisabled: !model,
            render: () => {
              return (
                <Column gap={6}>
                  <FormField
                    label="Select an event model"
                    description={`The event must have a relationship to "${engine.segment.name}"`}
                  >
                    <EventModelSelect />
                  </FormField>
                  <FormField
                    label="Select relevant collections"
                    description="Decision engine will use relationships from the event to the collection catalog for per-item attribution. If no relationship exists we will attribute to all the items that were recommended."
                  >
                    <CollectionSelect />
                  </FormField>
                </Column>
              );
            },
          },
          {
            label: "Impact",
            render: () => {
              return (
                <Column gap={6} height="100%">
                  <ClassificationField />
                  <WeightField modelId={model?.id} />
                </Column>
              );
            },
          },
          {
            label: "Finalize",
            continueLabel: "Add outcome",
            render: () => {
              return (
                <Controller
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <FormField label="Name" error={error?.message}>
                      <TextInput
                        defaultValue={model.name}
                        isInvalid={Boolean(error)}
                        {...field}
                      />
                    </FormField>
                  )}
                />
              );
            },
          },
        ]}
        title="Add outcome"
        onClose={onClose}
        onSubmit={form.submit}
      />
    </Form>
  );
};
