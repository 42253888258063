import { useEffect } from "react";

import immutableUpdate from "immutability-helper";

import { toSingleCondition } from "src/components/audiences/utils";
import { getModelIdFromColumn } from "src/components/explore/visual/utils";
import {
  useAudiencesAndMetricsForParentModelQuery,
  useParentModelAndRelationshipsQuery,
} from "src/graphql";
import { AggregationOption } from "src/pages/metrics/constants";
import {
  AnalyticsFrequency,
  AndOrCondition,
  PropertyCondition,
  exhaustiveCheck,
} from "src/types/visual";
import { useEncodedSearchParams } from "src/hooks/use-encoded-search-params";

import { mapAggregationConfigurationToConfigurationOption } from "../../metrics/utils";
import {
  AnalyticsContextType,
  ConversionCriteriaData,
  ConversionWindow,
  FunnelStep,
  GraphType,
  GroupByColumn,
  MeasurementType,
  Metric,
  MetricSelection,
  SelectedAudience,
  URLData,
} from "../types";
import { getEventIdFromMetricSelection } from "../utils";
import {
  DEFAULT_FUNNEL_CONVERSION_WINDOW,
  DEFAULT_FUNNEL_STEPS,
  DEFAULT_GROUP_BY_COLUMNS,
  DEFAULT_METRIC_SELECTION,
  DEFAULT_SELECTED_AUDIENCES,
  DEFAULT_SELECTED_DATES,
  PLACEHOLDER_AUDIENCE,
  STATIC_ARRAY,
  TimeOptions,
  defaultFunnelUrlState,
  defaultPerformanceUrlState,
} from "./constants";

export const useUrlState = (): AnalyticsContextType => {
  const [analyticsState, setAnalyticsState] =
    useEncodedSearchParams<URLData>("data");

  // State from url
  // shared
  const graphType: GraphType = analyticsState?.graph ?? GraphType.Performance;
  const parentModelId: number | null = analyticsState?.parentModelId
    ? Number(analyticsState.parentModelId)
    : null;
  const selectedAudiences: SelectedAudience[] | undefined =
    analyticsState?.audiences ?? DEFAULT_SELECTED_AUDIENCES;
  const groupByColumns: (GroupByColumn | undefined)[] =
    analyticsState?.groupBy ?? DEFAULT_GROUP_BY_COLUMNS;
  const selectedDates: string[] =
    analyticsState?.selectedDates ?? DEFAULT_SELECTED_DATES;

  // performance
  const metricSelection: MetricSelection[] =
    analyticsState?.metrics ?? DEFAULT_METRIC_SELECTION;
  const timeValue: TimeOptions =
    analyticsState?.timeValue ?? TimeOptions.SevenDays;
  const cumulative: boolean = analyticsState?.cumulative ?? false;
  const rollupFrequency: AnalyticsFrequency = [
    GraphType.Breakdown,
    GraphType.Table,
  ].includes(graphType)
    ? AnalyticsFrequency.All
    : analyticsState?.frequency ?? AnalyticsFrequency.Daily;

  // funnels
  const funnelSteps: FunnelStep[] =
    analyticsState?.funnelSteps ?? DEFAULT_FUNNEL_STEPS;
  const funnelConversionWindow: ConversionWindow =
    analyticsState?.funnelConversionWindow ?? DEFAULT_FUNNEL_CONVERSION_WINDOW;
  const measuringType: ConversionCriteriaData["measuringType"] =
    analyticsState?.measuringType ?? MeasurementType.TotalConversion;

  const parentModelAndRelationships = useParentModelAndRelationshipsQuery(
    { id: parentModelId?.toString() ?? "" },
    { enabled: Boolean(parentModelId) },
  );

  const audiencesAndMetrics = useAudiencesAndMetricsForParentModelQuery(
    { id: parentModelId?.toString() ?? "" },
    {
      enabled: Boolean(parentModelId),
      select: (data) => {
        const { segments, goals } = data;

        return {
          ...data,
          goals: goals.map(({ audience_aggregation, ...goal }) => ({
            ...goal,
            audienceAggregation: audience_aggregation,
          })),
          segments: segments.map((segment) => ({
            ...segment,
            splits: segment.splits.map(({ friendly_name, ...split }) => ({
              ...split,
              name: friendly_name,
              enabled: true,
            })),
          })),
        };
      },
      keepPreviousData: true,
    },
  );

  // Derived state
  const audiences = audiencesAndMetrics.data?.segments ?? STATIC_ARRAY;
  const metrics = (audiencesAndMetrics.data?.goals as Metric[]) ?? STATIC_ARRAY;
  const parent = parentModelAndRelationships.data?.segments_by_pk;
  const relationships = parent?.relationships;
  const events = relationships?.filter(({ to_model: { event } }) =>
    Boolean(event),
  );

  const setGraphType = (type: GraphType) => {
    const changingToOrFromFunnels =
      type === GraphType.Funnel || graphType === GraphType.Funnel;

    setAnalyticsState({
      graph: type,
      groupBy: changingToOrFromFunnels
        ? DEFAULT_GROUP_BY_COLUMNS
        : groupByColumns,
    });
  };

  const resetView = (view: GraphType) => {
    switch (view) {
      // Uses the same state as performance
      case GraphType.Performance:
      case GraphType.Breakdown:
      case GraphType.Table:
        setAnalyticsState(defaultPerformanceUrlState);
        break;
      case GraphType.Funnel:
        setAnalyticsState(defaultFunnelUrlState);
        break;
      default:
        exhaustiveCheck(view);
    }
  };

  const addMetric = () => {
    setAnalyticsState({
      metrics: metricSelection.concat(DEFAULT_METRIC_SELECTION),
    });
  };

  const getFilteredGroupByColumnsFromMetricSelection = (
    updatedMetrics: MetricSelection[],
  ) => {
    const modelIds = updatedMetrics
      .map((updatedMetric) =>
        getEventIdFromMetricSelection(updatedMetric, metrics),
      )
      .concat([parent?.id]);

    const filteredGroupByColumns = groupByColumns.filter(
      (column) => column && modelIds.includes(getModelIdFromColumn(column)),
    );

    return filteredGroupByColumns.length > 0
      ? filteredGroupByColumns
      : DEFAULT_GROUP_BY_COLUMNS;
  };

  const updateMetricAtIndex = (
    index: number,
    updates: undefined | Partial<MetricSelection>,
  ) => {
    if (!updates) {
      return;
    }

    let updatedMetrics: MetricSelection[];

    if (updates.conditions) {
      const conditions = toSingleCondition(
        updates.conditions,
      ) as MetricSelection["conditions"];

      updatedMetrics = immutableUpdate(
        metricSelection ?? [{} as MetricSelection],
        {
          [index]: {
            $merge: { ...updates, conditions },
          },
        },
      );
    } else {
      updatedMetrics = immutableUpdate(
        metricSelection ?? [{} as MetricSelection],
        {
          [index]: {
            $merge: updates,
          },
        },
      );
    }

    setAnalyticsState({
      metrics: updatedMetrics,
      groupBy: getFilteredGroupByColumnsFromMetricSelection(updatedMetrics),
    });
  };

  const resetMetricAtIndex = (index: number) => {
    const metric = metrics.find(({ id }) => id === metricSelection[index]?.id);
    const aggregationMethod =
      (metric && mapAggregationConfigurationToConfigurationOption(metric)) ??
      AggregationOption.Count;

    updateMetricAtIndex(index, {
      aggregationMethod,
      conditions: metric?.config.filter?.subconditions ?? [],
      attributionWindow: metric?.attribution_window,
      column: undefined,
    });
  };

  const removeMetricAtIndex = (indexToRemove: number) => {
    const newMetricSelection = metricSelection.filter(
      (_, index) => index !== indexToRemove,
    );
    setAnalyticsState({
      metrics:
        newMetricSelection.length > 0
          ? newMetricSelection
          : DEFAULT_METRIC_SELECTION,
      groupBy: getFilteredGroupByColumnsFromMetricSelection(newMetricSelection),
    });
  };

  const selectParentModel = (id: number) => {
    setAnalyticsState({
      parentModelId: id,
      audiences: undefined,
      metrics: DEFAULT_METRIC_SELECTION,
    });
  };

  const addAudience = (audience?: SelectedAudience) => {
    setAnalyticsState({
      audiences: selectedAudiences.concat([audience ?? PLACEHOLDER_AUDIENCE]),
    });
  };

  const updateAudienceAtIndex = (index: number, updates: SelectedAudience) => {
    setAnalyticsState({
      audiences: immutableUpdate(selectedAudiences, {
        [index]: { $merge: updates },
      }),
    });
  };

  const addGroupByColumn = (
    column: GroupByColumn | undefined,
    index: number,
  ) => {
    setAnalyticsState({
      groupBy: immutableUpdate(groupByColumns, { [index]: { $set: column } }),
    });
  };

  const addGroupByColumns = (
    columns: (GroupByColumn | undefined)[],
    fromIndex: number,
  ) => {
    const prevGroupBys = groupByColumns.slice(0, fromIndex);
    const groupByUpdate = prevGroupBys.concat(columns);

    setAnalyticsState({
      groupBy: groupByUpdate,
    });
  };

  const removeGroupByColumns = (startIndex: number, endIndex?: number) => {
    const newGroupByColumns = groupByColumns
      .slice(0, startIndex)
      .concat(groupByColumns.slice((endIndex ?? startIndex) + 1));

    setAnalyticsState({
      groupBy:
        newGroupByColumns.length > 0
          ? newGroupByColumns
          : DEFAULT_GROUP_BY_COLUMNS,
    });
  };

  const removeAudienceAtIndex = (removalIndex: number) => {
    const newAudiences = selectedAudiences.filter(
      (_, index) => index !== removalIndex,
    );

    setAnalyticsState({
      audiences:
        newAudiences.length === 0 ? [PLACEHOLDER_AUDIENCE] : newAudiences,
    });
  };

  const setCumulative = (cumulative: boolean) => {
    setAnalyticsState({ cumulative });
  };

  const setRollupFrequency = (frequency: AnalyticsFrequency) => {
    setAnalyticsState({ frequency });
  };

  const setLookbackWindow = (value: TimeOptions) => {
    setAnalyticsState({ selectedDates: [], timeValue: value });
  };

  const setSelectedDates = (dates: Date[]) => {
    setAnalyticsState({
      selectedDates: dates.map((date) => date.toISOString()),
      timeValue: TimeOptions.Custom,
    });
  };

  const setFunnelSteps = (steps: FunnelStep[]) => {
    const newSteps: FunnelStep[] = [];

    steps.forEach((step) => {
      if (step.subconditions) {
        const subconditions = toSingleCondition(
          step.subconditions,
        ) as AndOrCondition<PropertyCondition>[];

        newSteps.push({ ...step, subconditions });
      } else {
        newSteps.push(step);
      }
    });

    setAnalyticsState({ funnelSteps: newSteps });
  };

  const setFunnelConversionCriteria = (
    criteria: Partial<ConversionCriteriaData>,
  ) => {
    const newCriteria = {
      funnelConversionWindow,
      measuringType,
      ...criteria,
    };

    setAnalyticsState(newCriteria);
  };

  // Auto select the parent model
  useEffect(() => {
    if (parent && selectedAudiences.length === 0) {
      setAnalyticsState({
        audiences: [{ id: parent.id, name: parent.name, splits: [] }],
      });
    }
  }, [parent, selectedAudiences]);

  return {
    audiences,
    cumulative,
    rollupFrequency,
    funnelConversionWindow,
    funnelSteps,
    graphType,
    groupByColumns,
    events: events ?? STATIC_ARRAY,
    measuringType,
    metrics,
    metricSelection: (parent && metricSelection) || DEFAULT_METRIC_SELECTION,
    parent: parent ?? null,
    parentModelId,
    parentModelLoading: parentModelAndRelationships.isLoading,
    audiencesAndMetricsLoading:
      audiencesAndMetrics.isLoading || audiencesAndMetrics.isRefetching,
    selectedAudiences,
    selectedDates,
    timeValue,

    addAudience,
    addGroupByColumn,
    addGroupByColumns,
    addMetric,
    removeAudienceAtIndex,
    removeGroupByColumns,
    removeMetricAtIndex,
    resetMetricAtIndex,
    resetView,
    selectParentModel,
    setCumulative,
    setRollupFrequency,
    setFunnelConversionCriteria,
    setFunnelSteps,
    setGraphType,
    setSelectedDates,
    setLookbackWindow,
    updateAudienceAtIndex,
    updateMetricAtIndex,
  };
};
