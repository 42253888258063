import { CheckboxGroup, Spinner } from "@hightouchio/ui";

import { useUserGroupsQuery } from "src/graphql";
import { PermissionedCheckbox } from "./permission";

export const UserGroupSelect = ({ onChange, value, organizationId }) => {
  const { data: userGroups, isLoading } = useUserGroupsQuery(
    { organizationId },
    {
      select: (data) => data.user_groups,
    },
  );

  const defaultGroupId = userGroups?.find((group) => group.name === "All users")
    ?.id;

  if (defaultGroupId && !value.includes(defaultGroupId)) {
    onChange([...value, defaultGroupId]);
  }

  if (isLoading) {
    return <Spinner size="lg" m="auto" />;
  }

  return (
    <CheckboxGroup>
      {userGroups?.map((group) => {
        const isDefault = group.name === "All users";
        return (
          <PermissionedCheckbox
            key={group.id}
            value={group.id}
            organizationPermission={{ resource: "group", id: group.id }}
            isChecked={value.includes(group.id)}
            isDisabled={isDefault}
            onChange={(e) => {
              if (e.target.checked) {
                onChange([...value, group.id]);
              } else {
                onChange(value.filter((id) => id !== group.id));
              }
            }}
            label={group.name}
          />
        );
      })}
    </CheckboxGroup>
  );
};
