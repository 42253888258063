import { FC } from "react";

import {
  Column,
  IconButton,
  Row,
  Text,
  CloseIcon,
  Tooltip,
  Combobox,
  FilterIcon,
  Box,
  Button,
  PlusIcon,
  GroupedCombobox,
} from "@hightouchio/ui";
import { groupBy, isEqual } from "lodash";
import { isPresent } from "ts-extras";

import { usePropertyFilterValidation } from "src/components/explore/visual/property-filter";
import { PropertyInput } from "src/components/explore/visual/property-input";
import {
  formatValue,
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { useColumnSuggestionsQuery } from "src/graphql";
import {
  ColumnType,
  DefaultOperators,
  FilterableColumn,
  isColumnReference,
  OperatorOptions,
  PropertyCondition,
} from "src/types/visual";

import { InputGroup } from "./input-group";
import { SubSection } from "./subsection";

interface FilterEventsProps {
  isLoading?: boolean;
  options?: FilterableColumn[];
  parent?: {
    id: number;
    connection?: { definition: { type: string } } | null;
    name: string;
  } | null;
  subconditions: PropertyCondition[];
  onChange: (
    index: number,
    conditionUpdates: Partial<PropertyCondition>,
  ) => void;
  onAddSubcondition: () => void;
  onRemoveSubcondition: (index: number) => void;
}

export const FilterEvents: FC<FilterEventsProps> = ({
  isLoading = false,
  options = [],
  parent,
  subconditions = [{}],
  onAddSubcondition,
  onRemoveSubcondition,
  onChange,
}) => {
  return (
    <SubSection>
      <Text color="text.secondary" fontWeight="medium">
        <FilterIcon /> Filter events
      </Text>

      {subconditions.map((condition, index) => {
        return (
          <Column key={index} gap={2}>
            {index !== 0 && (
              <Row align="center" gap={2} mr={2}>
                <Text color="text.placeholder">AND</Text>
                <Box
                  height="1px"
                  borderBottom="1px solid"
                  borderColor="base.border"
                  width="100%"
                />
              </Row>
            )}
            <FilterEvent
              columns={options}
              condition={condition}
              isLoading={isLoading}
              parent={parent}
              onChange={(updates) => onChange(index, updates)}
              onRemove={() => onRemoveSubcondition(index)}
            />
          </Column>
        );
      })}

      <Box>
        <Button
          icon={PlusIcon}
          variant="tertiary"
          size="sm"
          onClick={onAddSubcondition}
        >
          Add filter
        </Button>
      </Box>
    </SubSection>
  );
};

interface FilterEventProps {
  condition: PropertyCondition;
  columns: FilterableColumn[];
  isLoading?: boolean;
  parent?: {
    id: number;
    connection?: { definition: { type: string } } | null;
    name: string;
  } | null;
  onChange: (updates: Partial<PropertyCondition>) => void;
  onRemove: () => void;
}

const FilterEvent: FC<Readonly<FilterEventProps>> = ({
  condition,
  columns,
  isLoading,
  parent,
  onChange,
  onRemove,
}) => {
  const { propertyError, operatorError, valueError } =
    usePropertyFilterValidation(condition);

  const modelId = isColumnReference(condition.property)
    ? getModelIdFromColumn(condition.property)
    : parent?.id;
  const columnName = isColumnReference(condition.property)
    ? getPropertyNameFromColumn(condition.property)
    : condition.property;

  const columnSuggestionsQuery = useColumnSuggestionsQuery(
    {
      modelIds: [String(modelId)],
      columnNames: [columnName].filter(isPresent),
    },
    {
      enabled: modelId !== null && columnName !== null && isPresent(columnName),
    },
  );

  const columnsGroupedByModel = groupBy(
    columns,
    ({ model_name }) => model_name,
  );
  const modelNames = Object.keys(columnsGroupedByModel);

  const groupedColumns = modelNames.map((model) => ({
    label: model,
    options:
      columnsGroupedByModel[model]?.map((col) => ({
        label: col.alias || col.name,
        value: col,
      })) ?? [],
  }));

  const suggestions = columnSuggestionsQuery.data?.getTopK?.columns?.find?.(
    (column) =>
      column.modelId === String(modelId) && column.name === columnName,
  )?.values;

  const connectionType = parent?.connection?.definition.type;
  const operatorOptions = condition?.propertyType
    ? OperatorOptions[condition.propertyType].filter(
        (op) =>
          !("supportedSources" in op) ||
          op.supportedSources.includes(connectionType),
      )
    : [];

  const formattedValue = formatValue(condition, condition.value, {
    showParameterizedLabel: true,
  });

  return (
    <Row align="flex-start" justify="space-between" gap={2}>
      <Row gap={2} flexWrap="wrap" overflowX="auto" flex={1} minWidth={0}>
        <Column
          flex={1}
          minWidth={0}
          sx={{ ":first-of-type": { minWidth: "60px" } }}
        >
          <GroupedCombobox
            isInvalid={Boolean(propertyError)}
            isLoading={isLoading}
            optionGroups={groupedColumns ?? {}}
            placeholder="Select a property..."
            size="sm"
            value={columns.find((column) =>
              isEqual(column.column_reference, condition.property),
            )}
            variant="alternative"
            width="100%"
            onChange={(column) => {
              if (!column) {
                return;
              }

              const columnType = (column.custom_type ||
                column.type) as ColumnType;

              onChange({
                propertyType: columnType,
                property: column.column_reference,
                value: null,
                operator: DefaultOperators[columnType],
              });
            }}
          />
        </Column>

        {condition.property && (
          <Box width="100%" sx={{ div: { width: "100%" } }}>
            <InputGroup
              isInvalid={Boolean(operatorError || valueError)}
              width="lg"
              content={
                <Row flex={1} minWidth={0} gap={2}>
                  <Column flex={1} minWidth={0}>
                    <Combobox
                      isInvalid={Boolean(operatorError)}
                      isLoading={isLoading}
                      options={operatorOptions ?? []}
                      value={condition.operator}
                      width="100%"
                      onChange={(operator) =>
                        onChange({
                          operator,
                          value: null,
                        })
                      }
                    />
                    {operatorError && (
                      <Text color="text.danger" mt={2} size="sm">
                        {operatorError}
                      </Text>
                    )}
                  </Column>
                  <PropertyInput
                    condition={condition}
                    error={valueError}
                    suggestions={suggestions}
                    onChange={onChange}
                  />
                </Row>
              }
            >
              <Text color="primary.base" mr={1}>
                {
                  operatorOptions.find(
                    (option) => option.value === condition.operator,
                  )?.label
                }
              </Text>{" "}
              {formattedValue}
            </InputGroup>
          </Box>
        )}

        {Boolean(propertyError) && (
          <Text color="text.danger" mt={2} size="sm">
            {propertyError}
          </Text>
        )}

        {Boolean(operatorError || valueError) && (
          <Text color="text.danger" mt={2} size="sm">
            {operatorError || valueError}
          </Text>
        )}
      </Row>
      <Tooltip message="Remove filter">
        <IconButton
          aria-label="Remove filter."
          icon={CloseIcon}
          size="sm"
          onClick={onRemove}
        />
      </Tooltip>
    </Row>
  );
};
