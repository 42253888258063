import { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  Button,
  ButtonGroup,
  ChevronLeftIcon,
  Column,
  EditableText,
  Heading,
  Paragraph,
  Row,
  Spinner,
  StatusBadge,
  Text,
  UpsellButton,
  useToast,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "src/router";
import { isPresent } from "ts-extras";

import bottomRightGradient from "src/assets/backgrounds/bottom-right-blue-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-green-gradient.svg";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import { toSingleCondition } from "src/components/audiences/utils";
import { PageHeader } from "src/components/layout";
import { PageAlert, PageAlertProps } from "src/components/page-alert";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { SplashPage } from "src/components/splash-page";
import {
  FormErrorProvider,
  useFormErrorContext,
} from "src/contexts/form-error-context";
import {
  useAudiencesForGoalsQuery,
  useCreateGoalMutation,
  useCreateSavedAnalyticsChartMutation,
  useParentModelsForAnalyticsQuery,
  useSavedAnalyticsChartQuery,
  useUpdateSavedAnalyticsChartGoalsAndCohortsMutation,
  useUpdateSavedAnalyticsChartMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import {
  SimpleMetricFormData,
  SimpleMetricModal,
} from "src/pages/metrics/simple-metric-modal";
import { getAggregationConfiguration } from "src/pages/metrics/utils";
import {
  ConditionType,
  PredefinedMetric,
  Relationship,
} from "src/types/visual";
import { AnalyticsIcon } from "src/ui/icons/analytics";
import { useRowSelect } from "src/ui/table/use-row-select";

import { AnalyticsContent } from "./analytics-content";
import { useFunnelMetrics } from "./hooks/use-funnel-metrics";
import { useMetricSeries } from "./hooks/use-metric-series";
import { AnalyticsBasePlaceholder } from "./placeholders";
import {
  SaveNameAndDescriptionModal,
  SaveNameAndDescriptionModalForm,
} from "src/components/modals/save-name-and-description-modal";
import { SavedChartsDrawer } from "./saved-charts-drawer";
import {
  AnalyticsFormStateProvider,
  AnalyticsUrlProvider,
  SavedAnalyticsState,
  useAnalyticsContext,
} from "./state";
import { GraphType, MetricSelection } from "./types";
import {
  getEventById,
  getMetricById,
  getNumberOfSeconds,
  getSavedChartData,
} from "./utils";
import { useEntitlements } from "src/hooks/use-entitlement";

const pageAlert: Record<string, PageAlertProps> = {
  parentModel: {
    title: "First, you need to configure a parent model",
    message:
      "This workspace doesn’t contain any parent models. Before you can view analytics, you’ll need to create a parent model and an audience.",
    link: (
      <Link href="/schema-v2">
        Go to schema setup <ArrowRightIcon />
      </Link>
    ),
  },
};

export const AnalyticsPageContent: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const isSavedView = Boolean(id);
  const navigate = useNavigate();

  // We use the audience preview permission because we're effectively
  // running queries on an audience with additional filtering
  const { isPermitted: hasPreviewPermission } = useResourcePermission({
    v1: { resource: "audience", grant: "preview" },
  });

  const { toast } = useToast();

  const { hasValidationErrors } = useFormErrorContext();

  const savedAnalyticsChartQuery = useSavedAnalyticsChartQuery(
    { id: id ?? "" },
    {
      enabled: Boolean(id),
      select: (data) => data.saved_analytics_charts_by_pk,
    },
  );

  const {
    audiencesAndMetricsLoading,
    cumulative,
    funnelConversionWindow,
    funnelSteps,
    graphType,
    groupByColumns,
    events,
    metrics,
    metricSelection,
    rollupFrequency,
    parentModelId,
    parent,
    selectedAudiences,
    selectedDates: selectedDateStrings,
    timeValue,

    selectParentModel,
    setCumulative,
    updateMetricAtIndex,
  } = useAnalyticsContext();

  const form = useFormContext<SavedAnalyticsState>();

  const selectedDates = useMemo(
    () => selectedDateStrings.map((dateStr) => new Date(dateStr)),
    [selectedDateStrings?.[0], selectedDateStrings?.[1]],
  );

  const [savingChart, setSavingChart] = useState(false);
  const [showSavedCharts, setShowSavedCharts] = useState(false);

  const audiencesRef = useRef(selectedAudiences);
  audiencesRef.current = selectedAudiences;

  const [metricDataToSave, setMetricDataToSave] =
    useState<MetricSelection | null>(null);

  const createGoalMutation = useCreateGoalMutation();
  const createSavedAnalyticsChartMutation =
    useCreateSavedAnalyticsChartMutation();
  const updateSavedAnalyticsChartMutation =
    useUpdateSavedAnalyticsChartMutation();
  const updateSavedAnalyticsChartGoalsAndCohortsMutation =
    useUpdateSavedAnalyticsChartGoalsAndCohortsMutation();

  const parentModelsForAnalyticsQuery = useParentModelsForAnalyticsQuery(
    undefined,
    {
      refetchOnMount: "always",
      onSuccess: (data) => {
        if (!parentModelId && data.segments?.[0]) {
          selectParentModel(data.segments[0].id);
        }

        return data;
      },
    },
  );

  const parentModelOptions = (
    parentModelsForAnalyticsQuery.data?.segments ?? []
  ).map((model) => ({
    label: model.name,
    value: model.id,
    connection: model.connection,
    icon: model.connection?.definition.icon ?? "",
  }));

  const { data: audiencesForMetrics } = useAudiencesForGoalsQuery(
    { parentModelId: parentModelId?.toString() ?? "" },
    { enabled: Boolean(parentModelId), select: (data) => data.segments },
  );

  const createMetric = async (data: SimpleMetricFormData) => {
    const index = metricSelection.findIndex(
      ({ id }) => id === metricDataToSave?.id,
    );

    if (!parentModelId || index < 0) {
      return;
    }

    let selectedEvent: Relationship | undefined;
    if (metricDataToSave?.eventModelId === null) {
      const metric = getMetricById(metrics, metricDataToSave.id);
      selectedEvent = getEventById(events, {
        eventModelId: metric?.config.eventModelId,
      });
    } else {
      selectedEvent = getEventById(events, {
        relationshipId: metricDataToSave?.id.toString(),
      });
    }

    try {
      const aggregationConfig = getAggregationConfiguration(
        metricDataToSave?.aggregationMethod,
      );

      const result = await createGoalMutation.mutateAsync({
        input: {
          name: data.name,
          description: data.description,
          parent_model_id: parentModelId.toString(),
          attribution_window: {
            quantity: data.quantity,
            unit: data.unit,
            basis: data.basis,
          },
          enabled: true,
          audience_goals: {
            data:
              audiencesForMetrics?.map((audience) => ({
                segment_id: audience.id,
                enabled: true,
              })) ?? [],
          },
          aggregation: aggregationConfig?.aggregation,
          audience_aggregation: aggregationConfig?.audienceAggregation,
          config: {
            type: ConditionType.Event,
            eventModelId: selectedEvent?.to_model.id.toString(),
            relationshipId: selectedEvent?.id.toString(),
            filter: {
              subconditions: toSingleCondition(metricDataToSave?.conditions),
            },
            column: metricDataToSave?.column?.column_reference ?? null,
          },
        },
      });

      if (!result.insert_goals_one) {
        // What do we do if this is null?
        return;
      }

      toast({
        id: "metrics-form-toast",
        title: "Metric created successfully",
        variant: "success",
      });

      updateMetricAtIndex(index, {
        id: result.insert_goals_one.id,
        eventModelId: null,
        name: data.name,
        description: data.description,
        aggregationMethod: metricDataToSave?.aggregationMethod,
        conditions: metricDataToSave?.conditions,
        attributionWindow: {
          quantity: data.quantity,
          unit: data.unit,
          basis: data.basis,
        },
        column: metricDataToSave?.column,
      });

      setMetricDataToSave(null);
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "Error creating metric.",
        variant: "error",
      });
    }
  };

  const saveChart = async (data: SaveNameAndDescriptionModalForm) => {
    try {
      const formattedData = getSavedChartData({
        cohorts: selectedAudiences ?? [],
        customDateRange: selectedDates,
        funnelSteps,
        metrics,
        metricSelection,
        parentModelId,
        timeValue,
      });

      const result = await createSavedAnalyticsChartMutation.mutateAsync({
        input: {
          name: data.name,
          description: data.description ?? null,
          frequency: rollupFrequency,
          chart_type: graphType,
          lookback_window: formattedData.lookbackWindow,
          group_by: groupByColumns,
          parent_model_id: parentModelId as unknown as string, // casting but this is a number
          funnel_stages: {
            data:
              graphType === GraphType.Funnel ? formattedData.funnelSteps : [],
          },
          goals: {
            data: graphType !== GraphType.Funnel ? formattedData.metrics : [],
          },
          cohorts: {
            data: formattedData.cohorts,
          },
        },
      });
      setSavingChart(false);

      navigate(`/analytics/${result.insert_saved_analytics_charts_one?.id}`);
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  const updateChart = async (data: SavedAnalyticsState) => {
    if (!id) {
      Sentry.captureException(
        new Error("[Saved analytics charts] No chart ID provided for update"),
      );
      return;
    }

    try {
      const formattedData = getSavedChartData({
        cohorts: data.selectedAudiences ?? [],
        customDateRange: selectedDates,
        funnelSteps,
        metrics,
        metricSelection: data.metricSelection,
        parentModelId: data.parentModelId,
        timeValue: data.timeValue,
      });

      const goalsToRemove =
        savedAnalyticsChartQuery.data?.goals.map(({ id }) => id) ?? [];
      const cohortsToRemove =
        savedAnalyticsChartQuery.data?.cohorts.map(({ id }) => id) ?? [];
      const funnelStagesToRemove =
        savedAnalyticsChartQuery.data?.funnel_stages.map(({ id }) => id) ?? [];

      await updateSavedAnalyticsChartGoalsAndCohortsMutation.mutateAsync({
        id,
        input: {
          frequency: data.rollupFrequency,
          chart_type: data.graphType,
          lookback_window: formattedData.lookbackWindow,
          group_by: data.groupByColumns,
          parent_model_id: data.parentModelId as unknown as string, // casting but this is a number
        },
        bridge_goal_row_ids_to_remove: goalsToRemove,
        bridge_cohort_row_ids_to_remove: cohortsToRemove,
        bridge_funnel_stage_row_ids_to_remove: funnelStagesToRemove,
        goals_to_add: formattedData.metrics.map((object) => ({
          ...object,
          saved_chart_id: id,
        })),
        cohorts_to_add: formattedData.cohorts.map((object) => ({
          ...object,
          saved_chart_id: id,
        })),
        funnel_stages_to_add: formattedData.funnelSteps.map((object) => ({
          ...object,
          saved_chart_id: id,
        })),
      });
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  const openMetricModal = (metricData: MetricSelection) => {
    if (hasValidationErrors()) {
      toast({
        id: "validation-toast",
        title: "Validation failed",
        message: "Please complete all required fields to save a new metric",
        variant: "error",
      });

      return;
    }

    setMetricDataToSave(metricData);
  };

  const updateChartName = async (name: string) => {
    if (!id) {
      Sentry.captureException(
        new Error("[Saved analytics charts] No chart ID provided for update"),
      );
      return;
    }

    try {
      await updateSavedAnalyticsChartMutation.mutateAsync({
        id,
        input: {
          name,
        },
      });

      toast({
        id: "update-chart-name-toast",
        title: "Chart name updated successfully",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "update-chart-name-toast",
        title: "Failed to update chart name",
        message: error.message,
        variant: "error",
      });
    }
  };

  const {
    isPolling,
    data: metricResults,
    pollingError,
    errors: metricSeriesErrors,
  } = useMetricSeries({
    enabled: hasPreviewPermission && graphType !== GraphType.Funnel,
    audiences: selectedAudiences ?? [],
    customDateRange: selectedDates,
    frequency: rollupFrequency,
    groupByColumns: groupByColumns.filter(isPresent),
    metricSelection,
    metrics,
    parentModelId,
    timeValue,
  });

  const {
    data: funnelsData,
    isPolling: isFunnelsPolling,
    errors: funnelsErrors,
  } = useFunnelMetrics({
    completionWindow: getNumberOfSeconds(funnelConversionWindow),
    customDateRange: selectedDates,
    groupByColumns: groupByColumns.filter(isPresent),
    enabled: hasPreviewPermission && graphType === GraphType.Funnel,
    parentModelId,
    selectedAudiences: selectedAudiences ?? [],
    stages: funnelSteps,
    timeValue,
  });

  const returnedOnlyErrors =
    metricResults.length > 0 &&
    metricResults.every(({ result }) => {
      if ("error" in result) {
        return true;
      }

      return false;
    });

  const { selectedRows, onRowSelect } = useRowSelect();

  // Create a stable reference for the side effect below
  const graph = useMemo(
    () =>
      transformMetricDataForGraph({
        audiences: selectedAudiences ?? [],
        cumulative,
        events,
        groupByColumns: groupByColumns.filter(isPresent),
        numberOfSelectedMetrics: metricSelection.length,
        metricResults,
        metrics,
        parent,
        transformForPerformance: graphType === GraphType.Performance,
      }),
    [
      selectedAudiences,
      cumulative,
      events,
      metricResults,
      metricSelection.length,
      metrics,
      parent,
      graphType,
      groupByColumns,
    ],
  );

  useEffect(() => {
    if (!isPolling) {
      onRowSelect(graph.series.map(({ key }) => key));
    }
  }, [isPolling, graph.series]);

  const disableAccumulationSelection = metricSelection.some(
    ({ id }) => id === PredefinedMetric.AudienceSize,
  );
  useEffect(() => {
    if (disableAccumulationSelection) {
      setCumulative(false);
    }
  }, [disableAccumulationSelection]);

  const parentModelsLoading =
    parentModelsForAnalyticsQuery.isLoading ||
    parentModelsForAnalyticsQuery.isRefetching;

  const noParentModels =
    !parentModelsLoading && parentModelOptions.length === 0;

  const noMetricsSelected = metricSelection.filter(({ id }) => id).length === 0;
  const noFunnelStepsSelected =
    funnelSteps.filter(({ eventModelId }) => eventModelId).length === 0;

  const showSidebar =
    hasPreviewPermission && (Boolean(parentModelId) || parentModelsLoading);

  const errorMessage =
    !isPolling && Boolean(pollingError || returnedOnlyErrors) ? (
      <Paragraph
        color={
          pollingError || returnedOnlyErrors ? "text.danger" : "text.primary"
        }
      >
        {pollingError
          ? pollingError.message
          : returnedOnlyErrors
            ? "Query failed"
            : selectedRows.length > 0
              ? "Please adjust the time filter to view the graph"
              : "Please select at least one series to view the graph"}
      </Paragraph>
    ) : null;

  return (
    <>
      <Helmet>
        <title>Charts</title>
      </Helmet>

      <PageHeader
        outsideTopbar={
          <>
            {noParentModels && <PageAlert {...pageAlert.parentModel!} />}
            <Row
              align="center"
              justify="space-between"
              gap={6}
              px={6}
              height={16}
              boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
            >
              <Row gap={6}>
                {isSavedView ? (
                  <Row align="center" gap={2}>
                    <Link href="/analytics">
                      <Text color="text.secondary" size="lg">
                        Charts
                      </Text>
                    </Link>
                    <Text color="text.secondary" size="lg">
                      /
                    </Text>
                    <Text fontWeight="medium">
                      <EditableText
                        size="lg"
                        value={
                          savedAnalyticsChartQuery.data?.name ?? "Saved chart"
                        }
                        onChange={updateChartName}
                      />
                    </Text>
                    {savedAnalyticsChartQuery.data?.archived_at && (
                      <StatusBadge variant="inactive">Archived</StatusBadge>
                    )}
                  </Row>
                ) : (
                  <Heading size="xl">Charts</Heading>
                )}
              </Row>
              {hasPreviewPermission && parentModelOptions.length > 0 && (
                <ButtonGroup>
                  {isSavedView && (
                    <Button
                      isDisabled={
                        updateSavedAnalyticsChartMutation.isLoading ||
                        updateSavedAnalyticsChartGoalsAndCohortsMutation.isLoading ||
                        !form.formState.isDirty ||
                        form.formState.isSubmitting
                      }
                      onClick={() => form.reset()}
                    >
                      Discard changes
                    </Button>
                  )}
                  <Button
                    isDisabled={
                      (form && !form.formState.isDirty) ||
                      (graphType !== GraphType.Funnel && noMetricsSelected) ||
                      (graphType === GraphType.Funnel && noFunnelStepsSelected)
                    }
                    isLoading={
                      updateSavedAnalyticsChartMutation.isLoading ||
                      updateSavedAnalyticsChartGoalsAndCohortsMutation.isLoading
                    }
                    variant="primary"
                    onClick={
                      isSavedView
                        ? form.handleSubmit(updateChart)
                        : () => setSavingChart(true)
                    }
                  >
                    {isSavedView ? "Save changes" : "Save chart"}
                  </Button>
                  <Box borderRight="1px solid" borderColor="base.divider" />
                  <Button
                    directionIcon={ChevronLeftIcon}
                    variant="secondary"
                    onClick={() => setShowSavedCharts(true)}
                  >
                    Charts
                  </Button>
                </ButtonGroup>
              )}
            </Row>
          </>
        }
      />

      {parentModelsLoading || audiencesAndMetricsLoading ? (
        <Column align="center" justify="center" flex={1} minHeight={0}>
          <Spinner size="lg" />
        </Column>
      ) : noParentModels ? (
        <AnalyticsBasePlaceholder />
      ) : (
        <AnalyticsContent
          errorMessage={errorMessage}
          funnelsData={funnelsData}
          funnelsErrors={funnelsErrors}
          graph={graph}
          isLoading={isPolling || isFunnelsPolling}
          isSavedView={isSavedView}
          metricSeriesErrors={metricSeriesErrors}
          parentModelOptions={parentModelOptions}
          showSidebar={showSidebar}
          onSaveMetric={openMetricModal}
        />
      )}

      <SavedChartsDrawer
        isOpen={showSavedCharts}
        onClose={() => setShowSavedCharts(false)}
      />

      <SaveNameAndDescriptionModal
        type="chart"
        isOpen={savingChart}
        onClose={() => setSavingChart(false)}
        onSubmit={saveChart}
      />

      <SimpleMetricModal
        metricDefinition={metricDataToSave}
        isLoading={createGoalMutation.isLoading}
        isOpen={Boolean(metricDataToSave)}
        onClose={() => setMetricDataToSave(null)}
        onSave={(data) => createMetric(data)}
      />
    </>
  );
};

export const Analytics = () => {
  const { id } = useParams<{ id?: string }>();
  const location = useLocation();
  const { data: entitlementsData } = useEntitlements(true, true);
  const analyticsEnabled = entitlementsData.entitlements.analytics;

  // saved charts use local state, while dynamic charts use url state
  const Provider = id ? AnalyticsFormStateProvider : AnalyticsUrlProvider;

  const unlockCampaignIntelligence = () => {
    newPylonMessage("Hi, I'd like to unlock Campaign Intelligence!");
  };
  const trackLearnMoreClick = () => {
    analytics.track("Campaign Intelligence Learn More Clicked");
  };

  if (!analyticsEnabled) {
    return (
      <SplashPage
        icon={<AnalyticsIcon />}
        eyebrow="Campaign Intelligence"
        heading="Self-serve answers to all your marketing questions, using AI"
        description="Measure, explore, and analyze customer data and campaign performance, directly from the data warehouse."
        actions={
          <>
            <UpsellButton onClick={unlockCampaignIntelligence}>
              Unlock Campaign Intelligence
            </UpsellButton>
            <LinkButton
              href="https://hightouch.com/platform/campaign-intelligence"
              onClick={trackLearnMoreClick}
            >
              Learn more
            </LinkButton>
          </>
        }
        visual={
          <Box
            as="img"
            src="https://cdn.sanity.io/images/pwmfmi47/production/bb688c73bd51a5858d653c745395fa9574b726bd-1224x947.webp"
            maxHeight="100%"
            width="100%"
          />
        }
        backgroundGradient={
          <>
            <Box
              as="img"
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
            />
            <Box
              as="img"
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
            />
          </>
        }
      />
    );
  }

  return (
    <Provider key={id ? location.key : undefined}>
      <FormErrorProvider>
        <AnalyticsPageContent />
      </FormErrorProvider>
    </Provider>
  );
};
