import { useEffect, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Pill,
  PlusIcon,
  Row,
  Switch,
  Text,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import noop from "lodash/noop";
import { Outlet, useNavigate, useParams } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Drawer } from "src/components/drawer";
import { Form, useHightouchForm } from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Table } from "src/ui/table";

import { GoldenRecordOutletContext } from "../types";

const Placeholder = {
  image: genericPlaceholder,
  title: "No models",
  body: "Select input models to run an identity resolution process on. Use the resulting identity graph to create a golden record of user information.",
  error: "Models failed to load, please try again.",
};

export const GoldenRecord = () => {
  const { enableProfileBuilder } = useFlags();
  const { recordId } = useParams<{ recordId?: string }>();
  const navigate = useNavigate();

  const [enableGoldenRecord, setEnableGoldenRecord] = useState(true);

  const closeDrawer = () => navigate("./");

  const form = useHightouchForm({
    onSubmit: () => Promise.resolve(),
    values: {},
  });

  useEffect(() => {
    if (!enableProfileBuilder) {
      navigate("../");
    }
  }, [enableProfileBuilder]);

  const outletContext: GoldenRecordOutletContext = {
    onClose: closeDrawer,
  };

  return (
    <Column pb={24} gap={4}>
      <Row justify="space-between">
        <Column gap={2}>
          <Row
            sx={{
              label: {
                display: "flex",
                gap: 2,
                cursor: "pointer",
              },
            }}
          >
            <Text as="label" size="lg" fontWeight="medium">
              Enable golden record
              <Switch
                isChecked={enableGoldenRecord}
                onChange={setEnableGoldenRecord}
              />
            </Text>
          </Row>
          <Text color="text.secondary">
            Compile the best identifiers from your models to make the build a
            user parent model.
          </Text>
        </Column>
        {enableGoldenRecord && <Button onClick={noop}>Configuration</Button>}
      </Row>

      {enableGoldenRecord && (
        <>
          <Table
            placeholder={{
              ...Placeholder,
              button: (
                <Button variant="primary" onClick={noop}>
                  Add model
                </Button>
              ),
            }}
            data={data}
            columns={[
              {
                name: "Column name",
                cell: ({ name, tag }) => (
                  <Row gap={1}>
                    {/* TODO: hover and click effects on pill */}
                    <TextWithTooltip>{name}</TextWithTooltip> <Pill>{tag}</Pill>
                  </Row>
                ),
              },
              {
                name: "Survivorship rule",
                cell: ({ rule }) => <TextWithTooltip>{rule}</TextWithTooltip>,
              },
              {
                name: "Model",
                cell: ({ to_model: { name } }) => (
                  <TextWithTooltip>{name}</TextWithTooltip>
                ),
              },
            ]}
            onRowClick={({ id }) => navigate(id)}
          />

          {/* TODO: only show if no error? */}
          <Row justify="space-between">
            <Button icon={PlusIcon} variant="tertiary" onClick={noop}>
              Add column
            </Button>
            <ButtonGroup>
              <Button size="lg" onClick={noop}>
                Inspect results
              </Button>
              <Button size="lg" onClick={noop} variant="primary">
                View schema
              </Button>
            </ButtonGroup>
          </Row>
        </>
      )}

      <Drawer
        closeOnEsc
        isOpen={Boolean(recordId)}
        showOverlay={false}
        trapFocus={false}
        size="lg"
        contentWrapperStyles={{
          borderLeft: "1px",
          borderTop: "1px",
          borderColor: "base.border",
        }}
        onClose={closeDrawer}
      >
        <Form form={form}>
          <Outlet context={outletContext} />
        </Form>
      </Drawer>
    </Column>
  );
};

const data = [
  {
    id: "1",
    name: "email",
    tag: "Primary label",
    rule: "Most recent",
    to_model: { name: "IDR graph" },
  },
];
