import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  Avatar,
  Button,
  Column,
  Heading,
  Row,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "src/router";
import { isPresent } from "ts-extras";

import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { SidebarForm } from "src/components/page";
import {
  PermissionedButton,
  PermissionedEditableHeading,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  AudiencesForPriorityListsQuery,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAudiencesForPriorityListsQuery,
  useDeletePriorityListMutation,
  useObjectQuery,
  usePriorityListQuery,
  useUpdatePriorityListMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { useTableConfig } from "src/ui/table";
import { formatDate } from "src/utils/time";

import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { AudienceSelector } from "./audience-selector";
import { DragAndDropEditor } from "./drag-and-drop-editor";

type FormData = { audiences: AudiencesForPriorityListsQuery["segments"][0][] };

export const PriorityList = () => {
  const { priority_list_id } = useParams();
  const { schemaV2 } = useFlags();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v1: { resource: "audience", grant: "update" },
  });

  const [showAddAudiences, setShowAddAudiences] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updatePriorityListMutation = useUpdatePriorityListMutation();
  const deletePriorityListMutation = useDeletePriorityListMutation();

  const priorityListQuery = usePriorityListQuery(
    { id: priority_list_id ?? "" },
    { enabled: Boolean(priority_list_id) },
  );

  const priorityList = priorityListQuery.data?.priority_lists_by_pk;
  const parentModelId = priorityList?.parent_model.id;
  const priorityListAudienceIds = priorityList?.priority_list_memberships?.map(
    ({ segment }) => segment.id.toString(),
  );

  const { limit, offset, orderBy } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 100_000,
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      id: { _in: priorityListAudienceIds },
      query_type: { _eq: "visual" },
      visual_query_parent_id: { _eq: parentModelId },
    };

    return hasuraFilters;
  }, [parentModelId, priorityListAudienceIds]);

  // Fetch audiences data to display in priority list
  const audiencesForPriorityListsQuery = useAudiencesForPriorityListsQuery(
    {
      filters,
      limit,
      offset,
      orderBy,
    },
    {
      enabled: isPresent(parentModelId),
    },
  );

  // Get parent model information
  const { data: parentModel, isLoading: parentModelLoading } = useObjectQuery(
    {
      id: String(parentModelId),
    },
    {
      enabled: Boolean(parentModelId),
      select: (data) => data.segments_by_pk,
    },
  );

  const parentModelSource = parentModel?.connection;
  const initialAudiences = useMemo(() => {
    const result: AudiencesForPriorityListsQuery["segments"] = [];

    // preserve rank order sent from server
    priorityList?.priority_list_memberships?.forEach(({ segment: { id } }) => {
      const audience = audiencesForPriorityListsQuery.data?.segments.find(
        ({ id: audienceId }) => id === audienceId,
      );
      if (audience) {
        result.push(audience);
      }
    });

    return result;
  }, [
    priorityList?.priority_list_memberships,
    audiencesForPriorityListsQuery.data?.segments,
  ]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      audiences: initialAudiences,
    },
  });

  const { remove, replace } = useFieldArray({
    control: control,
    name: "audiences",
  });

  const audiences = watch("audiences");

  const trackPriorityListUpdates = () => {
    analytics.track("Priority List Updated", {
      priority_list_id: priority_list_id,
      parent_model_id: parentModelId,
    });
  };

  const save = async (data: FormData) => {
    try {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        audienceIds: data.audiences.map(({ id }) => id.toString()),
      });

      trackPriorityListUpdates();

      toast({
        id: "update-priority-list-toast",
        title: "Priority list updated",
        variant: "success",
      });

      priorityListQuery.refetch();
    } catch (error) {
      toast({
        id: "update-priority-list-toast",
        title: "Failed to update this priority list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const saveName = async (name: string) => {
    try {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        name,
        audienceIds: initialAudiences.map(({ id }) => id.toString()),
      });

      trackPriorityListUpdates();

      toast({
        id: "update-priority-list-toast",
        title: "Priority list updated",
        variant: "success",
      });

      priorityListQuery.refetch();
    } catch (error) {
      toast({
        id: "update-priority-list-toast",
        title: "Failed to update this priority list",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const deletePriorityList = async () => {
    try {
      await deletePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
      });

      analytics.track("Priority List Deleted", {
        priority_list_id,
        parent_model_id: priorityList?.parent_model?.id,
        priority_list_name: priorityList?.name,
      });

      // success toast shown in delete confirmation modal

      navigate("/priority-lists");
    } catch (error) {
      toast({
        id: "delete-model-error",
        title: "Failed to delete this priority list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const resetForm = () => {
    reset({
      audiences: initialAudiences,
    });
  };

  useEffect(() => {
    // update form when priority list from db changes
    reset({
      audiences: initialAudiences,
    });
  }, [priorityList, initialAudiences?.length]);

  const priorityListLoading =
    priorityListQuery.isLoading || audiencesForPriorityListsQuery.isLoading;

  const updatedByUsername =
    priorityList?.updated_by_user?.name || priorityList?.created_by_user?.name;

  return (
    <PermissionProvider
      permission={{
        v1: { resource: "audience", grant: "read" },
      }}
    >
      <Page
        crumbs={[{ label: "All priority lists", link: "/priority-lists" }]}
        title={`${
          priorityList?.name ?? "Unnamed priority list"
        } - Priority lists - Audiences`}
      >
        {priorityListLoading || parentModelLoading ? (
          <Row align="center" flex={1} height="100%" justify="center">
            <Spinner />
          </Row>
        ) : (
          <>
            <Column gap={4} mb={4}>
              <Row align="flex-end" justify="space-between">
                <PermissionedEditableHeading
                  // TODO[Permissions]: Add V2 permissions where you have to be able to update all audiences
                  permission={{
                    v1: {
                      resource: "audience",
                      grant: "update",
                    },
                  }}
                  size="lg"
                  value={priorityList?.name ?? ""}
                  onChange={saveName}
                />

                <PermissionedButton
                  // TODO[Permissions]: Add V2 permissions where you have to be able to update all audiences
                  permission={{
                    v1: {
                      resource: "audience",
                      grant: "update",
                    },
                  }}
                  isLoading={deletePriorityListMutation.isLoading}
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </PermissionedButton>
              </Row>
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon
                    src={parentModelSource?.definition?.icon}
                    name={parentModelSource?.definition?.name}
                  />
                  <Link
                    href={
                      schemaV2
                        ? `/schema-v2/view?source=${parentModelSource?.id}&id=${parentModel?.id}`
                        : `/schema/parent-models/${parentModel?.id}`
                    }
                  >
                    <Text isTruncated color="inherit">
                      {parentModel?.name ?? "Private model"}
                    </Text>
                  </Link>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate(
                      (priorityList?.updated_at || priorityList?.created_at)!,
                    )}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>
              </DetailBar>
            </Column>

            <Tabs>
              <TabList>
                <Tab>Audiences</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Row
                    flex={1}
                    justify="space-between"
                    minHeight={0}
                    mt={6}
                    gap={8}
                  >
                    <Column flex={1} minHeight={0}>
                      {priorityListQuery.error ||
                      audiencesForPriorityListsQuery.error ? (
                        <Column flex={1} minHeight={0}>
                          <Alert
                            variant="inline"
                            message="There was a problem fetching this priority list"
                            title="There was a problem"
                            type="error"
                          />
                        </Column>
                      ) : (
                        <>
                          <Row align="center" justify="space-between">
                            <Heading>Set priority</Heading>
                            <PermissionedButton
                              // TODO[Permissions]: Add V2 permissions where you have to be able to update all audiences
                              permission={{
                                v1: {
                                  resource: "audience",
                                  grant: "update",
                                },
                              }}
                              onClick={() => setShowAddAudiences(true)}
                            >
                              Add audiences
                            </PermissionedButton>
                          </Row>
                          <DragAndDropEditor
                            data={audiences}
                            isLoading={
                              audiencesForPriorityListsQuery.isLoading ||
                              priorityListQuery.isRefetching
                            }
                            readOnly={!hasUpdatePermission}
                            onRemove={remove}
                            onUpdate={replace}
                          />
                        </>
                      )}
                    </Column>

                    <SidebarForm
                      hideInviteTeammate
                      hideSendMessage
                      buttons={
                        <>
                          <PermissionedButton
                            // TODO[Permissions]: Add V2 permissions where you have to be able to update all audiences
                            permission={{
                              v1: {
                                resource: "audience",
                                grant: "update",
                              },
                            }}
                            isDisabled={!isDirty}
                            isLoading={updatePriorityListMutation.isLoading}
                            variant="primary"
                            onClick={handleSubmit(save)}
                          >
                            Save
                          </PermissionedButton>
                          <Button isDisabled={!isDirty} onClick={resetForm}>
                            Cancel
                          </Button>
                        </>
                      }
                      docsUrl={`${
                        import.meta.env.VITE_DOCS_URL
                      }/customer-studio/priority-lists`}
                      name="adding a priority list"
                    />
                  </Row>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <AudienceSelector
              isOpen={showAddAudiences}
              parentModelId={parentModelId}
              selectedData={audiences}
              onClose={() => setShowAddAudiences(false)}
              onSubmit={(data) => replace(data)}
            />

            <DeleteConfirmationModal
              isOpen={showDeleteModal}
              label="priority list"
              onClose={() => {
                setShowDeleteModal(false);
              }}
              onDelete={deletePriorityList}
            />
          </>
        )}
      </Page>
    </PermissionProvider>
  );
};
