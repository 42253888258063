import { Box } from "@hightouchio/ui";
import { useState, useEffect } from "react";

import { useNavigation } from "src/router";

export const RouteLoadingBar = () => {
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading";

  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timer;

    if (isLoading) {
      setVisible(true);
      setProgress(0);

      // Simulate progress increment
      timer = setInterval(() => {
        setProgress((prev) => {
          const increment = Math.random() * 10;
          const newProgress = prev + increment;
          return newProgress < 90 ? newProgress : 90;
        });
      }, 200);
    } else {
      // Complete the progress
      setProgress(100);
      // Hide the bar after a short delay
      timer = setTimeout(() => {
        setVisible(false);
        setProgress(0);
      }, 300);
    }

    return () => clearInterval(timer);
  }, [isLoading]);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="4px"
      zIndex="banner" // Ensure it's above other elements
      pointerEvents="none"
      overflow="hidden"
      visibility={visible ? "visible" : "hidden"}
      transition="visibility 0.3s ease"
    >
      <Box
        width={`${progress}%`}
        height="100%"
        bg="gray.500"
        transition="width 0.2s ease-out"
        boxShadow="0 0 8px rgba(0, 0, 0, 0.2)"
      />
    </Box>
  );
};
