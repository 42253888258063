import { SchemaModelType } from "src/types/schema";
import { AssetType, InteractionType } from "src/types/visual";

export const SMS_INTERACTION_OPTIONS = [
  { label: "SMS sent", value: InteractionType.SmsSent },
  { label: "SMS delivered", value: InteractionType.SmsDelivered },
  { label: "SMS clicked", value: InteractionType.SmsClicked },
  { label: "SMS replied", value: InteractionType.SmsReplied },
  { label: "SMS opt-out", value: InteractionType.SmsOptedOut },
];

export const INTERACTION_TYPE_OPTIONS = [
  // emails
  { label: "Email delivered", value: InteractionType.EmailDelivered },
  { label: "Email opened", value: InteractionType.EmailOpened },
  { label: "Email clicked", value: InteractionType.EmailClicked },
  { label: "Unsubscribe", value: InteractionType.Unsubscribe },

  // ads
  { label: "Ad Sessions", value: InteractionType.Sessions },
];

export const SMS_ASSET_OPTIONS = [
  {
    label: "SMS",
    value: AssetType.Sms,
  },
];
export const ASSET_TYPE_OPTIONS = [
  { label: "Email", value: AssetType.Email },
  { label: "Ad", value: AssetType.Ad },
];

export const RelationshipModelTypes = [
  SchemaModelType.Event,
  SchemaModelType.Related,
  SchemaModelType.Interaction,
  SchemaModelType.Asset,
  SchemaModelType.AdStats,
];
