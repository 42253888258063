import {
  Column,
  FormField,
  SectionHeading,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "src/router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { WizardDrawer } from "src/components/drawer";
import { Form, useHightouchForm } from "src/components/form";
import { CatalogSelect } from "./components/catalog-select";
import { Fallback } from "./components/fallback";
import { ConditionGroup, filterSchema } from "./components/filter";
import { useCreateCollectionMutation } from "src/graphql";
import { OutletContext } from "..";

const schema = yup.object().shape({
  name: yup.string().required(),
  catalog_id: yup.string().required(),
  config: yup.object().required(),
  filter: filterSchema,
});

export const AddCollection = () => {
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const mutation = useCreateCollectionMutation();

  const form = useHightouchForm({
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      await mutation.mutateAsync({
        input: {
          decision_engine_id: engine.id,
          config: data.config,
          collection: {
            data: {
              catalog_id: data.catalog_id,
              name: data.name,
              filter: data.filter,
            },
          },
        },
      });
      navigate(`/ai/collections`);
    },
    defaultValues: {
      name: "",
      catalog_id: "",
      config: {},
      filter: {
        type: "and",
        conditions: [],
      },
    },
  });

  const { watch } = form;

  const catalogId = watch("catalog_id");

  return (
    <Form form={form}>
      <WizardDrawer
        size="xl"
        isOpen
        steps={[
          {
            label: "Select a catalog",
            render: () => <CatalogSelect />,
          },
          {
            label: "Filter the catalog",
            render: () => {
              return (
                <Column gap={4}>
                  <Column>
                    <SectionHeading>Filter the catalog</SectionHeading>
                    <Text color="text.secondary">
                      Specify which items from the selected catalog should be
                      included in this collection
                    </Text>
                  </Column>
                  <ConditionGroup
                    leftModelId={catalogId}
                    rightModelId={engine.segment.id}
                  />
                </Column>
              );
            },
          },
          {
            label: "Finalize",
            continueLabel: "Add collection",
            render: () => (
              <Column gap={6}>
                <Controller
                  name="name"
                  render={({ field }) => (
                    <FormField label="Collection name">
                      <TextInput {...field} placeholder="Collection name..." />
                    </FormField>
                  )}
                />
                <Fallback />
              </Column>
            ),
          },
        ]}
        title="Add collection"
        onClose={() => {
          navigate(`/ai/collections`);
        }}
        onSubmit={form.submit}
      />
    </Form>
  );
};
