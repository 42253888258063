import { FC, useEffect, useState } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Row,
  Button,
  useToast,
  Column,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalHeader,
  Heading,
  ChakraModalBody,
  ChakraModalFooter,
  Alert,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import capitalize from "lodash/capitalize";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "src/router";

import {
  TraitDefinitionsSetInput,
  useTraitQuery,
  useUpdateTraitMutation,
} from "src/graphql";
import { NotFound } from "src/pages/not-found";
import { Drawer } from "src/components/drawer";

import { Header } from "./header";
import { TraitCalculationForm } from "./trait-calculation-form";
import { TraitReferences, isTraitReferenced } from "./trait-references";
import {
  formatTraitConfig,
  traitTypeToCalculationMethod,
  validateConfig,
} from "./utils";

type Props = {
  isTemplate?: boolean;
};

type EditFormProps = {
  relationship_id: string;
  type: TraitType;
  config: TraitConfig;
};

export const EditTrait: FC<Readonly<Props>> = ({ isTemplate }) => {
  const { traitId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showSaveModal, setShowSaveModal] = useState(false);

  const updateTrait = useUpdateTraitMutation();

  const form = useForm<EditFormProps>();
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types on Column
  const type = watch("type");
  const config = watch("config");

  const { data, isLoading } = useTraitQuery(
    { id: traitId ?? "" },
    { enabled: traitId != null },
  );
  const trait = data?.trait_definitions_by_pk;

  const onCancel = () =>
    navigate(`/traits/${isTemplate ? "templates" : "active"}/${traitId}`);
  const onCloseDrawer = () =>
    navigate(`/traits/${isTemplate ? "templates" : "active"}`);

  // Set initial form data
  useEffect(() => {
    if (!trait) return;

    const initialFormValues = {
      is_template: trait.is_template,
      relationship_id: trait.relationship?.id,
      calculation_method: traitTypeToCalculationMethod[trait.type],
      type: trait.type as TraitType,
      config: trait.config as TraitConfig,
    };
    reset(initialFormValues);
  }, [reset, trait]);

  const handleSave = () => {
    if (!trait) {
      return;
    }

    if (isTraitReferenced(trait)) {
      setShowSaveModal(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (formData: EditFormProps) => {
    if (!trait) {
      return;
    }

    try {
      const input: TraitDefinitionsSetInput = {
        relationship_id: formData.relationship_id,
        type: formData.type,
        config: formatTraitConfig(
          formData.type,
          formData.config,
          trait?.parent_model,
        ),
      };

      await updateTrait.mutateAsync({
        id: trait.id,
        input,
      });

      toast({
        id: "edit-trait",
        title: "Trait was updated successfully",
        variant: "success",
      });

      navigate(
        `/traits/${trait.is_template ? "templates" : "active"}/${traitId}`,
      );
    } catch (error) {
      toast({
        id: "edit-trait",
        title: "Trait failed to be created",
        message: error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const isSaveDisabled =
    !isDirty || updateTrait.isLoading || !validateConfig(type, config);

  const traitType = isTemplate ? "template" : "trait";

  return (
    <Drawer isLoading={isLoading} isOpen size="xl" onClose={onCloseDrawer}>
      {!trait ? (
        <DrawerBody>
          <NotFound />
        </DrawerBody>
      ) : (
        <>
          <Row
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Header
              id={trait.id}
              name={trait.name}
              description={trait.description}
              parentModel={trait.parent_model}
              isTemplate={trait.is_template}
              isArchived={Boolean(trait.archived_at)}
              isEditable
              onClose={onCloseDrawer}
            />
          </Row>
          <DrawerBody>
            <Column overflowY="auto" flex={1}>
              <FormProvider {...form}>
                <TraitCalculationForm
                  parentModel={trait.parent_model}
                  isAssociatedToTemplate={Boolean(trait.trait_template_id)}
                />
              </FormProvider>
            </Column>
          </DrawerBody>

          <DrawerFooter>
            <ButtonGroup>
              <Button
                isDisabled={isSaveDisabled}
                size="lg"
                variant="primary"
                onClick={handleSave}
              >
                Save changes
              </Button>
              <Button size="lg" onClick={onCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </DrawerFooter>

          <ChakraModal
            closeOnEsc
            closeOnOverlayClick
            isCentered
            scrollBehavior="inside"
            isOpen={showSaveModal}
            onClose={() => setShowSaveModal(false)}
          >
            <ChakraModalOverlay>
              <ChakraModalContent
                maxHeight="90vh"
                maxWidth="500px"
                padding={0}
                my="auto"
              >
                <ChakraModalHeader
                  alignItems="center"
                  padding={6}
                  borderBottom="1px solid"
                  borderColor="base.border"
                >
                  <Row alignItems="center" width="100%">
                    <Heading>Save trait changes?</Heading>
                  </Row>
                </ChakraModalHeader>
                <ChakraModalBody px={6} pb={6} m={0}>
                  <Alert
                    variant="inline"
                    type="warning"
                    title={`${capitalize(traitType)} in use`}
                    message={`Saving this ${traitType} will update the following active audiences and syncs.`}
                  />
                  <TraitReferences trait={trait} />
                </ChakraModalBody>
                <ChakraModalFooter
                  p={4}
                  m={0}
                  borderTop="1px solid"
                  borderColor="base.border"
                >
                  <Button onClick={() => setShowSaveModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    isDisabled={isSaveDisabled}
                    variant="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Confirm changes
                  </Button>
                </ChakraModalFooter>
              </ChakraModalContent>
            </ChakraModalOverlay>
          </ChakraModal>
        </>
      )}
    </Drawer>
  );
};
