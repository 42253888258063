import { FC, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Box,
  Column,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Row,
  SearchInput,
  SparkleIcon,
  Text,
  useToast,
  TagIcon,
  DeleteIcon,
  FolderIcon,
  Tooltip,
  Paragraph,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useNavigate } from "src/router";

import modelPlaceholder from "src/assets/placeholders/model.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { DraftIcon } from "src/components/drafts/draft-icon";
import {
  isFilterActive,
  labelFilter,
  createdFilter,
} from "src/components/folders/filter-helpers";
import {
  createdByFilterConfig,
  Filters,
  labelFilterConfig,
  modelQueryTypeFilterConfig,
  sourceTypeFilterConfigForModels,
  useFilters,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PageAlert } from "src/components/page-alert";
import {
  PermissionedLinkButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import { PermissionProvider } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ModelsQuery,
  ModelsQueryVariables,
  OrderBy,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useDeleteModelsMutation,
  useDraftsQuery,
  useModelFiltersQuery,
  useModelsQuery,
  useUpdateModelsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import {
  PageTable,
  SortOption,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { QueryTypeBadge } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import { openUrl } from "src/utils/urls";
import { PageSidebar } from "src/components/layout/page-sidebar";
import { QueryType } from "src/types/models";

const initialSort: SortOption<keyof SegmentsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof SegmentsOrderBy>[] = [
  { key: "query_type", direction: OrderBy.Desc, label: "Model type" },
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  {
    key: "syncs_aggregate.count" as any,
    direction: OrderBy.Desc,
    label: "Number of syncs",
  },
  { key: "last_run_size", direction: OrderBy.DescNullsLast, label: "Largest" },
  { key: "last_run_size", direction: OrderBy.AscNullsLast, label: "Smallest" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const useFastModelsQuery = getQueryWithOpts<ModelsQuery, ModelsQueryVariables>(
  useModelsQuery,
  { useFastEndpoint: true },
);

export const Models: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { mutateAsync: bulkDelete } = useDeleteModelsMutation();
  const [addingLabels, setAddingLabels] = useState(false);
  const {
    selectedFolder,
    setSelectedFolder,
    setMovingToFolder,
    movingToFolder,
    header,
    refetchFolders,
    modelCount,
    nestedFolders: folders,
    loadingFolders,
  } = useFolderState({
    search,
    resourceType: "models",
    folderType: "models",
  });

  const { limit, offset, page, setPage } = useTableConfig<SegmentsOrderBy>();

  const orderBy = useTableSort<SegmentsOrderBy>(initialSort, sortOptions);

  const updateModelsMutation = useUpdateModelsMutation();

  const { data: modelFilters, isLoading: filtersLoading } =
    useModelFiltersQuery(undefined, {
      select: (data) => data.segments,
    });

  const filterDefinitions = useMemo(() => {
    const filters: {
      viewKey: string;
      loading: boolean;
      filters: Record<string, any>;
    } = {
      viewKey: "models",
      loading: filtersLoading,
      filters: {
        type: {
          options: modelQueryTypeFilterConfig(modelFilters || []),
          title: "Query type",
        },
        source_type: {
          options: sourceTypeFilterConfigForModels(modelFilters || []),
          title: "Source type",
        },
        created: {
          options: createdByFilterConfig(modelFilters || []),
          title: "Created by",
        },
        label: {
          options: labelFilterConfig(modelFilters || []),
          title: "Labels",
        },
        duplicate_keys: {
          options: [
            { id: "true", label: "True" },
            { id: "false", label: "False" },
          ],
          title: "Duplicate primary keys",
        },
      },
    };

    if (modelFilters?.some((f) => f.matchboosting_enabled)) {
      filters.filters.matchbooster_enabled = {
        options: [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
        title: "Match Boosted",
        canSearch: false,
      };
    }

    return filters;
  }, [modelFilters]);

  const {
    result: { state: filterState, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    if (!modelFilters?.length) {
      return {};
    }

    const folderFilter = (): SegmentsBoolExp => {
      const folderIds: string[] = [];

      if (selectedFolder?.id) {
        folderIds.push(selectedFolder.id);
      }
      if (selectedFolder?.flattenedChildren?.length) {
        folderIds.push(...selectedFolder.flattenedChildren.map((f) => f.id));
      }

      if (folderIds.length) {
        return {
          folder_id: { _in: folderIds },
        };
      } else {
        return {};
      }
    };

    const matchboosterFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.matchbooster_enabled)) {
        if (filterState.matchbooster_enabled.selected.length !== 1) {
          return {};
        }
        if (
          filterState.matchbooster_enabled.selected.map((f) => f.id)[0] ===
          "true"
        ) {
          return {
            matchboosting_enabled: { _eq: true },
          };
        }
        if (
          filterState.matchbooster_enabled.selected.map((f) => f.id)[0] ===
          "false"
        ) {
          return {
            matchboosting_enabled: { _eq: false },
          };
        }
      }
      return {};
    };

    const duplicateKeyFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.duplicate_keys)) {
        if (filterState.duplicate_keys.selected.length !== 1) {
          return {};
        }
        if (
          filterState.duplicate_keys.selected.map((f) => f.id)[0] === "true"
        ) {
          return {
            last_run_duplicate_primary_keys: { _gt: 0 },
            destination_instances: {
              last_run_planner_type: { _neq: "all", _is_null: false },
            },
          };
        }
        if (
          filterState.duplicate_keys.selected.map((f) => f.id)[0] === "false"
        ) {
          return {
            _and: [
              {
                _or: [
                  { last_run_duplicate_primary_keys: { _eq: 0 } },
                  { last_run_duplicate_primary_keys: { _is_null: true } },
                  {
                    _not: {
                      destination_instances: {
                        last_run_planner_type: { _neq: "all", _is_null: false },
                      },
                    },
                  },
                ],
              },
            ],
          };
        }
      }
      return {};
    };

    const queryTypeFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.type)) {
        return {
          query_type: { _in: filterState.type.selected.map((f) => f.id) },
        };
      }
      return {};
    };

    const sourceTypeFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.source_type)) {
        return {
          connection: {
            type: { _in: filterState.source_type.selected.map((f) => f.id) },
          },
        };
      }
      return {};
    };

    const andFilter = () => {
      const filter = {
        ...queryTypeFilter(),
        ...sourceTypeFilter(),
      };
      if (Object.keys(filter).length) {
        return {
          _and: [filter],
        };
      }
      return {};
    };

    const hasuraFilters: SegmentsBoolExp = {
      ...andFilter(),
      ...folderFilter(),
      ...labelFilter(filterState.label),
      ...createdFilter(filterState.created),
      ...duplicateKeyFilter(),
      ...matchboosterFilter(),
    };

    return hasuraFilters;
  }, [search, selectedFolder, filterState]);

  const hasuraFiltersWithSearch = useMemo(() => {
    if (search) {
      return { ...hasuraFilters, name: { _ilike: `%${search}%` } };
    } else {
      return hasuraFilters;
    }
  }, [hasuraFilters, search]);

  const modelsQuery = useFastModelsQuery(
    {
      offset,
      limit,
      filters: {
        ...hasuraFiltersWithSearch,
        is_schema: { _eq: false },
        query_type: { _nin: ["visual", "journey-node"] },
        _not: { flow_message: {} },
      },
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const { data: drafts } = useDraftsQuery({
    resourceType: "model",
    status: "pending",
  });

  const models = modelsQuery.data?.segments ?? [];
  const filteredModelCount =
    modelsQuery.data?.segments_aggregate?.aggregate?.count ?? 0;

  const { selectedRows, onRowSelect } = useRowSelect();

  const { data: entitlementsData } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a model, upgrade your plan.";

  const bulkDeleteModels = async () => {
    const count = selectedRows.length;
    const pluralizedLabel = pluralize("model", count);

    try {
      await bulkDelete({ where: { id: { _in: selectedRows.map(String) } } });
      toast({
        id: "bulk-delete-models",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });
      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-models",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No models found",
      error: "Models failed to load, please try again.",
    }),
    [],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  return (
    <>
      <PermissionProvider
        permission={{ v1: { resource: "model", grant: "update" } }}
      >
        <Page
          sidebar={
            <PageSidebar
              header={
                <SearchInput
                  placeholder="Search all models..."
                  value={search ?? ""}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              }
            >
              <Folders
                folders={folders ?? []}
                loading={loadingFolders}
                modelCount={modelCount}
                modelsRootName="All models"
                refetchFolders={refetchFolders}
                rootFolder="models"
                selectedFolder={selectedFolder}
                setRootFolder={() => undefined}
                setSelectedFolder={setSelectedFolder}
                viewType="models"
              />

              <Box borderBottom="1px solid" borderColor="base.divider" />

              <Filters
                clearFilters={clearFilters}
                createView={createView}
                creatingView={creatingView}
                deleteView={deleteView}
                filters={filterData}
                resetFilters={resetViewFilters}
                resource="model"
                selectView={selectView}
                selectedView={selectedView}
                updateCurrentView={updateCurrentView}
                updatingView={updatingView}
                viewNotSaved={viewNotSaved}
                views={views}
              />
            </PageSidebar>
          }
          title="Models"
        >
          <PageTable
            header={
              <>
                <Heading isTruncated size="xl">
                  {header}
                </Heading>
                <Row flexShrink={0} gap={3}>
                  {selectedRows.length > 0 && (
                    <Row align="center" gap={2}>
                      <Text>{`${pluralize(
                        "model",
                        selectedRows.length,
                        true,
                      )} selected`}</Text>
                      <Menu>
                        <MenuButton>Actions</MenuButton>
                        <MenuList>
                          <PermissionedMenuItem
                            permission={{
                              v1: { resource: "model", grant: "update" },
                            }}
                            icon={FolderIcon}
                            onClick={() => {
                              setMovingToFolder(true);
                            }}
                          >
                            Move to folder
                          </PermissionedMenuItem>
                          <PermissionedMenuItem
                            permission={{
                              v1: { resource: "model", grant: "update" },
                            }}
                            icon={TagIcon}
                            onClick={() => {
                              setAddingLabels(true);
                            }}
                          >
                            Add labels
                          </PermissionedMenuItem>
                          <MenuDivider />
                          <PermissionedMenuItem
                            permission={{
                              v1: { resource: "model", grant: "delete" },
                            }}
                            icon={DeleteIcon}
                            variant="danger"
                            onClick={() => {
                              setConfirmingDelete(true);
                            }}
                          >
                            Delete
                          </PermissionedMenuItem>
                        </MenuList>
                      </Menu>
                    </Row>
                  )}
                  <PermissionedLinkButton
                    href={`/models/new${
                      selectedFolder ? `?folder=${selectedFolder?.id}` : ""
                    }`}
                    isDisabled={overageLockout}
                    permission={{ v1: { resource: "model", grant: "create" } }}
                    tooltip={overageLockout && overageText}
                    variant="primary"
                    onClick={() => {
                      analytics.track("Add Model Clicked");
                    }}
                  >
                    Add model
                  </PermissionedLinkButton>
                </Row>
              </>
            }
            columns={[
              {
                name: "Name",
                cell: ({
                  id,
                  name,
                  connection: source,
                  draft: isInitialDraft,
                  query_runs,
                  query_type,
                  matchboosting_enabled,
                }) => {
                  const draft = drafts?.drafts.find(
                    (d) => String(d.resource_id) === String(id),
                  );

                  return (
                    <Row gap={3} overflow="hidden" width="100%" align="center">
                      <IntegrationIcon
                        src={source?.definition?.icon}
                        name={source?.definition?.name}
                      />
                      <Column gap={1} overflow="hidden">
                        <Row gap={2} align="center">
                          <Text isTruncated fontWeight="medium">
                            {name ?? "Private model"}
                          </Text>
                          {isInitialDraft && <DraftBadge />}
                          {draft && <DraftIcon draft={draft} />}
                        </Row>

                        <Row gap={2}>
                          {query_type && (
                            <QueryTypeBadge type={query_type as QueryType}>
                              {query_runs?.[0]
                                ? `${abbreviateNumber(
                                    query_runs?.[0]?.size,
                                  )} rows`
                                : "Unknown size"}
                            </QueryTypeBadge>
                          )}
                          {matchboosting_enabled && (
                            <Tooltip message="This model has Match Booster enabled">
                              <Badge
                                size="sm"
                                icon={SparkleIcon}
                                iconColor="warning.400"
                              >
                                Boosted
                              </Badge>
                            </Tooltip>
                          )}
                        </Row>
                      </Column>
                    </Row>
                  );
                },
              },
              {
                name: "Syncs",
                max: "max-content",
                min: "232px",
                disabled: ({ syncs }) => Boolean(syncs?.length),
                cell: ({ syncs }) => {
                  return <SyncsCell syncs={syncs} />;
                },
              },
              {
                ...LastUpdatedColumn,
                breakpoint: "md",
              },
              {
                name: "Folder",
                max: ".5fr",
                cell: ({ folder }) => {
                  if (folder) {
                    return (
                      <Row gap={2} align="center" overflow="hidden">
                        <Box as={FolderIcon} fontSize="16px" />
                        <Text isTruncated fontWeight="medium">
                          {folder.name}
                        </Text>
                      </Row>
                    );
                  }
                  return "--";
                },
                breakpoint: "md",
              },
              {
                name: "Labels",
                max: ".5fr",
                cell: ({ tags }) => {
                  if (isEmpty(tags)) {
                    return "--";
                  }
                  return <Labels labels={tags} />;
                },
                breakpoint: "lg",
              },
            ]}
            data={models}
            error={Boolean(modelsQuery.error)}
            loading={modelsQuery.isFetching}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={({ id }, event) =>
              openUrl(`/models/${id}`, navigate, event)
            }
            onSelect={onRowSelect}
            rowHeight="80px"
            pagination={{
              count: filteredModelCount,
              label: "models",
              page,
              setPage,
            }}
            sortOptions={sortOptions}
          />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="model"
        content={
          <Column gap={2}>
            <Paragraph>
              Are you sure you want to delete {selectedRows.length}{" "}
              {pluralize("model", selectedRows.length)}? You will not be able to
              undo this.
            </Paragraph>

            <Paragraph>
              All syncs associated with the selected models will also be
              deleted.
            </Paragraph>
          </Column>
        }
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteModels}
      />

      {movingToFolder && (
        <MoveFolder
          folder={null}
          folderType="models"
          modelIds={selectedRows.map((id) => id.toString())}
          viewType="models"
          onClose={() => {
            setMovingToFolder(false);
            modelsQuery.refetch();
            onRowSelect([]);
          }}
        />
      )}

      <EditLabelModal
        rows={selectedRows.length}
        isOpen={addingLabels}
        resourceType={ResourceType.Model}
        onClose={() => setAddingLabels(false)}
        onSubmit={async (labels) => {
          await updateModelsMutation.mutateAsync({
            ids: selectedRows.map(String),
            input: { tags: labels },
          });
          onRowSelect([]);
        }}
      />
    </>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.model) {
    return <Models />;
  }

  return (
    <Page
      fullWidth
      outsideTopbar={
        resources?.source ? null : (
          <PageAlert
            button={
              <PermissionedLinkButton
                href="/sources/new"
                permission={{ v2: { resource: "source", grant: "can_create" } }}
                variant="primary"
              >
                Configure data source
              </PermissionedLinkButton>
            }
            message="Hightouch must be connected to least one data source before you can create a model. Your source can be a data warehouse, spreadsheet, or other data system."
            title="First, you need to configure a data source"
          />
        )
      }
      title="Models"
    >
      <Heading mb={8} size="xl">
        Models
      </Heading>
      <Placeholder
        content={{
          image: modelPlaceholder,
          title: "No models in this workspace",
          body: "A model describes how your data source will be queried. For most sources, you can use SQL to filter, join, and transform your data before syncing. Alternatively, you can select an existing table or view, or import models from other tools like Looker and dbt.",
          button: resources?.source ? (
            <PermissionedLinkButton
              href="/models/new"
              permission={{ v1: { resource: "model", grant: "create" } }}
              variant="primary"
            >
              Add model
            </PermissionedLinkButton>
          ) : null,
        }}
      />
    </Page>
  );
};

export default Loader;
