import { Box, Column, Row, Spinner, Text } from "@hightouchio/ui";
import { Card } from "src/components/card";
import { useDecisionEngineDestinationResourceQuery } from "src/graphql";

export const Push = ({ destinationId, resourceId }) => {
  const { data } = useDecisionEngineDestinationResourceQuery(
    {
      destinationId,
      resourceId,
    },
    { select: (data) => data.getDecisionEngineDestinationResource },
  );

  if (!data) {
    return (
      <Card>
        <Spinner m="auto" />
      </Card>
    );
  }

  if (data.__typename === "DecisionEngineDestinationPushResource") {
    return (
      <Card bg="grass.200" minH="200px">
        <Column borderRadius="md" bg="gray.100" p={3} gap={3}>
          <Row align="center" gap={2}>
            <Box w="24px" h="24px" bg="gray.300" borderRadius="md" />
            <Text
              fontWeight="semibold"
              color="text.tertiary"
              size="sm"
              textTransform="uppercase"
            >
              App name
            </Text>
          </Row>

          <Column>
            <Text fontWeight="semibold">{data.title ?? "Title"}</Text>
            <Text>{data.body}</Text>
          </Column>
        </Column>
      </Card>
    );
  }

  return null;
};
