import { FC, useState } from "react";

import {
  Column,
  Row,
  MenuActionsButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  Tab,
  Menu,
  DeleteIcon,
  PlayIcon,
  Spinner,
  Button,
  useToast,
  ButtonGroup,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Outlet, useLocation, useNavigate } from "src/router";

import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  eventSourceDefinitions,
  eventWarehouseDestinationDefinitions,
} from "src/events/types";
import {
  useDeleteEventWarehouseSyncMutation,
  useRunEventWarehouseSyncMutation,
} from "src/graphql";
import { WarehouseOutletContext } from "src/events/syncs/common";
import { SyncName } from "src/events/syncs/common/sync-name";
import { useFlags } from "launchdarkly-react-client-sdk";

export type { WarehouseOutletContext as OutletContext };

const tabs = ["runs", "archive", "schedule", "alerting"];

export const WarehouseSync: FC<
  Readonly<{ sync: WarehouseOutletContext["sync"] }>
> = ({ sync }) => {
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { appEventMonitoringEnabled } = useFlags();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMutation = useDeleteEventWarehouseSyncMutation();
  const runMutation = useRunEventWarehouseSyncMutation({
    onSuccess: () => {
      // skip invalidating queries
    },
  });

  const sourceDefinition = eventSourceDefinitions[sync.event_source.type];
  const destinationDefinition =
    eventWarehouseDestinationDefinitions[sync.event_warehouse_destination.type];
  const lastRun = sync.runs?.[0];
  const isRunning = lastRun && lastRun.started_at && !lastRun.finished_at;

  const startRun = async () => {
    try {
      runMutation.mutateAsync({ id: sync.id });
      toast({
        id: "run-success",
        title: `Sync run has been initiated.`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "run-error",
        title: `There was a problem running your sync.`,
        variant: "error",
      });
    }
  };

  return (
    <Page title="Sync" crumbs={[{ label: "All syncs", link: "/events/syncs" }]}>
      <Column minWidth={0} mb={4} gap={2}>
        <Row flex={1} justify="space-between" minWidth={0} pt={1}>
          <SyncName
            isWarehouseSync
            sourceDefinition={sourceDefinition}
            destinationDefinition={destinationDefinition}
            source={sync.event_source}
            destination={sync.event_warehouse_destination}
          />
          <ButtonGroup>
            <Menu>
              <MenuActionsButton variant="secondary" />
              <MenuList>
                <MenuItem
                  icon={DeleteIcon}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
            {isRunning ? (
              <Button isDisabled>
                <Spinner size="sm" mr={2} />
                Running
              </Button>
            ) : (
              <Button
                icon={PlayIcon}
                onClick={() => {
                  startRun();
                }}
              >
                Run
              </Button>
            )}
          </ButtonGroup>
        </Row>
      </Column>
      <Tabs
        onChange={(index) => navigate(tabs[index]!)}
        index={tabs.findIndex((tab) => location.pathname.endsWith(tab))}
        mb={6}
      >
        <TabList>
          <Tab>Runs</Tab>
          <Tab>Archived failures</Tab>
          <Tab>Schedule</Tab>
          {appEventMonitoringEnabled && <Tab>Alerting</Tab>}
        </TabList>
      </Tabs>
      <Outlet context={{ sync, type: "warehouse" } as WarehouseOutletContext} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="sync"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: sync.id });
          navigate("/events/syncs");
        }}
      />
    </Page>
  );
};
