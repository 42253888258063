import { useState, FC } from "react";

import {
  Row,
  Box,
  Text,
  Button,
  Select,
  FormField,
  Spinner,
  PlusIcon,
} from "@hightouchio/ui";

import { Link } from "src/router";

import {
  useTunnelsQuery,
  useTestTunnelQuery,
  CreateNormalTunnelMutation,
  CreateReverseTunnelMutation,
} from "src/graphql";
import { Circle } from "src/ui/circle";
import { SourceTunnel } from "src/utils/sources";

import {
  CreateReverseTunnelForm,
  CreateNormalTunnelForm,
  ChooseTunnelForm,
} from "./create-tunnel";

export interface TunnelSelectProps {
  value:
    | {
        id: string | undefined;
        tunnel_id?: string;
      }
    | undefined;
  optional?: boolean;
  onChange: (tunnel: SourceTunnel | undefined) => void;
}

export const TunnelSelect: FC<TunnelSelectProps> = ({
  onChange,
  value,
  optional = false,
}) => {
  const [askingTunnelType, setAskingTunnelType] = useState(false);
  const [tunnelType, setTunnelType] = useState<"normal" | "reverse">("normal");
  const [creatingTunnel, setCreatingTunnel] = useState<boolean>(false);
  const [creatingReverseTunnel, setCreatingReverseTunnel] =
    useState<boolean>(false);

  const { data, refetch } = useTunnelsQuery();
  const tunnelOptions =
    data?.getTunnels?.map(({ tunnel: { name, id } }) => ({
      label: name ?? "",
      value: id,
    })) ?? [];

  const { data: tunnelTest, status: tunnelTestStatus } = useTestTunnelQuery(
    {
      id: value?.id ?? "",
    },
    {
      enabled: typeof value?.id === "string",
    },
  );

  const tunnelCreated = (
    {
      tunnel,
    }:
      | CreateNormalTunnelMutation["createNormalTunnel"]
      | CreateReverseTunnelMutation["createReverseTunnel"],
    name: string,
  ) => {
    if (tunnel.id) {
      onChange({
        id: tunnel.id,
        name,
      });

      void refetch();
    }
  };

  return (
    <>
      <FormField
        isOptional={optional}
        label="Tunnel"
        description="Tunnels allow easy access to services running within a private network."
      >
        {tunnelOptions.length || value ? (
          <>
            <Box alignItems="center" display="flex" gap={3}>
              <Select
                isClearable
                options={[
                  { label: "None", value: undefined },
                  ...tunnelOptions,
                ]}
                placeholder="Select a tunnel..."
                value={value?.id || value?.tunnel_id}
                onChange={(newValue) => {
                  const option = tunnelOptions.find(
                    (option) => option.value === newValue,
                  );

                  if (option?.value) {
                    onChange({
                      id: option.value,
                      name: option.label,
                    });
                  } else {
                    onChange(undefined);
                  }
                }}
              />

              <Button icon={PlusIcon} onClick={() => setAskingTunnelType(true)}>
                New tunnel
              </Button>
            </Box>
          </>
        ) : (
          <Row align="center" gap={4}>
            <Button onClick={() => setCreatingTunnel(true)}>
              Create a tunnel
            </Button>
            <Text textTransform="uppercase">or</Text>
            <Button onClick={() => setCreatingReverseTunnel(true)}>
              Create a reverse tunnel
            </Button>
          </Row>
        )}

        <Row mt={2} align="center" gap={2}>
          {tunnelTestStatus === "loading" && (
            <>
              <Spinner size="sm" />
              <Text size="sm">Testing...</Text>
            </>
          )}
          {tunnelTest?.checkTunnel.success && (
            <>
              <Circle bg="success.base" radius="8px" />
              <Text ml={2} size="sm">
                Active
              </Text>
            </>
          )}
          {tunnelTest?.checkTunnel.success === false && (
            <>
              <Circle bg="danger.base" radius="8px" />
              <Text>
                Disconnected. Go to{" "}
                <Link href="/settings/tunnels">tunnel settings</Link> to
                re-connect.
              </Text>
            </>
          )}
        </Row>
      </FormField>

      {askingTunnelType && (
        <ChooseTunnelForm
          setTunnelType={setTunnelType}
          tunnelType={tunnelType}
          onClose={() => {
            setAskingTunnelType(false);
            setTunnelType("normal");
          }}
          onContinue={() => {
            setAskingTunnelType(false);

            if (tunnelType === "normal") {
              setCreatingTunnel(true);
            } else {
              setCreatingReverseTunnel(true);
            }
          }}
        />
      )}

      {creatingTunnel && (
        <CreateNormalTunnelForm
          onClose={() => setCreatingTunnel(false)}
          onCreate={tunnelCreated}
        />
      )}
      {creatingReverseTunnel && (
        <CreateReverseTunnelForm
          onClose={() => setCreatingReverseTunnel(false)}
          onCreate={tunnelCreated}
        />
      )}
    </>
  );
};
