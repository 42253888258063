import { PluginHandler, Plugins } from "src/components/rte/common";
import { flattenOptions } from "src/ui/select";

import { FormkitGraphQLReference } from "@hightouch/formkit";
import { RichTextEditor } from "src/components/rte";
import { graphQLFetch } from "src/formkit/formkit";
import { useFormkitContext } from "./formkit-context";

type Props = {
  profile: string;
  value: string;
  onChange?: (value: string) => void;
  handler?: FormkitGraphQLReference;
  placeholder?: string;
  isDisabled?: boolean;
};

export const FormkitRTE: React.FC<Readonly<Props>> = ({
  value,
  onChange,
  profile,
  handler,
  placeholder,
  isDisabled,
}) => {
  const columns = flattenOptions(useFormkitContext().columns);
  const { destination, model } = useFormkitContext();

  const cache = new Map<string, Promise<{ label: string; value: unknown }[]>>();

  const match =
    (query: string) =>
    (option: { label: string; value: unknown } | undefined) => {
      const lowecasedQuery = query?.toLocaleLowerCase();

      return (
        option?.label?.toLocaleLowerCase()?.includes(lowecasedQuery) ||
        String(option?.value)
          .toLocaleLowerCase()
          ?.includes(lowecasedQuery)
      );
    };

  const pluginHandler: PluginHandler<Plugins> = async (props) => {
    const limit = props.args.limit || Number.MAX_SAFE_INTEGER;
    if (props.plugin === Plugins.LIQUID) {
      return columns.filter(match(props.args?.query)).splice(0, limit);
    }

    const promise =
      cache.get(props.plugin) ??
      cache
        .set(
          props.plugin,
          graphQLFetch({
            query: handler?.["query"],
            variables: {
              ...handler?.variables,
              input: {
                ...(typeof handler?.variables?.["input"] === "object"
                  ? handler?.variables?.["input"]
                  : null),
                variables: props,
              },
            },
            destinationId: destination?.id,
            modelId: model?.id,
          }),
        )
        .get(props.plugin);

    const result = await promise;

    return result?.filter(match(props.args?.query)).splice(0, limit) || [];
  };

  return (
    <RichTextEditor
      isDisabled={isDisabled}
      onChange={onChange}
      value={value || ""}
      placeholder={placeholder}
      profileProps={
        profile === "liquid-slack"
          ? { profile, handler: pluginHandler }
          : undefined
      }
    />
  );
};
