/**
 * Remove all elements from array `a` that are found in array `b`.
 *
 * @param a
 * @param b
 * @returns A new filtered array derived from array `a`.
 */
export const differenceOfFirstArray = <T extends { id: string | number }>(
  a: T[],
  b: T[],
): T[] => {
  return a.filter((object1) => {
    return !b.some((object2) => object1.id === object2.id);
  });
};

/**
 * Find the difference between two input arrays.
 * Creates a new array where all elements have unique ids.
 *
 * Ex:
 *
 * ```
 * const a = [{ id: 1 }, { id: 2 }];
 * const b = [{ id: 3 }, { id: 4 }, { id: 1 }];
 * const c = difference(a, b);
 * console.log(c) // [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
 * ```
 *
 * @param a First Array
 * @param b Second Array
 * @returns The difference between the two arrays.
 */
export const getDifference = <T extends { id: string | number }>(
  a: T[] | undefined = [],
  b: T[] | undefined = [],
): T[] => {
  return [...differenceOfFirstArray(a, b), ...differenceOfFirstArray(b, a)];
};
