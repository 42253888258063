import { FC, Suspense } from "react";

import { Column, Heading, Row, Spinner } from "@hightouchio/ui";
import { Navigate, Outlet, Route, Routes, useOutletContext } from "src/router";

import { useUser } from "src/contexts/user-context";
import { OrganizationQuery, useOrganizationQuery } from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { Roles } from "./roles";
import { NewRole } from "./roles/new";
import { RoleUsers } from "./roles/role/users";
import { RoleWorkspacePolicy as RoleWorkspaceGrants } from "./roles/role/workspaces/workspace";
import { RoleWorkspaces } from "./roles/role/workspaces";
import { Users } from "./users";
import { ViewUser } from "./users/view";
import { DestinationGrants } from "./roles/role/workspaces/workspace/destinations";
import { SourceGrants } from "./roles/role/workspaces/workspace/sources";
import { ParentModelGrants } from "./roles/role/workspaces/workspace/parent-models";
import { ViewRole } from "./roles/role";
import { AddUsers } from "./roles/role/users/add";
import { InviteUserModal } from "./users/invite";
import { Page } from "src/components/layout";
import { GeneralGrants } from "./roles/role/workspaces/workspace/general";
import { SSOSetup } from "./sso";
import { SSOGroupMapping } from "./sso/groups";
import { useOrganizationPermissions } from "src/components/permission/use-organization-permissions";
import { Organization } from "./organization";
import { RouteTabs } from "src/components/route-tabs";

export type OutletContext = {
  organization: NonNullable<OrganizationQuery["organizations_by_pk"]>;
};

export const OrganizationRoutes = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="settings" />} />
          <Route path="settings" element={<Organization />} />
          <Route path="members" element={<Users />}>
            <Route path="invite" element={<InviteUserModal />} />
            <Route path=":userId" element={<ViewUser />} />
          </Route>
          <Route path="groups" element={<Roles />}>
            <Route path="new" element={<NewRole />} />
            <Route path=":roleId" element={<ViewRole />}>
              <Route
                index
                element={
                  <Navigate
                    to={{ pathname: "workspaces", search: location.search }}
                    replace
                  />
                }
              />
              <Route path="members" element={<RoleUsers />}>
                <Route path="new" element={<AddUsers />} />
              </Route>
              <Route path="workspaces" element={<RoleWorkspaces />}>
                <Route path=":workspaceId" element={<RoleWorkspaceGrants />}>
                  <Route
                    index
                    element={
                      <Navigate
                        to={{ pathname: "general", search: location.search }}
                        replace
                      />
                    }
                  />
                  <Route path="general" element={<GeneralGrants />} />
                  <Route path="sources" element={<SourceGrants />} />
                  <Route path="destinations" element={<DestinationGrants />} />
                  <Route path="parent-models" element={<ParentModelGrants />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="sso" element={<SSOSetup />} />
          <Route path="sso-mappings" element={<SSOGroupMapping />} />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { workspace } = useUser();
  const id = workspace?.organization?.id?.toString();

  const { data: organization, isLoading } = useOrganizationQuery(
    { id: id || "" },
    { enabled: Boolean(id), select: (data) => data.organizations_by_pk },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  return <Outlet context={{ organization }} />;
};

export const Layout: FC = () => {
  const { organization } = useOutletContext<OutletContext>();
  const { isOrganizationAdmin, isAdminOfAnyWorkspace, isManagerOfAnyGroup } =
    useOrganizationPermissions();

  const isSsoEnabled = (organization?.auth0_connections || []).length > 0;

  const tabs = [
    { title: "General", path: "settings" },
    { title: "Users", path: "members" },
  ];

  if (isOrganizationAdmin || isAdminOfAnyWorkspace || isManagerOfAnyGroup) {
    tabs.push({ title: "Groups", path: "groups" });
  }

  if (isOrganizationAdmin) {
    tabs.push({ title: "Single sign-on", path: "sso" });

    if (isSsoEnabled) {
      tabs.push({ title: "SSO mappings", path: "sso-mappings" });
    }
  }

  return (
    <Page
      title="Organization settings"
      contain
      fullWidth
      sx={{ pb: 0 }}
      header={
        <Column>
          <Row p={6}>
            <Heading size="xl">Organization settings</Heading>
          </Row>
          <RouteTabs tabs={tabs} px={6} />
        </Column>
      }
    >
      <Suspense fallback={<Spinner size="lg" m="auto" />}>
        <Outlet context={{ organization }} />
      </Suspense>
    </Page>
  );
};
