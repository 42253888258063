import { FC } from "react";

import {
  Text,
  Heading,
  Row,
  Column,
  Button,
  PlayIcon,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { useOutletContext } from "src/router";

import { Step } from "src/components/step";
import { SidebarFormContent } from "src/components/page";
import { useRunIdentityResolutionMutation } from "src/graphql";

import { OutletContext } from ".";

export const SummaryDraft: FC = () => {
  const runMutation = useRunIdentityResolutionMutation();
  const { graph, runsDisabled } = useOutletContext<OutletContext>();
  const { toast } = useToast();

  const modelsCount = graph.models?.length ?? 0;
  let mergeRulesCount = 0;
  // We modified merge_rules to be an array of MergeRules objects, we need to handle the old
  // structure for backwards compatibility.
  if (Array.isArray(graph.merge_rules)) {
    mergeRulesCount = graph.merge_rules.reduce(
      (totalConditions, mergeRuleGroup) =>
        totalConditions + mergeRuleGroup.conditions?.length ?? 0,
      0,
    );
  } else {
    mergeRulesCount = graph.merge_rules?.conditions?.length ?? 0;
  }
  const limitRulesCount = graph.block_rules?.length ?? 0;

  const startRun = async (fullReRun: boolean) => {
    try {
      await runMutation.mutateAsync({
        id: graph.id,
        fullReRun,
      });

      toast({
        id: "run-success",
        title: `Identity graph run has been initiated.`,
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "run-error",
        title: "There was a problem running your identity graph.",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Row gap={6} align="start" justify="space-between">
        <Column
          align="start"
          bg="white"
          borderRadius="md"
          boxShadow="xs"
          border="1px solid"
          borderColor="base.border"
          gap={6}
          p={6}
          w="100%"
          flex={1}
        >
          <Column gap={2}>
            <Heading>Draft identity graph</Heading>

            <Text color="text.secondary">
              Complete the setup and run the identity graph to start seeing
              results.
            </Text>
          </Column>

          <Step
            isChecked={modelsCount >= 1}
            heading="Input models:"
            description="Select the input data to build your identity graph off of."
          >
            <Link href={`/idr/${graph.id}/models`}>
              {modelsCount} models added
            </Link>
          </Step>

          <Step
            isChecked={mergeRulesCount >= 1}
            heading="Rules:"
            description="Define the rules of when to merge identities."
          >
            <Link href={`/idr/${graph.id}/rules`}>
              {mergeRulesCount} merge {pluralize("rule", mergeRulesCount)}
            </Link>
            <Link href={`/idr/${graph.id}/rules`}>
              {limitRulesCount} limit {pluralize("rule", limitRulesCount)}
            </Link>
          </Step>

          <Tooltip
            isDisabled={!runsDisabled}
            message="Complete the setup to run the identity graph"
          >
            <Button
              isDisabled={runsDisabled}
              variant="primary"
              icon={PlayIcon}
              onClick={() => {
                startRun(true);
              }}
            >
              Run
            </Button>
          </Tooltip>
        </Column>

        <Column
          bg="white"
          borderRadius="md"
          boxShadow="xs"
          border="1px solid"
          borderColor="base.border"
          flex={0.3}
          minW="294px"
          p={6}
        >
          <SidebarFormContent
            hideInviteTeammate
            name="identity resolution"
            docsUrl=""
          />
        </Column>
      </Row>
    </>
  );
};
