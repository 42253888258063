import { useEffect, useState } from "react";

import { Button, Dialog, Text } from "@hightouchio/ui";
import { useNavigate } from "src/router";

export const SaveWarning = ({ dirty: _dirty }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [destination, _setDestination] = useState<string | undefined>();

  useEffect(() => {
    if (confirmed && destination) {
      navigate(destination);
    }
  }, [confirmed, destination]);

  return (
    <>
      <Dialog
        isOpen={isOpen}
        variant="form"
        width="lg"
        title="Lose changes?"
        actions={
          <>
            <Button onClick={() => setIsOpen(false)}>Go back</Button>
            <Button
              variant="primary"
              onClick={() => {
                setIsOpen(false);
                setConfirmed(true);
              }}
            >
              Yes, continue
            </Button>
          </>
        }
        onClose={() => setIsOpen(false)}
      >
        <Text>
          You haven’t saved your latest changes. Are you sure you want to leave?
        </Text>
      </Dialog>
    </>
  );
};
