import {
  ConfirmationDialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Paragraph,
} from "@hightouchio/ui";
import { FC, useState } from "react";
import {
  MonitorConditionTemplatesInsertInput,
  SubscribeableResourcesQuery,
  useDeleteAllTriggerOverridesMutation,
  useDeleteDefaultDestinationTriggersMutation,
  useDestinationsByTypeQuery,
  useInsertDefaultDestinationTriggersMutation,
  useResourceDefaultConditionsQuery,
} from "src/graphql";
import { flatten } from "lodash";
import pluralize from "pluralize";
import { useFormContext } from "react-hook-form";
import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";

interface BulkManagementGlobalOptionsProps {
  filteredResources: SubscribeableResourcesQuery["destinations"];
}

export const DestinationTriggerGlobalOptions: FC<
  BulkManagementGlobalOptionsProps
> = ({ filteredResources }) => {
  const {
    formState: { isDirty },
  } = useFormContext();

  const { mutateAsync: deleteDefaultTriggers } =
    useDeleteDefaultDestinationTriggersMutation();

  const { mutateAsync: removeSyncOverrides } =
    useDeleteAllTriggerOverridesMutation();

  const targetDestination = filteredResources?.[0];

  const {
    data: destinationsOfSameType,
    isLoading: loadingSimilarDestinations,
  } = useDestinationsByTypeQuery(
    {
      destinationType: targetDestination?.type ?? "",
    },
    {
      enabled: !!targetDestination && filteredResources?.length === 1,
      select(data) {
        return data.destinations.filter(
          (d) => d.id?.toString() !== targetDestination?.id?.toString(),
        );
      },
    },
  );

  const ConfirmAction: Record<
    string,
    {
      body: string;
      title: string;
      extra?: (args) => string;
      action: () => Promise<unknown>;
    }
  > = {
    ClearAllOverrides: {
      body: "Are you sure you want to remove all sync-level overrides?",
      title: "Clear overrides",
      action: async () => {
        await removeSyncOverrides({
          resourceIds: flatten(
            filteredResources.map((r) => r.syncs.map((s) => s.id.toString())),
          ),
          resourceType: MonitoredResourceType.Sync,
        });
        // Reloading the window isn't ideal here, but it saves us
        // having to add a loading spinner _somewhere_ in the ui
        // which we don't have a good place for right now.
        window.location.reload();
      },
      extra: (destinationIds: string[]) =>
        !destinationIds
          ? ""
          : `This will impact ${destinationIds.length} ${pluralize(
              "destination",
              destinationIds.length,
            )}.`,
    },
    RestoreDefaults: {
      body: "Are you sure you want to restore default triggers? Sync-level overrides will not be removed.",
      title: "Restore defaults",
      action: async () =>
        deleteDefaultTriggers({
          destinationIds: filteredResources.map((r) => r.id.toString()),
        }),
    },
    ApplyToDestinationType: {
      body: "Apply these triggers to all destinations of the same type.",
      title: "Apply destination defaults",
      action: async () => applyToAllDestinationsOfType(),
      extra: (destinationIds: string[]) =>
        !destinationIds
          ? ""
          : `This will impact ${destinationIds.length} ${pluralize(
              "destination",
              destinationIds.length,
            )}.`,
    },
  };
  const [confirmAction, setConfirmAction] = useState<
    (typeof ConfirmAction)[keyof typeof ConfirmAction] | undefined
  >();

  const {
    data: defaultConditionsForDestination,
    isLoading: loadingTargetDestinationDefaults,
  } = useResourceDefaultConditionsQuery(
    {
      parentResourceType: ParentResourceTypes.Destination,
      parentResourceId: targetDestination?.id?.toString() ?? "",
    },
    {
      enabled: !!targetDestination && filteredResources?.length === 1,
      select(data) {
        return data.monitor_condition_templates;
      },
    },
  );

  const { mutateAsync: updateDefaultsForType } =
    useInsertDefaultDestinationTriggersMutation();

  const applyToAllDestinationsOfType = async () => {
    if (
      !destinationsOfSameType ||
      !targetDestination ||
      loadingSimilarDestinations ||
      loadingTargetDestinationDefaults
    )
      return;

    const destinationIds = destinationsOfSameType.map((d) => d.id.toString());

    const objects: MonitorConditionTemplatesInsertInput[] = [];

    for (const destinationId of destinationIds) {
      for (const condition of defaultConditionsForDestination ?? []) {
        objects.push({
          ...condition,
          parent_resource_id: destinationId.toString(),
        });
      }
    }

    // This is going to nuke any existing defaults on the other destinations
    // then upsert the defaults from the target destination.
    await updateDefaultsForType({
      parentResourceIdsToClear: destinationIds,
      objects,
    });
  };

  return (
    <>
      <Menu>
        <MenuButton isDisabled={!filteredResources?.length}>Options</MenuButton>
        <MenuList>
          <MenuItem
            disabled={isDirty}
            hidden={
              filteredResources.length > 1 || !destinationsOfSameType?.length
            }
            onClick={() =>
              setConfirmAction(ConfirmAction.ApplyToDestinationType)
            }
          >
            Apply these triggers to all "
            {filteredResources?.[0]?.definition.name}" destinations in this
            workspace
          </MenuItem>{" "}
          <MenuItem
            disabled={isDirty}
            hidden={filteredResources.length > 1}
            onClick={async () =>
              setConfirmAction(ConfirmAction.RestoreDefaults)
            }
          >
            Restore default triggers for "
            {filteredResources?.[0]?.definition.name}"{" "}
          </MenuItem>
          <MenuItem
            disabled={isDirty}
            hidden={filteredResources.length <= 1}
            onClick={async () =>
              setConfirmAction(ConfirmAction.RestoreDefaults)
            }
          >
            Restore default triggers for selected destinations
          </MenuItem>
          <MenuItem
            disabled={isDirty}
            onClick={async () =>
              setConfirmAction(ConfirmAction.ClearAllOverrides)
            }
          >
            Remove all sync-level overrides
          </MenuItem>
        </MenuList>
      </Menu>
      <ConfirmationDialog
        isOpen={!!confirmAction}
        title={confirmAction?.title ?? ""}
        confirmButtonText="Proceed"
        variant="warning"
        onClose={() => setConfirmAction(undefined)}
        onConfirm={async () => {
          await confirmAction?.action();
          setConfirmAction(undefined);
        }}
      >
        <Paragraph>
          {confirmAction?.body} {confirmAction?.extra?.(destinationsOfSameType)}
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
