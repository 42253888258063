import { FC } from "react";

import { StatusBadge } from "@hightouchio/ui";
import { UnreachableCaseError } from "ts-essentials";

import { IdrRunStatus } from "src/pages/identity-resolution/types";

export const IdentityResolutionStatusBadge: FC<{ status: IdrRunStatus }> = ({
  status,
}) => {
  switch (status) {
    case IdrRunStatus.Success:
      return <StatusBadge variant="success">Healthy</StatusBadge>;
    case IdrRunStatus.Failure:
      return <StatusBadge variant="error">Failed</StatusBadge>;
    case IdrRunStatus.Processing:
      return <StatusBadge variant="processing">Processing</StatusBadge>;
    case IdrRunStatus.Queued:
      return <StatusBadge variant="processing">Queued</StatusBadge>;
    case IdrRunStatus.Cancelling:
      return <StatusBadge variant="inactive">Cancelling</StatusBadge>;
    case IdrRunStatus.Cancelled:
      return <StatusBadge variant="inactive">Cancelled</StatusBadge>;
    default:
      throw new UnreachableCaseError(status);
  }
};
