import { FC, ReactNode, useMemo } from "react";

import {
  Text,
  Heading,
  Row,
  Column,
  Box,
  Alert,
  Tooltip,
  InformationIcon,
  ArrowRightIcon,
  HeadingProps,
  StatsItemTitle,
} from "@hightouchio/ui";
import { Link } from "src/router";
import pluralize from "pluralize";
import { useOutletContext } from "src/router";

import { IdentityResolutionStatusBadge } from "src/pages/identity-resolution/status-badge";
import {
  IdentityGraph,
  IdrRunStatus,
  IdrStats,
  ModelStats,
} from "src/pages/identity-resolution/types";
import { IdrEventIcon, IdrProfileIcon } from "src/ui/icons/identity-resolution";
import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { commaNumber } from "src/utils/numbers";
import { diff, formatDatetime } from "src/utils/time";

import { OutletContext } from ".";
import { SummaryDraft } from "./summary-draft";

const EmptyNumberPlaceholder = "--";

export const Summary: FC = () => {
  const { graph } = useOutletContext<OutletContext>();
  const currentRun = graph.runs[0];
  const previousRun = graph.runs[1];

  const lastRun = currentRun?.finished_at ? currentRun : previousRun;

  if (lastRun) {
    return <IdrSummaryDashboard run={lastRun} graph={graph} />;
  }

  // If we don't have a completed run, but we have a current run, it means that we are in the
  // process of executing the first run for this graph. In that case, we draw the dashboard with a
  // bunch of placeholder values.
  if (currentRun) {
    return <IdrSummaryDashboard run={currentRun} graph={graph} />;
  }

  return <SummaryDraft />;
};

const IdrSummaryDashboard: FC<{
  run: IdentityGraph["runs"][number];
  graph: IdentityGraph;
}> = ({ run, graph }) => {
  const stats: IdrStats | undefined = run.stats;

  const summaryValues: {
    totalHtIds: string;
    totalSrcRows: string;
    eventHtIds: string;
    eventSrcRows: string;
    profileHtIds: string;
    profileSrcRows: string;
  } = useMemo(() => {
    if (stats) {
      return {
        totalHtIds: !stats.isLegacy
          ? commaNumber(stats.num_ht_ids)
          : EmptyNumberPlaceholder,
        totalSrcRows: commaNumber(
          stats.events.source_rows + stats.profiles.source_rows,
        ),
        eventHtIds: commaNumber(stats.events.num_ht_ids),
        eventSrcRows: commaNumber(stats.events.source_rows),
        profileHtIds: commaNumber(stats.profiles.num_ht_ids),
        profileSrcRows: commaNumber(stats.profiles.source_rows),
      };
    }
    // If we don't have stats, we just need to add in a bunch of placeholder values
    return {
      totalHtIds: EmptyNumberPlaceholder,
      totalSrcRows: EmptyNumberPlaceholder,
      eventHtIds: EmptyNumberPlaceholder,
      eventSrcRows: EmptyNumberPlaceholder,
      profileSrcRows: EmptyNumberPlaceholder,
      profileHtIds: EmptyNumberPlaceholder,
    };
  }, [stats]);

  // Gather model counts by type so we can determine whether to pluralize help text.
  let numObjectModels = 0;
  let numEventModels = 0;
  for (const model of graph.models) {
    if (model.type === "event") {
      numEventModels++;
    } else {
      numObjectModels++;
    }
  }

  const objectModelText = pluralize("model", numObjectModels);
  const eventModelText = pluralize("model", numEventModels);

  return (
    <Column gap={6}>
      {run.status === IdrRunStatus.Failure && (
        <Alert
          variant="inline"
          type="error"
          message={run.error?.error ?? "Internal error"}
          title="Run failure"
        />
      )}
      <Box
        display="grid"
        gap={6}
        gridTemplateRows="repeat(2, minmax(1fr, 210px))"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        width="100%"
      >
        <Box gridRow="1" gridColumn="1">
          <StatsSummaryCard
            heading="Overview"
            statsContent={{
              primary: {
                tooltip: "Number of profiles generated",
                heading: "Hightouch IDs",
                value: summaryValues.totalHtIds,
              },
              secondary: {
                tooltip: "Number of input rows from all models",
                heading: "Source Rows",
                value: summaryValues.totalSrcRows,
              },
            }}
          />
        </Box>
        <Box gridRow="1" gridColumn="2">
          <StatsSummaryCard
            heading="Object models"
            icon={<IdrProfileIcon width="24px" height="24px" />}
            statsContent={{
              primary: {
                tooltip: `Number of profiles deduplicated from the object ${objectModelText}`,
                heading: "Unique IDs",
                value: summaryValues.profileHtIds,
              },
              secondary: {
                tooltip: `Number of input rows from the object ${objectModelText}`,
                heading: "Source Rows",
                value: summaryValues.profileSrcRows,
              },
            }}
          />
        </Box>
        <Box gridRow="1" gridColumn="3">
          <StatsSummaryCard
            heading="Event models"
            icon={<IdrEventIcon width="24px" height="24px" />}
            statsContent={{
              primary: {
                tooltip: `Number of profiles resolved from the event ${eventModelText}`,
                heading: "Unique IDs",
                value: summaryValues.eventHtIds,
              },
              secondary: {
                tooltip: `Number of input rows from the event ${eventModelText}`,
                heading: "Source Rows",
                value: summaryValues.eventSrcRows,
              },
            }}
          />
        </Box>
        <Box gridColumn="1" gridRow="2">
          <Run {...run} graphId={graph.id} />
        </Box>
        <Box gridColumn="2 / 4" gridRow="2">
          <PerModelStats stats={stats} graph={graph} />
        </Box>
      </Box>
    </Column>
  );
};

const Label: FC<{ children: ReactNode; textAlign?: "right" }> = ({
  children,
  textAlign,
}) => (
  <Box
    as="span"
    textTransform="uppercase"
    color="text.secondary"
    fontSize="sm"
    fontWeight="semibold"
    textAlign={textAlign}
  >
    {children}
  </Box>
);

const Run: FC<{
  created_at?: string | null;
  started_at?: string | null;
  finished_at?: string | null;
  status?: string;
  stats?: IdrStats | null;
  graphId: string;
}> = ({ started_at, finished_at, created_at, status, stats, graphId }) => {
  return (
    <Column
      gap={4}
      justify="space-between"
      p={6}
      bg="white"
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      boxShadow="xs"
    >
      <Column gap={2}>
        <Text color="text.primary" size="lg" fontWeight="medium">
          {finished_at ? "Last run" : "Current run"}
        </Text>
        <Text fontWeight="medium">
          {finished_at
            ? formatDatetime(started_at ?? "")
            : formatDatetime(created_at ?? "")}
        </Text>
      </Column>
      <Column gap={2}>
        <Label>Status</Label>
        <IdentityResolutionStatusBadge status={status as IdrRunStatus} />
      </Column>
      <Column gap={2}>
        <Label>Processing time</Label>
        {started_at && finished_at ? (
          <Text>{diff(started_at, finished_at)}</Text>
        ) : (
          <Text color="text.secondary">Calculating...</Text>
        )}
      </Column>
      <Column gap={2}>
        <Label>Profiles</Label>
        <Box textAlign="left">
          {stats?.profiles
            ? `+ ${commaNumber(stats.profiles.new)}`
            : EmptyNumberPlaceholder}
        </Box>
      </Column>
      <Link href={`/idr/${graphId}/runs`}>
        View all runs <ArrowRightIcon />
      </Link>
    </Column>
  );
};

const DashboardNumberWithHeader: FC<{
  tooltip: string;
  heading: string;
  value: string;
  numberSize: HeadingProps["size"];
}> = ({ tooltip, heading, value, numberSize }) => {
  // If the value is a placeholder, reduce visual emphasis
  const numberColor =
    value === EmptyNumberPlaceholder ? "text.secondary" : "text.primary";

  return (
    <Row gap={4}>
      <Column width="100%">
        <Row align="center" gap={1} pb={1}>
          <StatsItemTitle>{heading}</StatsItemTitle>
          <Tooltip message={tooltip}>
            <InformationIcon color="text.secondary" />
          </Tooltip>
        </Row>
        <Heading size={numberSize} color={numberColor}>
          {value}
        </Heading>
      </Column>
    </Row>
  );
};

// A dashboard card with 2 statistics - a primary statistic that is visually larger is displayed
// above a smaller secondary statistic.
const StatsSummaryCard: FC<{
  heading: string;
  icon?: ReactNode | undefined;
  statsContent: {
    primary: { tooltip: string; heading: string; value: string };
    secondary: { tooltip: string; heading: string; value: string };
  };
}> = ({ heading, icon, statsContent }) => {
  return (
    <Column
      bg="white"
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      p={6}
      boxShadow="xs"
      display="grid"
      gap={2}
      height="100%"
    >
      <Row
        align="center"
        gap={2}
        height="24px"
        fontSize="large"
        fontWeight="medium"
        pb={2}
      >
        {icon}
        {heading}
      </Row>
      <DashboardNumberWithHeader {...statsContent.primary} numberSize="2xl" />
      <DashboardNumberWithHeader {...statsContent.secondary} numberSize="lg" />
    </Column>
  );
};

const PerModelStats: FC<{
  stats: IdrStats | undefined;
  graph: IdentityGraph;
}> = ({ stats, graph }) => {
  const allModelStats: (ModelStats & {
    modelName: string;
    modelType: string;
  })[] = useMemo(() => {
    const modelStats: (ModelStats & {
      modelName: string;
      modelType: string;
    })[] = [];
    if (stats) {
      // Iterate through the graph's models rather than the stats models because if a model is
      // deleted, there will be a period when we have stats but not the model information, so we
      // can't determine the name of the model.
      for (const model of graph.models) {
        const findModelStats = (modelStat: ModelStats) => {
          return Number(modelStat.id) === model.model.id;
        };
        const thisModelStats =
          model.type === "event"
            ? stats.events.models.find(findModelStats)
            : stats.profiles.models.find(findModelStats);
        // This should always be present if we have any stats.
        if (thisModelStats) {
          modelStats.push({
            ...thisModelStats,
            modelName: model.model.name,
            modelType: model.type,
          });
        }
      }
      return modelStats;
    }
    return [];
  }, [stats, graph.models]);

  const columns: TableColumn[] = [
    {
      name: "MODELS",
      max: "3fr",
      min: "80px",
      headerSx: { "&:first-of-type": { pl: 6 } },
      cellSx: {
        pl: "6 !important",
        borderBottom: undefined,
        paddingTop: "16px",
      },
      cell: ({ modelName, modelType }) => {
        return (
          <TextWithTooltip message={modelName}>
            <Row gap={2}>
              {modelType === "event" ? (
                <IdrEventIcon width="20px" height="20px" />
              ) : (
                <IdrProfileIcon width="20px" height="20px" />
              )}
              {modelName}
            </Row>
          </TextWithTooltip>
        );
      },
    },
    {
      name: "SOURCE ROWS",
      min: "110px",
      cellSx: {
        borderBottom: undefined,
        paddingTop: "16px",
      },
      cell: ({ source_rows }) => {
        return <Text color="text.secondary">{commaNumber(source_rows)}</Text>;
      },
    },
    {
      name: "UNIQUE IDS",
      min: "110px",
      cellSx: {
        borderBottom: undefined,
        paddingTop: "16px",
      },
      cell: ({ num_ht_ids }) => {
        return <Text fontWeight="medium">{commaNumber(num_ht_ids)}</Text>;
      },
    },
  ];

  const placeholder = (
    <Column alignItems="center" justifyContent="center" py={16}>
      <Text color="text.secondary">
        No per-model stats are available. Run your graph to load the statistics.
      </Text>
    </Column>
  );

  return (
    // Note: the inner box is just there for padding.
    <Column
      bg="white"
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      boxShadow="xs"
      px="0"
      pb="4"
      pt="1"
    >
      <Table
        columns={columns}
        data={allModelStats}
        loading={false}
        error={false}
        placeholder={{ custom: placeholder }}
        rowHeight="36px"
      />
    </Column>
  );
};
