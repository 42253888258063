import { FC, useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  IconButton,
  Paragraph,
  Row,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { captureException } from "@sentry/react";
import { uniq } from "lodash";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useNavigate, useOutletContext, useParams } from "src/router";

import { Drawer } from "src/components/drawer";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  useDeleteIdentityResolutionModelMutation,
  useUpdateIdentityResolutionModelMutation,
} from "src/graphql";
import { updatePylonPosition } from "src/lib/pylon";
import { defaultIdentifiers } from "src/pages/identity-resolution/types";
import eventIcon from "src/pages/schema/assets/event.svg";
import profileIcon from "src/pages/schema/assets/parent.svg";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { abbreviateNumber } from "src/utils/numbers";

import { OutletContext } from ".";
import { MapperField, OrderByField, validationResolver } from "./create-model";

type FormState = {
  order_by: { column: string; order: "asc" | "desc" };
  mappings: { column: string; identifier: string }[];
  identifiers: string[];
};

export const Model: FC = () => {
  const { modelId: id } = useParams<{ modelId: string }>();
  const { graph, identifiers } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const onClose = () => {
    navigate(`/idr/${graph.id}/models`);
  };

  const model = graph.models.find((model) => String(model.id) === String(id));

  const updateMutation = useUpdateIdentityResolutionModelMutation();
  const deleteMutation = useDeleteIdentityResolutionModelMutation();

  const form = useForm<FormState>({
    defaultValues: {
      identifiers: uniq([...defaultIdentifiers, ...identifiers]),
      order_by: model?.order_by,
      mappings: [...(model?.mappings ?? []), { identifier: "", column: "" }],
    },
    resolver: async (data, context, options) => {
      // Add in the type so the validation resolver knows which schema to use,
      // even though it's not in the form.
      return await validationResolver(
        { ...data, type: model?.type },
        context,
        options,
      );
    },
  });

  const submit: SubmitHandler<FormState> = async (data) => {
    const { mappings, order_by } = data;
    try {
      await updateMutation.mutateAsync({
        id: String(id),
        model: {
          order_by,
        },
        mappings: mappings.map((mapping) => ({
          idr_model_id: String(id),
          model_id: String(model?.model.id),
          ...mapping,
        })),
      });
      toast({
        id: "update-model",
        title: "Model updated",
        variant: "success",
      });
      onClose();
    } catch (error) {
      captureException(error);
      toast({
        id: "update-model",
        title: "There was a problem updating your model",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    updatePylonPosition(672);
    return () => {
      updatePylonPosition(0);
    };
  }, []);

  if (!model) {
    return <Navigate to={`/idr/${graph.id}/models`} replace />;
  }

  return (
    <>
      <Drawer closeOnEsc trapFocus={false} size="lg" isOpen onClose={onClose}>
        <Box borderLeft="1px" borderTop="1px" borderColor="base.border">
          <Row
            justify="space-between"
            align="flex-start"
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Column gap={2}>
              <Row align="center" gap={2} overflow="hidden" height="24px">
                <Box
                  as="img"
                  src={model.type === "event" ? eventIcon : profileIcon}
                  width="24px"
                />
                <TextWithTooltip fontWeight="medium" isTruncated size="lg">
                  {model.model.name}
                </TextWithTooltip>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Row
                  borderRight="1px"
                  borderColor="base.divider"
                  borderStyle="solid"
                  pr={2}
                  align="center"
                >
                  <Badge>
                    {model.model.query_runs?.[0]
                      ? `${abbreviateNumber(
                          model.model.query_runs?.[0]?.size,
                        )} rows`
                      : "Unknown size"}
                  </Badge>
                </Row>
                <Link href={`/models/${model.model.id}`}>View model</Link>
              </Row>
            </Column>
            <IconButton icon={CloseIcon} aria-label="Close" onClick={onClose} />
          </Row>

          <DrawerBody>
            <Column gap={6} flex={1} minH={0} overflow="auto">
              <FormProvider {...form}>
                <MapperField columns={model.model.columns} />
                {model.type === "event" ? (
                  <OrderByField
                    columns={model.model.columns}
                    type={model.type as any}
                  />
                ) : null}
              </FormProvider>
            </Column>
          </DrawerBody>

          <DrawerFooter>
            <ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!form.formState.isDirty}
                isLoading={updateMutation.isLoading}
                size="lg"
                onClick={() => {
                  form.handleSubmit(submit)();
                }}
              >
                Save changes
              </Button>
              <Button
                isDisabled={!form.formState.isDirty}
                size="lg"
                onClick={() => {
                  form.reset();
                }}
              >
                Discard changes
              </Button>
            </ButtonGroup>
            <Button
              size="lg"
              variant="warning"
              onClick={() => {
                setIsDeleting(true);
              }}
            >
              Delete
            </Button>
          </DrawerFooter>
        </Box>
      </Drawer>
      <DeleteConfirmationModal
        label="model"
        content={
          <Paragraph>
            Deleting this model will remove its data from the identity graph.
          </Paragraph>
        }
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: String(id) });
          setIsDeleting(false);
          onClose();
        }}
      />
    </>
  );
};
