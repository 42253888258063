import { Row } from "@hightouchio/ui";
import get from "lodash/get";
import { useMemo } from "react";

import { PermissionedLinkButton } from "src/components/permission";
import {
  DestinationInstancesOrderBy,
  DestinationSyncsQuery,
  OrderBy,
  useDestinationSyncsQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import placeholderImage from "src/assets/placeholders/sync.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { formatDatetime } from "src/utils/time";
import { getDifference } from "src/utils/get-difference";
import { LoaderFunction, useNavigate, useParams } from "src/router";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { queryClient } from "src/utils/query-client";

type Sync =
  | NonNullable<
      DestinationSyncsQuery["destinations_by_pk"]
    >["destination_instances"][0]
  | DestinationSyncsQuery["sync_templates"][0]["destination_instances"][0];

type SyncCompareFunction = (args: {
  syncA: Sync;
  syncB: Sync;
  sortKey: string;
  sortDirection: OrderBy;
}) => number;

enum SortKeys {
  ModelName = "segment.name",
  SourceName = "segment.connection.name",
  CreatedAt = "created_at",
}

export const loader: LoaderFunction = async ({ params }) => {
  const variables = {
    destinationId: params.destination_id!,
    sameDestinationId: Number(params.destination_id!),
  };
  await queryClient.fetchQuery({
    queryKey: useDestinationSyncsQuery.getKey(variables),
    queryFn: useDestinationSyncsQuery.fetcher(variables),
  });
  return null;
};

export const Element = () => {
  const { destination_id: id } = useParams<{ destination_id: string }>();
  const navigate = useNavigate();
  const { data: entitlementsData } = useEntitlements(true);

  const {
    limit,
    offset,
    page,
    orderBy,
    sortKey,
    sortDirection,
    setPage,
    onSort,
  } = useTableConfig<DestinationInstancesOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a sync, upgrade your plan.";

  const { data: syncsData } = useDestinationSyncsQuery(
    {
      destinationId: id!,
      sameDestinationId: Number(id),
    },
    {
      suspense: true,
    },
  );

  // Need both syncs from a destination and syncs from sync_templates
  const destinationSyncs = getDifference<Sync>(
    syncsData?.destinations_by_pk?.destination_instances,
    syncsData?.sync_templates.flatMap(
      ({ destination_instances }) => destination_instances,
    ),
  );

  const sortedSyncs = useMemo(() => {
    if (!sortKey || !sortDirection) {
      return destinationSyncs;
    }

    const sortedSyncs = [...destinationSyncs];

    sortedSyncs.sort((syncA, syncB) =>
      syncCompareFunction({ syncA, syncB, sortKey, sortDirection }),
    );

    return sortedSyncs;
  }, [destinationSyncs, sortKey, sortDirection]);

  const paginatedSyncs = sortedSyncs.slice(offset, offset + limit);

  return (
    <>
      <Table
        columns={[
          {
            name: "Model",
            sortDirection: orderBy?.segment?.name,
            onClick: () => onSort(SortKeys.ModelName),
            cell: ({ segment }) => {
              const name = segment?.name;
              const definition = segment?.connection?.definition;
              return (
                <TextWithTooltip fontWeight="medium">
                  {name || definition?.name}
                </TextWithTooltip>
              );
            },
          },
          {
            name: "Source",
            sortDirection: orderBy?.segment?.connection?.name,
            onClick: () => onSort(SortKeys.SourceName),
            cell: ({ segment }) => {
              const definition = segment?.connection?.definition;
              return (
                <Row gap={2} align="center" overflow="hidden">
                  <IntegrationIcon
                    name={definition?.name}
                    src={definition?.icon}
                  />
                  <TextWithTooltip fontWeight="medium" isTruncated>
                    {definition?.name}
                  </TextWithTooltip>
                </Row>
              );
            },
          },
          {
            name: "Created at",
            sortDirection: orderBy?.created_at,
            onClick: () => onSort(SortKeys.CreatedAt),
            cell: ({ created_at }) =>
              created_at ? formatDatetime(created_at) : "-",
          },
        ]}
        data={paginatedSyncs}
        placeholder={{
          image: placeholderImage,
          title: "No models are synced to this destination",
          body: "Still working on this? Add a sync when you’re ready",
          button: (
            <PermissionedLinkButton
              permission={{
                v1: { resource: "sync", grant: "create" },
              }}
              variant="primary"
              isDisabled={overageLockout}
              href="/syncs/new"
              tooltip={overageLockout && overageText}
            >
              Add a sync
            </PermissionedLinkButton>
          ),
        }}
        onRowClick={({ id }) => navigate(`/syncs/${id}`)}
      />
      <Row sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
        <Pagination
          count={destinationSyncs.length}
          label="syncs"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </>
  );
};

/**
 * A comparing function used to sort syncs.
 *
 * @param arguments arguments
 * @param arguments.syncA The first destination to compare
 * @param arguments.destinationb The second destination to compare
 * @param arguments.sortKey The path to the value to sort on
 * @param arguments.sortDirection The sort direction
 * @returns
 */
const syncCompareFunction: SyncCompareFunction = ({
  syncA,
  syncB,
  sortKey,
  sortDirection,
}) => {
  if (
    !sortDirection ||
    (!sortDirection.startsWith("asc") && !sortDirection.startsWith("desc"))
  ) {
    return 0;
  }

  let result;
  let aValue = get(syncA, sortKey);
  let bValue = get(syncB, sortKey);

  switch (sortKey) {
    // string sorting
    case "status":
    case "segment.name":
    case "destination.name":
      result = aValue.trim().localeCompare(bValue.trim());
      break;
    // date sorting
    case "created_at":
      aValue = new Date(aValue);
      bValue = new Date(bValue);
      result = aValue - bValue;
      break;
    default:
      // syncs columns are all string based except for date columns
      return 0;
  }

  return sortDirection.startsWith("asc") ? result : -result;
};
