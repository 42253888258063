import { useState, FC } from "react";

import { Button, Dialog, Text } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import moment from "moment";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";

export const LockOutModal: FC = () => {
  const { workspace } = useUser();
  const [showModal, setShowModal] = useState<boolean>(true);
  const organization = workspace?.organization;

  if (
    !organization ||
    !organization?.show_lock_popup ||
    sessionStorage.getItem("lockoutDismissed")
  ) {
    return null;
  }

  const isCompleteLockout = organization.lock_popup_date
    ? moment(organization.lock_popup_date, "YYYY-MM-DD").isBefore(moment())
    : false;

  analytics.track("Lock Out Modal Shown", {
    workspace_id: workspace.id,
    is_complete_lockout: isCompleteLockout,
    lock_popup_date: organization.lock_popup_date,
  });

  const onClose = () => {
    if (!isCompleteLockout) {
      sessionStorage.setItem("lockoutDismissed", "true");
      analytics.track("Lock Out Modal Dismissed", {
        workspace_id: workspace.id,
        is_complete_lockout: isCompleteLockout,
        lock_popup_date: organization.lock_popup_date,
      });
      setShowModal(false);
    }
  };

  const onLiveChatClick = () => {
    analytics.track("Lock Out Live Chat Clicked", {
      workspace_id: workspace.id,
      is_complete_lockout: isCompleteLockout,
      lock_popup_date: organization.lock_popup_date,
    });

    newPylonMessage(
      `Hi, I'd like to ${
        isCompleteLockout || organization.lock_popup_date ? "unlock" : "upgrade"
      } my account!`,
    );
  };

  const defaultLockoutMessage = isCompleteLockout
    ? "Your workspace is in overage and has been locked. Please reach out to\
      support to unlock your account."
    : organization.lock_popup_date
      ? "Your workspace is in overage and will be locked in " +
        (moment(organization.lock_popup_date, "YYYY-MM-DD").diff(
          moment(),
          "days",
        ) +
          1) +
        " days. Please reach out to support to upgrade your account."
      : "Your account is in overage. Please reach out to support to\
        upgrade your account.";

  const lockoutMessage =
    organization.custom_lockout_message || defaultLockoutMessage;

  return (
    <Dialog
      isOpen={showModal}
      variant="info"
      title="Action required to unlock this workspace"
      actions={
        isCompleteLockout ? (
          <LinkButton href="mailto:friends@hightouch.com" variant="primary">
            Contact support
          </LinkButton>
        ) : (
          <>
            <Button onClick={onClose}>Dismiss</Button>
            <Button
              variant="primary"
              onClick={() => {
                onClose();
                onLiveChatClick();
              }}
            >
              Chat with us
            </Button>
          </>
        )
      }
      onClose={onClose}
    >
      <Text>{lockoutMessage}</Text>
    </Dialog>
  );
};
