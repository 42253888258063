import { FC, forwardRef, ReactNode, Ref } from "react";

import { Row, RowProps } from "@hightouchio/ui";

import { NAV_WIDTHS_PER_BREAKPOINT } from "src/components/layout/constants";
import { DefaultPageContainerPadding } from "src/components/layout/page-container";
import { SIZES } from "src/ui/box/container";

interface Props {
  fit?: boolean;
  children: ReactNode;
  ref?: Ref<HTMLDivElement>;
  center?: boolean;
}

export const ActionBarHeight = 72;

const styles: RowProps = {
  borderTop: "1px",
  borderColor: "base.border",
  bg: "white",
  minHeight: `${ActionBarHeight}px`,
  width: "100%",
  align: "center",
};

export const ActionBar: FC<Readonly<Props>> = forwardRef<HTMLDivElement, Props>(
  ({ children, fit, center = true }, ref) => {
    if (fit) {
      return (
        <Row
          ref={ref}
          {...styles}
          pos="sticky"
          bottom={0}
          px={DefaultPageContainerPadding.X}
          justifyContent="space-between"
        >
          {children}
        </Row>
      );
    }
    return (
      <Row
        ref={ref}
        {...styles}
        justify={center ? "center" : "flex-start"}
        position="fixed"
        bottom={0}
        right={0}
        left={NAV_WIDTHS_PER_BREAKPOINT}
        maxWidth={NAV_WIDTHS_PER_BREAKPOINT.map(
          (size) => `calc(100% - ${size})`,
        )}
        zIndex={1}
      >
        <Row
          justifyContent="space-between"
          maxWidth={center ? SIZES.page : "unset"}
          width="100%"
          pl={DefaultPageContainerPadding.X}
          pr={16}
        >
          {children}
        </Row>
      </Row>
    );
  },
);

ActionBar.displayName = "ActionBar";
