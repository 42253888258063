import { ClickIcon, Column, TimeIcon } from "@hightouchio/ui";
import pluralize from "pluralize";

import { useQueryBuilderContext } from "src/components/explore/context/query-builder-context";
import { JourneyNodeDetails } from "src/pages/journeys/types";
import { WaitUntilEventConfig } from "src/types/journeys";

import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

export const HoldUntilNodeDescription = ({
  segment_id,
  event_relationship_id,
  config,
}: JourneyNodeDetails<WaitUntilEventConfig>) => {
  const { events } = useQueryBuilderContext();

  const selectedEvent = events.find(
    (event) =>
      event.to_model.id.toString() === segment_id?.toString() &&
      event.id.toString() === event_relationship_id?.toString(),
  );

  return selectedEvent ? (
    <Column gap={4}>
      <DescriptionRow label="Event performed" icon={ClickIcon}>
        {selectedEvent.to_model.name}
      </DescriptionRow>
      <DescriptionRow label="Wait up to" icon={TimeIcon}>
        {pluralize(
          config.timeout_duration.unit,
          config.timeout_duration.quantity,
          true,
        )}
      </DescriptionRow>
    </Column>
  ) : (
    <EmptyStateDescription />
  );
};
