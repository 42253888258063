import {
  Text,
  Box,
  Column,
  Row,
  SearchInput,
  SectionHeading,
  AudienceIcon,
  useDisclosure,
  ChevronDoubleLeftIcon,
  IconButton,
  ChevronDoubleRightIcon,
  Checkbox,
  Spinner,
} from "@hightouchio/ui";
import { FC } from "react";
import { useOutletContext } from "src/router";
import useQueryState from "src/hooks/use-query-state";

import { OutletContext } from "src/pages/decision-engines";
import { Pagination } from "src/ui/table";
import { getColumnName } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";

export const Users = ({
  page,
  setPage,
  users,
  search,
  onSearchChange,
  numRowsWithoutLimit,
  loading,
}) => {
  const [usersQueryState] = useQueryState("users");
  const { engine } = useOutletContext<OutletContext>();

  const selectedUsers = usersQueryState ? usersQueryState.split(",") : [];

  const primaryKey = getColumnName(
    engine.segment.primary_key!,
    engine.segment.columns,
  );
  const primaryLabel = getColumnName(
    engine.segment.visual_query_primary_label!,
    engine.segment.columns,
  );
  const secondaryLabel = getColumnName(
    engine.segment.visual_query_secondary_label!,
    engine.segment.columns,
  );

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  if (!isOpen) {
    return (
      <Column p={6} borderRight="1px" borderColor="base.border" h="100%">
        <IconButton
          aria-label="Open users"
          icon={ChevronDoubleRightIcon}
          onClick={onOpen}
          variant="secondary"
        />
      </Column>
    );
  }

  return (
    <Column
      borderRight="1px"
      borderColor="base.border"
      flex={1}
      height="100%"
      overflow="hidden"
      bg="white"
    >
      <Column
        gap={2}
        px={6}
        pt={6}
        borderBottom="1px"
        borderColor="base.border"
        pb={4}
      >
        <Row gap={4} align="center" justify="space-between" h="36px">
          <Row gap={2} align="center">
            <Box as={AudienceIcon} boxSize={5} color="text.secondary" />
            <SectionHeading>Users</SectionHeading>
          </Row>
          <IconButton
            aria-label="Close users"
            icon={ChevronDoubleLeftIcon}
            variant="tertiary"
            onClick={onClose}
          />
        </Row>
        <SearchInput
          width="100%"
          value={search}
          onChange={(event) => onSearchChange(event.target.value)}
          placeholder={`Search by ${primaryKey}`}
        />
      </Column>
      {!loading && users?.length > 0 && (
        <Row borderBottom="1px" borderColor="base.border" px={6} py={2}>
          <Text size="sm" color="text.secondary">
            {`${
              selectedUsers.length ? `${selectedUsers.length} of ` : ""
            }${abbreviateNumber(numRowsWithoutLimit)} users`}
          </Text>
        </Row>
      )}
      <Column overflow="auto" flex={1}>
        {loading ? (
          <Spinner mt={6} mx="auto" />
        ) : users?.length ? (
          users.map((user) => (
            <User
              key={user[primaryKey]}
              primaryKey={user[primaryKey]!}
              primaryLabel={user[primaryLabel]!}
              secondaryLabel={user[secondaryLabel]!}
            />
          ))
        ) : (
          <Box textAlign="center" p={6} color="text.tertiary">
            No users found
          </Box>
        )}
      </Column>
      {numRowsWithoutLimit > 100 && !loading && (
        <Row p={4} borderTop="1px" borderColor="base.border" justify="flex-end">
          <Pagination
            compact
            text=""
            count={Math.min(numRowsWithoutLimit, 10000)}
            page={page}
            rowsPerPage={100}
            setPage={setPage}
          />
        </Row>
      )}
    </Column>
  );
};

export const User: FC<
  Readonly<{
    primaryLabel: string;
    secondaryLabel: string;
    primaryKey: string | number;
  }>
> = ({ primaryKey, primaryLabel, secondaryLabel }) => {
  const [users, setUsers] = useQueryState("users");
  const selectedUsers = users ? users.split(",") : [];
  const isSelected = selectedUsers.includes(String(primaryKey));

  const onClick = () => {
    if (isSelected) {
      setUsers(
        selectedUsers.filter((id) => id !== String(primaryKey)).join(","),
      );
    } else {
      setUsers([...selectedUsers, primaryKey].join(","));
    }
  };

  return (
    <Row
      gap={4}
      alignItems="center"
      onClick={onClick}
      cursor="pointer"
      bg={isSelected ? "primary.background" : undefined}
      borderBottom="1px"
      borderColor="base.border"
      h="52px"
      flexShrink={0}
      px={6}
      _hover={{
        bg: "gray.100",
      }}
    >
      <Box as={Checkbox} isChecked={isSelected} pointerEvents="none" />
      <Column>
        <Text size="md" fontWeight="medium">
          {primaryLabel}
        </Text>
        <Box
          fontSize="sm"
          color="text.secondary"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {secondaryLabel}
        </Box>
      </Column>
    </Row>
  );
};
