import { FC } from "react";

import { Box, ErrorIcon, Row, Text, Tooltip } from "@hightouchio/ui";
import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";
import { capitalize } from "lodash";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { LinkWithTooltip } from "src/components/link-with-tooltip";
import { Skeleton } from "src/components/loading";
import { useDecisionEngineDestinationResourcesQuery } from "src/graphql";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";

type Props = {
  channel: {
    config: DecisionEngineChannelConfig;
    destination: {
      id: string;
      type: string;
      config: Record<string, unknown>;
      definition: {
        icon: string;
        name: string;
      };
    };
  };
  resourceId: string;
};

export const DestinationResourceLink: FC<Readonly<Props>> = ({
  channel,
  resourceId,
}) => {
  const { resource, isLoading, error, url } = useDestinationResource({
    channel,
    resourceId,
  });

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <Text color="danger.base">Failed to load</Text>;
  }

  const definition = getChannelDefinition(channel.destination.type);
  const resourceType = definition.getResourceName(channel.config);

  if (!resource) {
    return (
      <Text color="text.secondary">{capitalize(resourceType)} not added</Text>
    );
  }

  return (
    <Row
      gap={2}
      align="center"
      overflow="hidden"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <IntegrationIcon
        name={channel.destination.definition.name}
        src={channel.destination.definition.icon}
      />
      <LinkWithTooltip href={url}>{resource?.name}</LinkWithTooltip>
      {resource && definition.isInactive(resource) && (
        <Tooltip
          message={`${channel.destination.definition.name} ${resourceType} not activated`}
        >
          <Box as={ErrorIcon} boxSize={5} color="danger.base" />
        </Tooltip>
      )}
    </Row>
  );
};

export const useDestinationResource = ({
  channel: { destination },
  resourceId,
}: Props) => {
  const { data, error, isLoading } = useDecisionEngineDestinationResourcesQuery(
    {
      destinationId: destination.id,
    },
    { select: (data) => data.getDecisionEngineDestinationResources },
  );

  const resource = data?.find((c) => String(c.id) === String(resourceId));

  const url = resource
    ? getChannelDefinition(destination.type).getResourceUrl(
        resource,
        destination.config,
      )
    : "";

  return {
    url,
    resource,
    isLoading,
    error,
  };
};
