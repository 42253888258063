import { FC, useEffect } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Button,
  CloseIcon,
  Column,
  Combobox,
  FormField,
  Heading,
  IconButton,
  Text,
  TextInput,
  Textarea,
  useToast,
  DrawerBody,
  DrawerFooter,
  ArrowLeftIcon,
  Row,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "src/router";

import {
  TraitDefinitionsConstraint,
  useCreateTraitMutation,
  useTraitQuery,
  useTraitsQuery,
} from "src/graphql";
import { NotFound } from "src/pages/not-found";
import { Drawer } from "src/components/drawer";

import { CalculationSummary } from "./calculation-summary";
import { PreviewTrait } from "./preview-trait";
import { TraitConditions } from "./trait-conditions";
import { validateConfig } from "./utils";
import { PermissionedButton } from "../permission";

type FormProps = {
  name: string;
  description: string;
  parent_model_id: string;
  relationship_id: string;
  trait_template_id: string;
  type: TraitType;
  config: TraitConfig;
};

export const CreateTemplatedTrait: FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const templateId = searchParams.get("template_id");

  const onCloseDrawer = () => navigate("/traits/active");

  const { data, isLoading } = useTraitQuery({ id: templateId ?? "" });
  const trait = data?.trait_definitions_by_pk;

  const traitTemplatesQuery = useTraitsQuery({
    filter: {
      is_template: { _eq: true },
      archived_at: { _eq: null },
    },
  });

  const templateOptions = (
    traitTemplatesQuery.data?.trait_definitions ?? []
  ).map((template) => ({
    label: template.name,
    value: template.id,
    description: template.description,
  }));

  const { toast } = useToast();
  const form = useForm<FormProps>();
  const { control, handleSubmit, reset, watch } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types on Column
  const name = watch("name");
  const type = watch("type");
  const config = watch("config");
  const createTrait = useCreateTraitMutation();

  useEffect(() => {
    if (!trait) return;

    const initialFormValues = {
      type: trait.type as TraitType,
      parent_model_id: trait.parent_model.id,
      relationship_id: trait.relationship?.id,
      config: trait.config as TraitConfig,
      trait_template_id: trait.id,
    };
    reset(initialFormValues);
  }, [trait]);

  const onSubmit = async (formData: FormProps) => {
    try {
      const newTrait = await createTrait.mutateAsync({
        input: {
          name: formData.name,
          description: formData.description,
          parent_model_id: formData.parent_model_id,
          relationship_id: formData.relationship_id,
          type: formData.type,
          config: formData.config,
          is_template: false,
          trait_template_id: formData.trait_template_id,
        },
      });

      toast({
        id: "create-trait",
        title: "Trait was created successfully",
        variant: "success",
      });

      const newTraitId = newTrait.insert_trait_definitions_one?.id;
      navigate(`/traits/active/${newTraitId}`);
    } catch (error) {
      toast({
        id: "create-trait",
        title: "Trait failed to be created",
        message: error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? `There is an existing trait named "${formData.name}" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const isSaveDisabled =
    createTrait.isLoading || !name || !validateConfig(type, config);

  return (
    <Drawer isLoading={isLoading} isOpen size="xl" onClose={onCloseDrawer}>
      {!trait ? (
        <NotFound />
      ) : (
        <>
          <Row
            align="center"
            justifyContent="space-between"
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Heading>New trait</Heading>
            <IconButton
              aria-label="Close"
              icon={CloseIcon}
              onClick={onCloseDrawer}
            />
          </Row>

          <DrawerBody>
            <Column flex={1} overflowY="auto" gap={6}>
              <Column gap={2}>
                <Text fontWeight="medium">
                  Create a new trait from a template
                </Text>
                <Text>
                  A trait lets you perform a calculation on a column of a
                  related/event model. It acts as a new column on a parent model
                  and can be utilized in associated Audiences.
                </Text>
              </Column>

              <FormField
                label="Choose a template"
                tip="This trait will be available in the audiences of this parent model."
              >
                <Combobox
                  options={templateOptions}
                  onChange={(templateId) => {
                    if (templateId) {
                      setSearchParams({ template_id: templateId });
                    }
                  }}
                  value={templateId}
                  width="100%"
                />
              </FormField>

              <Column
                borderRadius="6px"
                border="1px solid"
                borderColor="base.border"
                p={4}
                gap={4}
              >
                <CalculationSummary
                  model={trait.relationship?.to_model}
                  type={type}
                  config={config}
                  hideConditions
                />
                <FormProvider {...form}>
                  <TraitConditions
                    parentModel={trait.parent_model}
                    relationship={trait.relationship}
                  />
                </FormProvider>
              </Column>

              <FormField
                label="Name"
                tip="Shows up in the audience query builder when selecting from a list of traits."
              >
                <Column gap={6}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <TextInput
                        placeholder="Enter a name"
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                      <Textarea
                        placeholder="Enter a description"
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                </Column>
              </FormField>

              <PreviewTrait
                parentModel={trait.parent_model}
                trait={{
                  name,
                  type: trait.type as TraitType,
                  config: trait.config,
                  relationshipId: trait.relationship?.id,
                }}
              />
            </Column>
          </DrawerBody>
          <DrawerFooter>
            <Button
              icon={ArrowLeftIcon}
              size="lg"
              onClick={() => navigate("/traits/active/new_from_template")}
            >
              Back
            </Button>
            <PermissionedButton
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: trait.parent_model.id,
                },
              }}
              isDisabled={isSaveDisabled}
              size="lg"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </PermissionedButton>
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
