import { FC, ReactNode, useEffect, useMemo } from "react";

import {
  Box,
  Button,
  Column,
  InformationIcon,
  MeetingIcon,
  Row,
  SectionHeading,
  Select,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@hightouchio/ui";
import { noop } from "ts-essentials";
import { isPresent } from "ts-extras";

import CrossAudienceGraph from "src/components/analytics/cross-audience-graph";
import { GraphScale } from "src/components/analytics/cross-audience-graph/constants";
import { DateRangePicker } from "src/components/analytics/date-range-picker";
import { FunnelGraph } from "src/components/analytics/funnel-graph";

import { Graph } from "src/components/analytics/cross-audience-graph/types";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useHeaderHeight } from "src/contexts/header-height-context";
import { FunnelMetricDataForCohort } from "src/graphql";
import { AggregationOption } from "src/pages/metrics/constants";
import { PredefinedMetric } from "src/types/visual";
import { useRowSelect } from "src/ui/table/use-row-select";

import { AnalyticsBreakdown } from "./analytics-breakdown";
import { AnalyticsSidebar } from "./analytics-sidebar";
import { AnalyticsTable } from "./analytics-table";
import { placeholderContentWidthPx } from "./constants";
import {
  AnalyticsPlaceholderImage,
  FunnelsPlaceholder,
  UnconfiguredPlaceholder,
  PermissionsPlaceholder,
} from "./placeholders";
import {
  AccumulationTypes,
  LookbackOptions,
  RollupFrequencies,
  TimeOptions,
  useAnalyticsContext,
} from "./state";
import { SummaryStats } from "./summary-stats";
import { GraphType, MetricSelection, ParentModelOption } from "./types";
import { formatDatePickerLabel } from "./utils";

type AnalyticsContentProps = {
  errorMessage?: ReactNode;
  funnelsData: FunnelMetricDataForCohort[];
  funnelsErrors?: Record<string, string>;
  graph: Graph;
  isLoading?: boolean;
  isSavedView: boolean;
  metricSeriesErrors?: Record<string, string>;
  parentModelOptions: ParentModelOption[];
  showSidebar: boolean;
  onSaveMetric?: (metricData: MetricSelection) => void;
};

export const AnalyticsContent: FC<AnalyticsContentProps> = ({
  errorMessage,
  funnelsErrors = {},
  funnelsData,
  graph,
  isLoading: externalLoading = false,
  isSavedView = false,
  metricSeriesErrors = {},
  parentModelOptions,
  showSidebar,
  onSaveMetric,
}) => {
  const { headerHeight } = useHeaderHeight();
  const { selectedRows, onRowSelect } = useRowSelect();

  const {
    cumulative,
    funnelSteps,
    graphType,
    groupByColumns,
    metricSelection,
    rollupFrequency,
    selectedAudiences,
    selectedDates: selectedDateStrings,
    timeValue,

    setCumulative,
    setLookbackWindow,
    setRollupFrequency,
    setSelectedDates,
  } = useAnalyticsContext();

  const selectedDates = useMemo(
    () => selectedDateStrings.map((dateStr) => new Date(dateStr)),
    [selectedDateStrings?.[0], selectedDateStrings?.[1]],
  );

  // We use the audience preview permission because we're effectively
  // running queries on an audience with additional filtering
  const {
    isPermitted: hasPreviewPermission,
    isLoading: previewPermissionLoading,
  } = useResourcePermission({
    v1: { resource: "audience", grant: "preview" },
  });

  const isLoading = externalLoading || previewPermissionLoading;

  const scale = graph.series.every(
    ({ aggregation }) => aggregation === AggregationOption.PercentOfAudience,
  )
    ? GraphScale.Percent
    : GraphScale.Linear;
  const hasSummaryStats = Boolean(graph.summary.length);
  const hasMetricOrEventSelected = metricSelection.some(({ name }) => name);
  const audiencesSelected = Boolean(
    selectedAudiences &&
      selectedAudiences.filter(({ name }) => Boolean(name)).length > 0,
  );
  const hasFunnelStepsSelected = funnelSteps.length > 1;
  const queryConfigured = hasMetricOrEventSelected && audiencesSelected;
  const funnelQueryConfigured = hasFunnelStepsSelected && audiencesSelected;

  const disableAccumulationSelection = metricSelection.some(
    ({ id }) => id === PredefinedMetric.AudienceSize,
  );
  useEffect(() => {
    if (disableAccumulationSelection) {
      setCumulative(false);
    }
  }, [disableAccumulationSelection]);

  useEffect(() => {
    // TODO(Samuel): could have a bug here when other loading states are triggered...
    if (!isLoading) {
      onRowSelect(graph.series.map(({ key }) => key));
    }
  }, [isLoading, graph.series]);

  const isPerformance = graphType === GraphType.Performance;
  const isBreakdown = graphType === GraphType.Breakdown;
  const isFunnels = graphType === GraphType.Funnel;
  const isTable = graphType === GraphType.Table;

  if (!hasPreviewPermission && !previewPermissionLoading) {
    return <PermissionsPlaceholder />;
  }

  return (
    <Row flex={1} pos="relative">
      {showSidebar && (
        <AnalyticsSidebar
          funnelsErrors={funnelsErrors}
          isLoading={isLoading}
          isSavedView={isSavedView}
          metricSeriesErrors={metricSeriesErrors}
          parentModelOptions={parentModelOptions}
          onSaveMetric={onSaveMetric}
        />
      )}

      <Column
        // Summary Stats height is not fixed. If we try to fit all of the cotent in one viewport,
        // the graph will likely be too small, so just make the content scrollable instead.
        height={hasSummaryStats ? "100%" : `calc(100vh - ${headerHeight}px)`}
        width="100%"
        overflowX="auto"
      >
        {isFunnels &&
          (funnelQueryConfigured ? (
            <FunnelGraph
              data={funnelsData}
              hasErrors={Object.values(funnelsErrors).length > 0}
              isLoading={isLoading}
            />
          ) : (
            <FunnelsPlaceholder />
          ))}
        {isBreakdown &&
          (queryConfigured ? (
            <AnalyticsBreakdown
              data={graph.series}
              groupByColumns={groupByColumns.filter(isPresent)}
              isLoading={isLoading}
              lookbackWindow={timeValue}
              selectedDates={selectedDates}
              onSelectDateRange={setSelectedDates}
              onUpdateLookbackWindow={setLookbackWindow}
            />
          ) : (
            <UnconfiguredPlaceholder />
          ))}
        {isTable &&
          (queryConfigured ? (
            <AnalyticsTable
              data={graph.series}
              isLoading={isLoading}
              lookbackWindow={timeValue}
              selectedDates={selectedDates}
              onSelectDateRange={setSelectedDates}
              onUpdateLookbackWindow={setLookbackWindow}
            />
          ) : (
            <UnconfiguredPlaceholder />
          ))}
        {isPerformance &&
          (queryConfigured ? (
            <>
              <Row
                align="center"
                justify="space-between"
                mt={4}
                pl="30px"
                pr="50px"
                gap={2}
                width="100%"
              >
                <Row gap={2}>
                  <Select
                    options={RollupFrequencies}
                    value={rollupFrequency}
                    width="auto"
                    onChange={(rollupFrequency) => {
                      if (rollupFrequency) setRollupFrequency(rollupFrequency);
                    }}
                    size="sm"
                  />
                  <ToggleButtonGroup
                    size="sm"
                    value={timeValue}
                    onChange={(value) => {
                      setLookbackWindow(value as TimeOptions);
                    }}
                  >
                    {LookbackOptions.map((option) => (
                      <ToggleButton
                        key={option.value}
                        {...option}
                      ></ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  <DateRangePicker
                    maxDate={new Date()}
                    selectedDates={selectedDates}
                    onChange={(dates) => {
                      setSelectedDates(dates);
                    }}
                  >
                    <Box
                      as={Button}
                      background={
                        timeValue === TimeOptions.Custom ? "gray.200" : "unset"
                      }
                      fontWeight={
                        timeValue === TimeOptions.Custom ? "semibold" : "normal"
                      }
                      icon={MeetingIcon}
                      size="sm"
                      onClick={noop}
                    >
                      {formatDatePickerLabel(selectedDates, timeValue)}
                    </Box>
                  </DateRangePicker>
                </Row>
                <Row align="center" gap={1}>
                  <Select
                    isDisabled={disableAccumulationSelection}
                    optionAccessory={({ icon }) => ({
                      type: "icon",
                      icon,
                    })}
                    options={AccumulationTypes}
                    value={cumulative}
                    size="sm"
                    width="3xs"
                    onChange={(value) => setCumulative(Boolean(value))}
                  />
                  {disableAccumulationSelection && (
                    <Tooltip message='Audience size can only be displayed "over time"'>
                      <Text color="color.secondary">
                        <InformationIcon />
                      </Text>
                    </Tooltip>
                  )}
                </Row>
              </Row>
              {!isLoading && <SummaryStats summaryStats={graph.summary} />}
              <Column flex={1} minHeight={0}>
                <CrossAudienceGraph
                  graph={graph}
                  isLoading={isLoading}
                  scale={scale}
                  selectedRows={selectedRows}
                  onSelect={onRowSelect}
                  placeholder={
                    <>
                      <Column
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Column
                          textAlign="center"
                          width={placeholderContentWidthPx}
                        >
                          <AnalyticsPlaceholderImage />
                        </Column>
                        <SectionHeading>No data</SectionHeading>
                        {errorMessage}
                      </Column>
                    </>
                  }
                />
              </Column>
            </>
          ) : (
            <UnconfiguredPlaceholder />
          ))}
      </Column>
    </Row>
  );
};
