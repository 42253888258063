import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import {
  IconButton,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuList,
  PlusIcon,
  Row,
  Spinner,
  Tooltip,
} from "@hightouchio/ui";
import { capitalize } from "lodash";
import { FC } from "react";
import { createSearchParams, Outlet, useNavigate } from "src/router";
import {
  useAlertingCredentialsQuery,
  useDisconnectPagerdutyMutation,
  useDisconnectSlackMutation,
  useWorkspaceNotificationChannelsQuery,
  WorkspaceNotificationChannelsQuery,
} from "src/graphql";
import { DashboardSidebarItem } from "../app-home/dashboard-sidebar-item";
import { ChannelDefinition } from "./channel-definitions";
import { SubscribedResourceItem } from "./subscribed-resource-item";
import { Card } from "src/components/card";
import { PermissionedLinkButton, PermissionedMenuItem } from "../permission";

const ChannelList: FC<{
  channels: WorkspaceNotificationChannelsQuery["workspace_notification_channels"];
  icon: any;
}> = ({ channels }) => {
  const navigate = useNavigate();

  return (
    <>
      {channels?.map((channel, idx) => {
        return (
          <SubscribedResourceItem
            key={idx}
            channel={channel}
            shouldNavigate={() => {
              if (!channel.channel_type) return;
              navigate(`${channel.id}`);
            }}
          />
        );
      })}
    </>
  );
};

ChannelList.displayName = ChannelList.name;

const MonitoringCredentialsPermission = {
  v1: { resource: "workspace", grant: "update" },
  v2: { resource: "workspace", grant: "can_update" },
} as const;

const CredentialManagementMenu: FC<{ channelType: ChannelType }> = ({
  channelType,
}) => {
  const navigate = useNavigate();

  const { mutateAsync: disconnectSlackCredentials } =
    useDisconnectSlackMutation();
  const { mutateAsync: disconnectPagerdutyCredentials } =
    useDisconnectPagerdutyMutation();

  let disconnectCredentials;

  if (channelType === ChannelType.Slack) {
    disconnectCredentials = disconnectSlackCredentials;
  }

  if (channelType === ChannelType.PagerDuty) {
    disconnectCredentials = disconnectPagerdutyCredentials;
  }

  return (
    <Menu>
      <MenuActionsButton />
      <MenuList>
        <PermissionedMenuItem
          permission={MonitoringCredentialsPermission}
          onClick={() => navigate(`configure/${channelType}`)}
        >
          Manage {capitalize(channelType)} credentials
        </PermissionedMenuItem>
        <MenuDivider />
        <PermissionedMenuItem
          permission={MonitoringCredentialsPermission}
          variant="danger"
          onClick={() => disconnectCredentials()}
        >
          Disconnect {capitalize(channelType)}
        </PermissionedMenuItem>
      </MenuList>
    </Menu>
  );
};

CredentialManagementMenu.displayName = CredentialManagementMenu.name;

export const ManageWorkspaceNotificationChannels: FC = () => {
  const navigate = useNavigate();

  const { data: workspaceChannels, isLoading: loadingChannels } =
    useWorkspaceNotificationChannelsQuery(undefined, {
      select(data) {
        return data.workspace_notification_channels;
      },
    });

  const { data: alertingCredentials, isLoading: loadingCredentials } =
    useAlertingCredentialsQuery(undefined);

  const requiresSetup = (channelType: ChannelType) => {
    switch (channelType) {
      case ChannelType.Slack:
        return (alertingCredentials?.slack_credentials?.length || 0) === 0;
      case ChannelType.PagerDuty:
        return (alertingCredentials?.pagerduty_credentials?.length || 0) === 0;
      default:
        return false;
    }
  };

  return (
    <>
      {Object.values(ChannelType).map((channelType, idx) => {
        const definition = ChannelDefinition[channelType];
        if (!definition) return null;
        const channels =
          workspaceChannels?.filter(
            (channel) => channel.channel_type == channelType,
          ) ?? [];
        return (
          <Card key={idx}>
            <DashboardSidebarItem
              key={idx}
              empty={channels?.length == 0}
              icon={<definition.icon />}
              title={capitalize(channelType)}
              header={
                loadingCredentials ? (
                  <Spinner size="sm" />
                ) : requiresSetup(channelType) ? (
                  <PermissionedLinkButton
                    permission={MonitoringCredentialsPermission}
                    href={`configure/${channelType}`}
                  >
                    Connect
                  </PermissionedLinkButton>
                ) : (
                  <Row alignItems="center">
                    {definition.configurable ? (
                      <CredentialManagementMenu channelType={channelType} />
                    ) : null}
                    <Tooltip
                      message={`Create a new ${channelType} notification`}
                    >
                      <IconButton
                        aria-label="add-new-channel"
                        icon={PlusIcon}
                        onClick={() => {
                          navigate({
                            pathname: `new`,
                            search: createSearchParams({
                              channelType,
                            }).toString(),
                          });
                        }}
                      />
                    </Tooltip>
                  </Row>
                )
              }
              isLoading={loadingChannels}
            >
              {!requiresSetup(channelType) && (
                <ChannelList channels={channels} icon={definition?.icon} />
              )}
            </DashboardSidebarItem>
          </Card>
        );
      })}
      <Outlet
        context={{
          slackCredentials: alertingCredentials?.slack_credentials?.[0],
          pagerDutyCredentials: alertingCredentials?.pagerduty_credentials?.[0],
        }}
      />
    </>
  );
};

ManageWorkspaceNotificationChannels.displayName =
  ManageWorkspaceNotificationChannels.name;
