import { FC, useCallback, useMemo, useState } from "react";

import {
  ChakraModalBody,
  ChakraModalFooter,
  ChakraModalHeader,
  Column,
  Button,
  ButtonGroup,
  PlusIcon,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  Row,
  Text,
  Box,
  Tag,
  Checkbox,
  FormField,
  Select,
  Paragraph,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "src/router";

import { useUser } from "src/contexts/user-context";
import { SyncTemplatesForParentModelQuery } from "src/graphql";
import { Audience } from "src/types/visual";
import {
  SyncRunStatus,
  disambiguateSyncs,
  syncRunStatusToIndicatorVariant,
} from "src/utils/syncs";

import { useAddSyncTemplates } from "./use-add-sync-templates";
import { useSyncCreationPermissions } from "src/components/permission/creation/sync";

type Props = {
  audience: NonNullable<Audience>;
  syncTemplates: SyncTemplatesForParentModelQuery["sync_templates"];
  onClose: () => void;
};

export const AddSyncTemplatesModal: FC<Readonly<Props>> = ({
  audience,
  syncTemplates,
  onClose,
}) => {
  const { workspace } = useUser();
  const { appDisableSyncCreationForAudiences } = useFlags();

  const { addSyncTemplates, isLoading } = useAddSyncTemplates();
  const navigate = useNavigate();
  const [selectedSyncTemplates, setSelectedSyncTemplates] = useState<
    { id: number; name: string; destination: { id: string } }[]
  >([]);

  const treatmentGroups = audience.splits.filter(
    (split) => !split.is_holdout_group,
  );
  const treatmentGroupOptions = treatmentGroups.map((treatmentGroup) => ({
    label: `${treatmentGroup.friendly_name} (${treatmentGroup.percentage}%)`,
    value: treatmentGroup,
  }));
  const [selectedTreatmentGroup, setSelectedTreatmentGroup] = useState<
    NonNullable<Audience>["splits"][0] | undefined
  >(treatmentGroupOptions?.[0]?.value);

  const disambiguatedSyncs = useMemo(
    () => disambiguateSyncs(audience.syncs),
    [audience.syncs],
  );

  const syncsIdsForSelectedTreatmentGroup = selectedTreatmentGroup
    ? selectedTreatmentGroup.destination_instance_splits.map(
        (dis) => dis.destination_instance_id,
      )
    : [];
  const activeSyncs = useMemo(
    () =>
      selectedTreatmentGroup
        ? disambiguatedSyncs.filter((sync) =>
            syncsIdsForSelectedTreatmentGroup.includes(sync.id),
          )
        : disambiguatedSyncs,
    [disambiguateSyncs, selectedTreatmentGroup],
  );

  const activeSyncTemplateIds = activeSyncs
    .filter(({ sync_template_id }) => sync_template_id != null)
    .map(({ sync_template_id }) => sync_template_id);
  const inactiveSyncTemplates = syncTemplates.filter(
    ({ id }) => !activeSyncTemplateIds.includes(id),
  );

  const newSyncUrl = `/syncs/new?model=${audience.id}`;

  const handleAddSyncTemplates = async () => {
    await addSyncTemplates({
      audienceId: audience.id,
      splitId: selectedTreatmentGroup?.id,
      syncTemplates: selectedSyncTemplates,
    });
    onClose();
  };

  const { syncTemplateFilter } = useSyncCreationPermissions(audience.id);

  const toggleSyncTemplate = useCallback(
    (
      isChecked: boolean,
      syncTemplate: SyncTemplatesForParentModelQuery["sync_templates"][0],
    ) => {
      if (isChecked) {
        setSelectedSyncTemplates((prevState) => prevState.concat(syncTemplate));
      } else {
        setSelectedSyncTemplates((prevState) =>
          prevState.filter((item) => item !== syncTemplate),
        );
      }
    },
    [],
  );

  return (
    <>
      <ChakraModal
        closeOnEsc
        closeOnOverlayClick
        isCentered
        scrollBehavior="inside"
        isOpen
        onClose={onClose}
      >
        <ChakraModalOverlay />
        <ChakraModalContent p={0} my="auto">
          <ChakraModalHeader
            borderBottom="1px solid"
            borderColor="base.border"
            p={6}
          >
            Add syncs
          </ChakraModalHeader>
          <ChakraModalBody m={0}>
            <Column bg="base.lightBackground" py={4} gap={4} px={6}>
              <Paragraph>
                {treatmentGroups.length > 1
                  ? "Select a split group and add syncs usng the templates below."
                  : "Add syncs using the templates below."}{" "}
                Templates are pre-configured to work with this Audience's data
                source.
                {!appDisableSyncCreationForAudiences ||
                  (!workspace?.sync_templates_only && (
                    <>
                      {" "}
                      If your destination isn't listed below, you can{" "}
                      <Link href={newSyncUrl}>create a new sync</Link> or{" "}
                      <Link href="/schema-v2/settings/sync-templates/new">
                        create a new sync template
                      </Link>
                      .
                    </>
                  ))}
              </Paragraph>

              {treatmentGroups.length > 1 && (
                <FormField label="Split group">
                  <Select
                    options={treatmentGroupOptions}
                    value={selectedTreatmentGroup}
                    onChange={setSelectedTreatmentGroup}
                  />
                </FormField>
              )}

              {activeSyncs.length > 0 && (
                <Column gap={2}>
                  <Text fontWeight="medium">Active syncs</Text>
                  <Row flexWrap="wrap" gap={2}>
                    {activeSyncs.map((sync) => (
                      <Tag
                        key={sync.id}
                        imageUrl={sync?.destination?.definition.icon}
                        status={syncRunStatusToIndicatorVariant(
                          sync.status as SyncRunStatus,
                        )}
                      >
                        {sync.name}
                      </Tag>
                    ))}
                  </Row>
                </Column>
              )}
            </Column>

            <Column
              gap={2.5}
              py={4}
              px={6}
              borderTop="1px solid"
              borderColor="base.border"
            >
              <Text fontWeight="medium">
                Add sync templates
                {treatmentGroups.length > 1 &&
                  selectedTreatmentGroup &&
                  ` to ${selectedTreatmentGroup.friendly_name}`}
              </Text>

              <Column
                sx={{
                  "& > :not(:last-child)": {
                    borderBottom: "1px solid",
                    borderColor: "base.border",
                  },
                }}
              >
                {inactiveSyncTemplates.length ? (
                  inactiveSyncTemplates.map((syncTemplate) => {
                    const isDisabled = !syncTemplateFilter(syncTemplate.id);
                    return (
                      <Row
                        key={syncTemplate.id}
                        alignItems="center"
                        gap={3}
                        py={2}
                      >
                        <Checkbox
                          isDisabled={isDisabled}
                          isChecked={selectedSyncTemplates.includes(
                            syncTemplate,
                          )}
                          onChange={(event) =>
                            toggleSyncTemplate(
                              event.target.checked,
                              syncTemplate,
                            )
                          }
                        />
                        <Row
                          alignItems="center"
                          cursor="pointer"
                          pointerEvents={isDisabled ? "none" : "auto"}
                          gap={3}
                          onClick={() => {
                            const isSelected = selectedSyncTemplates.find(
                              (template) => template.id === syncTemplate.id,
                            );
                            toggleSyncTemplate(!isSelected, syncTemplate);
                          }}
                        >
                          <Box
                            as="img"
                            h={8}
                            w={8}
                            p="calc(4px * 1.333)"
                            src={syncTemplate.destination.definition.icon}
                          />
                          <Text fontWeight="medium">{syncTemplate.name}</Text>
                        </Row>
                      </Row>
                    );
                  })
                ) : (
                  <Text color="text.secondary">
                    All sync templates have been applied
                  </Text>
                )}
              </Column>
            </Column>
          </ChakraModalBody>
          <ChakraModalFooter
            borderTop="1px solid"
            borderColor="base.border"
            justifyContent={
              appDisableSyncCreationForAudiences ||
              workspace?.sync_templates_only
                ? "right"
                : "space-between"
            }
            p={6}
            m={0}
          >
            {!appDisableSyncCreationForAudiences &&
              !workspace?.sync_templates_only && (
                <Button
                  icon={PlusIcon}
                  variant="tertiary"
                  onClick={() => navigate(newSyncUrl)}
                >
                  Create new sync
                </Button>
              )}

            <ButtonGroup>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                isDisabled={isLoading || selectedSyncTemplates.length === 0}
                isLoading={isLoading}
                variant="primary"
                onClick={handleAddSyncTemplates}
              >
                Add syncs
              </Button>
            </ButtonGroup>
          </ChakraModalFooter>
        </ChakraModalContent>
      </ChakraModal>
    </>
  );
};
