import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { queryStatusPage } from "src/utils/query-status-page";
import { formatFriendlyDistanceToNow } from "src/utils/time";

export type StatusPageIncident = {
  id: string;
  title: string;
  message: string;
};

const getPreloadedAppIncident = () => {
  return localStorage.getItem("app-incident-banner");
};

export const useIncidentBanner = (): {
  loading: boolean;
  incident?: StatusPageIncident;
  onDismiss: () => void;
} => {
  const { incidentBanner } = useFlags();
  const [incident, setIncident] = useState<StatusPageIncident | undefined>();
  const [loading, setLoading] = useState(false);
  const [lastBannerDismissed, setLastBannerDismissed] = useState(
    getPreloadedAppIncident,
  );

  async function fetchData() {
    try {
      setLoading(true);
      const statusPageData = await queryStatusPage();

      const unresolvedIncident = statusPageData?.incidents?.[0];
      const recentUpdate = unresolvedIncident?.incident_updates?.[0];

      if (unresolvedIncident && recentUpdate) {
        const relativeUpdateTime = formatFriendlyDistanceToNow(
          recentUpdate.updated_at,
        );

        setIncident({
          title: unresolvedIncident.name,
          message: `${recentUpdate.body} (Last update ${relativeUpdateTime})`,
          id: unresolvedIncident.id,
        });
      }

      setLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
    }
  }

  const dismissBanner = () => {
    if (incident?.id) {
      localStorage.setItem("app-incident-banner", incident.id);
      setLastBannerDismissed(incident?.id);
    }
  };

  useEffect(() => {
    fetchData();

    // Re-fetch the data every 5 minutes in the event the user does't refresh the page, and an outage occurs.
    const interval = setInterval(() => {
      fetchData();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return {
    loading,
    incident:
      incidentBanner && lastBannerDismissed !== incident?.id
        ? incident
        : undefined,
    onDismiss: dismissBanner,
  };
};
