import { FC, useState } from "react";

import {
  Badge,
  CloseIcon,
  IconButton,
  Row,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { AllResourceSubscriptionsQuery } from "src/graphql";
import { ChannelDefinition, channelName } from "./channel-definitions";
import { captureException } from "@sentry/react";

export const SubscribedResourceItem: FC<{
  channel: AllResourceSubscriptionsQuery["resource_notification_channels"][0]["channel"];
  shouldNavigate?: () => void;
  onRemove?: () => Promise<unknown>;
}> = ({ channel, shouldNavigate, onRemove }) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const definition = ChannelDefinition[channel.channel_type];

  return (
    <Row
      height="32px"
      as={Badge}
      cursor={shouldNavigate ? "pointer" : undefined}
      align="center"
      gap={2}
      userSelect="none"
      onClick={shouldNavigate}
    >
      <Row align="center" gap={1} fontSize="xl">
        <definition.icon />
        <Text>{channelName(channel)}</Text>
      </Row>
      {onRemove && (
        <Tooltip message="Unassign recipient">
          <IconButton
            isLoading={isLoading}
            size="sm"
            aria-label="Unassign recipient"
            icon={CloseIcon}
            onClick={async () => {
              setIsLoading(true);
              try {
                await onRemove();
              } catch (e) {
                captureException(e);
                toast({
                  id: "resource-notifications",
                  title: "Failed to unassign",
                  variant: "error",
                });
              } finally {
                setIsLoading(false);
              }
            }}
          />
        </Tooltip>
      )}
    </Row>
  );
};

SubscribedResourceItem.displayName = SubscribedResourceItem.name;
