import { Column, Pill, Row, SearchInput, Text } from "@hightouchio/ui";
import sortBy from "lodash/sortBy";
import pluralize from "pluralize";
import { FC, useMemo, useState } from "react";
import { useUser } from "src/contexts/user-context";
import { useMembersQuery } from "src/graphql";
import { Pagination, Table } from "src/ui/table";

const ROWS_PER_PAGE = 10;

type AccessGrantsProps = {
  selectedRows: Array<string | number>;
  onRowSelect: (value: string | number | Array<string | number>) => void;
};

export const AccessGrants: FC<Readonly<AccessGrantsProps>> = ({
  selectedRows,
  onRowSelect,
}) => {
  const { workspace } = useUser();

  const { data: users, isLoading } = useMembersQuery(
    { workspaceId: workspace?.id },
    { select: (data) => data.all_memberships_v2 },
  );

  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(0);

  const filteredUsers = useMemo(() => {
    const sortedUsers = sortBy(
      users,
      (membership) => membership.user?.name?.toLowerCase(),
    );

    if (!search) return sortedUsers;

    const lowerCaseSearch = search.toLowerCase();
    return sortedUsers?.filter(
      ({ user }) =>
        user?.name.toLowerCase().includes(lowerCaseSearch) ||
        user?.email.toLowerCase().includes(lowerCaseSearch),
    );
  }, [search, users]);

  return (
    <Column gap={4} paddingBottom={2} zIndex={0}>
      <Column>
        <Text fontWeight="medium">Grant access to users</Text>
        <Text color="text.secondary">
          Select users who should be allowed to build audiences for this subset
        </Text>
      </Column>
      <Row justifyContent="space-between">
        <SearchInput
          width="xs"
          placeholder="Search users..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Row align="center" gap={2}>
          <Pill>{selectedRows.length}</Pill>
          <Text color="text.secondary">
            {pluralize("users", selectedRows.length)} selected
          </Text>
        </Row>
      </Row>
      <Table
        primaryKey="user_id"
        columns={[
          {
            name: "Name",
            cell: ({ user }) => user?.name,
          },
          {
            name: "Email address",
            cell: ({ user }) => user?.email,
          },
        ]}
        data={filteredUsers.slice(
          currentPage * ROWS_PER_PAGE,
          currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE,
        )}
        loading={isLoading}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />
      <Pagination
        count={filteredUsers.length}
        label="users"
        page={currentPage}
        rowsPerPage={ROWS_PER_PAGE}
        setPage={setCurrentPage}
      />
    </Column>
  );
};
