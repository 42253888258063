import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";
import { FormField, TagInput } from "@hightouchio/ui";
import { FC } from "react";
import { Controller } from "react-hook-form";

import { useDecisionEngineDestinationResourcesQuery } from "src/graphql";

export const CampaignAttribution: FC<
  Readonly<{
    channel: {
      config: DecisionEngineChannelConfig;
      destination: {
        type: string;
        id: string;
        config: Record<string, unknown>;
        definition: {
          icon: string;
          name: string;
        };
      };
    };
  }>
> = ({ channel: { destination } }) => {
  const { data, isLoading } = useDecisionEngineDestinationResourcesQuery(
    {
      destinationId: destination.id,
    },
    { select: (data) => data.getDecisionEngineDestinationResources },
  );

  return (
    <Controller
      name="flowMessageConfig.attributionCampaignIds"
      render={({ field }) => {
        const values = field.value ?? [];
        const options = [...values, ...(data ?? [])];

        return (
          <FormField
            isOptional
            label="Campaign attribution"
            description="Additional campaign IDs that are sending this message, for outcome attribution purposes."
          >
            <TagInput
              {...field}
              value={values}
              isLoading={isLoading}
              supportsCreatableOptions
              options={options}
              optionLabel={(option) => option.name ?? option.id}
              optionValue={(option) => option.id}
              placeholder="Additional campaign IDs..."
              onCreateOption={(value) => {
                field.onChange([...values, { id: value }]);
              }}
            />
          </FormField>
        );
      }}
    />
  );
};
