import { FC, useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "src/router";

const APP_VERSION_KEY = "app-version";

const preloadedAppVersion = localStorage.getItem(APP_VERSION_KEY);

export const ForceReload: FC = () => {
  const { appVersion } = useFlags();

  const location = useLocation();

  useEffect(() => {
    if (appVersion && preloadedAppVersion !== appVersion) {
      localStorage.setItem(APP_VERSION_KEY, appVersion);
      window.location.reload();
    }
  }, [location]); // Only run effect when location changes

  return null;
};
