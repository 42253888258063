import { useEffect, useMemo } from "react";

import * as Sentry from "@sentry/react";
import omit from "lodash/omit";
import immutableUpdate from "immutability-helper";
import { UseFormSetValue } from "react-hook-form";
import { isPresent } from "ts-extras";

import { toSingleCondition } from "src/components/audiences/utils";
import { getModelIdFromColumn } from "src/components/explore/visual/utils";
import { useHightouchForm, UseHightouchFormReturn } from "src/components/form";
import {
  useAudiencesAndMetricsForParentModelQuery,
  useParentModelAndRelationshipsQuery,
} from "src/graphql";
import { AggregationOption } from "src/pages/metrics/constants";
import { mapAggregationConfigurationToConfigurationOption } from "src/pages/metrics/utils";
import {
  AnalyticsFrequency,
  AndOrCondition,
  exhaustiveCheck,
  PropertyCondition,
} from "src/types/visual";

import {
  AnalyticsActions,
  AnalyticsState,
  AnalyticsContextType,
  FunnelStep,
  GraphType,
  GroupByColumn,
  MetricSelection,
  SelectedAudience,
  Metric,
  isMetricSelection,
  ConversionCriteriaData,
  AudienceFilter,
} from "../types";
import {
  getEventIdFromMetricSelection,
  getMetricSelectionFromMetric,
  getTimeValueAndSelectedDates,
  mapMetricToMetricSelection,
} from "../utils";
import {
  DEFAULT_GROUP_BY_COLUMNS,
  DEFAULT_METRIC_SELECTION,
  defaultFunnelUrlState,
  defaultPerformanceUrlState,
  defaultState,
  PLACEHOLDER_AUDIENCE,
  TimeOptions,
  STATIC_ARRAY,
} from "./constants";

type KeysToOmit =
  | "audiences"
  | "metrics"
  | "parent"
  | "events"
  | "parentModelLoading"
  | "audiencesAndMetricsLoading";

export type SavedAnalyticsState = Omit<AnalyticsState, KeysToOmit>;

type UseAnalyticsStateReturn = {
  form: UseHightouchFormReturn<Omit<AnalyticsState, KeysToOmit>, void>;
  value: AnalyticsContextType;
};

type UseAnalyticsStateArgs = {
  chart?: {
    name: string;
    description: string | null;
    frequency: string;
    chartType: string;
    lookbackWindow:
      | {
          start: string;
          end: string;
        }
      | {
          lookback: TimeOptions | number;
        };
    groupBy: any | null;
    parentModelId: string;
    funnel_stages: Array<{
      id: string;
      event_model: {
        id: string;
      } | null;
      relationship_id: string | null;
      funnel_stage_definition: {
        subconditions: AndOrCondition<PropertyCondition>[];
      };
    }>;
    goals: Array<{
      id: string;
      goal_definition: any | null;
      goal: {
        id: string;
      } | null;
    }>;
    cohorts: Array<{
      id: string;
      cohort_definition: any | null;
      cohort: {
        id: any;
      } | null;
    }>;
  } | null;
};

const defaultStateWithoutOmittedKeys: SavedAnalyticsState = omit(defaultState, [
  "audiences",
  "metrics",
  "parent",
  "events",
  "parentModelLoading",
  "audiencesAndMetricsLoading",
]);

export const useAnalyticsState = ({
  chart,
}: UseAnalyticsStateArgs): UseAnalyticsStateReturn => {
  const form = useHightouchForm({
    onSubmit: () => Promise.resolve(),
    defaultValues: defaultStateWithoutOmittedKeys,
  });

  const parentModelId = chart?.parentModelId
    ? Number(chart.parentModelId)
    : undefined;

  const parentModelAndRelationships = useParentModelAndRelationshipsQuery(
    { id: parentModelId?.toString() ?? "" },
    { enabled: Boolean(parentModelId) },
  );

  const audiencesAndMetrics = useAudiencesAndMetricsForParentModelQuery(
    { id: parentModelId?.toString() ?? "" },
    {
      enabled: Boolean(parentModelId),
      select: (data) => {
        const { segments, goals } = data;

        return {
          ...data,
          goals: goals.map(({ audience_aggregation, ...goal }) => ({
            ...goal,
            audienceAggregation: audience_aggregation,
          })),
          segments: segments.map((segment) => ({
            ...segment,
            splits: segment.splits.map(({ friendly_name, ...split }) => ({
              ...split,
              name: friendly_name,
              enabled: true,
            })),
          })),
        };
      },
      keepPreviousData: true,
    },
  );

  // derived state
  const audiences = audiencesAndMetrics.data?.segments ?? STATIC_ARRAY;
  const metrics = (audiencesAndMetrics.data?.goals as Metric[]) ?? STATIC_ARRAY;
  const parent = parentModelAndRelationships.data?.segments_by_pk ?? null;
  const relationships = parent?.relationships;
  const events = useMemo(
    () =>
      relationships?.filter(({ to_model: { event } }) => Boolean(event)) ??
      STATIC_ARRAY,
    [relationships],
  );

  useEffect(() => {
    if (parentModelId) {
      const metricSelection: MetricSelection[] = [];
      chart?.goals.forEach(({ goal, goal_definition }) => {
        if (goal) {
          const data = getMetricSelectionFromMetric({
            metricId: goal.id,
            events,
            metrics,
          });

          data && metricSelection.push(data);
        } else if (goal_definition) {
          let goalDefinition: MetricSelection | null = goal_definition;
          if (!goal_definition?.aggregationMethod) {
            goalDefinition = mapMetricToMetricSelection(
              goal_definition as Metric,
              metrics,
              parent?.filterable_audience_columns ?? [],
              events,
            );
          }

          if (!goalDefinition || !isMetricSelection(goalDefinition)) {
            Sentry.captureException(
              "Saved goal definition is not of type MetricSelection",
            );
          }

          goalDefinition && metricSelection.push(goalDefinition);
        }
      });

      const selectedAudiences: SelectedAudience[] = [];
      chart?.cohorts.forEach(({ cohort, cohort_definition }) => {
        let audience: SelectedAudience | undefined;
        if (cohort?.id) {
          audience = audiences.find(({ id }) => id === cohort.id);
        } else {
          // For named ad hoc filters
          const filter: AudienceFilter | undefined = cohort_definition?.filter;

          audience = {
            id: parentModelId,
            name: cohort_definition?.name ?? parent?.name,
            splits: [],
            filter,
          };
        }

        if (audience) {
          selectedAudiences.push(audience);
        }
      });

      const { timeValue, selectedDates } = getTimeValueAndSelectedDates(
        chart?.lookbackWindow,
      );

      const funnelSteps: FunnelStep[] = (chart?.funnel_stages ?? [])
        .map((stage) => {
          if (!stage.event_model || !stage.relationship_id) {
            Sentry.captureException(
              `Funnel stage ${stage.id}: event or relationship was deleted.`,
            );
            return null;
          }

          return {
            eventModelId: stage.event_model.id,
            relationshipId: stage.relationship_id,
            subconditions: stage.funnel_stage_definition?.subconditions ?? [],
          };
        })
        .filter(isPresent);

      const dataToSet: Partial<AnalyticsState> = {
        graphType: chart?.chartType as GraphType,
        groupByColumns: chart?.groupBy,
        metricSelection,
        timeValue,
        funnelSteps,
        selectedDates,
        parentModelId,
        rollupFrequency: chart?.frequency as AnalyticsFrequency,
        selectedAudiences,
      };

      form.reset({
        ...defaultStateWithoutOmittedKeys,
        ...dataToSet,
      });
    }
  }, [
    chart?.goals,
    chart?.cohorts,
    chart?.lookbackWindow,
    chart?.chartType,
    chart?.groupBy,
    chart?.frequency,
    parentModelId,
    parent,
    metrics,
    audiences,
    events,
  ]);

  // form state
  const values = form.watch();

  const setValue: UseFormSetValue<AnalyticsState> = (name, value) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    form.setValue(name, value, { shouldDirty: true });

  const getFilteredGroupByColumnsFromMetricSelection = (
    updatedMetrics: MetricSelection[],
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const groupByColumns = form.getValues("groupByColumns") ?? [];

    const modelIds = updatedMetrics
      .map((updatedMetric) =>
        getEventIdFromMetricSelection(updatedMetric, metrics),
      )
      .concat([parent?.id]);

    const filteredGroupByColumns = groupByColumns.filter(
      (column) => column && modelIds.includes(getModelIdFromColumn(column)),
    );

    return filteredGroupByColumns.length > 0
      ? filteredGroupByColumns
      : DEFAULT_GROUP_BY_COLUMNS;
  };

  const addAudience = () => {
    const selectedAudiences = form.getValues("selectedAudiences");

    setValue(
      "selectedAudiences",
      selectedAudiences?.concat([PLACEHOLDER_AUDIENCE]),
    );
  };

  const addGroupByColumn = (
    column: GroupByColumn | undefined,
    index: number,
  ) => {
    const groupByColumns = form.getValues("groupByColumns") ?? [];
    setValue(
      "groupByColumns",
      immutableUpdate(groupByColumns, { [index]: { $set: column } }),
    );
  };

  const addGroupByColumns = (
    columns: (GroupByColumn | undefined)[],
    fromIndex: number,
  ) => {
    const groupByColumns = form.getValues("groupByColumns") ?? [];

    const prevGroupBys = groupByColumns.slice(0, fromIndex);
    const groupByUpdate = prevGroupBys.concat(columns);

    setValue("groupByColumns", groupByUpdate);
  };

  const addMetric = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const metricSelection = form.getValues("metricSelection") ?? [];

    setValue(
      "metricSelection",
      metricSelection.concat(DEFAULT_METRIC_SELECTION),
    );
  };

  const removeAudienceAtIndex = (removalIndex: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const selectedAudiences = form.getValues("selectedAudiences");

    const newAudiences = selectedAudiences?.filter(
      (_, index) => index !== removalIndex,
    );

    setValue(
      "selectedAudiences",
      !newAudiences || newAudiences.length === 0
        ? [PLACEHOLDER_AUDIENCE]
        : newAudiences,
    );
  };

  const removeGroupByColumns = (startIndex: number, endIndex?: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const groupByColumns = form.getValues("groupByColumns") ?? [];
    const newGroupByColumns = groupByColumns
      .slice(0, startIndex)
      .concat(groupByColumns.slice((endIndex ?? startIndex) + 1));

    setValue(
      "groupByColumns",
      newGroupByColumns.length > 0
        ? newGroupByColumns
        : DEFAULT_GROUP_BY_COLUMNS,
    );
  };

  const removeMetricAtIndex = (removalIndex: number) => {
    const metricSelection = form.getValues("metricSelection") ?? [];
    const newMetricSelection = metricSelection.filter(
      (_, index) => index !== removalIndex,
    );

    setValue(
      "metricSelection",
      newMetricSelection.length > 0
        ? newMetricSelection
        : DEFAULT_METRIC_SELECTION,
    );
    setValue(
      "groupByColumns",
      getFilteredGroupByColumnsFromMetricSelection(newMetricSelection),
    );
  };

  const resetMetricAtIndex = (index: number) => {
    const metricSelection = form.getValues("metricSelection") ?? [];
    const metric = metrics.find(({ id }) => id === metricSelection[index]?.id);
    const aggregationMethod =
      (metric && mapAggregationConfigurationToConfigurationOption(metric)) ??
      AggregationOption.Count;

    updateMetricAtIndex(index, {
      aggregationMethod,
      conditions: metric?.config.filter?.subconditions ?? [],
      attributionWindow: metric?.attribution_window,
      column: undefined,
    });
  };

  const resetView = (view: GraphType) => {
    switch (view) {
      // Uses the same state as performance
      case GraphType.Breakdown:
      case GraphType.Performance:
        Object.entries(defaultPerformanceUrlState).forEach(([key, value]) => {
          setValue(key as any, value);
        });
        break;
      case GraphType.Funnel:
        Object.entries(defaultFunnelUrlState).forEach(([key, value]) => {
          setValue(key as any, value);
        });
        break;
      default:
        exhaustiveCheck(view as never);
    }
  };

  const selectParentModel = (id: number) => {
    setValue("parentModelId", id);
    setValue("selectedAudiences", undefined);
    setValue("metricSelection", DEFAULT_METRIC_SELECTION);
  };

  const setCumulative = (cumulative: boolean) => {
    setValue("cumulative", cumulative);
  };

  const setFunnelSteps = (steps: FunnelStep[]) => {
    const newSteps: FunnelStep[] = steps.map((step) => {
      if (step.subconditions) {
        const subconditions = toSingleCondition(
          step.subconditions,
        ) as AndOrCondition<PropertyCondition>[];

        return { ...step, subconditions };
      } else {
        return step;
      }
    });

    setValue("funnelSteps", newSteps);
  };

  const setFunnelConversionCriteria = (
    criteria: Partial<ConversionCriteriaData>,
  ) => {
    const funnelConversionWindow = form.getValues("funnelConversionWindow");
    const measuringType = form.getValues("measuringType");

    const newCriteria = {
      funnelConversionWindow,
      measuringType,
      ...criteria,
    };

    setValue("funnelConversionWindow", newCriteria.funnelConversionWindow);
    setValue("measuringType", newCriteria.measuringType);
  };

  const setGraphType = (graphType: GraphType) => {
    setValue("graphType", graphType);
    if (graphType === GraphType.Funnel) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Circular reference problem with Column types
      const groupByColumns = form.getValues("groupByColumns") ?? [];
      setValue("groupByColumns", groupByColumns.slice(0, 1));
    }
  };

  const setLookbackWindow = (value: TimeOptions) => {
    setValue("selectedDates", []);
    setValue("timeValue", value);
  };

  const setRollupFrequency = (frequency: AnalyticsFrequency) => {
    setValue("rollupFrequency", frequency);
  };

  const setSelectedDates = (dates: Date[]) => {
    setValue(
      "selectedDates",
      dates.map((date) => date.toISOString()),
    );
    setValue("timeValue", TimeOptions.Custom);
  };

  const updateAudienceAtIndex = (index: number, updates: SelectedAudience) => {
    const selectedAudiences = form.getValues("selectedAudiences");

    setValue(
      "selectedAudiences",
      immutableUpdate(selectedAudiences, { [index]: { $merge: updates } }),
    );
  };

  const updateMetricAtIndex = (
    index: number,
    updates: undefined | Partial<MetricSelection>,
  ) => {
    if (!updates) {
      return;
    }

    const metricSelection = form.getValues("metricSelection");
    let updatedMetrics: MetricSelection[];

    if (updates.conditions) {
      const conditions = toSingleCondition(
        updates.conditions,
      ) as MetricSelection["conditions"];

      updatedMetrics = immutableUpdate(
        metricSelection ?? [{} as MetricSelection],
        {
          [index]: {
            $merge: { ...updates, conditions },
          },
        },
      );
    } else {
      updatedMetrics = immutableUpdate(
        metricSelection ?? [{} as MetricSelection],
        {
          [index]: {
            $merge: updates,
          },
        },
      );
    }

    setValue("metricSelection", updatedMetrics);
    setValue(
      "groupByColumns",
      getFilteredGroupByColumnsFromMetricSelection(updatedMetrics),
    );
  };

  const actions: AnalyticsActions = {
    addAudience,
    addGroupByColumn,
    addGroupByColumns,
    addMetric,
    removeAudienceAtIndex,
    removeGroupByColumns,
    removeMetricAtIndex,
    resetMetricAtIndex,
    resetView,
    selectParentModel,
    setCumulative,
    setFunnelConversionCriteria,
    setFunnelSteps,
    setGraphType,
    setLookbackWindow,
    setRollupFrequency,
    setSelectedDates,
    updateAudienceAtIndex,
    updateMetricAtIndex,
  };

  return {
    form,
    value: {
      audiences,
      metrics,
      parent,
      events,
      parentModelLoading: parentModelAndRelationships.isLoading,
      audiencesAndMetricsLoading:
        audiencesAndMetrics.isLoading || audiencesAndMetrics.isRefetching,
      ...values,
      ...actions,
    },
  };
};
