import { Column, SectionHeading } from "@hightouchio/ui";
import * as yup from "yup";
import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";

import { DecisionEngineChannelsInsertInput } from "src/graphql";
import { ChannelOptions } from "src/pages/decision-engines/utils";
import { Card } from "src/components/card";
import { Channel } from "./channel";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";

const channelSchema = (configSchema: yup.ObjectSchema) =>
  yup.object().shape({
    enabled: yup.boolean().required(),
    destination_id: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Destination is required"),
      otherwise: yup.string().notRequired(),
    }),
    config: configSchema,
  });

const commonChannelConfigSchema = yup.object().shape({
  externalId: yup.string().when("enabled", {
    is: true,
    then: yup.string().required("Identifier is required"),
    otherwise: yup.string().notRequired(),
  }),
});

export const channelsSchema = yup.object().shape({
  email: channelSchema(commonChannelConfigSchema),
  sms: channelSchema(commonChannelConfigSchema),
  push: channelSchema(commonChannelConfigSchema),
  raw: channelSchema(
    yup.object().shape({
      fileType: yup.string().when("enabled", {
        is: true,
        then: yup.string().required("File format is required"),
        otherwise: yup.string().notRequired(),
      }),
      objectKey: yup.string().when("enabled", {
        is: true,
        then: yup.string().required("Object key is required"),
        otherwise: yup.string().notRequired(),
      }),
    }),
  ),
});

export const formatChannel = (
  channel,
  type: DecisionEngineChannelType,
): DecisionEngineChannelsInsertInput =>
  channel
    ? {
        id: channel.id,
        type: channel.type,
        enabled: channel.enabled,
        destination_id: channel.destination?.id,
        config: getChannelDefinition(
          channel.destination?.type,
        ).getChannelConfig(channel.config),
      }
    : {
        type,
        enabled: false,
        destination_id: "",
        config: {
          externalId: "",
        },
      };

export const Channels = () => {
  return (
    <Card gap={6}>
      <Column>
        <SectionHeading>Channels</SectionHeading>
      </Column>
      {ChannelOptions.map(({ label, value }) => {
        return <Channel key={value} label={label} type={value} />;
      })}
    </Card>
  );
};
