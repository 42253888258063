import {
  Box,
  Button,
  Column,
  Combobox,
  Dialog,
  DrawerBody,
  FormField,
  MultiSelect,
  Row,
  SectionHeading,
  Select,
  Text,
  TextInput,
  ToggleButton,
  ToggleButtonGroup,
  useDisclosure,
  useToast,
} from "@hightouchio/ui";
import { Fragment, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { Editor } from "src/components/editor";
import {
  Form,
  useHightouchForm,
  useHightouchFormContext,
} from "src/components/form";
import { useUser } from "src/contexts/user-context";
import { DecisionEngineMessageQuery, useModelQuery } from "src/graphql";
import { useModelState } from "src/hooks/use-model-state";
import { getColumnName, getRawModelRow, useModelRun } from "src/utils/models";
import { lowercase, snakecaseObject } from "src/utils/object";
import { FlowMessageContext } from "..";
import { useMessagePreview } from "src/pages/decision-engines/utils";
import json5 from "json5";
import { Email } from "./components/email";
import { Push } from "./components/push";

export const MessagePreview = () => {
  const userContext = useUser();
  const context = useOutletContext<FlowMessageContext>();
  const engine = context.engine;
  const message = context.flowMessage.message;

  const [jsonPayload, setJsonPayload] = useState(
    JSON.stringify(
      {
        hightouch: {
          user: {},
          recommendation: {},
        },
      },
      null,
      4,
    ),
  );
  const { toast } = useToast();
  const { run, previewId, error, isPreviewable } = useMessagePreview({
    channel: message.channel,
  });
  const [mode, setMode] = useState<"preview" | "data">(
    isPreviewable ? "preview" : "data",
  );
  const form = useHightouchForm({
    success: false,
    onSubmit: async ({ email }) => {
      console.log(context.flowMessage);
      try {
        const { hightouch } = json5.parse(jsonPayload);
        const { variables, ...items } = hightouch.recommendation ?? {};
        await run({
          email,
          user: hightouch.user,
          items,
          variables,
          segment: engine.segment,
          workspaceId: userContext.workspace?.id,
          campaignId: context.flowMessage.config.campaignId,
        });
      } catch (e) {
        toast({
          id: "invalid-data",
          title: "Invalid data provided",
          variant: "error",
        });
      }
    },
    defaultValues: {
      email: "",
      user: {} as Record<string, any>,
      items: {},
      variables: {} as Record<string, string | undefined>,
    },
  });

  const { watch, setValue } = form;

  const variables = watch("variables");

  useEffect(() => {
    const subscription = watch((data: any) => {
      const { items, user, variables } = data;
      const rawUser = getRawModelRow(user, engine.segment.columns);
      setJsonPayload(
        JSON.stringify(
          {
            hightouch: {
              user: lowercase(rawUser),
              recommendation: {
                ...(Object.keys(items).length
                  ? lowercase(snakecaseObject(items), 2)
                  : {}),
                ...(Object.keys(variables).length
                  ? {
                      variables,
                    }
                  : {}),
              },
            },
          },
          null,
          4,
        ),
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <DrawerBody bg="gray.50">
      <Form form={form}>
        <Row height="100%" gap={4} p={6} overflow="hidden">
          <Column gap={4}>
            <SectionHeading>Generate preview</SectionHeading>
            <Card gap={4}>
              <UserSelect />
            </Card>
            {message.variables?.length > 0 && (
              <Card gap={2}>
                <Text
                  fontWeight="medium"
                  textTransform="uppercase"
                  color="text.secondary"
                >
                  Variables
                </Text>
                {message.variables.map((variable) => (
                  <FormField key={variable.name} label={variable.name}>
                    <Select
                      isClearable
                      placeholder="Select a value..."
                      value={variables[variable.name]}
                      onChange={(value) => {
                        setValue(`variables.${variable.name}`, value);
                      }}
                      options={(variable.values ?? []) as Array<string>}
                      optionValue={(option) => option}
                      optionLabel={(option) => option}
                    />
                  </FormField>
                ))}
              </Card>
            )}
            {message.collections?.length > 0 && (
              <Card gap={2}>
                <Text
                  fontWeight="medium"
                  textTransform="uppercase"
                  color="text.secondary"
                >
                  Collections
                </Text>
                {message.collections?.map((collection) => (
                  <CollectionSelect
                    key={collection.decision_engine_collection.id}
                    collection={collection}
                  />
                ))}
              </Card>
            )}
          </Column>

          <Column flex={2} gap={2} height="100%" overflow="hidden">
            <Row gap={4} align="center" justify="space-between">
              {isPreviewable && (
                <ToggleButtonGroup
                  value={mode}
                  onChange={(value) => setMode(value as any)}
                >
                  <ToggleButton label="Preview" value="preview" />
                  <ToggleButton label="Data" value="data" />
                </ToggleButtonGroup>
              )}
              <TestModal channel={message.channel} error={error} />
            </Row>

            {mode === "preview" ? (
              <Controller
                name="variables"
                render={({ field }) => {
                  switch (message.channel.type) {
                    case "email":
                      return (
                        <Email
                          error={error}
                          variables={field.value}
                          previewId={previewId}
                        />
                      );
                    case "push":
                      return (
                        <Push
                          destinationId={message.channel.destination.id}
                          resourceId={context.flowMessage.config.campaignId}
                        />
                      );
                    default:
                      return <Fragment />;
                  }
                }}
              />
            ) : (
              <Card p={0} overflow="hidden">
                <Editor
                  bg="white"
                  onChange={(value) => {
                    setJsonPayload(value);
                  }}
                  value={jsonPayload}
                  language="json"
                />
              </Card>
            )}
          </Column>
        </Row>
      </Form>
    </DrawerBody>
  );
};

const CollectionSelect = ({
  collection,
}: {
  collection: NonNullable<
    DecisionEngineMessageQuery["decision_engine_messages_by_pk"]
  >["collections"][0];
}) => {
  const modelQuery = useModelQuery(
    {
      id: collection.decision_engine_collection.collection.catalog.model.id,
    },
    { select: (data) => data.segments_by_pk },
  );

  const modelState = useModelState(modelQuery.data);
  const { runQuery, rows, loading } = useModelRun(modelState.state);

  useEffect(() => {
    if (modelState.state.id) {
      runQuery({
        limit: true,
      });
    }
  }, [modelState.state.id]);

  useEffect(() => {
    if (modelQuery.data) {
      modelState.reset(modelQuery.data);
    }
  }, [modelQuery.data]);

  const primaryKey =
    collection.decision_engine_collection.collection.catalog.primary_key;

  return (
    <Controller
      name={`items.${collection.decision_engine_collection.collection.name.toLowerCase()}`}
      defaultValue={[]}
      render={({ field }) => (
        <FormField
          label={collection.decision_engine_collection.collection.name}
          tip={`Select ${collection.item_count} items`}
        >
          <MultiSelect
            placeholder="Select items..."
            value={field.value?.map((item) => item[primaryKey]) ?? []}
            onChange={(ids) => {
              const items = rows?.filter((r) => ids.includes(r[primaryKey]));
              field.onChange(items);
            }}
            isLoading={modelQuery.isLoading || loading}
            options={(rows ?? []) as Array<any>}
            optionLabel={(option) =>
              option[
                collection.decision_engine_collection.collection.catalog
                  .primary_label
              ]
            }
            optionValue={(option) => option[primaryKey]}
          />
        </FormField>
      )}
    />
  );
};

const UserSelect = () => {
  const { engine } = useOutletContext<FlowMessageContext>();

  const modelQuery = useModelQuery(
    {
      id: engine.segment.id,
    },
    { select: (data) => data.segments_by_pk },
  );

  const modelState = useModelState(modelQuery.data);
  const { runQuery, rows, loading } = useModelRun(modelState.state);

  useEffect(() => {
    if (modelState.state.id) {
      runQuery({
        limit: true,
      });
    }
  }, [modelState.state.id]);

  useEffect(() => {
    if (modelQuery.data) {
      modelState.reset(modelQuery.data);
    }
  }, [modelQuery.data]);

  if (modelQuery.data && rows?.length) {
    const primaryKey = getColumnName(
      modelQuery.data.primary_key!,
      modelQuery.data.columns,
    );
    const primaryLabel = getColumnName(
      modelQuery.data.visual_query_primary_label!,
      modelQuery.data.columns,
    );
    const secondaryLabel = getColumnName(
      modelQuery.data.visual_query_secondary_label!,
      modelQuery.data.columns,
    );

    const options = (rows.map((row) => ({
      ...row,
      [primaryKey]: row[primaryKey].toString(),
    })) ?? []) as Array<Record<string, any>>;

    return (
      <Controller
        name="user"
        render={({ field }) => {
          return (
            <FormField label="User">
              <Combobox
                placeholder="Select a user..."
                isLoading={modelQuery.isLoading || loading}
                value={field.value[primaryKey]}
                onChange={(value) => {
                  const user = options.find((r) => r[primaryKey] === value);
                  if (user) {
                    field.onChange(user);
                  }
                }}
                options={options}
                optionLabel={(option) => option[primaryLabel]}
                optionValue={(option) => option[primaryKey]}
                optionDescription={
                  secondaryLabel
                    ? (option) => option[secondaryLabel]
                    : undefined
                }
              />
            </FormField>
          );
        }}
      />
    );
  }
  return (
    <FormField label="User">
      <Combobox
        isLoading
        options={[]}
        value={undefined}
        onChange={() => null}
      />
    </FormField>
  );
};

export const TestModal = ({
  error,
  channel,
}: {
  error: string;
  channel: {
    type: string;
  };
}) => {
  const { toast } = useToast();
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();
  const { submit } = useHightouchFormContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const email = watch("email");

  const close = () => {
    setValue("email", "");
    onClose();
  };

  if (channel.type === "raw") {
    return (
      <Box
        as={Button}
        isLoading={isSubmitting}
        onClick={async () => {
          await submit();
          toast({
            id: "test-sent",
            title: `A test recommendation has been sent.`,
            variant: "success",
          });
        }}
        ml="auto"
      >
        Send test
      </Box>
    );
  }

  return (
    <>
      <Box as={Button} onClick={onOpen} ml="auto">
        Send test
      </Box>
      <Dialog
        isOpen={isOpen}
        onClose={close}
        title="Send test"
        variant="form"
        actions={
          <>
            <Button onClick={close}>Cancel</Button>
            <Button
              variant="primary"
              isLoading={isSubmitting}
              onClick={async () => {
                await submit();
                toast({
                  id: "send-test",
                  title: `A test message has been sent to ${email}.`,
                  variant: "success",
                });
                close();
              }}
            >
              Send test
            </Button>
          </>
        }
      >
        <Column gap={4}>
          <Text>
            The message you have defined will be sent to the user specified
            below.
          </Text>
          <Controller
            name="email"
            render={({ field }) => (
              <FormField label="User identifier..." error={error}>
                <TextInput
                  isInvalid={Boolean(error)}
                  {...field}
                  placeholder="User identifier..."
                />
              </FormField>
            )}
          />
        </Column>
      </Dialog>
    </>
  );
};
