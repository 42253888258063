import { FC, ReactElement, useState } from "react";

import { Column, Spinner, Text } from "@hightouchio/ui";

import { GraphScale } from "./constants";
import {
  getTableHeight,
  MAX_TABLE_HEIGHT,
  ResultsTable,
} from "./results-table";
import { Graph } from "./types";
import { AnalyticsGraph } from "./analytics-graph";

export const MAX_LINES = 30;

type Props = {
  graph: Graph;
  isLoading?: boolean;
  placeholder: ReactElement;
  scale: GraphScale;
  selectedRows: (string | number)[];
  onSelect: (key: string | number | (string | number)[]) => void;
};

const CrossAudienceGraph: FC<Props> = ({
  graph,
  isLoading,
  placeholder,
  scale = GraphScale.Linear,
  selectedRows,
  onSelect,
}) => {
  const [hoveredLine, setHoveredLine] = useState<string>();

  if (isLoading) {
    return (
      <Column
        align="center"
        justifyContent="center"
        flex={1}
        minHeight={0}
        gap={4}
      >
        <Spinner size="lg" />
        <Text color="text.secondary" size="sm">
          Query processing...
        </Text>
      </Column>
    );
  }

  // Don't show more than MAX_LINES to prevent page from crashing.
  const series = graph.series
    .filter(({ key }) => selectedRows.includes(key))
    .slice(0, MAX_LINES);

  if (!graph.series.length) {
    return placeholder;
  }

  const tableHeight = getTableHeight(graph.series.length);

  const hasSummaryStats = Boolean(graph.summary.length);

  return (
    <>
      <AnalyticsGraph
        data={series}
        height={
          hasSummaryStats
            ? "400px"
            : `calc(100% - ${
                tableHeight > MAX_TABLE_HEIGHT ? MAX_TABLE_HEIGHT : tableHeight
              }px)`
        }
        hoveredLine={hoveredLine}
        onHoverLine={setHoveredLine}
        scale={scale}
      />
      <ResultsTable
        graph={graph}
        scale={scale}
        selectedRows={selectedRows}
        onSelect={onSelect}
        onMouseEnterRow={(key) => {
          if (typeof key === "string") {
            setHoveredLine(key);
          }
        }}
        onMouseLeaveRow={() => setHoveredLine(undefined)}
      />
    </>
  );
};

export default CrossAudienceGraph;
