import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";

import {
  Text,
  Column,
  Heading,
  Row,
  SearchInput,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { groupBy } from "lodash";
import { FC, useMemo, useState } from "react";
import { useSubscribedResources } from "./use-subscribed-resources";
import { Table } from "src/ui/table";
import { getSearchRegExp } from "src/utils/string";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  useCreateResourceNotificationTemplateChannelMutation,
  useDeleteResourceChannelTemplateMutation,
  useWorkspaceNotificationChannelsQuery,
} from "src/graphql";
import { ResourceQuickSubscribeMenu } from "./resource-quick-subscribe-menu";
import { SubscribedResourceItem } from "./subscribed-resource-item";
import pluralize from "pluralize";

export interface Props {
  resourceType?: string;
  resourceId?: string;
}

export const ResourceNotificationChannels: FC = () => {
  const [search, setSearch] = useState<string>("");
  const { toast } = useToast();

  const {
    isLoading: resourcesLoading,
    availableResources,
    resourceSubscriptions: resourceNotifications,
    defaultSubscriptions,
  } = useSubscribedResources({
    resourceType: MonitoredResourceType.Sync,
  });

  const { data: workspaceChannels, isLoading: loadingChannels } =
    useWorkspaceNotificationChannelsQuery(undefined, {
      select(data) {
        return data.workspace_notification_channels;
      },
    });

  const { mutateAsync: unsubscribeDefaultResource } =
    useDeleteResourceChannelTemplateMutation();
  const { mutateAsync: subscribeDefaultResource } =
    useCreateResourceNotificationTemplateChannelMutation();

  const resourceOverridesHref = (resourceId: string) => {
    return `overrides?parentResourceId=${resourceId}`;
  };

  const destinationsWithSyncs = useMemo(() => {
    const groupedSyncs = groupBy(
      availableResources?.syncs,
      (sync) => sync.destination?.id,
    );

    return availableResources?.destinations
      .map((destination) => ({
        destination,
        syncs: groupedSyncs[destination.id] ?? [],
      }))
      .filter((destinationAndSyncs) => {
        if (!search) return true;
        const regex = getSearchRegExp(search, "i");
        return (
          regex.test(destinationAndSyncs.destination.name ?? "") ||
          regex.test(destinationAndSyncs.destination.definition.name ?? "")
        );
      })
      .sort((a, b) => b.syncs.length - a.syncs.length);
  }, [availableResources, search]);

  return (
    <Column flex={1}>
      <Row justifyContent="space-between" align="center" p={6}>
        <Heading size="md">Assign recipients by destination</Heading>
        <SearchInput
          placeholder="Search destinations..."
          value={search ?? ""}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </Row>
      <Table
        allowWrap
        data={destinationsWithSyncs}
        loading={resourcesLoading || loadingChannels}
        placeholder={{
          title: "No destinations",
          body: "You will need to create at least one destination before assigning alert recipients",
        }}
        columns={[
          {
            name: "Destination",
            max: "auto",
            min: "300px",
            cell: ({ destination, syncs }) => {
              const destinationGroup = destination;
              const destinationName =
                destinationGroup?.name || destinationGroup?.definition?.name;

              const groupOverrides = syncs?.filter((sync) =>
                Boolean(
                  resourceNotifications?.find(
                    (sub) =>
                      sub.resource_id.toString() === sync.id.toString() &&
                      !sub.isTemplate,
                  ),
                ),
              );

              return (
                <Row align="center" gap={3} py={3}>
                  <IntegrationIcon
                    src={destinationGroup?.definition?.icon}
                    name={destinationName}
                  />
                  <Column>
                    <Text fontWeight="medium">{destinationName}</Text>
                    {syncs && (
                      <Text size="md" color="gray.700">
                        {syncs?.length} {pluralize("sync", syncs?.length)}
                        {groupOverrides?.length > 0 && (
                          <>
                            {" • "}
                            <Link href={resourceOverridesHref(destination.id)}>
                              {`${groupOverrides?.length} ${pluralize(
                                "override",
                                groupOverrides?.length,
                              )}`}
                            </Link>
                          </>
                        )}
                      </Text>
                    )}
                  </Column>
                </Row>
              );
            },
          },
          {
            name: "Alert recipients",
            cell: ({ destination, syncs }) => {
              const destinationGroup = syncs[0]?.destination;
              const destinationGroupSubs = defaultSubscriptions?.filter(
                (sub) =>
                  sub.parent_resource_id.toString() ===
                  destination.id.toString(),
              );
              const availableDestinationChannels = workspaceChannels?.filter(
                (wc) =>
                  !destinationGroupSubs
                    ?.map((dgs) => dgs.channel.id)
                    .includes(wc.id),
              );
              return (
                <Row align="center" flexWrap="wrap" gap={2} py={3}>
                  {destinationGroupSubs?.length === 0 && (
                    <ResourceQuickSubscribeMenu
                      fullSize={true}
                      resourceType={ParentResourceTypes.Destination}
                      resourceId={destinationGroup?.id}
                      onSelectChannel={async ({ channelId }) => {
                        await subscribeDefaultResource({
                          channelId,
                          parentResourceId: destination.id.toString(),
                          parentResourceType: ParentResourceTypes.Destination,
                        });
                        toast({
                          id: "resource-notifications",
                          title: "Recipient assigned",
                          variant: "success",
                        });
                      }}
                      availableDestinationChannels={
                        availableDestinationChannels ?? []
                      }
                    />
                  )}
                  {destinationGroupSubs?.map((sub) => (
                    <SubscribedResourceItem
                      key={sub.id}
                      channel={sub.channel}
                      onRemove={async () => {
                        await unsubscribeDefaultResource({
                          resourceChannelTemplateId: sub.id,
                        });
                        toast({
                          id: "resource-notifications",
                          title: "Recipient unassigned",
                          variant: "success",
                        });
                      }}
                    />
                  ))}
                  {destinationGroupSubs?.length ? (
                    <ResourceQuickSubscribeMenu
                      resourceType={ParentResourceTypes.Destination}
                      resourceId={destinationGroup?.id}
                      onSelectChannel={async ({ channelId }) => {
                        await subscribeDefaultResource({
                          channelId,
                          parentResourceId: destination.id.toString(),
                          parentResourceType: ParentResourceTypes.Destination,
                        });
                        toast({
                          id: "resource-notifications",
                          title: "Recipient assigned",
                          variant: "success",
                        });
                      }}
                      availableDestinationChannels={
                        availableDestinationChannels ?? []
                      }
                    />
                  ) : null}
                </Row>
              );
            },
          },
        ]}
      />
    </Column>
  );
};
