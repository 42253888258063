import { FC, useState } from "react";

import {
  Box,
  BulbSuggestionIcon,
  Checkbox,
  Column,
  Pill,
  Row,
  SearchInput,
  Text,
} from "@hightouchio/ui";
import { format as formatDate } from "date-fns";

import {
  Graph,
  GroupColumn,
} from "src/components/analytics/cross-audience-graph/types";
import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";
import { GroupByColumn } from "src/pages/analytics/types";
import {
  getColumnReferenceFromGroupByColumn,
  getNumberOfUniqueValues,
} from "src/pages/analytics/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";

import { GraphScale } from "./constants";
import { formatMetricValue } from "./utils";

const CHECKBOX_WIDTH = 16;
const LARGE_COLUMN_WIDTH = 120;
const MEDIUM_COLUMN_WIDTH = 80;
const SMALL_COLUMN_WIDTH = 60;
const PADDING = 16;
const MAX_VISIBLE_ROWS = 100;
const MAX_SELECTED_SERIES = 20;

const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 40;
export const MAX_TABLE_HEIGHT = ROW_HEIGHT * 4 + HEADER_HEIGHT; // 4 rows + header

const borderStyles = {
  borderRight: "1px solid",
  borderColor: "base.border",
};

const getColumnOffset = (priorColumnWidths: number[]) => {
  const sumPreviousColumnWidths = priorColumnWidths.reduce(
    (acc, width) => acc + width,
    0,
  );
  const padding = PADDING * priorColumnWidths.filter(Boolean).length * 2;
  return sumPreviousColumnWidths + padding;
};

export const getTableHeight = (numRows: number) => {
  return numRows * 60 + 40;
};

type ResultsTableProps = {
  graph: Graph;
  scale: GraphScale;
  selectedRows: (string | number)[];
  onMouseEnterRow?: (key: string | number) => void;
  onMouseLeaveRow?: (key: string | number) => void;
  onSelect: (key: string | number | (string | number)[]) => void;
};

export const ResultsTable: FC<ResultsTableProps> = ({
  graph,
  scale,
  selectedRows,
  onSelect,
  onMouseEnterRow,
  onMouseLeaveRow,
}) => {
  const [search, setSearch] = useState("");

  const selectAll = (event) => {
    if (event.target.checked) {
      onSelect(graph.series.slice(0, 30).map(({ key }) => key));
    } else {
      onSelect([]);
    }
  };

  const visibleRows = graph.series.slice(0, MAX_VISIBLE_ROWS);
  const rowsTruncated = graph.series.length > MAX_VISIBLE_ROWS;
  const numberOfMetrics = getNumberOfUniqueValues(visibleRows, "metricName");
  const hasMultipleMetrics = numberOfMetrics > 1;
  const hasSplits = graph.series.some(({ splitName }) => Boolean(splitName));

  const maxChecked =
    graph.series.length === selectedRows.length ||
    selectedRows.length >= MAX_SELECTED_SERIES;

  const filteredRows = visibleRows.filter(
    ({ audienceName, name, splitName, grouping }) => {
      const lowerCasedSearch = search.trim().toLowerCase();
      const groupMetadata = (grouping ?? [])?.flatMap(({ column, value }) => [
        getPropertyNameFromColumn(column)?.toLowerCase() ?? "",
        value?.toLowerCase() ?? "--",
      ]);

      return (
        name.toLowerCase().includes(lowerCasedSearch) ||
        audienceName.toLowerCase().includes(lowerCasedSearch) ||
        splitName?.toLowerCase().includes(lowerCasedSearch) ||
        groupMetadata.filter((value) => value.includes(lowerCasedSearch))
          .length > 0
      );
    },
  );

  const groupings = filteredRows.flatMap(
    ({ grouping }) =>
      grouping?.map(({ column, value }) => ({
        [`${getPropertyNameFromColumn(column)}`]: value,
      })),
  );

  const hasGroups = groupings.length > 0;

  const stickyColumnWidths = [
    // Checkbox
    CHECKBOX_WIDTH,
    // Metric name
    hasMultipleMetrics ? LARGE_COLUMN_WIDTH : 0,
    // Audience name
    LARGE_COLUMN_WIDTH,
    // Split name
    hasSplits ? MEDIUM_COLUMN_WIDTH : 0,
    // groupings
    ...groupings.map(() => SMALL_COLUMN_WIDTH),
  ];

  return (
    <Box
      position="relative"
      borderTop="1px solid"
      borderColor="base.border"
      pt={4}
    >
      <Row align="center" justifyContent="space-between" px={6} gap={2} mb={4}>
        <Row align="center" gap={2}>
          <Text fontWeight="medium" size="lg">
            All series
          </Text>{" "}
          <Box>
            <Pill>{graph.series.length}</Pill>
          </Box>
        </Row>

        <SearchInput
          placeholder="Search series..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Row>
      {filteredRows.length > 0 && (
        <Box
          id="wrapper"
          maxHeight={`${MAX_TABLE_HEIGHT}px`}
          overflowY="auto"
          ml={6}
        >
          <Box
            as="table"
            width="100%"
            overflowX="auto"
            sx={{ borderCollapse: "separate", borderSpacing: 0 }}
          >
            <Box
              as="thead"
              sx={{
                tr: {
                  th: {
                    position: "sticky",
                    top: 0,
                    height: `${HEADER_HEIGHT}px`,
                    px: `${PADDING}px`,
                    bg: "white",
                    borderBottom: "2px solid",
                    borderColor: "base.border",
                    textAlign: "left",
                    textTransform: "uppercase",
                    fontWeight: "semibold",
                  },
                },
              }}
            >
              <Box as="tr">
                <Box as="th" left={0} zIndex={2}>
                  <Row
                    align="center"
                    height="100%"
                    width={`${stickyColumnWidths[0]}px`}
                  >
                    <Checkbox isChecked={maxChecked} onChange={selectAll} />
                  </Row>
                </Box>
                {hasMultipleMetrics && (
                  <Box
                    as="th"
                    left={`${getColumnOffset(
                      stickyColumnWidths.slice(0, 1),
                    )}px`}
                    width={`${LARGE_COLUMN_WIDTH}px`}
                    zIndex={2}
                  >
                    <Row
                      align="center"
                      height="100%"
                      width={`${stickyColumnWidths[1]}px`}
                      flexShrink={0}
                      gap={2}
                    >
                      <TextWithTooltip
                        isTruncated
                        color="text.secondary"
                        message="Metric"
                      >
                        Metric
                      </TextWithTooltip>
                      <Box>
                        <Pill>
                          {getNumberOfUniqueValues(filteredRows, "metricName")}
                        </Pill>
                      </Box>
                    </Row>
                  </Box>
                )}
                <Box
                  as="th"
                  left={`${getColumnOffset(stickyColumnWidths.slice(0, 2))}px`}
                  width={`${stickyColumnWidths[2]}px`}
                  zIndex={2}
                  {...(hasSplits || hasGroups ? {} : borderStyles)}
                >
                  <Row align="center" height="100%" gap={1}>
                    <TextWithTooltip
                      isTruncated
                      color="text.secondary"
                      message="Audience"
                    >
                      Audience
                    </TextWithTooltip>
                    <Box>
                      <Pill>
                        {getNumberOfUniqueValues(filteredRows, "name")}
                      </Pill>
                    </Box>
                  </Row>
                </Box>
                {hasSplits && (
                  <Box
                    as="th"
                    left={`${getColumnOffset(
                      stickyColumnWidths.slice(0, 3),
                    )}px`}
                    width={`${stickyColumnWidths[3]}px`}
                    {...(hasGroups ? {} : borderStyles)}
                    zIndex={2}
                  >
                    <Row align="center" height="100%" gap={1}>
                      <TextWithTooltip
                        isTruncated
                        color="text.secondary"
                        message="Audience"
                      >
                        Splits
                      </TextWithTooltip>
                      <Box>
                        <Pill>
                          {getNumberOfUniqueValues(filteredRows, "splitName", [
                            undefined,
                          ])}
                        </Pill>
                      </Box>
                    </Row>
                  </Box>
                )}
                {graph.series?.[0]?.grouping?.map(
                  (column: GroupByColumn | GroupColumn, index) => {
                    const widthIndex = 3 + index + 1;
                    const columnName =
                      getPropertyNameFromColumn(
                        getColumnReferenceFromGroupByColumn(column),
                      ) ?? "Group";

                    return (
                      <Box
                        key={columnName}
                        as="th"
                        left={`${getColumnOffset(
                          stickyColumnWidths.slice(0, widthIndex),
                        )}px`}
                        width={`${stickyColumnWidths[widthIndex]}px`}
                        {...(index + 1 === groupings.length
                          ? {}
                          : borderStyles)}
                        zIndex={2}
                      >
                        <Row align="center" height="100%" gap={1}>
                          <TextWithTooltip
                            isTruncated
                            color="text.secondary"
                            message={columnName}
                          >
                            {columnName}
                          </TextWithTooltip>
                          <Box>
                            <Pill>
                              {getNumberOfUniqueValues(groupings, columnName, [
                                undefined,
                              ])}
                            </Pill>
                          </Box>
                        </Row>
                      </Box>
                    );
                  },
                )}
                {(graph.series?.[0]?.data ?? []).map((dataPoint) => {
                  const date = formatDate(dataPoint.calculatedAt, "LLL d");

                  return (
                    <Box as="th" key={dataPoint.calculatedAt} zIndex={1}>
                      <Row align="center" height="100%">
                        <TextWithTooltip
                          isTruncated
                          color="text.secondary"
                          message={date}
                        >
                          {date}
                        </TextWithTooltip>
                      </Row>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box
              as="tbody"
              sx={{
                tr: {
                  _hover: {
                    td: {
                      bg: "base.lightBackground",
                    },
                  },
                  td: {
                    height: `${ROW_HEIGHT}px`,
                    px: `${PADDING}px`,
                  },
                },
              }}
            >
              {filteredRows.map(
                ({
                  key,
                  splitName,
                  metricName,
                  data,
                  color,
                  audienceName,
                  grouping,
                }) => {
                  const isChecked = selectedRows.includes(key);

                  return (
                    <Box
                      key={key}
                      as="tr"
                      onMouseEnter={() => isChecked && onMouseEnterRow?.(key)}
                      onMouseLeave={() => onMouseLeaveRow?.(key)}
                    >
                      <Box as="td" left={0} position="sticky" bg="white">
                        <Row
                          align="center"
                          height="100%"
                          width={`${stickyColumnWidths[0]}px`}
                          sx={{
                            span: {
                              "&[data-checked]": {
                                bg: color,
                                border: "none",
                                _hover: {
                                  bg: color,
                                },
                              },
                              _hover: {
                                bg: "transparent",
                                borderColor: color,
                              },
                              svg: {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <Checkbox
                            isDisabled={!isChecked && maxChecked}
                            isChecked={isChecked}
                            onChange={() => onSelect(key)}
                          />
                        </Row>
                      </Box>
                      {hasMultipleMetrics && (
                        <Box
                          as="td"
                          position="sticky"
                          bg="white"
                          left={`${getColumnOffset(
                            stickyColumnWidths.slice(0, 1),
                          )}px`}
                        >
                          <Row
                            align="center"
                            height="100%"
                            width={`${stickyColumnWidths[1]}px`}
                            flexShrink={0}
                          >
                            <TextWithTooltip
                              isTruncated
                              color="text.secondary"
                              message={metricName}
                              size="sm"
                            >
                              {metricName}
                            </TextWithTooltip>
                          </Row>
                        </Box>
                      )}
                      <Box
                        as="td"
                        position="sticky"
                        bg="white"
                        left={`${getColumnOffset(
                          stickyColumnWidths.slice(0, 2),
                        )}px`}
                        {...(hasSplits || hasGroups ? {} : borderStyles)}
                      >
                        <Row
                          align="center"
                          height="100%"
                          width={`${stickyColumnWidths[2]}px`}
                        >
                          <TextWithTooltip
                            isTruncated
                            color="text.secondary"
                            message={audienceName}
                            size="sm"
                          >
                            {audienceName}
                          </TextWithTooltip>
                        </Row>
                      </Box>
                      {hasSplits && (
                        <Box
                          as="td"
                          position="sticky"
                          bg="white"
                          left={`${getColumnOffset(
                            stickyColumnWidths.slice(0, 3),
                          )}px`}
                          {...(hasGroups ? {} : borderStyles)}
                        >
                          <Row
                            align="center"
                            height="100%"
                            width={`${stickyColumnWidths[3]}px`}
                          >
                            <TextWithTooltip
                              isTruncated
                              color="text.secondary"
                              message={splitName}
                              size="sm"
                            >
                              {splitName ?? "--"}
                            </TextWithTooltip>
                          </Row>
                        </Box>
                      )}
                      {grouping?.map((column, index) => {
                        const widthIndex = 3 + index + 1;

                        return (
                          <Box
                            key={`${column.value}-${index}`}
                            as="td"
                            position="sticky"
                            bg="white"
                            left={`${getColumnOffset(
                              stickyColumnWidths.slice(0, widthIndex),
                            )}px`}
                            {...(index + 1 === groupings.length
                              ? {}
                              : borderStyles)}
                          >
                            <Row
                              align="center"
                              height="100%"
                              width={`${stickyColumnWidths[widthIndex]}px`}
                            >
                              <TextWithTooltip
                                isTruncated
                                color="text.secondary"
                                message={column.value ?? "--"}
                                size="sm"
                              >
                                {column.value ?? "--"}
                              </TextWithTooltip>
                            </Row>
                          </Box>
                        );
                      })}
                      {data.map(({ metricValue }, index) => (
                        <Box key={`${key}-${index}`} as="td">
                          <Row align="center" height="100%">
                            <TextWithTooltip
                              isTruncated
                              color="text.secondary"
                              message={metricValue.toString()}
                              size="sm"
                            >
                              {formatMetricValue(
                                metricValue,
                                scale === GraphScale.Percent,
                              )}
                            </TextWithTooltip>
                          </Row>
                        </Box>
                      ))}
                    </Box>
                  );
                },
              )}
            </Box>
          </Box>
        </Box>
      )}

      {filteredRows.length === 0 && (
        <Column
          align="center"
          justify="center"
          flex={1}
          minWidth={0}
          height={`${MAX_TABLE_HEIGHT}px`}
        >
          <Text color="text.secondary">
            No results found. Please update your search and try again.
          </Text>
        </Column>
      )}
      {rowsTruncated && (
        <Box
          p={2}
          bg="gray.100"
          border="1px solid"
          borderColor="gray.400"
          borderRadius="md"
          my={2}
          mx={2}
        >
          <Row
            as={Text}
            align="center"
            gap={2}
            color="gray.600"
            fontSize="16px"
          >
            <BulbSuggestionIcon />
            <Text size="sm">
              We limit the number of segments to {MAX_VISIBLE_ROWS}.
            </Text>
          </Row>
        </Box>
      )}
    </Box>
  );
};
