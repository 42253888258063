import { FC, useState } from "react";

import { AudienceSplit } from "@hightouch/lib/query/visual/types";
import {
  Row,
  Text,
  Tooltip,
  Column,
  SectionHeading,
  Box,
  WarningIcon,
  Skeleton,
  SkeletonBox,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "src/router";

import placeholder from "src/assets/placeholders/sync.svg";
import { useUser } from "src/contexts/user-context";
import {
  ModelWithoutColumnsQuery,
  OrderBy,
  useDestinationMetadataQuery,
  useIsDestinationMetadataQuery,
  useSyncsLabelsQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import {
  calculateAudienceSizeValues,
  processAudienceSizeComponent,
} from "src/pages/syncs/sync/components/external-data";
import { isPresent } from "src/types/utils";
import { Audience } from "src/types/visual";
import { SortOption, Table, TableColumn, useTableSort } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";
import { PermissionedButton } from "src/components/permission";

import { AddSyncButton as AddAudienceSyncButton } from "src/components/audiences/add-sync/add-sync-button";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import {
  isAudienceStatusProcessing,
  isMatchedUsersCountSettling,
} from "src/utils/match-boosting";
import { ParentModel } from "src/pages/audiences/types";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";
import { DestinationCell } from "src/pages/syncs/components/destination-cell";

type Props = {
  parentModel?: NonNullable<ParentModel>;
  model?: NonNullable<Audience>;
  syncs?: NonNullable<ModelWithoutColumnsQuery["segments_by_pk"]>["syncs"][0][];
  // Because the model above is actually sometimes an audience and it's often not
  // provided, it's too difficult to extract the matchbooster enabled status from
  // the model. So we just pass it in here.
  modelMatchboostingEnabled: boolean;
  onAdd: () => void;
};

type SortKeys = {
  destination: OrderBy | undefined;
  matched_users: OrderBy | undefined;
};

const initialSort: SortOption<keyof SortKeys> = {
  key: "destination",
  label: "Destination",
  direction: OrderBy.Asc,
};

const sortOptions: SortOption<keyof SortKeys>[] = [initialSort];

export const Syncs: FC<Readonly<Props>> = ({
  parentModel,
  model,
  syncs,
  onAdd,
  modelMatchboostingEnabled,
}) => {
  const { workspace } = useUser();
  const navigate = useNavigate();
  const { data: entitlementsData } = useEntitlements(true);
  const { appDisableSyncCreationForAudiences } = useFlags();

  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a sync, upgrade your plan.";

  const isAudience = Boolean(parentModel);
  const showSplitsColumn = isAudience;
  const [showExternalColumn, setShowExternalColumn] = useState(false);

  const showAddSyncButton =
    !appDisableSyncCreationForAudiences || !workspace?.sync_templates_only;

  const orderBy = useTableSort<SortKeys>(initialSort, sortOptions);

  const syncsLabelsQuery = useSyncsLabelsQuery(
    {
      filters: { segment_id: { _eq: model?.id } },
      limit: 100,
      offset: 0,
    },
    {
      select: (data) => data.syncs,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const sortedSyncs = syncs?.sort((a, b) => {
    if (!a || !b || !a.destination?.name || !b.destination?.name) return 0;

    if (orderBy?.destination) {
      return orderBy?.destination === OrderBy.Asc
        ? a.destination.name.localeCompare(b.destination.name || "")
        : b.destination.name.localeCompare(a.destination.name || "");
    }

    return 0;
  });

  const columns: TableColumn[] = [
    {
      name: "Status",
      min: "148px",
      max: "148px",
      cell: ({ status, sync_requests, draft: isInitialDraft }) => {
        if (isInitialDraft) {
          return <DraftBadge />;
        }

        const syncRequest = sync_requests?.[0];
        const request = syncRequest ? syncRequest : { status_computed: status };
        return <SyncRunStatusBadge request={request} status={status} />;
      },
    },
    {
      name: "Destination",
      max: "1.5fr",
      cell: ({ id, description, config, destination }) => {
        useIsDestinationMetadataQuery(
          { id },
          {
            select: (data) => data.supportsDestinationMetadata,
            onSuccess: (data) => {
              if (data) {
                setShowExternalColumn(true);
              }
            },
          },
        );

        const name = destination?.name;
        const definition = destination?.definition ?? {};
        const syncBoosted = isSyncMatchBoosted(config);
        const metadata = syncsLabelsQuery.data?.find((s) => s.id === id)
          ?.labels;

        return (
          <DestinationCell
            destinationName={name ?? ""}
            syncDescription={description}
            metadata={metadata}
            definition={definition}
            isSyncBoosted={syncBoosted}
          />
        );
      },
    },
    Boolean(showExternalColumn) && {
      name: "Matched users",
      cell: ({ id, config, first_sync_request }) => {
        const { data: destinationMetadata, isLoading } =
          useDestinationMetadataQuery(
            {
              id,
            },
            { select: (data) => data.getDestinationMetadata },
          );

        const { matchPercent, matchedUserCount } = calculateAudienceSizeValues(
          destinationMetadata?.audienceSize,
          destinationMetadata?.matchRate,
          config.useExistingSegment,
          config.deleteMode,
        );

        const audienceIsProcessing =
          isAudienceStatusProcessing(destinationMetadata);
        const matchedCountIsSettling = isMatchedUsersCountSettling(
          first_sync_request?.[0]?.created_at,
          destinationMetadata,
          config.useExistingSegment,
        );
        const syncMatchboosterEnabled = isSyncMatchBoosted(config);

        if (isLoading) {
          return (
            <Skeleton isLoading={isLoading}>
              <SkeletonBox height="14px" width="100px" />
            </Skeleton>
          );
        }

        if (
          destinationMetadata?.audienceSize &&
          destinationMetadata.audienceSize >= 0
        ) {
          return (
            <Tooltip
              message={
                <Column>
                  {modelMatchboostingEnabled && syncMatchboosterEnabled && (
                    <Row gap={1}>
                      <Text color="white" fontWeight="medium">
                        Match Booster:
                      </Text>
                      <Text color="success.border" fontWeight="normal">
                        Enabled
                      </Text>
                    </Row>
                  )}
                  <Row gap={1}>
                    <Text color="white" fontWeight="medium">
                      Matched users:
                    </Text>
                    <Text color="white" fontWeight="normal">
                      {audienceIsProcessing ? "--" : matchedUserCount}
                    </Text>
                  </Row>
                  <Row gap={1}>
                    <Text color="white" fontWeight="medium">
                      Match rate:
                    </Text>
                    <Text color="white" fontWeight="normal">
                      {audienceIsProcessing ? "--" : matchPercent || "--"}
                    </Text>
                  </Row>
                  {matchedCountIsSettling && (
                    <Row textAlign="left">
                      <Text color="white">
                        Warning: Matched users count may still be updating
                      </Text>
                    </Row>
                  )}
                </Column>
              }
            >
              <Box display="flex" gap={1}>
                {audienceIsProcessing
                  ? "Processing... check back later."
                  : processAudienceSizeComponent(
                      destinationMetadata?.audienceSize,
                      destinationMetadata?.matchRate,
                      config.useExistingSegment,
                      config.deleteMode,
                    )}

                {matchedCountIsSettling && (
                  <Box
                    as={WarningIcon}
                    color="warning.border"
                    fontSize="16px"
                  />
                )}
              </Box>
            </Tooltip>
          );
        }

        return "--";
      },
    },
    {
      name: "Last run",
      max: "max-content",
      cell: ({ id, sync_requests }) => {
        const syncRequest = sync_requests?.[0];
        return syncRequest ? (
          <>
            <Link href={`/syncs/${id}/runs/${syncRequest?.id}`}>
              <Text fontWeight="medium">
                {formatDatetime(syncRequest?.created_at)}
              </Text>
            </Link>
          </>
        ) : null;
      },
    },
    {
      ...LastUpdatedColumn,
    },
    !!showSplitsColumn && {
      name: "Splits",
      max: "1fr",
      cell: ({ destination_instance_splits }) => {
        if (destination_instance_splits.length === 0) {
          return "--";
        }

        const splitsText = destination_instance_splits.map(
          ({ split }: { split: AudienceSplit }) =>
            `${split.friendly_name} (${split.percentage}%)`,
        );

        return <TextWithTooltip>{splitsText.join(", ")}</TextWithTooltip>;
      },
    },
  ].filter(isPresent);

  const addModelSyncButton = (
    <PermissionedButton
      isDisabled={overageLockout}
      permission={{ v1: { resource: "sync", grant: "create" } }}
      variant="primary"
      onClick={() => onAdd()}
    >
      Add sync
    </PermissionedButton>
  );

  return (
    <Column width="100%">
      <Row alignItems="center" justifyContent="space-between" mb={3}>
        <SectionHeading>All syncs</SectionHeading>
        <Tooltip message={overageText} isDisabled={!overageLockout}>
          {showAddSyncButton ? (
            model ? (
              <AddAudienceSyncButton
                audience={model}
                parentModel={parentModel}
                isDisabled={overageLockout}
                variant="primary"
              />
            ) : (
              addModelSyncButton
            )
          ) : (
            ""
          )}
        </Tooltip>
      </Row>
      <Table
        columns={columns}
        data={sortedSyncs}
        sortOptions={sortOptions}
        rowHeight="80px"
        placeholder={{
          title: `This ${
            isAudience ? "audience" : "model"
          } is not syncing to any destinations`,
          body: "Still working on this? Add a sync when you’re ready.",
          image: placeholder,
        }}
        onRowClick={({ id }, event) => openUrl(`/syncs/${id}`, navigate, event)}
      />
    </Column>
  );
};
