import { FormField, Select } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { useUpdateAssetModelMutation } from "src/graphql";
import { SchemaField, SchemaFieldForm } from "src/pages/schema/types";
import { Form, useHightouchForm } from "src/components/form";
import { ColumnField } from "./column-field";
import {
  ASSET_TYPE_OPTIONS,
  SMS_ASSET_OPTIONS,
} from "src/pages/schema/constants";
import { useFlags } from "launchdarkly-react-client-sdk";

export const AssetTypeField: SchemaField = (props) => {
  const { enableCampaignSms } = useFlags();

  return (
    <Controller
      name="asset.type"
      render={({ field, fieldState: { error } }) => (
        <FormField label="Asset type" error={error?.message}>
          <Select
            {...field}
            {...props}
            isInvalid={Boolean(error)}
            options={[
              ...ASSET_TYPE_OPTIONS,
              ...(enableCampaignSms ? SMS_ASSET_OPTIONS : []),
            ]}
            placeholder="Select an asset type..."
          />
        </FormField>
      )}
    />
  );
};

export const AssetPrimaryKeyField: SchemaField = (props) => {
  return (
    <ColumnField
      name="asset.primary_key_column"
      label="Asset ID (Primary key)"
      tip="Key that is unique and consistent across queries. E.g. “id” or “email”"
      {...props}
    />
  );
};

export const AssetPrimaryLabelField: SchemaField = (props) => {
  return (
    <ColumnField
      name="asset.primary_label_column"
      label="Asset label"
      tip="Used when previewing results from. E.g. “campaign_name”"
      {...props}
    />
  );
};

export const AssetFields: SchemaField = (props) => {
  return (
    <>
      <AssetTypeField {...props} />
      <AssetPrimaryKeyField {...props} />
      <AssetPrimaryLabelField {...props} />
    </>
  );
};

export const AssetForm: SchemaFieldForm = ({ object, hasUpdatePermission }) => {
  const { mutateAsync: updateAssetModel } = useUpdateAssetModelMutation();

  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async (data) => {
      await updateAssetModel({
        id: object.id,
        set: data.asset,
      });
    },
    values: {
      asset: {
        type: object.asset?.type ?? "",
        primary_key_column: object.asset?.primary_key_column ?? "",
        primary_label_column: object.asset?.primary_label_column ?? "",
      },
    },
  });

  const { dirtyFields } = form.formState;

  return (
    <Form form={form}>
      <AssetTypeField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting && dirtyFields.asset?.type}
      />
      <AssetPrimaryKeyField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={
          form.formState.isSubmitting && dirtyFields.asset?.primary_key_column
        }
      />
      <AssetPrimaryLabelField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={
          form.formState.isSubmitting && dirtyFields.asset?.primary_label_column
        }
      />
    </Form>
  );
};
