import { Box, Column, Heading, Row, Spinner, useToast } from "@hightouchio/ui";
import { FC, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "src/router";
import { ManageWorkspaceNotificationChannels } from "src/components/notification-channels";
import { NotificationConfigurationDrawer } from "src/components/notification-channels/notification-configuration-drawer";
import { ResourceNotificationChannels } from "src/components/notification-channels/resource-subscriptions";
import {
  PagerDutyCredentialsForm,
  SlackCredentialsForm,
} from "src/pages/extensions/alerting";

import { capitalize } from "lodash";
import { SyncNotificationOverrides } from "src/components/notification-channels/default-notification-overrides";
import { BulkDestinationAlertTriggerManagement } from "src/components/resource-alert-triggers/bulk-management";
import { DefaultTriggerOverrides } from "src/components/resource-alert-triggers/default-trigger-overrides";
import { useUser } from "src/contexts/user-context";
import { AdvancedAlertingSplashPage } from "src/components/resource-alert-triggers/splash-page";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MigrateV1AlertsToV2 } from "src/components/resource-alert-triggers/migrate-to-v2";
import { useSearchParam } from "src/components/routeable-tabs/utils";
import { RouteTabs } from "src/components/route-tabs";

export const MonitoringRoutes: FC = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<AlertingRoot />}>
        <Route index element={<Navigate to="recipients" />} />
        <Route path="recipients" element={<RecipientManagement />}>
          <Route
            path="configure/slack"
            element={
              <SlackCredentialsForm
                onClose={() => navigate("/alerting/recipients")}
              />
            }
          />
          <Route
            path="configure/pagerduty"
            element={
              <PagerDutyCredentialsForm
                onClose={() => navigate("/alerting/recipients")}
              />
            }
          />
          <Route path="new" element={<NotificationConfigurationDrawer />} />
          <Route path="overrides" element={<SyncNotificationOverrides />} />
          <Route
            path=":channelId"
            element={<NotificationConfigurationDrawer />}
          />
        </Route>
        <Route path="triggers" element={<TriggerManagement />}>
          <Route path="overrides" element={<DefaultTriggerOverrides />} />
        </Route>
      </Route>
    </Routes>
  );
};

MonitoringRoutes.displayName = MonitoringRoutes.name;

export const AlertingRoot: FC = () => {
  const { toast } = useToast();

  const [search] = useSearchParams();

  const connectedChannel = search.get("connectedChannel");

  useEffect(() => {
    if (connectedChannel) {
      toast({
        id: "connected-channel",
        variant: "success",
        title: `Connected ${capitalize(connectedChannel)}`,
      });
    }
  }, [connectedChannel]);

  const { workspace } = useUser();
  const { appAlertingV2Eligible } = useFlags();
  const [isMigrating, _] = useSearchParam("migrating");

  if (!workspace?.alerting_v2_enabled) {
    return <AdvancedAlertingSplashPage />;
  }
  if (!appAlertingV2Eligible && !workspace.alerting_v2_enabled) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Column minH="100%">
        <Helmet>
          <title>Alerting</title>
        </Helmet>
        <Column gap={4}>
          <Box as={Heading} size="xl" p={6} pb={0}>
            Alerting
          </Box>
          <RouteTabs
            px={6}
            tabs={[
              { title: "Recipients", path: "recipients" },
              { title: "Triggers", path: "triggers" },
            ]}
          />
        </Column>

        <Suspense fallback={<Spinner size="lg" m="auto" />}>
          <Outlet />
        </Suspense>
      </Column>
      {isMigrating && <MigrateV1AlertsToV2 />}
    </>
  );
};

AlertingRoot.displayName = AlertingRoot.name;

const TriggerManagement: FC = () => {
  return (
    <Column flex={1}>
      <BulkDestinationAlertTriggerManagement />
      <Outlet />
    </Column>
  );
};

const RecipientManagement: FC = () => {
  return (
    <Row flex={1}>
      <Column
        px={6}
        py={4}
        borderRight="1px"
        borderColor="base.border"
        background="base.lightBackground"
        gap={4}
        flex={1}
        minWidth="300px"
        maxWidth="300px"
      >
        <ManageWorkspaceNotificationChannels />
      </Column>
      <ResourceNotificationChannels />
    </Row>
  );
};
