import { Box, Column, Row, Text } from "@hightouchio/ui";
import { orderBy } from "lodash";
import pluralize from "pluralize";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "src/router";
import { NodeProps, Node as ReactFlowNode, useReactFlow } from "reactflow";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  NODE_HEIGHT,
  NODE_WIDTH,
  getParams,
  schemaIcons,
} from "src/pages/schema/graph/utils";
import { SchemaModelType } from "src/types/schema";
import { drawerWidth } from "..";
import { NodeData } from "../types";
import { useGraphContext } from "./context";
import { Menu } from "./menu";
import { MenuButton } from "./menu-button";
import { CHILD_NODE_HEIGHT, useCanUpdateSchema } from "./utils";

const DEFAULT_CHILDREN_TO_SHOW = 5;

export const GroupNodeBody: FC<
  Readonly<{
    node: NodeProps<NodeData>;
  }>
> = ({ node }) => {
  const { onToggleExpandedGroup } = useGraphContext();
  const { setCenter } = useReactFlow();
  const navigate = useNavigate();
  const { queryString } = getParams();
  const [searchParams] = useSearchParams();
  const selectedId = searchParams.get("id");

  const zoomToChild = (position: number) => {
    const offsetX = drawerWidth / 2;

    const x = node.xPos + NODE_WIDTH + offsetX / 2;
    const y = node.yPos + position * CHILD_NODE_HEIGHT;

    setCenter(x, y, {
      zoom: 1,
      duration: 500,
    });
  };

  const [hoveredNodeId, setHoveredNodeId] = useState<string | undefined>(
    undefined,
  );
  const [openMenuNodeId, setOpenMenuNodeId] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    // If a node gets selected, close the menu
    setOpenMenuNodeId(undefined);
  }, [selectedId]);

  const childNodes = orderBy(
    node.data.children ?? [],
    [(node) => node.data.label?.toLowerCase()],
    ["asc"],
  );

  const childrenToShow = useMemo(() => {
    if (node.data.isExpanded) {
      return childNodes;
    }
    return childNodes.slice(0, DEFAULT_CHILDREN_TO_SHOW);
  }, [node.data.isExpanded]);

  return (
    <Row
      height="100%"
      align="center"
      pl={4}
      pr={6}
      gap={2}
      onMouseLeave={() => setHoveredNodeId(undefined)}
    >
      <Column gap={2} width="100%">
        <Text
          size="sm"
          fontWeight="semibold"
          textTransform="uppercase"
          color="text.secondary"
        >
          {pluralize("model", childNodes.length, true)}
        </Text>
        {childrenToShow.map((node, index) => (
          <Row
            key={node.id}
            align="center"
            position="relative"
            overflowX="visible"
            background={selectedId === node.id ? "base.lightBackground" : ""}
            __css={{
              span: {
                color: selectedId === node.id ? "link.default" : "",
              },
            }}
            color="link.default"
            _hover={{
              background: "base.lightBackground",
              span: {
                color: "link.default",
              },
            }}
            onMouseEnter={() => setHoveredNodeId(node.id)}
          >
            <ChildNodeRow
              hoveredNodeId={hoveredNodeId}
              openMenuNodeId={openMenuNodeId}
              position={index}
              node={node}
              visibleNodes={childrenToShow.length}
              onMenuOpenClick={() => {
                if (openMenuNodeId === node.id) {
                  setOpenMenuNodeId(undefined);
                } else {
                  setOpenMenuNodeId(node.id);
                }
                zoomToChild(index);
              }}
              onClick={() => {
                zoomToChild(index);
                navigate({
                  pathname: "view/query",
                  search: `${queryString}&id=${node.id}`,
                });
              }}
            />
          </Row>
        ))}

        <Row
          onClick={(event) => {
            event.preventDefault();
            onToggleExpandedGroup({
              groupNodeId: node.id,
              expanded: !node.data.isExpanded,
            });
          }}
        >
          <Text color="link.default">
            {node.data.isExpanded
              ? `Show less...`
              : `Show ${childNodes.length - 5} more..`}
          </Text>
        </Row>
      </Column>
    </Row>
  );
};

export const ChildNodeRow: FC<{
  node: ReactFlowNode;
  hoveredNodeId: string | undefined;
  openMenuNodeId: string | undefined;
  position: number;
  visibleNodes: number;
  onClick: () => void;
  onMenuOpenClick: () => void;
}> = ({
  node,
  hoveredNodeId,
  openMenuNodeId,
  position,
  visibleNodes,
  onClick,
  onMenuOpenClick,
}) => {
  const hasUpdatePermission = useCanUpdateSchema();
  const navigate = useNavigate();
  const { queryString } = getParams();

  const isHovered = hoveredNodeId === node.id;
  const isMenuOpen = openMenuNodeId === node.id;

  const menuOffsetY = useMemo(() => {
    const menuSize = NODE_HEIGHT + visibleNodes * CHILD_NODE_HEIGHT;

    return position * CHILD_NODE_HEIGHT - menuSize / 2 + NODE_HEIGHT; // additional NODE_HEIGHT for spacing down
  }, [position, visibleNodes]);

  return (
    <>
      <Row overflowX="hidden" gap={1} flexGrow={0} onClick={onClick}>
        <Box
          height="24px"
          width="24px"
          as="img"
          src={schemaIcons[node.type!]}
          alt={node.type}
        />

        <TextWithTooltip fontWeight="medium">{node.data.label}</TextWithTooltip>
      </Row>
      <Box position="absolute" right={-6} top={-9} zIndex={1000}>
        {(isHovered || isMenuOpen) && (
          <MenuButton
            isActive={isMenuOpen}
            position={0}
            onClick={onMenuOpenClick}
          />
        )}

        <Menu
          type={node.type as SchemaModelType}
          isDisabled={!hasUpdatePermission}
          isVisible={isMenuOpen}
          offsetY={menuOffsetY}
          onChange={async (option) => {
            navigate({
              pathname: "new",
              search: `${queryString}&id=${node.id}&type=${option.type}`,
            });
          }}
          onClose={() => {}}
        />
      </Box>
    </>
  );
};
