import { Combobox, EmptyState, Heading, Row } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { Outlet } from "src/router";
import { Page } from "src/components/layout";
import {
  MinimalParentModelsQuery,
  useMinimalParentModelsQuery,
} from "src/graphql";
import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { PageSpinner } from "src/components/loading";
import { RouteTabs } from "src/components/route-tabs";

export type OutletContext = {
  parentModel: MinimalParentModelsQuery["segments"][0];
};

export const Layout = () => {
  const { appSubsetsEnabled } = useFlags();

  const [parentModelId, setParentModelId] = useState<string | undefined>();

  const parentModelsQuery = useMinimalParentModelsQuery(
    {
      orderBy: { name: "asc" },
    },
    {
      select: (data) => data.segments,
    },
  );

  useEffect(() => {
    if (parentModelsQuery.data?.length && !parentModelId) {
      setParentModelId(parentModelsQuery.data[0]?.id);
    }
  }, [parentModelsQuery.data]);

  const selectedParentModel = parentModelsQuery.data?.find(
    (p) => p.id === parentModelId,
  );

  if (parentModelsQuery.isLoading) {
    return <PageSpinner />;
  }

  return (
    <Page title="Data Governance" fullWidth sx={{ m: 0, px: 8, py: 6 }}>
      <Row gap={4}>
        <Heading size="xl" mb={4}>
          Data Governance
        </Heading>
        <Combobox
          isLoading={parentModelsQuery.isLoading}
          placeholder="Select a parent model..."
          options={parentModelsQuery.data ?? []}
          optionAccessory={(option) => ({
            type: "image",
            url: option.connection?.definition.icon ?? "",
          })}
          optionLabel={(option) => option.name}
          optionValue={(option) => option.id}
          value={parentModelId}
          onChange={setParentModelId}
        />
      </Row>
      <RouteTabs
        tabs={[
          { title: "Destination rules", path: "destination-rules" },
          {
            title: "Subsets",
            path: "subset-categories",
            isHidden: !appSubsetsEnabled,
          },
        ]}
      />

      {selectedParentModel ? (
        <Outlet context={{ parentModel: selectedParentModel }} />
      ) : (
        <EmptyState
          imageUrl={genericPlaceholder}
          title="This workspace contains no parent models"
          message="Define a parent model in the Customer Studio schema to enable data governance features."
          m={4}
        />
      )}
    </Page>
  );
};
