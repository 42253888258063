import { v4 as uuidv4 } from "uuid";

import {
  MINI_NODE_INITIAL_SPACING,
  NODE_WIDTH,
} from "src/pages/journeys/constants";
import { JourneyNode } from "src/pages/journeys/types";
import { JourneyNodeType } from "src/types/journeys";
import { ConditionType } from "src/types/visual";

/**
 * Create initial branch nodes for a given node type
 */
export const getInitialBranchNodes = (
  type: JourneyNodeType,
  parentNodePosition: { x: number; y: number },
): JourneyNode[] => {
  const id1 = uuidv4();
  const id2 = uuidv4();

  switch (type) {
    case JourneyNodeType.Segments:
      return [
        {
          id: id1,
          type: JourneyNodeType.SegmentBranch,
          data: {
            id: id1,
            name: "Everyone else",
            segment_id: null,
            event_relationship_id: null,
            num_users: null,
            sync_configs: null,
            config: {
              type: JourneyNodeType.SegmentBranch,
              segment_priority_rank: 0,
              segment_is_catch_all: true,
              segment_conditions: { type: ConditionType.And, conditions: [] },
            },
          },
          position: {
            x: parentNodePosition.x,
            y: parentNodePosition.y + MINI_NODE_INITIAL_SPACING,
          },
        },
      ];
    case JourneyNodeType.WaitUntilEvent:
      return [
        {
          id: id1,
          type: JourneyNodeType.WaitUntilEventBranch,
          data: {
            id: id1,
            name: "Rule met",
            segment_id: null,
            event_relationship_id: null,
            num_users: null,
            sync_configs: null,
            config: {
              type: JourneyNodeType.WaitUntilEventBranch,
              branch: "event",
            },
          },
          position: {
            x: parentNodePosition.x - NODE_WIDTH / 2,
            y: parentNodePosition.y + MINI_NODE_INITIAL_SPACING * 2,
          },
        },
        {
          id: id2,
          type: JourneyNodeType.WaitUntilEventBranch,
          data: {
            id: id2,
            name: "Rule not met",
            segment_id: null,
            event_relationship_id: null,
            num_users: null,
            sync_configs: null,
            config: {
              type: JourneyNodeType.WaitUntilEventBranch,
              branch: "timeout",
            },
          },
          position: {
            x: parentNodePosition.x - NODE_WIDTH / 2 + NODE_WIDTH,
            y: parentNodePosition.y + MINI_NODE_INITIAL_SPACING * 2,
          },
        },
      ];
    default:
      return [];
  }
};
