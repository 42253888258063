import { useEffect, useMemo, FC } from "react";

import { Combobox, Select, Text } from "@hightouchio/ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  ReferencePropertyDefaultOperators,
  FilterableColumn,
  isColumnReference,
  ReferencedPropertyCondition,
  ReferencePropertyOperatorOptions,
} from "src/types/visual";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { FilterProps, HStack } from "./condition";
import { XButton } from "./condition-buttons";
import { validateReferencePropertyFilter } from "./condition-validation";
import { Filter } from "./filter";
import { getColumnFromValue, getColumnOptions } from "./utils";

export type ReferencePropertyFilterProps =
  FilterProps<ReferencedPropertyCondition> & {
    referenceColumns?: FilterableColumn[];
  };

export const ReferencePropertyFilter: FC<
  Readonly<ReferencePropertyFilterProps>
> = (props) => {
  const {
    columns: overrideColumns,
    referenceColumns,
    condition,
    onChange,
    onRemove,
  } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { columns: allColumns } = useQueryBuilderContext();
  const columns = overrideColumns ?? allColumns;

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const valueFromColumnError = filterErrors?.valueFromColumn;

  useEffect(() => {
    setFieldError(filterId, validateReferencePropertyFilter(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.property, condition.valueFromColumn, filterId]);

  const propertyOptions = getColumnOptions(
    columns ?? [],
    !isColumnReference(condition.property),
  );
  // Use string for valueFromColumn instead of column reference
  const referenceOptions = getColumnOptions(referenceColumns ?? [], true);
  const connectionType = props.audience?.connection?.type;
  const operatorOptions = condition.propertyType
    ? ReferencePropertyOperatorOptions[condition.propertyType].filter(
        (op) =>
          !("supportedSources" in op) ||
          op.supportedSources.includes(connectionType),
      )
    : undefined;
  const operatorLabel = operatorOptions?.find(
    (option) => option.value === condition.operator,
  )?.label;

  return (
    <HStack
      gap={2}
      sx={{ alignItems: "flex-start", input: { fontWeight: "medium" } }}
    >
      <Combobox
        isInvalid={Boolean(propertyError)}
        options={propertyOptions}
        placeholder="Select a property"
        value={condition.property}
        width="4xs"
        onChange={(value) => {
          const column = getColumnFromValue(propertyOptions, value);

          if (!column) {
            return;
          }

          onChange({
            propertyType: column.type,
            property: value,
            operator: column.type
              ? ReferencePropertyDefaultOperators[column.type]
              : null,
            valueFromColumn: null,
          });
        }}
      />
      {condition.property && (
        <Filter
          content={
            <HStack
              gap={2}
              sx={{
                alignItems: "flex-start",
                button: { fontWeight: "medium" },
                input: { fontWeight: "medium" },
              }}
            >
              <Select
                removePortal
                options={operatorOptions ?? []}
                placeholder="Filter on"
                width="auto"
                value={condition.operator}
                onChange={(operator) => {
                  onChange({ operator });
                }}
              />
              <Combobox
                removePortal
                isInvalid={Boolean(valueFromColumnError)}
                options={referenceOptions}
                placeholder="Select a parent property"
                value={condition.valueFromColumn}
                width="2xs"
                onChange={(valueFromColumn) => {
                  onChange({ valueFromColumn });
                }}
              />
            </HStack>
          }
          error={valueFromColumnError}
        >
          <Text color="text.secondary" fontWeight="medium">
            {operatorLabel}
          </Text>
          <Text
            isTruncated
            color={condition.valueFromColumn === null ? "text.secondary" : ""}
            fontWeight="medium"
          >
            {condition.valueFromColumn || "(Select a parent property)"}
          </Text>
        </Filter>
      )}
      {onRemove && <XButton onRemove={onRemove} />}
    </HStack>
  );
};
