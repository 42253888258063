import { useEffect, useState } from "react";

import {
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Paragraph,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import {
  ApproverOptions,
  DraftSubmissionForm,
  publishNow,
  saveAndRequestApproval,
  saveOnly,
} from "src/components/drafts/draft-submission-form";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { useDraft } from "src/contexts/draft-context";
import {
  DraftOperation,
  DraftStatus,
  ModelDraft,
  ResourceToPermission,
  SyncDraft,
  useDraftsQuery,
  useSubmitDraftModelMutation,
  useSubmitDraftSyncMutation,
  useUpdateDraftsStatusMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { invalidateQueries } from "src/utils/query-client";
import {
  ResourcePermissionInput,
  useResourcePermission,
} from "../permission/use-resource-permission";

interface DraftSubmissionProps<Resource extends "sync" | "model"> {
  getResourceId: () => string | Promise<string>;
  resource: Resource;
  operation: DraftOperation;
  draft: SyncDraft | ModelDraft | undefined;
  permission: ResourcePermissionInput<Resource, Resource>;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    resourceId: string,
    { publishNow }: { publishNow: boolean },
  ) => void;
  dependentResource?: {
    resourceId: string;
    resourceType: ResourceToPermission;
  };
  approverOptions: ApproverOptions;
}

export function DraftSubmissionModal<Resource extends "sync" | "model">({
  getResourceId,
  resource,
  operation,
  open,
  onClose,
  onSubmit,
  draft,
  dependentResource,
  permission,
  approverOptions,
}: DraftSubmissionProps<Resource>) {
  const { isPermitted: canApprove } = useResourcePermission(permission);

  const [comment, setComment] = useState<string>("");
  const { toast } = useToast();
  const {
    draftChange,
    editingDraft,
    setEditingDraft,
    draft: existingDraft,
  } = useDraft();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [error, setError] = useState("");
  const { mutateAsync: submitSync } = useSubmitDraftSyncMutation({
    onSuccess: () => {
      // Prevent refetching queries
    },
  });
  const { mutateAsync: submitModel } = useSubmitDraftModelMutation({
    onSuccess: () => {
      // Prevent refetching queries
    },
  });
  const { mutateAsync: updateDraftStatus } = useUpdateDraftsStatusMutation();
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>(
    canApprove ? publishNow : saveAndRequestApproval,
  );
  const { data: dependentDraft } = useDraftsQuery(
    {
      resourceId: dependentResource?.resourceId.toString() ?? "",
      resourceType: dependentResource?.resourceType ?? "",
      status: "pending",
    },
    {
      enabled:
        Boolean(dependentResource?.resourceId) &&
        Boolean(dependentResource?.resourceType),
      select: (data) => data?.drafts?.[0],
    },
  );
  const extraDraftIds = dependentDraft ? [dependentDraft.id] : [];

  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    const alreadyRequestedFrom =
      (existingDraft?.approval_requests.map(
        (request) => request.user_id,
      ) as number[]) || [];
    setSelectedUsers(alreadyRequestedFrom);
  }, [existingDraft]);

  useEffect(() => {
    if (!open) {
      setComment("");
      setSelectedRadioOption(canApprove ? publishNow : saveAndRequestApproval);
    }
  }, [open]);

  const saveResource = async (resourceId: string) => {
    const draftInput = {
      resourceId: String(resourceId),
      comment,
      approverIds:
        selectedRadioOption === saveAndRequestApproval ? selectedUsers : [],
      operation,
    };
    try {
      if (resource === ResourceToPermission.Sync) {
        const res = await submitSync({
          ...draftInput,
          draft: draft as SyncDraft,
        });
        return res.submitDraftSync.id;
      } else if (resource === ResourceToPermission.Model) {
        const res = await submitModel({
          ...draftInput,
          draft: draft as ModelDraft,
        });
        return res.submitDraftModel.id;
      }
    } catch (err) {
      toast({
        id: "submit-draft",
        title: "Couldn't submit this draft",
        variant: "error",
      });

      setError(err.message);
      Sentry.captureException(err);
    }

    throw new Error("Invalid resource type");
  };

  const onDelete = async () => {
    const resourceId = await getResourceId();
    const func =
      resource === ResourceToPermission.Sync ? submitSync : submitModel;
    try {
      // clear the existing draft.
      await func({
        resourceId,
        approverIds: [],
      });
    } catch (err) {
      Sentry.captureException(err);
      toast({
        id: "delete-draft",
        title: "Couldn't delete this draft",
        variant: "error",
      });

      // bubble up the error so we don't show a success toast.
      throw err;
    }

    // replace with the new resource.
    await saveResource(resourceId);
    setEditingDraft(true);
  };

  const title = () => {
    if (canApprove) {
      return "Approve & Publish";
    }
    return `This ${resource} requires approval`;
  };

  // If we've already created a draft of this resource, and we started editing the
  // existing resource, then we should show a warning that we're about to delete the existing draft
  // and replace it with a new one.
  if (
    existingDraft &&
    existingDraft.operation !== DraftOperation.Create &&
    !editingDraft &&
    draftChange &&
    Object.keys(draftChange).length > 0
  ) {
    return (
      <DeleteConfirmationModal
        isOpen={open}
        label="draft"
        content={
          <Paragraph>
            There is an existing draft on this resource. Do you want to delete
            the existing draft?
          </Paragraph>
        }
        onClose={onClose}
        onDelete={onDelete}
      />
    );
  }

  const trackAction = (resourceId) => {
    analytics.track(`Draft ${selectedRadioOption}`, {
      resource_id: resourceId,
      resource_type: resource,
      operation,
      num_requested_approvers: selectedUsers.length,
    });
  };

  const handleSave = async () => {
    setSaveLoading(true);

    const resourceId = await getResourceId();

    const isPublish = selectedRadioOption === publishNow;

    trackAction(resourceId);

    const draftId = await saveResource(resourceId);

    // If the user wants to publish these changes immediately, we apply the changes by changing the draft status to approved.
    if (isPublish) {
      await updateDraftStatus({
        draftIds: [draftId, ...extraDraftIds],
        status: DraftStatus.Approved,
      });
      setEditingDraft(false);
    } else {
      setEditingDraft(true);
      invalidateQueries();
    }

    onSubmit(resourceId, { publishNow: isPublish });

    setSaveLoading(false);
    onClose();
  };

  const saveButtonText =
    selectedRadioOption === publishNow
      ? "Publish"
      : selectedRadioOption === saveOnly
        ? "Save draft"
        : "Save & request approval";

  return (
    <ChakraModal isOpen={open} onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent my="auto" p={0} overflowY="auto" maxH="100%">
        <ChakraModalHeader p={6}>{title()}</ChakraModalHeader>
        <ChakraModalBody px={6} overflow="auto">
          <DraftSubmissionForm
            canApprove={canApprove}
            comment={comment}
            resource={resource}
            selectedRadioOption={selectedRadioOption}
            selectedUsers={selectedUsers}
            setComment={setComment}
            setSelectedRadioOption={setSelectedRadioOption}
            setSelectedUsers={setSelectedUsers}
            approverOptions={approverOptions}
          />
          {error && (
            <Text color="text.danger" mt={3}>
              {error}
            </Text>
          )}
        </ChakraModalBody>
        <ChakraModalFooter p={6} mt={4}>
          <Button isJustified onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            isJustified
            isDisabled={
              selectedRadioOption === saveAndRequestApproval &&
              selectedUsers.length === 0
            }
            isLoading={saveLoading}
            variant="primary"
            onClick={handleSave}
          >
            {saveButtonText}
          </Button>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
}
