import { FC, useEffect, useMemo, useState } from "react";

import {
  DiffModeOverride,
  DiffModeOverrideReason,
} from "@hightouch/lib/sync/diff-mode/types";
import {
  Alert,
  Avatar,
  ButtonGroup,
  Code,
  Column,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  EditableDescription,
  FolderIcon,
  FormField,
  Menu,
  MenuActionsButton,
  MenuList,
  Row,
  SectionHeading,
  Text,
  useToast,
  ChakraListItem,
  ChakraUnorderedList,
  Paragraph,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "src/router";

import { DeployButton } from "src/components/deployments/button";
import { DetailBar } from "src/components/detail-bar";
import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { isQueryDraft } from "src/components/drafts/model-draft";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { DetailPage } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { OverageModal } from "src/components/modals/overage-modal";
import { ColumnSelect } from "src/components/models/column-select";
import { PrimaryKeyDialog } from "src/components/models/primary-key-dialog";
import { Query } from "src/components/models/query";
import {
  PermissionedButton,
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import {
  ModelColumnsQuery,
  ModelDraft,
  ModelWithoutColumnsQuery,
  ResourceToPermission,
  useDeleteModelMutation,
  useModelColumnsQuery,
  useModelWithoutColumnsQuery,
  useStartSyncRunsWithDiffModeOverrideMutation,
  useUpdateModelMutation,
  useUpdateSyncsMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/components/loading";
import { formatDate } from "src/utils/time";

import { PermissionedLinkButton } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { useModelState } from "src/hooks/use-model-state";
import { ModelSyncs } from "./syncs";
import { ModelActivity } from "./activity";
import { ModelColumns } from "./columns";
import { ModelMatchbooster } from "./match-booster";

type Model = NonNullable<ModelWithoutColumnsQuery["segments_by_pk"]>;
interface ModelProps {
  model: Model;
  refetch: () => void;
}

export type OutletContext = {
  model: Model;
  columns: ModelColumnsQuery["model_columns"];
};

export const ModelWrapper: FC = () => {
  const { model_id: id } = useParams<{ model_id?: string }>();

  const {
    data: model,
    isLoading,
    refetch,
  } = useModelWithoutColumnsQuery(
    { id: id ?? "", includeExternalFields: true },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );

  if (!id || isLoading) {
    return <PageSpinner />;
  }

  if (!model) {
    return (
      <Warning subtitle="It may have been deleted" title="Model not found" />
    );
  }

  return (
    <DraftProvider
      initialResourceIsDraft={model.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Model}
    >
      <Model model={model} refetch={refetch} />
    </DraftProvider>
  );
};

const Model: FC<ModelProps> = ({ model, refetch }: ModelProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const modelState = useModelState(model);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const {
    updateResourceOrDraft,
    editingDraft,
    draft,
    editingDraftChanges,
    mergeResourceWithDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const id = model.id;

  const { mutateAsync: updateModel } = useUpdateModelMutation();
  const primaryKeyMutation = useUpdateModelMutation({
    onSuccess: () => {
      // Prevents invalidation of cache
    },
  });
  const { mutateAsync: deleteModel } = useDeleteModelMutation();
  const { mutateAsync: updateSyncs } = useUpdateSyncsMutation();
  const { mutateAsync: startSyncRunsWithDiffModeOverride } =
    useStartSyncRunsWithDiffModeOverrideMutation();

  const { data: modelColumns } = useModelColumnsQuery(
    {
      modelId: id,
    },
    { select: (data) => data.model_columns },
  );

  const currentLabels = model.tags ?? {};
  const labelKeys = Object.keys(currentLabels);
  const type = model.query_type as QueryType;
  const syncs = model.syncs;
  const source = model.connection;

  const draftResource = draft?.new_resource
    ? (draft.new_resource as ModelDraft)
    : undefined;
  const columns =
    (editingDraft && draftResource?.modelColumns
      ? draftResource?.modelColumns
      : modelColumns) ?? [];
  const duplicatePrimaryKeysCount = model.last_run_duplicate_primary_keys;

  const context: OutletContext = useMemo(
    () => ({
      model,
      columns,
    }),
    [model, columns],
  );

  useEffect(() => {
    if (draft && model) {
      const copy = mergeResourceWithDraft(model);
      modelState.reset(copy as Model);
    } else {
      modelState.reset(model);
    }
  }, [model, editingDraft]);

  const onUpdate = () => {
    analytics.track("Model Updated", {
      model_id: model.id,
      model_type: type,
      model_name: model.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    toast({
      id: "update-model",
      title: "Model was updated",
      variant: "success",
    });

    refetch();
  };

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: id ?? "" },
  });

  const updateName = async (name: string) => {
    await updateModel({
      id: model.id,
      input: {
        name,
      },
    });
    onUpdate();
  };

  const updateDescription = async (description: string) => {
    await updateModel({
      id: model.id,
      input: {
        description,
      },
    });
    onUpdate();
  };

  const [newPrimaryKey, setNewPrimaryKey] = useState<string | undefined>();

  const updatePrimaryKey = async (primaryKey: string) => {
    const oldPrimaryKey = model.primary_key ?? "";
    modelState.onChange({ primary_key: primaryKey });

    const updatePayload = {
      primary_key: primaryKey,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (updateResourceOrDraft) {
      try {
        await updateResourceOrDraft(
          { _set: updatePayload },
          () => {
            toast({
              id: "update-primary-key",
              title: "Primary key updated",
              variant: "success",
            });
          },
          () =>
            primaryKeyMutation.mutateAsync({
              id: model.id,
              input: updatePayload,
            }),
          model.draft,
        );
      } catch (e) {
        Sentry.captureException(e);
        toast({
          id: "update-primary-key-error",
          title: "Failed to update primary key",
          message: e.message,
          variant: "error",
        });
        modelState.onChange({ primary_key: oldPrimaryKey });
      }
    }
  };

  const updatePrimaryKeyWithDiffModeOverride = async (
    primaryKey: string,
    diffModeOverride: DiffModeOverride,
    scheduleSyncsNow?: boolean,
  ) => {
    const oldPrimaryKey = model.primary_key ?? "";
    modelState.onChange({ primary_key: primaryKey });

    const updatePayload = {
      primary_key: primaryKey,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again
      approved_draft_id: null,
    };

    // Update or start syncs with the new diff mode override.
    const updateOrStartSyncs = async () => {
      try {
        if (!scheduleSyncsNow) {
          // Update sync (aka destination instance) with next diff mode override
          // for the next time it runs.
          await updateSyncs({
            ids: syncs.map((sync) => sync.id),
            object: {
              next_diff_mode_override: diffModeOverride,
            },
          });
          toast({
            id: "update-primary-key",
            title: `Primary key updated`,
            variant: "success",
          });
        } else {
          // Kick off runs immediately.
          const result = await startSyncRunsWithDiffModeOverride({
            segment_id: model.id,
            diff_mode_override: diffModeOverride,
            diff_mode_override_reason:
              DiffModeOverrideReason.ChangedPrimaryKeyScheduleNow,
          });
          const numUpdates =
            result.startSyncRunsWithDiffModeOverride?.result?.length;
          toast({
            id: "update-primary-key",
            title: `Primary key updated and scheduled ${numUpdates} ${
              numUpdates === 1 ? "sync" : "syncs"
            }`,
            variant: "success",
          });
        }
      } catch (e) {
        Sentry.captureException(e);
        toast({
          id: "update-primary-key-error",
          title: "Failed to update primary key",
          message: e.message,
          variant: "error",
        });
      }
    };

    if (updateResourceOrDraft) {
      try {
        // Update the model pk, then update or start the syncs.
        await updateResourceOrDraft(
          { _set: updatePayload },
          updateOrStartSyncs, // do on pk update
          () =>
            primaryKeyMutation.mutateAsync({
              id: model.id,
              input: updatePayload,
            }),
          model.draft,
        );
      } catch (e) {
        Sentry.captureException(e);
        toast({
          id: "update-primary-key-error",
          title: "Failed to update primary key",
          message: e.message,
          variant: "error",
        });
        modelState.onChange({ primary_key: oldPrimaryKey });
      }
    }
  };

  const folder = useFolder({
    folderId: model.folder?.id ?? null,
    folderType: "models",
    viewType: "models",
  });

  const hasPrimaryKeyIssue = Boolean(
    modelColumns?.length &&
      !modelColumns.some((c) => c.name === model.primary_key),
  );

  const updatedByUsername =
    model.updated_by_user?.name || model.created_by_user?.name;

  const crumbs: Crumb[] = [{ label: "All models", link: "/models" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: `/models?folder=${folder.id}`,
      });
    });
  }

  const { type: sourceDescriptionType, description: sourceDescription } =
    model?.source_description || {};

  return (
    <>
      <PermissionProvider
        permission={{
          v2: {
            resource: "model",
            grant: "can_update",
            id: model.id,
          },
        }}
      >
        <DetailPage
          tabs={[
            {
              title: (
                <Row align="center" gap={2}>
                  Configuration
                  {editingDraft &&
                  draftResource?._set &&
                  isQueryDraft(draftResource?._set) ? (
                    <DraftCircle />
                  ) : null}
                </Row>
              ),
              path: "configuration",
            },
            {
              path: "syncs",
              count: syncs.length,
              title: "Syncs",
            },
            {
              path: "activity",
              title: "Activity",
            },

            {
              path: "columns",
              title: (
                <Row align="center" gap={2}>
                  Columns{" "}
                  {editingDraft && draftResource?.modelColumns ? (
                    <DraftCircle />
                  ) : null}
                </Row>
              ),
            },
            {
              path: "match-booster",
              title: "Match Booster",
            },
          ].filter(Boolean)}
          crumbs={crumbs}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Model}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          title={`${model.name ?? "Unnamed model"} - Models`}
          header={
            <>
              <Row align="center" justify="space-between" gap={4} width="100%">
                <PermissionedEditableHeading
                  permission={{
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: model.id,
                    },
                  }}
                  size="lg"
                  value={model.name ?? ""}
                  onChange={updateName}
                />

                <ButtonGroup>
                  <Menu>
                    <MenuActionsButton variant="secondary" />
                    <MenuList>
                      <PermissionedMenuItem
                        permission={{
                          v2: {
                            resource: "model",
                            grant: "can_update",
                            id: model.id,
                          },
                        }}
                        icon={FolderIcon}
                        onClick={() => {
                          setIsFolderModalOpen(true);
                        }}
                      >
                        Move to folder
                      </PermissionedMenuItem>
                      <PermissionedMenuItem
                        permission={{
                          v2: {
                            resource: "model",
                            grant: "can_update",
                            id: model.id,
                          },
                        }}
                        icon={EditIcon}
                        onClick={() => {
                          setIsEditLabelModalOpen(true);
                        }}
                      >
                        Edit labels
                      </PermissionedMenuItem>
                      <PermissionedMenuItem
                        permission={{
                          v2: {
                            resource: "model",
                            grant: "can_create",
                            creationOptions: {
                              type: "model",
                              sourceId: model.connection?.id?.toString() ?? "",
                            },
                          },
                        }}
                        icon={CopyIcon}
                        onClick={() => {
                          navigate(`/models/${model.id}/clone`);
                        }}
                      >
                        Clone
                      </PermissionedMenuItem>
                      <PermissionedMenuItem
                        permission={{
                          v2: {
                            resource: "model",
                            grant: "can_delete",
                            id: model.id,
                          },
                        }}
                        icon={DeleteIcon}
                        variant="danger"
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                      >
                        Delete
                      </PermissionedMenuItem>
                    </MenuList>
                  </Menu>
                  {model && (
                    <DeployButton
                      permission={{
                        v2: {
                          resource: "model",
                          grant: "can_create",
                          creationOptions: {
                            type: "model",
                            sourceId: model.connection?.id?.toString() ?? "",
                          },
                        },
                      }}
                      isDisabled={Boolean(!model.slug || model.draft || draft)}
                      deployment={{
                        resourceName: "Model",
                        sourceResourceId: model.id,
                        resourceType: "segments",
                        metadata: { connectionId: model?.connection?.id },
                        isDraft: Boolean(model.draft || draft),
                      }}
                    />
                  )}
                  <PermissionedLinkButton
                    href={`/syncs/new?model=${id}`}
                    permission={{ v1: { resource: "sync", grant: "create" } }}
                  >
                    Add sync
                  </PermissionedLinkButton>
                </ButtonGroup>
              </Row>
              <Row>
                <EditableDescription
                  isDisabled={!hasUpdatePermission}
                  value={
                    model.description ??
                    (sourceDescription
                      ? `${sourceDescription} (sourced from ${sourceDescriptionType})`
                      : "")
                  }
                  onChange={updateDescription}
                />
              </Row>
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon
                    src={source?.definition?.icon}
                    name={source?.definition?.name}
                  />
                  <Link href={`/sources/${source?.id}`}>
                    <Text fontWeight="medium" color="inherit">
                      {source?.name}
                    </Text>
                  </Link>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate((model.updated_at || model.created_at)!)}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Slug:</Text>
                  <DisplaySlug currentSlug={model.slug} />
                </Row>
                {!editingDraftChanges && labelKeys.length > 0 && (
                  <Labels labels={currentLabels} />
                )}
              </DetailBar>
            </>
          }
        >
          {hasPrimaryKeyIssue &&
            !model.connection?.definition?.creatablePrimaryKey && (
              <Alert
                mb={8}
                variant="inline"
                type="warning"
                title="Undefined primary key"
                message="Without a primary key, your syncs may fail, or undefined behavior may occur. Please check to make sure that your primary key is set to a valid column."
              />
            )}

          <Routes>
            <Route
              index
              element={
                <Navigate to={`configuration${location?.search}`} replace />
              }
            />
            <Route
              path="configuration"
              element={
                <Column gap={8}>
                  {!!duplicatePrimaryKeysCount &&
                    model.syncs.some(
                      (sync) =>
                        sync.last_run_planner_type !== "all" &&
                        sync.last_run_planner_type !== null,
                    ) && (
                      <Alert
                        variant="inline"
                        type="warning"
                        title={`Hightouch detected ${duplicatePrimaryKeysCount} duplicate rows during a recent sync run.`}
                        message={
                          <Text>
                            Please edit your model to ensure that its primary
                            key column (
                            <Code>{modelState?.state.primary_key}</Code>) does
                            not contain any repeated values. You need to filter
                            out duplicates or select a different column that
                            serves as a unique identifier for each row.
                          </Text>
                        }
                      />
                    )}

                  <Column maxHeight="40vh" overflow="hidden">
                    <Query
                      // TODO: Merge in modelState here
                      model={modelState.state}
                      source={model.connection}
                      actions={
                        <PermissionedButton
                          permission={{
                            v2: {
                              resource: "model",
                              grant: "can_update",
                              id: model.id,
                            },
                          }}
                          onClick={() =>
                            navigate(
                              editingDraft
                                ? `/models/${id}/query?editing=true`
                                : `/models/${id}/query`,
                            )
                          }
                        >
                          Edit
                        </PermissionedButton>
                      }
                    />
                  </Column>

                  <Column gap={6}>
                    <SectionHeading>Configuration</SectionHeading>
                    <FormField label="Primary key">
                      <ColumnSelect
                        isLoading={primaryKeyMutation.isLoading}
                        isDisabled={!hasUpdatePermission}
                        columns={columns}
                        creatable={Boolean(
                          model.connection?.definition?.creatablePrimaryKey,
                        )}
                        model={model}
                        // when they are changing the primary key, use the new state
                        value={newPrimaryKey ?? modelState?.state.primary_key}
                        onChange={setNewPrimaryKey}
                      />
                    </FormField>
                  </Column>
                </Column>
              }
            />
            <Route path="syncs" element={<ModelSyncs context={context} />} />
            <Route
              path="activity"
              element={<ModelActivity context={context} />}
            />
            <Route
              path="columns"
              element={<ModelColumns context={context} />}
            />
            <Route
              path="match-booster"
              element={<ModelMatchbooster context={context} />}
            />
          </Routes>
        </DetailPage>
      </PermissionProvider>

      <PrimaryKeyDialog
        useDiffModeDialog={true}
        oldPrimaryKey={modelState.state.primary_key}
        newPrimaryKey={newPrimaryKey}
        onClose={() => {
          setNewPrimaryKey(undefined);
        }}
        onConfirm={async (options?: {
          diffModeOverride: DiffModeOverride;
          scheduleSyncsNow: boolean;
        }) => {
          if (newPrimaryKey) {
            if (!options) {
              await updatePrimaryKey(newPrimaryKey);
            } else {
              await updatePrimaryKeyWithDiffModeOverride(
                newPrimaryKey,
                options.diffModeOverride,
                options.scheduleSyncsNow,
              );
              setNewPrimaryKey(undefined);
            }
          }
        }}
      />

      <DeleteConfirmationModal
        isOpen={deleteModal}
        label="model"
        content={
          <Column gap={2}>
            <Paragraph>
              Are you sure you want to delete this model? You won't be able to
              undo this.
            </Paragraph>

            <Paragraph>The following syncs will be deleted as well:</Paragraph>

            <Column alignItems="start">
              <ChakraUnorderedList>
                {syncs.map(({ destination, id }) => (
                  <ChakraListItem key={id}>
                    <Link href={`/syncs/${id}`}>
                      {destination?.name || "Unknown sync"}
                    </Link>
                  </ChakraListItem>
                ))}
              </ChakraUnorderedList>
            </Column>
          </Column>
        }
        onClose={() => {
          setDeleteModal(false);
        }}
        onDelete={async () => {
          await deleteModel({
            id,
          });

          analytics.track("Model Deleted", {
            model_id: model.id,
            model_type: type,
            model_name: model.name,
            source_id: source?.id,
            source_type: source?.type,
          });

          navigate("/models");
        }}
      />

      <EditLabelModal
        isOpen={isEditLabelModalOpen}
        labels={currentLabels}
        resourceType={ResourceType.Model}
        onClose={() => setIsEditLabelModalOpen(false)}
        onSubmit={(labels) =>
          updateModel({
            id: id,
            input: {
              tags: labels,
            },
          })
        }
      />

      {isFolderModalOpen && (
        <MoveFolder
          folder={model.folder}
          folderType="models"
          modelIds={model.id}
          viewType="models"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <OverageModal />
    </>
  );
};
