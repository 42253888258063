import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  CloseIcon,
  Column,
  DrawerFooter,
  EditableHeading,
  IconButton,
  Row,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { FC } from "react";
import { Navigate, useNavigate, useOutletContext, useParams } from "src/router";

import { DeleteButton } from "src/components/delete-button";
import { Drawer } from "src/components/drawer";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  CollectionQuery,
  useCollectionQuery,
  useDeleteCollectionMutation,
  useUpdateCollectionMutation,
  useUpdateCollectionNameMutation,
} from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import * as yup from "yup";
import { Fallback } from "./components/fallback";
import { ConditionGroup, filterSchema } from "./components/filter";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import catalogIcon from "src/pages/schema/assets/catalog.svg";

export const Collection = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const navigate = useNavigate();

  const onClose = () => {
    navigate("/ai/collections");
  };

  const { data: collection, isLoading } = useCollectionQuery(
    {
      id: collectionId ?? "",
    },
    { select: (data) => data.decision_engine_collections_by_pk },
  );

  if (!isLoading && !collection) {
    return <Navigate to="/ai/collections" />;
  }

  return (
    <Drawer isOpen onClose={onClose} size="xl">
      {isLoading ? (
        <Spinner size="lg" m="auto" />
      ) : (
        <Content collection={collection!} />
      )}
    </Drawer>
  );
};

const schema = yup.object().shape({
  filter: filterSchema,
  config: yup.object().required(),
});

const Content: FC<
  Readonly<{
    collection: NonNullable<
      CollectionQuery["decision_engine_collections_by_pk"]
    >;
  }>
> = ({ collection }) => {
  const { toast } = useToast();
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const updateCollectionMutation = useUpdateCollectionMutation();
  const updateCollectionName = useUpdateCollectionNameMutation();
  const deleteMutation = useDeleteCollectionMutation();

  const onClose = () => {
    navigate("/ai/collections");
  };

  const form = useHightouchForm({
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      await updateCollectionMutation.mutateAsync({
        catalogCollectionId: collection.collection.id,
        filter: data.filter,
        config: data.config,
      });
    },
    values: {
      config: collection.config,
      filter: collection.collection.filter,
    },
  });

  const updateName = async (name: string) => {
    try {
      await updateCollectionName.mutateAsync({
        catalogCollectionId: collection.collection.id,
        name,
      });
      toast({
        id: "update-name",
        title: "Collection name updated",
        variant: "success",
      });
    } catch (e) {
      captureException(e);
      toast({
        id: "update-name",
        title: "Collection name failed to update",
        variant: "error",
      });
    }
  };

  return (
    <Form form={form}>
      <Column gap={2} p={6}>
        <Row alignItems="center" justifyContent="space-between">
          <EditableHeading
            value={collection.collection.name}
            onChange={updateName}
          />
          <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
        </Row>
        <Row align="center" gap={2} overflow="hidden">
          <Box as="img" src={catalogIcon} width="20px" />
          <TextWithTooltip fontWeight="medium">
            {collection.collection.catalog.model.name}
          </TextWithTooltip>
        </Row>
      </Column>
      <Box as={Tabs} flex={1}>
        <Box as={TabList} px={6}>
          <Tab>Filter</Tab>
          <Tab>Configuration</Tab>
        </Box>
        <TabPanels>
          <TabPanel>
            <Column px={6}>
              <ConditionGroup
                leftModelId={collection.collection.catalog.model.id}
                rightModelId={engine.segment.id}
              />
            </Column>
          </TabPanel>
          <TabPanel>
            <Column px={6}>
              <Fallback />
            </Column>
          </TabPanel>
        </TabPanels>
      </Box>
      <DrawerFooter>
        <FormActions
          isDisabled={engine.status !== DecisionEngineStatus.PENDING}
          tooltip={
            engine.status !== DecisionEngineStatus.PENDING
              ? "Please contact us to make modifications"
              : undefined
          }
        />
        <Tooltip message="Please contact us to delete a collection on an initialized engine">
          <Box>
            <DeleteButton
              isDisabled={engine.status !== DecisionEngineStatus.PENDING}
              label="collection"
              onDelete={() =>
                deleteMutation.mutateAsync({
                  catalogCollectionId: collection.collection.id,
                })
              }
              onSuccess={() => {
                navigate("ai/collections");
              }}
            />
          </Box>
        </Tooltip>
      </DrawerFooter>
    </Form>
  );
};
