import { FC } from "react";

import {
  WizardDrawer as HightouchUIWizardDrawer,
  WizardDrawerProps,
} from "@hightouchio/ui";

import { usePylonDrawerOffset } from "./use-pylon-drawer-offset";

export const WizardDrawer: FC<Readonly<WizardDrawerProps>> = ({
  isOpen = false,
  size = "md",
  ...props
}) => {
  usePylonDrawerOffset({ isOpen, size });

  return <HightouchUIWizardDrawer isOpen={isOpen} size={size} {...props} />;
};
