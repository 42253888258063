import { useToast } from "@hightouchio/ui";
import { useEffect } from "react";
import { useOutletContext } from "src/router";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { useModelState } from "src/hooks/use-model-state";
import * as analytics from "src/lib/analytics";
import { AudiencePageOutletState } from "src/pages/audiences/types";
import { QueryType } from "src/types/models";
import { ModelState, useUpdateQuery } from "src/utils/models";

export const AudienceQuery = () => {
  const { audience, parentModel, id } =
    useOutletContext<AudiencePageOutletState>();
  const modelState = useModelState(audience);
  const updateQuery = useUpdateQuery();
  const { toast } = useToast();

  const source = audience?.connection;

  const onUpdate = (showToast = true) => {
    analytics.track("Model Updated", {
      model_id: id,
      model_type: QueryType.Visual,
      model_name: audience?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    if (showToast) {
      toast({
        id: "update-audience",
        title: "Audience was updated",
        variant: "success",
      });
    }
  };

  const save = async (
    queryStateOverride: Partial<ModelState> = {},
    showToast = true,
  ) => {
    await updateQuery({
      model: {
        ...modelState.state,
        ...(queryStateOverride ?? {}),
      },
      columns: undefined, // we don't need to add/remove/update any columns when saving an audience
    });

    onUpdate(showToast);
  };

  useEffect(() => {
    // Reset everything but the visual query filter when the audience changes
    modelState.reset({
      ...audience,
      visual_query_filter: {
        ...(modelState.state.visual_query_filter ?? {}),

        // Child components expect conditions to be an array
        conditions: modelState.state.visual_query_filter?.conditions ?? [],

        // Always use the persisted size cap definition
        sizeCap: audience.visual_query_filter?.sizeCap,
      },
      query_type: QueryType.Visual,
    });
  }, [audience]);

  return (
    <FormErrorProvider>
      <AudienceExplore
        audience={audience}
        modelState={modelState}
        bodyOverflow="hidden"
        parentModel={parentModel}
        source={source}
        onSave={save}
      />
    </FormErrorProvider>
  );
};
