import { FC } from "react";

import {
  Alert,
  Button,
  Dialog,
  FormField,
  Tooltip,
  useDisclosure,
  Column,
  Row,
  Text,
  CheckIcon,
  CloseIcon,
  Badge,
} from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { ImpersonationPermissionsModal } from "./impersonation-permissions-modal";
import { useResourcePermission } from "src/components/permission/use-resource-permission";

export const WorkspaceWarning: FC = () => {
  const { user, workspace } = useUser();
  const {
    isOpen: permissionModalOpen,
    onOpen: openPermissionModal,
    onClose: closePermissionModal,
  } = useDisclosure();
  const {
    isOpen: workspaceInfoOpen,
    onOpen: openWorkspaceInfo,
    onClose: closeWorkspaceInfo,
  } = useDisclosure();

  const canUpgrade = user?.is_admin && !user?.is_admin_readonly;

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  if (!user) {
    return null;
  }

  if (!user.not_member_of_current_workspace) {
    return null;
  }

  return (
    <>
      <Alert
        variant="banner"
        type="error"
        justify="center"
        title={`You are not a member of this workspace (${user.current_workspace_id}).`}
        message="Be careful when assisting customers."
        actions={
          <>
            {hasUpdatePermission && (
              <Row alignItems="center" gap={1}>
                <Badge variant="warning">Workspace admin</Badge>
              </Row>
            )}
            <Tooltip
              isDisabled={canUpgrade}
              message="You have limited permissions in this workspace."
            >
              <Button
                variant="secondary"
                isDisabled={!canUpgrade}
                onClick={openPermissionModal}
              >
                Change permissions
              </Button>
            </Tooltip>
            <Button onClick={openWorkspaceInfo}>More info</Button>
          </>
        }
      />
      <ImpersonationPermissionsModal
        isOpen={permissionModalOpen}
        onClose={closePermissionModal}
      />
      <Dialog
        variant="info"
        isOpen={workspaceInfoOpen}
        title="Workspace information"
        actions={
          <Button variant="primary" onClick={closeWorkspaceInfo}>
            Close
          </Button>
        }
        onClose={closeWorkspaceInfo}
      >
        <Column gap={4}>
          <FormField label="Organization">
            {user?.organization?.name} ({user?.organization?.id})
          </FormField>
          <FormField label="Migrations">
            <Column gap={2}>
              {[
                {
                  label: "Permissions V2",
                  value: user?.permissions_v2_enabled,
                },
                { label: "Alerting V2", value: workspace?.alerting_v2_enabled },
              ].map((migration) => {
                return (
                  <Row key={migration.label} gap={2}>
                    {migration.value ? (
                      <CheckIcon color="success.base" />
                    ) : (
                      <CloseIcon color="danger.base" />
                    )}
                    <Text>{migration.label}</Text>
                  </Row>
                );
              })}
            </Column>
          </FormField>
        </Column>
      </Dialog>
    </>
  );
};
