import {
  Column,
  Row,
  SectionHeading,
  ToggleButton,
  ToggleButtonGroup,
  Text,
  MailIcon,
  Box,
  Spinner,
  Alert,
} from "@hightouchio/ui";
import { FC, useEffect, useState } from "react";

import {
  DecisionEngineFlowQuery,
  useInspectorMessagesBackgroundQuery,
  useRunSqlResultQuery,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { abbreviateNumber } from "src/utils/numbers";
import { Interaction, InteractionHeader } from "./interaction";
import { Pagination } from "src/ui/table";
import { MessageRow } from "./message-row";
import { parseIfString } from "src/utils/object";

export type InteractionRow = {
  interaction_id: string;
  action_features: {
    message: string;
    channel: string;
    frequency_arm: string;
    time_of_day: string;
  };
  primary_key: string; // user primary key
  sync_metadata: {
    variables: Record<string, string>;
    [key: string]: any;
  };
  send_at: string;
  user_primary_label: string;
};

export type FlowMessages = NonNullable<
  DecisionEngineFlowQuery["decision_engine_flows_by_pk"]
>["messages"];

type Props = {
  flowMessages: FlowMessages;
  usersByPrimaryKey: Record<string, any>;
};

export const Messages: FC<Readonly<Props>> = ({
  flowMessages,
  usersByPrimaryKey,
}) => {
  const [page, setPage] = useState(0);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [direction, setDirection] = useQueryState("direction");
  const [interactionId, setInteractionId] = useQueryState("message");
  const [flowId] = useQueryState("flow");
  const [interactionsError, setInteractionsError] = useState("");
  const [count, setCount] = useState(0);
  const [interactions, setInteractions] = useState<InteractionRow[]>([]);
  const [users] = useQueryState("users");
  const selectedUsers = users ? users.split(",") : [];

  const { data: jobId, isLoading: loadingJob } =
    useInspectorMessagesBackgroundQuery(
      {
        input: {
          flowId,
          direction: direction || "upcoming",
          primaryKeys: selectedUsers,
        },
      },
      {
        select: (data) => data.inspectorMessagesBackground,
      },
    );

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setInteractionsError(data.backgroundPreviewQueryResult.error);
        } else {
          setInteractions(
            data.backgroundPreviewQueryResult.rows.map((row) => ({
              ...row,
              sync_metadata: parseIfString(row.sync_metadata),
              action_features: parseIfString(row.action_features),
            })),
          );
          setCount(
            Number(data.backgroundPreviewQueryResult.numRowsWithoutLimit),
          );
          if (data.backgroundPreviewQueryResult.rows.length)
            setInteractionId(
              data.backgroundPreviewQueryResult.rows[0].interaction_id,
            );
        }
        setShouldPoll(false);
      },
    },
  );

  const isLoading = loadingJob || shouldPoll;

  const interactionRow = interactions.find(
    (row) => row.interaction_id === interactionId,
  );

  const selectedMessage = interactionRow
    ? flowMessages.find(
        (flowMessage) =>
          flowMessage.message.id === interactionRow.action_features.message,
      )
    : undefined;

  return (
    <>
      <Column
        borderRight="1px"
        borderColor="base.border"
        flex={1}
        height="100%"
        overflow="hidden"
        bg="white"
      >
        <Column
          borderBottom="1px"
          borderColor="base.border"
          p={6}
          pb={4}
          gap={2}
        >
          <Row gap={4} align="center" justify="space-between" h="36px">
            <Row gap={2} align="center">
              <Box as={MailIcon} boxSize={5} color="text.secondary" />
              <SectionHeading>Messages</SectionHeading>
            </Row>
          </Row>
          <Row gap={4}>
            <ToggleButtonGroup
              value={direction || "upcoming"}
              onChange={setDirection}
            >
              <ToggleButton label="Upcoming" value="upcoming" />
              <ToggleButton label="Past" value="past" />
            </ToggleButtonGroup>
          </Row>
        </Column>

        {!isLoading && count ? (
          <Row borderBottom="1px" borderColor="base.border" px={6} py={2}>
            <Text size="sm" color="text.secondary">
              {abbreviateNumber(count)} messages
            </Text>
          </Row>
        ) : null}

        <Column overflow="auto" flex={1}>
          {isLoading ? (
            <Spinner mt={6} mx="auto" />
          ) : !interactions?.length ? (
            <Text color="text.tertiary" mx="auto" mt={6}>
              No messages
            </Text>
          ) : (
            interactions?.map((row: InteractionRow) => {
              return (
                <MessageRow
                  key={row.interaction_id}
                  onClick={() => {
                    setInteractionId(row.interaction_id);
                  }}
                  flowMessages={flowMessages}
                  interaction={row}
                  isSelected={row.interaction_id === interactionId}
                />
              );
            })
          )}
        </Column>
        {count > 100 && (
          <Row
            p={4}
            borderTop="1px"
            borderColor="base.border"
            justify="flex-end"
          >
            <Pagination
              compact
              text=""
              count={count}
              page={page}
              rowsPerPage={100}
              setPage={setPage}
            />
          </Row>
        )}
      </Column>
      <Column flex={2} overflow="hidden">
        {interactionRow ? (
          <>
            <Row p={6} pb={1}>
              <InteractionHeader
                interaction={interactionRow}
                message={selectedMessage!}
              />
            </Row>

            <Interaction
              message={selectedMessage!}
              usersByPrimaryKey={usersByPrimaryKey}
              interaction={interactionRow}
              error={interactionsError}
            />
          </>
        ) : (
          <Alert
            m={6}
            variant="inline"
            type="subtle"
            title="No message selected"
            message="Select a message to view details"
          />
        )}
      </Column>
    </>
  );
};
