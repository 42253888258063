import { FC, useEffect, useMemo, useState } from "react";

import {
  Checkbox,
  Column,
  Menu,
  MenuButton,
  MenuList,
  Row,
  Text,
  Button,
  SearchInput,
  IconButton,
  CloseIcon,
  LinkIcon,
  SectionHeading,
  NumberInput,
  Dialog,
} from "@hightouchio/ui";
import { Link } from "src/router";
import pluralize from "pluralize";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Reorder } from "src/components/reorder";
import { Schedule } from "src/components/schedule/types";
import { usePermissionContext } from "src/components/permission/permission-context";
import {
  SyncsBoolExp,
  SyncsOrderBy,
  useSyncsForSequencesQuery,
} from "src/graphql";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { PermissionedButton } from "src/components/permission";

export interface SequenceMemberSync {
  id: string;
  segment: {
    id: string;
    name: string;
  };
  destination: {
    name: string;
    definition: {
      icon: string;
      name: string;
    };
  };
}
export interface SequenceMember {
  sync: SequenceMemberSync;
  abort_seq_on_failure: boolean;
  abort_seq_on_rejects?: number;
}
export interface FormState {
  slug: string;
  name: string;
  members: SequenceMember[];
  schedule: Schedule | null;
}

export const SequenceForm: FC = () => {
  const { watch, setValue } = useFormContext<FormState>();
  const { append, remove } = useFieldArray({
    name: "members",
  });
  const members = watch("members");

  const [showSelectSyncs, setShowSelectSyncs] = useState(false);

  const updatePermission = usePermissionContext();

  const terminationLabel = (value) => {
    const rejectedErrors =
      value.abort_seq_on_rejects !== null &&
      value.abort_seq_on_rejects !== undefined;
    if (value.abort_seq_on_failure && rejectedErrors)
      return "Rejected rows, Fatal error";
    if (value.abort_seq_on_failure) return "Fatal error";
    if (rejectedErrors) return "Rejected rows";
    return "No";
  };

  return (
    <>
      <Column gap={6}>
        <Row justify="space-between">
          <SectionHeading>Sequence</SectionHeading>
          <PermissionedButton
            permission={{ v1: { resource: "sync", grant: "create" } }}
            onClick={() => setShowSelectSyncs(true)}
          >
            Add syncs
          </PermissionedButton>
        </Row>

        <Reorder
          nodeKey="sync.id"
          onChange={(newItems) => {
            setValue("members", newItems, { shouldDirty: true });
          }}
          items={members}
        >
          {members.map((member, index) => (
            <Controller
              key={member.sync.id}
              name={`members.${index}`}
              render={({ field }) => (
                <Row gap={4} width="100%">
                  <Column gap={2} width="100%" overflow="hidden">
                    <Text
                      textTransform="uppercase"
                      color="text.secondary"
                      fontWeight="semibold"
                      size="sm"
                    >
                      Model
                    </Text>
                    <Text isTruncated fontWeight="medium">
                      {field.value.sync?.segment?.name}
                    </Text>
                  </Column>

                  <Column gap={2} maxWidth="200px" width="100%">
                    <Text
                      textTransform="uppercase"
                      color="text.secondary"
                      fontWeight="semibold"
                      size="sm"
                    >
                      Destination
                    </Text>
                    <Row align="center" gap={2} overflow="hidden">
                      <IntegrationIcon
                        name={field.value.sync?.destination?.definition?.name}
                        src={field.value.sync?.destination?.definition?.icon}
                      />
                      <Text fontWeight="medium" isTruncated>
                        {field.value.sync?.destination?.name}
                      </Text>
                    </Row>
                  </Column>
                  <Column
                    gap={2}
                    alignItems="flex-start"
                    width="180px"
                    flexShrink={0}
                  >
                    <Text
                      textTransform="uppercase"
                      color="text.secondary"
                      fontWeight="semibold"
                      size="sm"
                    >
                      Terminate
                    </Text>
                    <Menu>
                      <MenuButton size="sm">
                        {terminationLabel(field.value)}
                      </MenuButton>

                      <MenuList>
                        <Column gap={2} px={4} py={2}>
                          <Text>Terminate sequence when</Text>
                          <Column>
                            <Controller
                              name={`members.${index}.abort_seq_on_rejects`}
                              render={({ field }) => (
                                <>
                                  <Checkbox
                                    isChecked={Boolean(
                                      field.value !== null &&
                                        field.value !== undefined,
                                    )}
                                    isDisabled={Boolean(
                                      updatePermission?.unauthorized,
                                    )}
                                    label="Sync run has rejected rows"
                                    onChange={(event) =>
                                      field.onChange(
                                        event.target.checked ? 100 : null,
                                      )
                                    }
                                  />
                                  <Row align="center" ml={8} gap={2}>
                                    <Text
                                      color={
                                        field.value
                                          ? "text.primary"
                                          : "text.secondary"
                                      }
                                      fontWeight="medium"
                                    >
                                      Error rate threshold
                                    </Text>
                                    <Row width="80px">
                                      <NumberInput
                                        width="100%"
                                        formatOptions={{
                                          style: "percent",
                                        }}
                                        isDisabled={
                                          field.value === null ||
                                          field.value === undefined
                                        }
                                        value={
                                          field.value === 0
                                            ? 0
                                            : field.value / 100
                                        }
                                        onChange={(v) => {
                                          const value = v ?? 0;
                                          field.onChange(value * 100);
                                        }}
                                      />
                                    </Row>
                                  </Row>
                                </>
                              )}
                            />
                          </Column>
                          <Controller
                            name={`members.${index}.abort_seq_on_failure`}
                            render={({ field }) => (
                              <Checkbox
                                isChecked={field.value}
                                isDisabled={Boolean(
                                  updatePermission?.unauthorized,
                                )}
                                label="Sync run has fatal errors"
                                onChange={(event) =>
                                  field.onChange(event.target.checked)
                                }
                              />
                            )}
                          />
                        </Column>
                      </MenuList>
                    </Menu>
                  </Column>
                  <Row
                    gap={2}
                    justify="flex-end"
                    flex={1}
                    align="center"
                    flexShrink={0}
                  >
                    <Link href={`/syncs/${field.value.sync?.id}`} isExternal>
                      <IconButton
                        aria-label="View sync"
                        icon={LinkIcon}
                        onClick={() => {}}
                      />
                    </Link>
                    {members.length > 1 && (
                      <IconButton
                        aria-label="Remove sync"
                        icon={CloseIcon}
                        onClick={() => remove(index)}
                      />
                    )}
                  </Row>
                </Row>
              )}
            />
          ))}
        </Reorder>
      </Column>

      {showSelectSyncs && (
        <SelectSyncs
          members={members}
          onClose={() => setShowSelectSyncs(false)}
          onSubmit={(syncs) =>
            append(syncs.map((sync) => ({ sync, abort_seq_on_failure: true })))
          }
        />
      )}
    </>
  );
};

const SelectSyncs: FC<
  Readonly<{
    members: any;
    onSubmit: (syncs) => void;
    onClose: () => void;
  }>
> = ({ onSubmit, onClose, members }) => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { limit, offset, page, setPage } = useTableConfig<SyncsOrderBy>({
    limit: 8,
  });
  const { selectedRows, onRowSelect } = useRowSelect();
  const [selectedSyncs, setSelectedSyncs] = useState<any>([]);

  const filters = useMemo(() => {
    const searchFilters: SyncsBoolExp[] = [
      { segment: { name: { _ilike: `%${search}%` } } },
      { destination: { name: { _ilike: `%${search}%` } } },
      { destination: { type: { _ilike: `%${search}%` } } },
    ];

    return { _or: searchFilters };
  }, [search]);

  const {
    data,
    isLoading: initialLoading,
    isRefetching,
  } = useSyncsForSequencesQuery(
    { offset, limit, filters },
    { notifyOnChangeProps: "tracked", keepPreviousData: true },
  );

  const syncs = data?.syncs;
  const syncsCount = data?.syncs_aggregate?.aggregate?.count ?? 0;

  useEffect(() => {
    setPage(0);
  }, [filters]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, filters]);

  useEffect(() => {
    if (!isRefetching) {
      setLoading(false);
    }
  }, [isRefetching, syncs]);

  useEffect(() => {
    if (syncs) {
      const filtered = selectedSyncs.filter(({ id }) =>
        selectedRows.includes(id),
      );
      selectedRows.forEach((id) => {
        if (!filtered.find((sync) => sync.id === id)) {
          const sync = syncs.find((sync) => sync.id === id);
          if (sync) {
            filtered.push(sync);
          }
        }
      });
      setSelectedSyncs(filtered);
    }
  }, [selectedRows]);

  return (
    <Dialog
      isOpen
      variant="form"
      width="auto"
      height="xl" // Add height to avoid layout shift while search loads.
      title="Add syncs"
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            isDisabled={!selectedSyncs?.length}
            onClick={() => {
              onSubmit(selectedSyncs);
              onClose();
            }}
          >
            Add syncs
          </Button>
        </>
      }
      onClose={onClose}
    >
      <Row mb={4} justify="space-between" align="center" w="3xl">
        <SearchInput
          placeholder="Search syncs by model or destination..."
          value={search ?? ""}
          onChange={(e) => setSearch(e.target.value)}
        />
        {selectedRows?.length > 0 && (
          <Text>
            {selectedRows.length} {pluralize("sync", selectedRows.length)}{" "}
            selected
          </Text>
        )}
      </Row>
      <Table
        columns={[
          {
            name: "Model",
            cell: ({ segment }) => (
              <Text isTruncated fontWeight="medium">
                {segment?.name}
              </Text>
            ),
          },
          {
            name: "Destination",
            cell: ({ destination }) => (
              <Row align="center" gap={2} overflow="hidden">
                <IntegrationIcon
                  name={destination?.definition?.name}
                  src={destination?.definition?.icon}
                />
                <Text isTruncated fontWeight="medium">
                  {destination?.name ??
                    destination?.definition?.name ??
                    "Private destination"}
                </Text>
              </Row>
            ),
          },
        ]}
        data={syncs}
        disabled={({ id }) =>
          Boolean(members.find(({ sync }) => sync.id === id))
        }
        loading={initialLoading || (loading && isRefetching)}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />
      <Row width="100%" mt={4} justify="flex-end">
        <Pagination
          count={syncsCount}
          label="syncs"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </Dialog>
  );
};
