import { ReactNode, Suspense } from "react";

import { FeatureFlagProvider } from "src/contexts/feature-flags";
import { UserProvider } from "src/contexts/user-context";
import { CommandBar } from "src/components/commandbar";
import { HeaderHeightProvider } from "src/contexts/header-height-context";
import { GlobalStyles } from "src/components/global-styles";
import { ThemeProvider } from "@hightouchio/ui";
import { QueryProvider } from "src/utils/query-client";
import { PageSpinner } from "src/components/loading";
import { ErrorBoundary } from "src/components/error-boundary";

export const RootProviders = ({ children }: { children: ReactNode }) => {
  return (
    <QueryProvider>
      <ThemeProvider>
        {children}
        <GlobalStyles />
      </ThemeProvider>
    </QueryProvider>
  );
};

export const AppProviders = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<PageSpinner />}>
        <UserProvider>
          <FeatureFlagProvider>
            <HeaderHeightProvider>
              <CommandBar>{children}</CommandBar>
            </HeaderHeightProvider>
          </FeatureFlagProvider>
        </UserProvider>
      </Suspense>
    </ErrorBoundary>
  );
};
