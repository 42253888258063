import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Box,
  Column,
  FrameIcon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  PlusIcon,
  Row,
  SubtractIcon,
  Text,
  UndoIcon,
} from "@hightouchio/ui";
import { nanoid } from "nanoid";
import { useNavigate } from "src/router";
import ReactFlow, {
  Edge,
  EdgeTypes,
  MiniMap,
  Node,
  NodeProps,
  NodeTypes,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";

import {
  PermissionedLinkButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { drawerWidth } from "src/pages/schema";
import { Search } from "src/pages/schema/search";
import { SchemaModelType } from "src/types/schema";

import { useFlags } from "launchdarkly-react-client-sdk";
import {
  EdgeType,
  GraphModel,
  GraphRelationship,
  NodeData,
} from "src/pages/schema/types";
import { GraphContext } from "./context";
import { ConnectionLine, CustomEdge } from "./edges";
import { reposition } from "./layout";
import {
  AssetNode,
  CatalogNode,
  EventNode,
  GroupNode,
  InteractionNode,
  ParentNode,
  RelatedNode,
  AdStatsNode,
} from "./nodes";
import css from "./reactflow.module.css";
import {
  NODE_HEIGHT,
  NODE_WIDTH,
  findNodeParentId,
  getConnectedNodes,
  getGraph,
  getParams,
  newEphemeralEdge,
  newEphemeralNode,
} from "./utils";
import { useExpandedGroup } from "./grouping";

const nodeTypes: NodeTypes = {
  [SchemaModelType.Parent]: ParentNode,
  [SchemaModelType.Related]: RelatedNode,
  [SchemaModelType.Event]: EventNode,
  [SchemaModelType.Catalog]: CatalogNode,
  [SchemaModelType.Interaction]: InteractionNode,
  [SchemaModelType.Asset]: AssetNode,
  [SchemaModelType.Group]: GroupNode,
  [SchemaModelType.AdStats]: AdStatsNode,
};

const edgeTypes: EdgeTypes = { [EdgeType.Custom]: CustomEdge };

export const Graph: FC<
  Readonly<{
    selectedId: string | undefined | null;
    type: string | null;
    models: GraphModel[];
    relationships: GraphRelationship[];
    sourceId: string | null | undefined;
  }>
> = ({ selectedId, type, models, relationships, sourceId }) => {
  const navigate = useNavigate();
  const { fitView, setCenter, zoomIn, zoomOut, getNode } =
    useReactFlow<NodeData>();

  // Due to the re-layouting we do here, initial renders to ReactFlow happen before we're
  // correctly positioned. This can cause a flicker effect when we call `fitView` on mount.
  // To avoid this, block rendering until we've layouted
  // https://github.com/xyflow/xyflow/issues/533
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { expandedGroup, setExpandedGroup } = useExpandedGroup(
    models,
    relationships,
    selectedId || undefined,
  );

  const expandedGroupNodeId =
    expandedGroup && expandedGroup.expanded ? expandedGroup.nodeId : undefined;

  const initialGraph = useMemo(
    () =>
      getGraph({
        models,
        relationships,
        expandedGroupNodeId,
      }),
    [models, relationships, expandedGroupNodeId],
  );

  const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>(
    initialGraph.nodes,
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>(
    initialGraph.edges,
  );

  const params = getParams();

  const zoomToNode = useCallback(
    ({ node }: { node: Node<NodeData> }) => {
      const nodeHeight = node.style?.height
        ? Number(node.style.height)
        : NODE_HEIGHT;
      const yPos = node.position.y + Math.min(nodeHeight / 2, 800);
      const xPos = node.position.x + Number(node.style?.width ?? NODE_WIDTH);
      const offsetX = drawerWidth / 2;
      const x = xPos + offsetX / 2;

      setCenter(x, yPos, {
        zoom: nodeHeight > 800 ? 0.5 : 1,
        duration: node.type === SchemaModelType.Group ? 0 : 500,
      });
    },
    [getNode, setCenter, selectedId],
  );

  const onLayout = useCallback(
    async ({
      zoomNodeId,
      ephemeralNode,
      ephemeralEdge,
    }: {
      ephemeralNode?: Node<NodeData>;
      ephemeralEdge?: Edge;
      recenter?: boolean;
      zoomNodeId?: string;
    } = {}) => {
      const graph = getGraph({
        models,
        relationships,
        expandedGroupNodeId,
      });

      const newNodes = [
        ...graph.nodes,
        ...(ephemeralNode ? [ephemeralNode] : []),
      ];
      const newEdges = [
        ...graph.edges,
        ...(ephemeralEdge ? [ephemeralEdge] : []),
      ];

      const layoutedNodes = await reposition({
        nodes: newNodes,
        edges: newEdges,
        expandedGroupNodeId: expandedGroupNodeId || undefined,
        selectedId: selectedId || undefined,
        ephemeralNode,
      });

      setNodes(layoutedNodes);
      setEdges(newEdges);

      if (isInitialLoad) {
        setIsInitialLoad(false);
      }

      if (zoomNodeId) {
        const zoomNode = layoutedNodes.find((n) => n.id === zoomNodeId);

        if (zoomNode) {
          zoomToNode({
            node: zoomNode,
          });
        }
      } else {
        fitView({ padding: 0.25, duration: 500 });
      }
    },
    [
      expandedGroupNodeId,
      isInitialLoad,
      models,
      relationships,
      selectedId,
      zoomToNode,
    ],
  );

  useEffect(() => {
    if (type) {
      if (type === SchemaModelType.Parent) {
        const ephemeralNodeId = nanoid();
        onLayout({
          zoomNodeId: ephemeralNodeId,
          ephemeralNode: newEphemeralNode(
            ephemeralNodeId,
            SchemaModelType.Parent,
          ),
        });
      } else if (selectedId) {
        const ephemeralNodeId = nanoid();
        const ephemeralEdgeId = nanoid();

        const parentId = findNodeParentId(selectedId, nodes);

        onLayout({
          zoomNodeId: ephemeralNodeId,
          ephemeralNode: newEphemeralNode(
            ephemeralNodeId,
            type as SchemaModelType,
          ),
          ephemeralEdge: newEphemeralEdge(
            ephemeralEdgeId,
            parentId,
            ephemeralNodeId,
          ),
        });
      }
    } else {
      // If we have any ephemeral nodes (create flow), recenter on exit
      if (nodes.find((node) => node.data.isEphemeral)) {
        onLayout();
      } else {
        onLayout({ zoomNodeId: selectedId || undefined });
      }
    }
  }, [selectedId, type]);

  useEffect(() => {
    onLayout({ zoomNodeId: expandedGroup?.nodeId });
  }, [expandedGroup]);

  useEffect(() => {
    onLayout();
  }, [models, relationships]);

  // Prevent initial render to avoid flickering, wait until layout algorithm has finished
  if (isInitialLoad) {
    return null;
  }

  return (
    <GraphContextProvider
      edges={edges}
      nodes={nodes}
      onToggleExpandedGroup={({ groupNodeId, expanded }) => {
        setExpandedGroup({ nodeId: groupNodeId, expanded });
      }}
    >
      <ReactFlow
        edges={edges}
        nodes={nodes}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodesConnectable={false}
        nodesDraggable={false}
        onNodesChange={(changes) => {
          onNodesChange(changes);
        }}
        onEdgesChange={onEdgesChange}
        maxZoom={1}
        connectionLineComponent={ConnectionLine}
        proOptions={{ hideAttribution: true }}
        className={css.reactflow}
        fitView
        fitViewOptions={{ padding: 0.25 }}
      >
        <Column
          gap={4}
          pos="absolute"
          top={6}
          left={6}
          zIndex={5}
          sx={{ button: { borderColor: "base.border" } }}
        >
          <Search />

          <Column
            bg="white"
            border="1px"
            borderColor="base.border"
            borderRadius="md"
            overflow="hidden"
            sx={{
              button: {
                width: "38px",
                height: "38px",
                borderRadius: 0,
                ":nth-child(2)": {
                  border: "1px",
                  borderLeft: "none",
                  borderRight: "none",
                  borderColor: "base.border",
                },
              },
            }}
          >
            <IconButton
              aria-label="Zoom in"
              icon={PlusIcon}
              onClick={() => {
                zoomIn();
              }}
            />
            <IconButton
              aria-label="Zoom out"
              icon={SubtractIcon}
              onClick={() => {
                zoomOut();
              }}
            />
            <IconButton
              aria-label="Fit view"
              icon={FrameIcon}
              onClick={() => fitView({ padding: 0.25, duration: 500 })}
            />
          </Column>
        </Column>
        <MiniMap
          position="bottom-left"
          style={{
            border: "1px solid var(--chakra-colors-base-border)",
            borderRadius: "6px",
          }}
        />
        <GraphBackground />
        <Box pos="absolute" top={6} right={6} zIndex={5}>
          <CreateAction sourceId={sourceId} />
        </Box>
        {params.parent && (
          <Row pos="absolute" bottom={6} left={0} width="100%" justify="center">
            <Box
              as="button"
              zIndex={5}
              borderRadius="md"
              boxShadow="md"
              bg="text.primary"
              color="white"
              height="40px"
              flexShrink={0}
              px={4}
              onClick={() => {
                navigate(`/schema-v2?source=${params.source}`);
              }}
              _hover={{ opacity: 0.9 }}
              transition="opacity 0.15s ease-in-out"
            >
              <Row gap={2} align="center">
                <Box as={UndoIcon} height="24px" width="24px" />
                <Text color="inherit" fontWeight="medium">
                  Return to source schema
                </Text>
              </Row>
            </Box>
          </Row>
        )}
      </ReactFlow>
    </GraphContextProvider>
  );
};

export const GraphContextProvider: FC<
  Readonly<{
    children: ReactNode;
    edges: Edge[];
    nodes: Node[];
    onToggleExpandedGroup: ({
      groupNodeId,
      expanded,
    }: {
      groupNodeId: string;
      expanded: boolean;
    }) => void;
  }>
> = ({ children, nodes, edges, onToggleExpandedGroup }) => {
  const [highlight, setHighlight] = useState<NodeProps | null>(null);

  const highlightedNodes = useMemo(() => {
    if (highlight && edges && nodes) {
      const tracedNodes = getConnectedNodes(highlight.id, nodes, edges);
      return [highlight.id, ...tracedNodes];
    }
    return [];
  }, [highlight, edges]);

  return (
    <GraphContext.Provider
      value={{
        highlight,
        setHighlight,
        highlightedNodes,
        onToggleExpandedGroup,
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};

export const GraphBackground = () => (
  <Box
    pos="absolute"
    width="100%"
    height="100%"
    backgroundImage={`url(
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSIjRkJGQ0ZEIi8+CjxyZWN0IHg9IjE0IiB5PSIxNCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0iI0RCRTFFOCIvPgo8cmVjdCB4PSIxNCIgeT0iMTciIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNEQkUxRTgiLz4KPHJlY3QgeD0iMTciIHk9IjE0IiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjREJFMUU4Ii8+CjxyZWN0IHg9IjE3IiB5PSIxNyIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0iI0RCRTFFOCIvPgo8L3N2Zz4=")`}
  />
);

export const CreateAction = ({
  sourceId,
}: {
  sourceId: string | number | null | undefined;
}) => {
  const { decisionEngineEnabled } = useFlags();

  if (!decisionEngineEnabled) {
    return (
      <PermissionedLinkButton
        permission={{
          v1: { resource: "audience_schema", grant: "create" },
          v2: {
            resource: "model",
            grant: "can_create",
            creationOptions: {
              type: "schema",
              sourceId: sourceId?.toString() ?? "",
            },
          },
        }}
        isDisabled={!sourceId}
        variant="primary"
        href={`/schema-v2/new${
          sourceId ? `?source=${sourceId}&type=parent` : "?type=parent"
        }`}
      >
        Create parent model
      </PermissionedLinkButton>
    );
  }

  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton variant="primary">Create</MenuButton>
      <MenuList>
        <PermissionedMenuItem
          permission={{
            v1: { resource: "audience_schema", grant: "create" },
            v2: {
              resource: "model",
              grant: "can_create",
              creationOptions: {
                type: "schema",
                sourceId: sourceId?.toString() ?? "",
              },
            },
          }}
          onClick={() => {
            navigate(
              `/schema-v2/new${
                sourceId
                  ? `?source=${sourceId}&type=${SchemaModelType.Parent}`
                  : `?type=${SchemaModelType.Parent}`
              }`,
            );
          }}
        >
          Parent model
        </PermissionedMenuItem>
        <PermissionedMenuItem
          permission={{
            v1: { resource: "audience_schema", grant: "create" },
            v2: {
              resource: "model",
              grant: "can_create",
              creationOptions: {
                type: "schema",
                sourceId: sourceId?.toString() ?? "",
              },
            },
          }}
          onClick={() => {
            navigate(
              `/schema-v2/new${
                sourceId
                  ? `?source=${sourceId}&type=${SchemaModelType.Catalog}`
                  : `?type=${SchemaModelType.Catalog}`
              }`,
            );
          }}
        >
          Catalog
        </PermissionedMenuItem>
      </MenuList>
    </Menu>
  );
};
