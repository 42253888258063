import { FC, useState } from "react";

import { Column, Box, Heading, Row, Text, SearchInput } from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useSourceCatalog } from "src/components/sources/source-catalog";
import {
  CardButton,
  List,
  ListButton,
} from "src/components/welcome/components";
import { SourceDefinition, useSourceDefinitionsQuery } from "src/graphql";
import { cdn } from "src/utils/sanity";
import { getSearchRegExp } from "src/utils/string";

enum SourceCategory {
  Databases = "Data systems",
  Spreadsheets = "Spreadsheets",
  Other = "Other",
}

export const SourceCatalog: FC<{
  onSelect: (definition: SourceDefinition) => void;
}> = ({ onSelect }) => {
  const definitions = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
  });
  const { definitions: availableDefinitions } = useSourceCatalog(
    definitions.data ?? [],
    true,
  );
  const [category, setCategory] = useState("");

  return (
    <>
      <Row align="center" gap={4}>
        <SourceCard
          description="Snowflake, BigQuery, PostgreSQL, etc."
          selected={category === SourceCategory.Databases}
          logos={databaseLogos}
          title="It's a database"
          onClick={() => setCategory(SourceCategory.Databases)}
        />
        <SourceCard
          description="Google Sheets, Airtable, Microsoft Excel, etc."
          selected={category === SourceCategory.Spreadsheets}
          logos={spreadsheetLogos}
          title="It's a spreadsheet"
          onClick={() => setCategory(SourceCategory.Spreadsheets)}
        />
        <SourceCard
          description="Amazon S3, SFTP, Tableau, etc."
          selected={category === SourceCategory.Other}
          logos={otherLogos}
          title="It's something else"
          onClick={() => setCategory(SourceCategory.Other)}
        />
      </Row>
      {category && (
        <Column overflow="hidden" width="100%" flex={1}>
          <Heading mb={4}>Select your source</Heading>
          <List isLoading={definitions.isLoading}>
            {availableDefinitions
              .filter(
                ({ categories }) =>
                  categories?.find((c) => c?.name === category),
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((definition: SourceDefinition) => (
                <ListButton
                  key={definition.name}
                  onClick={() => {
                    onSelect(definition);
                  }}
                >
                  <IntegrationIcon
                    name={definition.name}
                    src={`${definition.icon}?h=48`}
                  />
                  <Text fontWeight="medium">{definition.name}</Text>
                </ListButton>
              ))}
          </List>
        </Column>
      )}
    </>
  );
};

export const SourceList: FC<{
  onSelect: (definition: SourceDefinition) => void;
}> = ({ onSelect }) => {
  const definitions = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
  });
  const { definitions: availableDefinitions } = useSourceCatalog(
    definitions.data ?? [],
    true,
  );
  const [search, setSearch] = useState("");

  return (
    <>
      <Row
        width="100%"
        sx={{ input: { width: "100%" }, "& > div": { width: "100%" } }}
        mb={6}
      >
        <SearchInput
          placeholder="Search all sources..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Row>
      <List>
        {availableDefinitions
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((definition) => {
            if (search) {
              const regex = getSearchRegExp(search, "i");
              return regex.test(definition.name);
            }
            return true;
          })
          .map((definition: SourceDefinition) => (
            <ListButton
              key={definition.name}
              onClick={() => {
                onSelect(definition);
              }}
            >
              <IntegrationIcon
                src={`${definition.icon}?h=48`}
                name={definition.name}
              />
              <Text fontWeight="medium">{definition.name}</Text>
            </ListButton>
          ))}
      </List>
    </>
  );
};

const SourceCard: FC<{
  selected: boolean;
  onClick: () => void;
  title: string;
  description: string;
  logos: Record<string, string>;
}> = ({ onClick, title, description, logos, selected }) => (
  <CardButton selected={selected} onClick={onClick}>
    <Heading mb={2}>{title}</Heading>
    <Text mb={5}>{description}</Text>
    <Row align="center" gap={4}>
      {Object.values(logos).map((src) => (
        <Box key={src} as="img" height="24px" src={cdn(src, 48)} />
      ))}
    </Row>
  </CardButton>
);

const databaseLogos = {
  snowflake: "a4ac9f5f978ab2446fc17bf116067cb7c74116a2-960x952.png",
  bigquery: "2a4f3d940e21dfa356bd993177586dab5e1b628f-2500x2500.svg",
  postgres: "245cb2ccbc976d6dc38d90456ca1fd7cdbcb2dc6-2424x2500.svg",
};

const spreadsheetLogos = {
  sheets: "e21cbe6366f56a4f52c8a3c2cbf78fd1a6645390-36x36.svg",
  airtable: "2efe1f193dbe1a2de3a289ff319068053cee8c83-512x512.svg",
  excel: "21f1186ae3d37c11f0538dd34ff9e7d243e00ea5-1101x1024.png",
};

const otherLogos = {
  s3: "f964e22514dff38c0aae83ec4f34fefcb3870776-310x310.svg",
  sftp: "683a08050c6bef49b5fc53e794b849c1f756dd55-256x256.png",
  tableau: "2254eaf76b58e7eb282779c1281066180f2db01c-573x554.png",
};
