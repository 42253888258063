import {
  BlockRule,
  MergeRule,
  Operator,
  Transformation,
  Stats,
  StatsForModelType,
  ModelStats,
} from "@hightouch/lib/idr/types";
import { ColumnType } from "@hightouch/lib/query/visual/types";

import { IdentityResolutionGraphQuery } from "src/graphql";

export type IdentityGraph = NonNullable<
  IdentityResolutionGraphQuery["idr_by_pk"]
>;

export enum IdrRunStatus {
  Cancelled = "CANCELLED",
  Cancelling = "CANCELLING",
  Failure = "FAILURE",
  Processing = "PROCESSING",
  Queued = "QUEUED",
  Success = "SUCCESS",
}

// TODO: centralize in backend?
export const defaultIdentifiers = [
  "Email",
  "Phone number",
  "First name",
  "Last name",
  "Full name",
  "Address",
  "Anonymous ID",
  "User ID",
  "Adobe ID",
  "Segment ID",
  "IP address",
];

export const transformationOptions: Record<
  Transformation,
  { label: string; description?: string }
> = {
  "case-insensitive": { label: "Case Insensitive" },
  "normalize-whitespace": {
    label: "Normalize",
    description: "Ignore whitespace",
  },
  "extract-number": {
    label: "Number",
    description: "Ignore non-numeric characters",
  },
};

export const transformationsByIdentifier: Record<string, Transformation[]> = {
  Email: ["case-insensitive", "normalize-whitespace"],
  Phone: ["extract-number"],
  Name: ["case-insensitive", "normalize-whitespace"],
  Address: ["case-insensitive", "normalize-whitespace"],
  "Anonymous ID": [],
  "Adobe ID": [],
  "Segment ID": [],
};

export const allTransformationOptions = Object.entries(
  transformationOptions,
).map(([value, option]) => ({
  value,
  ...option,
}));

export const transformationOptionsByIdentifier: Record<
  string,
  { value: Transformation; label: string; description?: string }[]
> = Object.fromEntries(
  Object.entries(transformationsByIdentifier).map(
    ([identifier, transformations]) => [
      identifier,
      transformations.map((transformation) => ({
        value: transformation,
        ...transformationOptions[transformation],
      })),
    ],
  ),
);

export type SupportedSource = "bigquery" | "snowflake" | "databricks";

export const operatorOptions: {
  value: Operator;
  label: string;
  sources?: SupportedSource[];
}[] = [
  {
    value: "eq",
    label: "Exact",
  },
  {
    value: "levenstein_90",
    label: "Levenshtein distance (90% match ratio)",
  },
  {
    value: "levenstein_70",
    label: "Levenshtein distance (70% match ratio)",
  },
  {
    value: "jaro_winkler_90",
    label: "Jaro-Winkler distance (90% confidence)",
    sources: ["bigquery", "snowflake"],
  },
  {
    value: "jaro_winkler_70",
    label: "Jaro-Winkler distance (70% confidence)",
    sources: ["bigquery", "snowflake"],
  },
  {
    value: "soundex",
    label: "Phonetic (Soundex)",
  },
];

export type LegacyStats = {
  events: {
    total: number;
    resolved: number;
    new: number;
  };
  profiles: {
    total: number;
    resolved: number;
    new: number;
  };
};

export type IdrStats = Stats & { isLegacy?: boolean | undefined };

export type {
  BlockRule,
  MergeRule,
  Operator,
  Transformation,
  StatsForModelType,
  ModelStats,
};
export { ColumnType };

export type GoldenRecordOutletContext = {
  onClose: () => void;
};

export type InspectorOutletContext = {
  onClose: () => void;
};
