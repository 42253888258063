import {
  Box,
  Column,
  FormField,
  IdentityIcon,
  MailIcon,
  Row,
  SearchInput,
  Heading,
  Select,
  SparkleIcon,
  Text,
} from "@hightouchio/ui";
import { useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { InsightsContext } from ".";
import { mockFeatures } from "src/pages/decision-engines/inspector/components/reasoning";
import { useState } from "react";
import { Table } from "src/ui/table";
import { PercentageBar } from "src/components/percentage-bar";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import useQueryState from "src/hooks/use-query-state";
import { shuffle } from "lodash";

const colors = [
  "var(--chakra-colors-electric-500)",
  "var(--chakra-colors-grass-500)",
  "var(--chakra-colors-teal-500)",
  "var(--chakra-colors-danger-500)",
  "var(--chakra-colors-warning-500)",
  "var(--chakra-colors-plum-500)",
];

export const InsightsPersonalizations = () => {
  return (
    <Column gap={6} p={6}>
      <Breakdown />
      <DecisionInsight />
    </Column>
  );
};

export const Breakdown = () => {
  const { flow } = useOutletContext<InsightsContext>();

  const size = flow.audience?.last_run_size ?? 1_000_000;

  const generateMockData = () => {
    return mockFeatures.map((feature) => {
      let remaining = size;
      return {
        name: feature.name,
        values: feature.values.map((label, index) => {
          const count =
            index === feature.values.length - 1
              ? remaining
              : Math.floor(Math.random() * remaining);
          remaining -= count;
          return { label, count };
        }),
      };
    });
  };

  const [mockData] = useState(generateMockData);

  return (
    <Card w="100%" gap={4}>
      <Row align="center" gap={4}>
        <Row
          align="center"
          justify="center"
          p={2}
          borderRadius="50%"
          bg="gray.100"
        >
          <Box as={IdentityIcon} boxSize={5} color="text.secondary" />
        </Row>
        <Heading>User attributes</Heading>
      </Row>
      <SearchInput
        value=""
        placeholder={`Search ${mockFeatures.length} attributes...`}
      />
      <Table
        data={mockData}
        columns={[
          {
            name: "Attribute",
            cell: (row) => (
              <TextWithTooltip fontWeight="medium">{row.name}</TextWithTooltip>
            ),
          },
          {
            name: "Breakdown",
            cell: (row) => (
              <Column gap={1} w="100%" py={2} minH="max-content">
                {row.values.map(({ label, count }, index) => {
                  const value = count / size;
                  return (
                    <Row key={label} justify="space-between" w="100%" gap={2}>
                      <Box w="80px">{label}</Box>
                      <PercentageBar
                        label={count.toString()}
                        value={value}
                        color={colors[index]}
                      />
                    </Row>
                  );
                })}
              </Column>
            ),
          },
        ]}
      />
    </Card>
  );
};

export const DecisionInsight = () => {
  const { flow } = useOutletContext<InsightsContext>();
  const [selectedFeature, setSelectedFeature] = useQueryState("a");
  const [selectedDecision, setSelectedDecision] = useQueryState("d", "message");

  const decisionOptions = [
    { label: "Message", value: "message", icon: MailIcon },
  ];

  return (
    <Card w="100%" gap={4}>
      <Row align="center" gap={4}>
        <Row
          align="center"
          justify="center"
          p={2}
          borderRadius="50%"
          bg="gray.100"
        >
          <Box as={SparkleIcon} boxSize={5} color="text.secondary" />
        </Row>
        <Heading>Personalization insights</Heading>
      </Row>
      <Row gap={4}>
        <Box as={FormField} label="User attribute" width="200px">
          <Select
            placeholder="All attributes..."
            options={mockFeatures}
            onChange={setSelectedFeature}
            value={selectedFeature ?? ""}
            optionLabel={(o) => o.name}
            optionValue={(o) => o.name}
            width="100%"
          />
        </Box>
        <Box as={FormField} label="Decision to analyze" width="200px">
          <Select
            value={selectedDecision}
            onChange={setSelectedDecision}
            options={decisionOptions}
            width="100%"
            optionAccessory={(option) => ({
              type: "icon",
              icon: option.icon,
            })}
          />
        </Box>
      </Row>
      <Table
        data={mockFeatures}
        columns={[
          {
            name: "Attribute",
            cell: (row) => (
              <Row align="center" gap={2} justify="space-between" w="100%">
                <Row align="center" gap={2}>
                  <Text fontWeight="medium">{row.name}</Text>
                  <Text>=</Text>
                  <Row
                    bg="gray.100"
                    p={1}
                    borderRadius="sm"
                    fontWeight="medium"
                  >
                    {row.values[1]}
                  </Row>
                </Row>
                <Text color="text.secondary">responds best to</Text>
              </Row>
            ),
          },
          {
            name: "Message",
            max: "max-content",
            cell: () => {
              return (
                <Text fontWeight="medium">
                  {shuffle(flow.messages)[0]?.message.name}
                </Text>
              );
            },
          },
          {
            name: "Confidence",
            cell: () => {
              const value = (60 + 40 * Math.random()) / 100;
              return (
                <PercentageBar
                  value={value}
                  color={value > 0.8 ? "avocado.500" : "warning.500"}
                />
              );
            },
          },
        ]}
      />
    </Card>
  );
};
