import { Alert, Button, FormField, Row, Select } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { Form, useHightouchForm } from "src/components/form";
import { useUpdateInteractionModelMutation } from "src/graphql";
import {
  INTERACTION_TYPE_OPTIONS,
  SMS_INTERACTION_OPTIONS,
} from "src/pages/schema/constants";
import { getParams } from "src/pages/schema/graph/utils";
import { SchemaField, SchemaFieldForm } from "src/pages/schema/types";
import { useNavigate } from "src/router";

export const InteractionForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateInteractionModel } =
    useUpdateInteractionModelMutation();

  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async ({ interaction_type }) => {
      await updateInteractionModel({
        id: object.id,
        set: { type: interaction_type },
      });
    },
    values: {
      interaction_type: object.event?.interaction?.type || null,
    },
  });

  return (
    <Form form={form}>
      <InteractionField
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
    </Form>
  );
};

export const InteractionField: SchemaField = (props) => {
  const { enableCampaignSms } = useFlags();

  return (
    <Controller
      name="interaction_type"
      render={({ field, fieldState: { error } }) => (
        <FormField label="Standard interaction type" error={error?.message}>
          <Select
            {...props}
            isInvalid={Boolean(error)}
            options={[
              ...INTERACTION_TYPE_OPTIONS,
              ...(enableCampaignSms ? SMS_INTERACTION_OPTIONS : []),
            ]}
            placeholder="Select an interaction type..."
            value={field.value}
            onChange={field.onChange}
          />
        </FormField>
      )}
    />
  );
};

export const MissingAssetModelWarning: FC<{ objectId: string }> = ({
  objectId,
}) => {
  const navigate = useNavigate();
  const { queryString } = getParams();

  return (
    <Alert
      variant="inline"
      type="error"
      title="Missing asset model"
      message="This interaction cannot be used until you connect a related asset. The related asset specifies information about the asset that the user interacted with."
      actions={
        <Row gap={2}>
          <Button
            onClick={() =>
              navigate(`/schema-v2/new${queryString}&id=${objectId}&type=asset`)
            }
          >
            Create a related asset
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/schema-v2/view/relationships/new${queryString}&id=${objectId}`,
              )
            }
          >
            Connect to an existing asset
          </Button>
        </Row>
      }
    />
  );
};
