import { FC, ReactNode } from "react";

import {
  Box as HightouchUiBox,
  ChevronRightIcon,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  Paragraph,
  Row as HightouchRow,
  SectionHeading,
} from "@hightouchio/ui";

interface Props {
  children: ReactNode;
  heading?: string;
  subheading?: string;
}

export const AccordionSection: FC<Readonly<Props>> = ({
  children,
  heading,
  subheading,
}) => (
  <ChakraAccordion allowToggle>
    <ChakraAccordionItem border="none">
      {({ isExpanded }) => {
        return (
          <HightouchUiBox>
            <ChakraAccordionButton
              cursor="pointer"
              outline="none !important"
              pl={2}
              borderRadius="md"
            >
              <HightouchUiBox>
                <HightouchRow>
                  <HightouchUiBox
                    as={ChevronRightIcon}
                    fontSize="18px"
                    transform={isExpanded ? "rotate(90deg)" : ""}
                    transition="transform 150ms ease-in-out"
                  />
                  <SectionHeading>{heading}</SectionHeading>
                </HightouchRow>
                {subheading && <Paragraph>{subheading}</Paragraph>}
              </HightouchUiBox>
            </ChakraAccordionButton>
            <ChakraAccordionPanel>{children}</ChakraAccordionPanel>
          </HightouchUiBox>
        );
      }}
    </ChakraAccordionItem>
  </ChakraAccordion>
);
