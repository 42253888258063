import {
  AndCondition,
  AndOrCondition,
  ConditionType,
  OrCondition,
} from "@hightouch/lib/query/visual/types";
import {
  Box,
  Button,
  ButtonGroup,
  Column,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { difference } from "lodash";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { useHightouchForm } from "src/components/form";
import {
  PermissionedButton,
  PermissionedEditableText,
} from "src/components/permission";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { useUser } from "src/contexts/user-context";
import {
  useCreateSubsetMutation,
  useMinimalSubsetCategoryQuery,
  useSubsetQuery,
  useUpdateSubsetMutation,
  useUpdateSubsetV2Mutation,
  useUpdateUserGroupSubsetGrantsMutation,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { useRowSelect } from "src/ui/table/use-row-select";
import { AccessGrants } from "./access-grants";
import { SubsetFilter } from "./subset-filter";
import { UserGroupGrants } from "./user-group-grants";
import { CategoryDrawerHeader } from "./category/category-drawer-header";

type FormValues = {
  name: string;
  conditions: AndOrCondition<AndCondition | OrCondition> | undefined;
  access_grants: number[];
};

const DEFAULT_SUBSET_CONDITIONS = {
  type: ConditionType.And,
  conditions: [],
};

export const Subset: FC = () => {
  const navigate = useNavigate();
  const { subset_category_id, subset_id } = useParams();
  const { user, workspace } = useUser();
  const { toast } = useToast();

  const permissionsV2Enabled = user?.permissions_v2_enabled ?? false;

  const { data: subsetData, isLoading: isLoadingSubset } = useSubsetQuery(
    { id: subset_id ?? "" },
    { enabled: subset_id != undefined },
  );
  const subset = subsetData?.audience_subset_values_by_pk;

  // If we're creating a new subset, fetch information we need to render the page correctly
  const { data: subsetGroupData, isLoading: isLoadingNewSubsetPageData } =
    useMinimalSubsetCategoryQuery(
      {
        id: subset_category_id ?? "",
      },

      { enabled: subset_id == undefined },
    );

  const subsetCategory =
    subset?.subset_group ?? subsetGroupData?.audience_subset_groups_by_pk;
  const parentModel = subsetCategory?.parent_model;

  // Hndlers for access grants table
  const { selectedRows: selectedUsers, onRowSelect: onUserSelect } =
    useRowSelect();

  const { hasValidationErrors } = useFormErrorContext();

  const createSubset = useCreateSubsetMutation();
  const updateSubsetGrants = useUpdateUserGroupSubsetGrantsMutation();
  const updateSubset = useUpdateSubsetMutation();
  const updateSubsetV2 = useUpdateSubsetV2Mutation();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    // data.access_grants is the original list of access grants, not the updated ones
    // use selectedUsers instead

    if (subset_id) {
      // Update subset

      // Diff the access grants, so we know who to remove and who to add
      const removedAccessGrants = difference(data.access_grants, selectedUsers);

      if (permissionsV2Enabled) {
        const grants = selectedUsers.map((u) => ({
          subset_id,
          user_group_id: String(u),
          can_draft: true,
          can_sync: true,
          workspace_id: workspace?.id,
        }));

        const removedGrants = removedAccessGrants.map((u) => ({
          subset_id,
          user_group_id: String(u),
          can_draft: false,
          can_sync: false,
          workspace_id: workspace?.id,
        }));
        await updateSubsetV2.mutateAsync({
          id: subset_id,
          input: {
            name: data.name,
            conditions: data.conditions,
          },
          upsert_subset_grants: [...grants, ...removedGrants],
        });
      } else {
        await updateSubset.mutateAsync({
          id: subset_id,
          input: {
            name: data.name,
            conditions: data.conditions,
          },
          remove_access_grants: removedAccessGrants.map((userId) => ({
            _and: [
              {
                subset_value_id: { _eq: subset_id },
                user_id: { _eq: userId.toString() },
              },
            ],
          })),
          upsert_access_grants: selectedUsers.map((userId) => ({
            subset_value_id: subset_id,
            user_id: userId.toString(),
          })),
        });
      }

      if (subset_id) {
        navigate(`../${subset_category_id}`);
      }
    } else {
      // Create subset
      const v1Grants = !permissionsV2Enabled
        ? {
            access_grants: {
              data: selectedUsers.map((userId) => ({
                user_id: userId.toString(),
              })),
            },
          }
        : {};
      // V2 grants are handled separately because there is a trigger that modifies the grants on creation
      const created = await createSubset.mutateAsync({
        input: {
          name: data.name,
          conditions: data.conditions,
          subset_group_id: subsetCategory?.id,
          ...v1Grants,
        },
      });

      if (permissionsV2Enabled) {
        const grants = selectedUsers.map((u) => ({
          subset_id: created.insert_audience_subset_values_one?.id,
          user_group_id: String(u),
          can_draft: true,
          can_sync: true,
          workspace_id: workspace?.id,
        }));

        await updateSubsetGrants.mutateAsync({
          objects: grants,
        });
      }

      navigate({
        pathname: `/governance/subset-categories/${subsetCategory?.id}/subsets/${created.insert_audience_subset_values_one?.id}`,
        search: window.location.search,
      });
    }
  };

  const form = useHightouchForm<FormValues>({
    onSubmit,
    success: subset_id
      ? "Successfully updated subset."
      : "Successfully created subset.",
    error: subset_id
      ? "Could update subset. Please try again."
      : "Could not create subset. Please try again.",
    values: {
      conditions: subset?.conditions ?? DEFAULT_SUBSET_CONDITIONS,
      name: subset?.name ?? "New subset",
      access_grants: permissionsV2Enabled
        ? subset?.user_group_subset_grants.map(
            ({ user_group }) => user_group?.id,
          ) ?? []
        : subset?.access_grants.map(({ user }) => user?.id) ?? [],
    },
  });

  const {
    control,
    formState: { isSubmitting },
    reset,
    setValue,
    submit,
    watch,
  } = form;

  const submitWithValidation = async () => {
    if (hasValidationErrors()) {
      toast({
        id: "filter-validation",
        title:
          "Filter has validation errors. Please check your conditions and try again.",
        variant: "error",
      });
      return;
    }

    await submit();
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const name = watch("name");
  const conditions = watch("conditions");
  const accessGrants = watch("access_grants");

  // Select existing user access grants
  useEffect(() => {
    onUserSelect(accessGrants);
  }, [onUserSelect, accessGrants]);

  const onClose = () => navigate("../");

  if (isLoadingNewSubsetPageData || isLoadingSubset) {
    return <PageSpinner />;
  }

  return (
    <>
      <CategoryDrawerHeader
        subsetCategoryId={subsetCategory?.id ?? ""}
        name={subsetCategory?.name ?? ""}
        description={subsetCategory?.description ?? ""}
        parentModel={parentModel}
        onClose={onClose}
      />

      <Column flexBasis="100%" flexGrow={1} overflow="auto">
        <Column gap={6} pt={6} px={6} height="100%">
          <Row align="center">
            <Row gap={2}>
              <Link
                href={`/governance/subset-categories/${subsetCategory?.id}`}
              >
                <Box
                  as={Text}
                  color="text.secondary"
                  _hover={{ color: "text.primary" }}
                >
                  {subsetCategory?.name}
                </Box>
              </Link>
              <Text fontWeight="medium" color="text.secondary">
                /
              </Text>
              <Text color="text.primary" fontWeight="medium">
                {name}
              </Text>
            </Row>
          </Row>

          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <PermissionedEditableText
                permission={{
                  v1: { resource: "audience_schema", grant: "update" },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: parentModel?.id,
                  },
                }}
                size="lg"
                fontWeight="medium"
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />

          <SubsetFilter
            parentModel={parentModel}
            conditions={conditions}
            setConditions={(c) => {
              if (c) {
                setValue("conditions", c);
              }
            }}
          />

          {permissionsV2Enabled ? (
            <UserGroupGrants
              parentModelId={parentModel?.id?.toString()}
              selectedRows={selectedUsers}
              onRowSelect={onUserSelect}
            />
          ) : (
            <AccessGrants
              selectedRows={selectedUsers}
              onRowSelect={onUserSelect}
            />
          )}
        </Column>
      </Column>

      <ActionBar fit>
        <ButtonGroup>
          <PermissionedButton
            permission={{
              v1: { resource: "audience_schema", grant: "update" },
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModel?.id,
              },
            }}
            onClick={submitWithValidation}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            size="lg"
            variant="primary"
          >
            {subset_id ? "Save subset" : "Create subset"}
          </PermissionedButton>

          <Button
            isDisabled={isSubmitting}
            size="lg"
            onClick={() => {
              if (subset_id) {
                reset();
              } else {
                onClose();
              }
            }}
          >
            {subset_id ? "Discard changes" : "Cancel"}
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};
