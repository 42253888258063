import { FC, useState } from "react";

import {
  Box,
  Button,
  Column,
  FormField,
  Menu,
  MenuButton,
  MenuList,
  Row,
  SectionHeading,
  SuccessIcon,
  Text,
  useToast,
  Dialog,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { Controller, useForm } from "react-hook-form";
import { Outlet, useOutletContext } from "src/router";

import { alertTypes } from "src/components/alerts";
import { Alert, isSuccessAlertType } from "src/components/alerts/types";
import { Card } from "src/components/card";
import alertingImage from "src/components/extensions/assets/alerting.png";
import { Overview } from "src/components/extensions/overview";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  AlertingCredentialsQuery,
  WorkspaceAlertsWithStatusesQuery,
  useAlertingCredentialsQuery,
  useCreatePagerDutyCredentialsMutation,
  useDeleteWorkspaceAlertsMutation,
  useWorkspaceAlertsWithStatusesQuery,
} from "src/graphql";
import { Circle } from "src/ui/circle";
import { AlertingIcon, PagerDutyIcon, SlackIcon } from "src/ui/icons";
import { PageSpinner } from "src/components/loading";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { formatDatetime } from "src/utils/time";

import {
  PermissionedButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { SecretInput } from "src/components/secret-input";
import { CreateAlert, EditAlert } from "src/pages/settings/alerts";
import { RouteTabs } from "src/components/route-tabs";

export const Alerting: FC = () => {
  const { data: credentialsData, isLoading: credentialsLoading } =
    useAlertingCredentialsQuery();
  const { data: alerts, isLoading: alertsLoading } =
    useWorkspaceAlertsWithStatusesQuery(undefined, {
      select: (data) => data.alerts,
    });

  const slackCredentials = credentialsData?.slack_credentials?.[0];
  const pagerDutyCredentials = credentialsData?.pagerduty_credentials?.[0];

  return (
    <Page
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Alerting - Extensions"
    >
      <RouteTabs
        depth={4}
        tabs={[
          { title: "Overview", path: "" },
          { title: "Configuration", path: "configuration" },
        ]}
      />
      <Box mt={8}>
        <Outlet
          context={{
            loading: alertsLoading || credentialsLoading,
            alerts,
            pagerDutyCredentials: pagerDutyCredentials,
            slackCredentials: slackCredentials,
          }}
        />
      </Box>
    </Page>
  );
};

export const AlertingOverview = () => {
  return (
    <Overview
      description="Hightouch syncs are mission critical to your business. Create alerts to inform you immediately about expired credentials, API errors, and other sync issues. Alerts can be sent via Slack, PagerDuty, email, or text message."
      icon={AlertingIcon}
      image={alertingImage}
      integrations={[
        { name: "Slack", icon: SlackIcon },
        { name: "PagerDuty", icon: PagerDutyIcon },
      ]}
      subtitle="Get notified about sync errors"
      title="Alerting"
    />
  );
};

type OutletContext = {
  loading: boolean;
  slackCredentials: AlertingCredentialsQuery["slack_credentials"][0];
  pagerDutyCredentials: AlertingCredentialsQuery["pagerduty_credentials"][0];
  alerts: WorkspaceAlertsWithStatusesQuery["alerts"];
};

enum Modals {
  Delete = "delete",
  Slack = "slack",
  PagerDuty = "pagerduty",
  EditAlert = "edit-alert",
  CreateAlert = "create-alert",
}

export const AlertingConfiguration: FC = () => {
  const { toast } = useToast();
  const { slackCredentials, pagerDutyCredentials, alerts, loading } =
    useOutletContext<OutletContext>();
  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const [modal, setModal] = useState<Modals | undefined>();
  const [selectedAlert, setSelectedAlert] = useState<Alert | undefined>();
  const { selectedRows, onRowSelect } = useRowSelect();

  const { mutateAsync: bulkDelete } = useDeleteWorkspaceAlertsMutation();

  const bulkDeleteAlerts = async () => {
    const count = selectedRows.length;
    const pluralizedLabel = pluralize("alert", count);

    try {
      await bulkDelete({ ids: selectedRows?.map(String) });

      toast({
        id: "bulk-delete-alerts",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-alerts",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const closeModal = () => setModal(undefined);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "workspace",
          grant: "can_update",
        },
      }}
    >
      <Column mb={8}>
        <SectionHeading mb={4}>Credentials</SectionHeading>
        <Box display="grid" gridTemplateColumns="350px 350px" gap={6}>
          <Card
            footer={
              hasUpdatePermission ? (
                <Text color="link.default" ml="auto">
                  {slackCredentials ? "Manage" : "Set up"}
                </Text>
              ) : undefined
            }
            onClick={
              hasUpdatePermission ? () => setModal(Modals.Slack) : undefined
            }
          >
            <Row justify="space-between">
              <Column>
                <Row align="center" mb={4}>
                  <SlackIcon size={24} />
                  <Text fontWeight="semibold" size="lg" ml={2}>
                    Slack
                  </Text>
                </Row>

                <Text color="text.secondary">
                  Send messages to a Slack channel
                </Text>
              </Column>

              <Column fontSize="20px">
                <SuccessIcon
                  color={slackCredentials ? "success.base" : "gray.base"}
                />
              </Column>
            </Row>
          </Card>

          <Card
            footer={
              hasUpdatePermission ? (
                <Text color="link.default" ml="auto">
                  {pagerDutyCredentials ? "Manage" : "Set up"}
                </Text>
              ) : undefined
            }
            onClick={
              hasUpdatePermission ? () => setModal(Modals.PagerDuty) : undefined
            }
          >
            <Row justify="space-between">
              <Column>
                <Row align="center" mb={4}>
                  <PagerDutyIcon />
                  <Text fontWeight="semibold" size="lg" ml={2}>
                    PagerDuty
                  </Text>
                </Row>
                <Text color="text.secondary">Send events to PagerDuty</Text>
              </Column>

              <Column fontSize="20px">
                <SuccessIcon
                  color={pagerDutyCredentials ? "success.base" : "gray.base"}
                />
              </Column>
            </Row>
          </Card>
        </Box>
      </Column>

      <Column>
        <Row justify="space-between" align="center" mb={4} mt={6}>
          <SectionHeading>Alerts</SectionHeading>

          <Row gap={4}>
            {selectedRows.length > 0 && (
              <Menu>
                <MenuButton>Actions</MenuButton>
                <MenuList>
                  <PermissionedMenuItem
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    variant="danger"
                    onClick={() => setModal(Modals.Delete)}
                  >
                    Delete
                  </PermissionedMenuItem>
                </MenuList>
              </Menu>
            )}
            <PermissionedButton
              variant="primary"
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              onClick={() => setModal(Modals.CreateAlert)}
            >
              Add alert
            </PermissionedButton>
          </Row>
        </Row>

        <Table
          columns={[
            {
              name: "Name",
              cell: ({ name }) => name,
            },
            {
              name: "Type",
              max: "max-content",
              cell: ({ type }) => (
                <Row align="center" gap={2}>
                  <IntegrationIcon
                    name={alertTypes[type].name}
                    src={alertTypes[type].icon}
                  />
                  <Text>{alertTypes[type].name}</Text>
                </Row>
              ),
            },
            {
              name: "Default (Fatal)",
              cell: ({ fatal_error_default }) =>
                fatal_error_default ? "On" : "Off",
            },
            {
              name: "Default (Row)",
              cell: ({ row_error_default }) =>
                row_error_default ? "On" : "Off",
            },
            {
              name: "Default (Success)",
              cell: ({ on_success_default, type }) =>
                !isSuccessAlertType(type)
                  ? "N/A"
                  : on_success_default
                    ? "On"
                    : "Off",
            },
            {
              name: "Last alerted",
              cell: ({ alert_statuses }) =>
                alert_statuses?.[0]?.last_attempted
                  ? formatDatetime(alert_statuses?.[0]?.last_attempted)
                  : "Never",
            },
          ]}
          data={alerts}
          placeholder={{
            title: "No alerts",
            error: "Alerts failed to load, please try again.",
          }}
          selectedRows={selectedRows}
          onRowClick={
            hasUpdatePermission
              ? (row) => {
                  setSelectedAlert(row as any);
                  setModal(Modals.EditAlert);
                }
              : undefined
          }
          onSelect={onRowSelect}
        />
      </Column>

      {modal === Modals.EditAlert && selectedAlert && (
        <EditAlert
          alert={selectedAlert}
          availableCredentials={{
            pagerduty_credentials: [pagerDutyCredentials],
            slack_credentials: [slackCredentials],
          }}
          onClose={() => {
            setSelectedAlert(undefined);
            closeModal();
          }}
        />
      )}
      {modal === Modals.CreateAlert && (
        <CreateAlert
          availableCredentials={{
            pagerduty_credentials: [pagerDutyCredentials],
            slack_credentials: [slackCredentials],
          }}
          onClose={closeModal}
        />
      )}
      {modal === Modals.Slack && <SlackCredentialsForm onClose={closeModal} />}
      {modal === Modals.PagerDuty && (
        <PagerDutyCredentialsForm onClose={closeModal} />
      )}

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={modal === Modals.Delete}
        label="alert"
        onClose={() => setModal(undefined)}
        onDelete={bulkDeleteAlerts}
      />
    </PermissionProvider>
  );
};

export const PagerDutyCredentialsForm: FC<
  Readonly<{ onClose: () => void }>
> = ({ onClose }) => {
  const { pagerDutyCredentials } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const { mutateAsync: create, isLoading: loadingCreate } =
    useCreatePagerDutyCredentialsMutation();
  const { control, handleSubmit } = useForm({ defaultValues: { api_key: "" } });

  const saving = loadingCreate;

  const submit = async (data) => {
    try {
      await create({
        api_key: data.api_key,
      });

      toast({
        id: "save-pagerduty-config",
        title: "PagerDuty configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "save-pagerduty-config",
        title: "Couldn't save your PagerDuty configuration",
        variant: "error",
      });

      Sentry.captureException(e);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      isOpen
      variant="form"
      width="lg"
      title="Configure PagerDuty"
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            isLoading={saving}
            onClick={handleSubmit(submit)}
          >
            Save
          </Button>
        </>
      }
      onClose={onClose}
    >
      {pagerDutyCredentials && (
        <Row align="center" mb={4}>
          <Circle bg="success.base" radius="10px" mr={2} />
          <Text fontWeight="semibold">Your PagerDuty account is connected</Text>
        </Row>
      )}
      <Row mb={6}>
        <Text color="text.secondary">
          Visit our{" "}
          <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/pagerduty/`}>
            docs
          </Link>{" "}
          for instructions.
        </Text>
      </Row>
      <FormField label="PagerDuty API key">
        <Controller
          control={control}
          name="api_key"
          render={({ field }) => (
            <SecretInput
              isHidden={Boolean(pagerDutyCredentials?.id)}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FormField>
    </Dialog>
  );
};

export const SlackCredentialsForm: FC<Readonly<{ onClose: () => void }>> = ({
  onClose,
}) => {
  const { slackCredentials } = useOutletContext<OutletContext>();

  return (
    <Dialog
      isOpen
      variant="form"
      width="lg"
      title="Configure Slack"
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => {
              window.location.href = `${
                import.meta.env.VITE_API_BASE_URL
              }/slack-alerts/oauth`;
            }}
          >
            {slackCredentials ? `Re-authorize Slack` : `Authorize Slack`}
          </Button>
        </>
      }
      onClose={onClose}
    >
      {slackCredentials && (
        <Row align="center" mb={4}>
          <Circle bg="success.base" radius="10px" mr={2} />
          <Text fontWeight="semibold">Your Slack account is connected</Text>
        </Row>
      )}
      <Row mb={6}>
        <Text color="text.secondary">
          Authenticate Hightouch to Slack to alert your team when a sync
          experiences an error.
        </Text>
      </Row>
    </Dialog>
  );
};
