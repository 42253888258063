import { FC, useMemo } from "react";

import {
  Column,
  Row,
  Tooltip,
  Text,
  Box,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Portal,
} from "@hightouchio/ui";
import moment from "moment";
import pluralize from "pluralize";
import { UnreachableCaseError } from "ts-essentials";

import { Circle } from "src/ui/circle";
import { WEEK_DAYS } from "src/utils/constants";

import {
  Schedule as ScheduleObject,
  ScheduleType,
  ScheduleExpression,
  ScheduleInterval,
} from "./types";
import { UTCDayToLocalDay } from "./visual-cron-expression";

type ScheduleProps = {
  schedule: ScheduleObject | undefined;
};

export const Schedule: FC<Readonly<ScheduleProps>> = ({ schedule }) => {
  if (!schedule) {
    return <Manual />;
  }

  const type = schedule.type;
  switch (type) {
    case ScheduleType.DBT_CLOUD:
      return (
        <DBT
          account={schedule.schedule?.account?.name}
          job={schedule.schedule?.job?.name}
        />
      );
    case ScheduleType.INTERVAL:
      return <Interval interval={schedule.schedule?.interval} />;
    case ScheduleType.CRON:
      return <Cron expression={schedule.schedule?.expression} />;
    case ScheduleType.CUSTOM:
      return <VisualCron expressions={schedule.schedule?.expressions ?? []} />;
    case ScheduleType.MANUAL:
      return <Manual />;
    case ScheduleType.FIVETRAN:
      return <Text fontWeight="medium">Fivetran</Text>;
    case ScheduleType.STREAMING:
      return <Text fontWeight="medium">Streaming</Text>;
    case ScheduleType.MATCH_BOOSTER:
      return <Text fontWeight="medium">Match Booster</Text>;
    case ScheduleType.JOURNEY_TRIGGERED:
      return <Text fontWeight="medium">Journey-Triggered</Text>;
    default:
      throw new UnreachableCaseError(type);
  }
};

interface VisualCronProps {
  expressions: ScheduleExpression[];
  popoverTriggerFlexDir?: "row" | "column";
}

export const VisualCron: FC<VisualCronProps> = ({
  expressions,
  popoverTriggerFlexDir = "row",
}) => {
  const expression = expressions[0];

  // Unexpected, but possible given the types so handle it gracefully
  if (!expression) {
    return null;
  }

  if (expressions.length === 1) {
    return <VisualCronExpression expression={expression} />;
  }

  return (
    <Row gap={2} justifyContent="center" flexDir={popoverTriggerFlexDir}>
      <VisualCronExpression expression={expression} />
      <ChakraPopover placement="bottom" closeOnBlur={true} isLazy>
        <ChakraPopoverTrigger>
          <Box color="primary.base" cursor="pointer" fontWeight="medium">
            +{expressions.length - 1} more
          </Box>
        </ChakraPopoverTrigger>
        <Portal>
          <Box
            sx={{
              "& .chakra-popover__popper": {
                zIndex: "popover",
              },
            }}
          >
            <ChakraPopoverContent width="max-content">
              <Column gap={2} p={3}>
                {expressions.slice(1).map((expression, index) => (
                  <VisualCronExpression expression={expression} key={index} />
                ))}
              </Column>
            </ChakraPopoverContent>
          </Box>
        </Portal>
      </ChakraPopover>
    </Row>
  );
};

interface VisualCronExpressionProps {
  expression: ScheduleExpression;
}

const VisualCronExpression: FC<VisualCronExpressionProps> = ({
  expression,
}) => {
  const selectedDays = useMemo(() => {
    return Object.keys(expression?.days ?? {}).map((day) => {
      return UTCDayToLocalDay(
        expression.time,
        Number(moment(day, "dddd").format("e")),
      );
    });
  }, [expression]);

  if (Object.keys(expression?.days || {}).length === 7) {
    return (
      <Text>
        Every day at{" "}
        {moment
          .utc(expression?.time, "HH:mm")
          .local()
          .format("h:mm A")}
      </Text>
    );
  }

  return (
    <Row gap={1} align="center">
      {WEEK_DAYS.map((day, index) => {
        const isSelected = selectedDays.includes(index);

        return (
          <Tooltip key={index} message={day}>
            <Circle
              radius="20px"
              bg={isSelected ? "forest.200" : undefined}
              border="1px"
              borderColor={isSelected ? "forest.400" : "gray.200"}
              color={isSelected ? "forest.800" : "gray.900"}
            >
              <Text size="sm" color="inherit" fontWeight="medium">
                {day[0]}
              </Text>
            </Circle>
          </Tooltip>
        );
      })}

      <Text ml={1} color="text.secondary">
        at{" "}
        {moment
          .utc(expression?.time, "HH:mm")
          .local()
          .format("h:mm A")}
      </Text>
    </Row>
  );
};

interface CronProps {
  expression: string | undefined;
}

export const Cron: FC<CronProps> = ({ expression }) => (
  <Text fontWeight="medium">{expression}</Text>
);

interface IntervalProps {
  interval: ScheduleInterval | undefined;
}

export const Interval: FC<IntervalProps> = ({ interval }) => {
  if (interval?.unit != null && interval.quantity != null) {
    return (
      <Text fontWeight="medium">
        Every {pluralize(interval?.unit, interval?.quantity, true)}
      </Text>
    );
  }

  return null;
};

export const Manual: FC = () => <Text fontWeight="medium">Manual</Text>;

interface DbtProps {
  job: string | undefined;
  account: string | undefined;
}

export const DBT: FC<DbtProps> = ({ job, account }) => (
  <Text fontWeight="medium">
    <strong>dbt Cloud</strong> ({account} - {job})
  </Text>
);
