import { FC, useEffect, useRef, useState } from "react";
import { Button, TextInput, Box, Textarea } from "@hightouchio/ui";

type Props = {
  isDisabled?: boolean;
  isHidden?: boolean;
  multiline?: boolean;
  placeholder?: string;
  value: string;
  onChange(value: string | null): void;
};

export const SecretInput: FC<Readonly<Props>> = ({
  onChange,
  value,
  multiline,
  isHidden,
  isDisabled,
  placeholder,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const maskValue = isHidden && !isEditing;
  const displayValue = maskValue ? "•".repeat(12) : value ?? "";

  const disabled = isDisabled || maskValue;

  useEffect(() => {
    if (isEditing) {
      const inputElement = container.current?.querySelector(
        "textarea, input",
      ) as HTMLElement | undefined;
      inputElement?.focus();
    }
  }, [isEditing]);

  return (
    <Box ref={container} display="flex" gap={3}>
      {multiline ? (
        <Textarea
          isDisabled={disabled}
          resize="vertical"
          placeholder={placeholder}
          value={displayValue}
          onChange={(event) => {
            onChange(event.currentTarget.value);
          }}
        />
      ) : (
        <TextInput
          isDisabled={disabled}
          type="password"
          placeholder={placeholder}
          value={displayValue}
          onChange={(event) => onChange(event.currentTarget.value)}
        />
      )}

      {isHidden && !isDisabled && (
        <Box
          alignItems="center"
          display="flex"
          flex="none"
          opacity={isEditing ? 0 : 1}
          pointerEvents={isEditing ? "none" : undefined}
        >
          <Button
            onClick={() => {
              if (multiline) {
                onChange("");
              } else {
                onChange(null);
              }
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        </Box>
      )}
    </Box>
  );
};
