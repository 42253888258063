import { ColumnProps } from "@hightouchio/ui";
import differenceBy from "lodash/differenceBy";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import partition from "lodash/partition";
import pick from "lodash/pick";

import { AudienceQuery, AudienceSplitsInsertInput } from "src/graphql";
import { AudienceSplit } from "src/types/visual";

export const transformSplitGroups = (
  audienceSplits: NonNullable<AudienceQuery["segments_by_pk"]>["splits"],
): AudienceSplit[] => {
  return audienceSplits.map((split) => ({
    id: split.id,
    friendly_name: split.friendly_name,
    percentage: split.percentage,
    destination_instance_ids: split.destination_instance_splits.map(
      ({ destination_instance_id }) => Number(destination_instance_id),
    ),
    is_holdout_group: split.is_holdout_group,
  }));
};

export const UNHIGHLIGHTED_OPACITY = 0.6;

export const sharedNodeStyles = (isHighlighted: boolean): ColumnProps => ({
  display: "flex",
  background: "white",
  border: "1px solid",
  borderColor: "base.border",
  borderRadius: "md",
  boxShadow: "xs",
  justifyContent: "space-between",
  opacity: isHighlighted ? 1 : UNHIGHLIGHTED_OPACITY,
  position: "relative",
  _hover: {
    boxShadow: "sm",
  },
});

export const constructUpdateSplitsMutationPayload = ({
  audienceId,
  oldSplits = [],
  newSplits = [],
}: {
  audienceId: string | number;
  oldSplits: AudienceSplit[];
  newSplits: AudienceSplit[];
}) => {
  // Partition splits by objects with and without an 'id' property
  const [existingSplits, addedSplits] = partition(newSplits, "id");

  const addSplitsPayload: AudienceSplitsInsertInput[] = addedSplits
    // a percentage of 0 would only be possible for a holdout group. But that means it's disabled so we should never create it.
    .filter((split: AudienceSplit) => split.percentage > 0)
    .map((split: AudienceSplit) => ({
      segment_id: String(audienceId),
      percentage: split.percentage,
      friendly_name: split.friendly_name,
      column_value: split.friendly_name,
      is_holdout_group: split.is_holdout_group,
      destination_instance_splits: {
        data: (split.destination_instance_ids ?? []).map(
          (destinationInstanceId) => ({
            destination_instance_id: String(destinationInstanceId),
          }),
        ),
      },
    }));

  // get IDs that exist in `oldSplits`, but not in `newSplits`
  const removeSplitsPayload = differenceBy(oldSplits, newSplits, "id").map(
    (split) => String(split.id),
  );

  // do deep equality comparisons between objects to see which splits have changed
  const diffableKeysForUpdate = [
    "friendly_name",
    "percentage",
    "is_holdout_group",
    "destination_instance_ids",
  ];
  const updatedSplits = differenceWith(existingSplits, oldSplits, (a, b) =>
    isEqual(pick(a, diffableKeysForUpdate), pick(b, diffableKeysForUpdate)),
  );

  // Check if a holdout group is part of the updated splits updated and its percentage is 0. That means it is now disabled, so we should remove the split entirely.
  const disabledHoldoutGroup = updatedSplits.find(
    (split) => split.is_holdout_group && split.percentage === 0,
  );
  if (disabledHoldoutGroup) {
    removeSplitsPayload.push(String(disabledHoldoutGroup.id));
  }

  const updateSplitsPayload = updatedSplits.map((split) => ({
    where: { id: { _eq: split.id } },
    _set: {
      // Note that we intentionally don't update `destination_instances` here since Hasura doesn't support nested relation updates.
      // Updating that field is done further below.
      percentage: split.percentage,
      friendly_name: split.friendly_name,
      column_value: split.friendly_name,
    },
  }));

  // In cases where destination instances change, we need to update the destination_instance_splits table (join table between destination_instances and audience_splits)
  // However, Hasura doesn't support updating nested relations (like you can on insertions)
  // Therefore, we'll just remove the existing rows (by filtering on split ID) and re-insert the rows to handle updates
  const updatedSplitsIds = updatedSplits.map((split) => String(split.id));

  const addDestinationInstanceSplitsPayload = updatedSplits.flatMap((split) =>
    split.destination_instance_ids.map((destinationInstanceId) => ({
      destination_instance_id: String(destinationInstanceId),
      split_id: split.id,
    })),
  );

  return {
    addSplitsPayload,
    removeSplitsPayload,
    updateSplitsPayload,
    updatedSplitsIds,
    addDestinationInstanceSplitsPayload,
  };
};

export const HOLDOUT_GROUP_SYNC_ID = -1;

export const getSplitGroupNodeId = (split: AudienceSplit) =>
  `split-${split.id}-${split.friendly_name}`;

export const getSyncNodeId = (syncId: number) => `sync-${syncId}`;

export const getSyncIdsForSplit = (split: AudienceSplit) => {
  if (split.is_holdout_group && split.percentage > 0) {
    return [HOLDOUT_GROUP_SYNC_ID];
  } else {
    return split.destination_instance_ids;
  }
};

export const isSplitGroupEnabled = (split: AudienceSplit) => {
  if (split.is_holdout_group && split.percentage === 0) {
    return false;
  }

  return true;
};
