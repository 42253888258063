import {
  FC,
  useEffect,
  useMemo,
  useState,
  MouseEventHandler,
  MouseEvent as ReactMouseEvent,
} from "react";

import {
  Box,
  Column,
  IconButton,
  Row,
  Tooltip as HightouchUiTooltip,
  Switch,
  Text,
  CloseIcon,
  Portal,
  ChakraListItem,
  Select,
  InformationIcon,
} from "@hightouchio/ui";
import capitalize from "lodash/capitalize";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { abbreviateNumber } from "src/utils/numbers";

import { BreakdownChartViews, HexColors } from "./constants";
import { ChartType } from "./audience-breakdowns";

const axisWidth = 120;
const horizontalPadding = 4;

const getVisibleValue = (value: unknown) => {
  if (value === null) {
    return "null";
  }

  if (value === "") {
    return "<empty string>";
  }

  // Cast to a string in case the value is a boolean
  // so we can display it as a label on the graph
  return String(value);
};

const valueFormatter = (
  value: number | string,
  formatKey: "percentage" | "count",
): string => {
  if (formatKey === "percentage" && typeof value === "number") {
    return `${(value * 100).toFixed(1)}%`;
  } else if (typeof value === "number") {
    return abbreviateNumber(value);
  }

  if (value.length > 11) {
    return value.slice(0, 11) + "...";
  }

  return value;
};

type Props = {
  color?: string;
  data: { value: string; count: number; percentage?: number }[];
  graphName: string;
  graphWidth: number;
  hoverColor?: string;
  isActive?: boolean;
  isAnimationActive?: boolean;
  secondaryAxisLabel?: string;
  onMouseEnterCell?: MouseEventHandler<HTMLDivElement>;
  onMouseLeaveCell?: MouseEventHandler<HTMLDivElement>;
  onRemoveGraph: (graphName: string) => void;
};

export const BreakdownGraph: FC<Props> = ({
  color = HexColors[0]?.base ?? "#E7ECFE",
  data,
  graphName,
  graphWidth: _graphWidth,
  hoverColor = HexColors[0]?.hover ?? "#728ff9",
  isActive,
  secondaryAxisLabel,
  onMouseEnterCell,
  onMouseLeaveCell,
  onRemoveGraph,
}) => {
  const [animate, setAnimate] = useState(true);
  const [activeCell, setActiveCell] = useState<number | null>(null);
  const [showAllData, setShowAllData] = useState(false);
  const [dataFormat, setDataFormat] = useState<"count" | "percentage">("count");
  const [layout, setLayout] = useState<"vertical" | "horizontal">("vertical");
  const [chartType, setChartType] = useState<ChartType>("bar");

  const formattedData = useMemo(
    () =>
      data.map(({ value, ...rest }) => ({
        ...rest,
        value: getVisibleValue(value),
      })),
    [data],
  );

  const filteredData = formattedData.filter(
    ({ value }) => showAllData || value !== "Other",
  );
  const hasOtherColumn = useMemo(
    () => formattedData.some(({ value }) => value === "Other"),
    [data],
  );

  const graphWidth = _graphWidth - horizontalPadding * 4 * 2; // Remove horizontal padding of <li> elements

  const enterCell = (
    event: ReactMouseEvent<HTMLDivElement>,
    cellIndex: number,
  ) => {
    setAnimate(false);
    setActiveCell(cellIndex);
    setAnimate(false);
    onMouseEnterCell?.(event);
  };

  const leaveCell = (event: ReactMouseEvent<HTMLDivElement>) => {
    setActiveCell(null);
    onMouseEnterCell?.(event);
  };

  const showTop10Label =
    !showAllData && chartType !== "table" && hasOtherColumn;

  const valueAxisName = (
    <>
      {capitalize(graphName)}
      {showTop10Label && (
        <Box as={Text} display="inline-block" color="gray.500" ml={1}>
          (top 10)
        </Box>
      )}
    </>
  );

  const secondaryAxisName = (
    <>
      {capitalize(secondaryAxisLabel)}
      <Box as={Text} display="inline-block" color="gray.500" ml={1}>
        {dataFormat === "percentage" ? " (percentage of total)" : ""}
      </Box>
    </>
  );

  const columns: TableColumn[] = [
    {
      name: capitalize(graphName),
      cell: ({ value }) => (
        <TextWithTooltip message={value}>{value}</TextWithTooltip>
      ),
    },
    {
      name: "Count",
      cell: ({ count }) => (
        <TextWithTooltip message={count}>{count}</TextWithTooltip>
      ),
    },
  ];

  const hasFilters = hasOtherColumn || chartType === "bar";

  const showSecondaryAxisName =
    (showTop10Label && layout === "vertical") ||
    (dataFormat === "percentage" && layout === "horizontal");

  return (
    <ChakraListItem mb={6}>
      <Column>
        <Row justify="space-between">
          <TextWithTooltip fontWeight="medium" message={graphName} size="lg">
            {capitalize(graphName)}
          </TextWithTooltip>

          <Row gap={4}>
            <Select
              options={BreakdownChartViews}
              value={chartType}
              width="auto"
              onChange={(value) => {
                if (!value) {
                  return;
                }

                setChartType(value);
              }}
            />

            <IconButton
              aria-label="Remove breakdown"
              icon={CloseIcon}
              ml="auto"
              onClick={() => onRemoveGraph(graphName)}
            />
          </Row>
        </Row>
        {showSecondaryAxisName && (
          <TextWithTooltip color="text.secondary" mb={2}>
            {showTop10Label && layout === "vertical"
              ? "(top 10)"
              : dataFormat === "percentage"
                ? "(percentage of total)"
                : ""}
          </TextWithTooltip>
        )}
      </Column>

      <Column
        width={chartType === "table" ? "100%" : graphWidth}
        sx={{
          ":not(:last-child)": { mb: 8 },
        }}
        onMouseLeave={onMouseLeaveCell}
      >
        <Column>
          {chartType === "pie" && (
            <PieChart
              key={`graphName-${showAllData.toString()}-pie`}
              height={230}
              width={graphWidth}
            >
              <Pie
                animationBegin={0}
                animationDuration={600}
                data={filteredData}
                dataKey="count"
                fill={color}
                isAnimationActive={animate}
                nameKey="value"
                onMouseEnter={enterCell}
                onMouseLeave={leaveCell}
              >
                {filteredData.map((_, cellIndex) => (
                  <Cell
                    key={`cell-${cellIndex}`}
                    fill={
                      isActive && activeCell === cellIndex
                        ? HexColors[cellIndex % HexColors.length]!.hover
                        : HexColors[cellIndex % HexColors.length]!.base
                    }
                  />
                ))}
                <LabelList
                  dataKey="value"
                  fill="#6d6d6d"
                  formatter={(value) => valueFormatter(value, "count")}
                  position="outside"
                  stroke="transparent"
                />
              </Pie>
              <Tooltip content={<TooltipContent />} isAnimationActive={false} />
            </PieChart>
          )}
          {chartType === "bar" && (
            <>
              <BarChart
                key={`graphName-${showAllData.toString()}-bar`}
                data={filteredData}
                height={230}
                layout={layout}
                margin={{ right: 50 }}
                width={graphWidth}
              >
                {layout === "vertical" && (
                  <>
                    <XAxis
                      allowDecimals={dataFormat === "percentage"}
                      dataKey={dataFormat}
                      tickFormatter={(value) =>
                        valueFormatter(value, dataFormat)
                      }
                      padding={{ left: 0.5 }}
                      type="number"
                    />
                    <YAxis
                      dataKey="value"
                      interval={0}
                      label={{
                        textOverflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      offset={100}
                      tickFormatter={(value) => valueFormatter(value, "count")}
                      type="category"
                      width={axisWidth}
                    />
                  </>
                )}
                {layout === "horizontal" && (
                  <>
                    <XAxis
                      angle={-40}
                      dataKey="value"
                      height={70}
                      interval={0}
                      textAnchor="end"
                      tickFormatter={(value) => valueFormatter(value, "count")}
                    />
                    <YAxis
                      allowDecimals={dataFormat === "percentage"}
                      dataKey={dataFormat}
                      padding={{ bottom: 0.5 }}
                      tickFormatter={(value) =>
                        valueFormatter(value, dataFormat)
                      }
                      width={axisWidth}
                    />
                  </>
                )}
                <Tooltip
                  content={<TooltipContent />}
                  cursor={{ fill: "transparent" }}
                  isAnimationActive={false}
                />
                <Bar
                  dataKey={dataFormat}
                  fill={color}
                  isAnimationActive={animate}
                  onMouseEnter={enterCell}
                  onMouseLeave={leaveCell}
                >
                  {filteredData.map((_, cellIndex) => (
                    <Cell
                      key={`cell-${cellIndex}`}
                      fill={
                        isActive && activeCell === cellIndex
                          ? hoverColor
                          : color
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
              <Row
                ml={`${axisWidth}px`}
                justifyContent="center"
                sx={{ span: { textAlign: "center" } }}
              >
                <Text mb={2} fontWeight="medium" overflowWrap="break-word">
                  {layout === "vertical" ? secondaryAxisName : valueAxisName}
                </Text>
              </Row>
            </>
          )}
          {chartType === "table" && (
            <Row mb={8} mt={4}>
              <Table
                backgroundColor="transparent"
                columns={columns}
                data={data}
              />
            </Row>
          )}
        </Column>
        {chartType !== "table" && hasFilters && (
          <Column gap={2}>
            {hasOtherColumn && (
              <Row
                sx={{
                  label: {
                    display: "flex",
                    gap: 2,
                    cursor: "pointer",
                  },
                }}
              >
                <Text as="label" fontWeight="medium">
                  <Switch
                    isChecked={showAllData}
                    onChange={(value) => {
                      setAnimate(true);
                      setShowAllData(value);
                    }}
                  />
                  Show all data
                  <HightouchUiTooltip message="Display the number of values that aren't in the top 10">
                    <Text color="text.secondary">
                      <InformationIcon />
                    </Text>
                  </HightouchUiTooltip>
                </Text>
              </Row>
            )}
            {chartType === "bar" && (
              <Row
                sx={{
                  label: {
                    display: "flex",
                    gap: 2,
                    cursor: "pointer",
                  },
                }}
              >
                <Text as="label" fontWeight="medium">
                  <Switch
                    isChecked={layout === "horizontal"}
                    onChange={() => {
                      setAnimate(true);
                      setLayout((prevValue) =>
                        prevValue === "horizontal" ? "vertical" : "horizontal",
                      );
                    }}
                  />
                  Switch axes
                </Text>
              </Row>
            )}
            {chartType === "bar" && (
              <Row
                sx={{
                  label: {
                    display: "flex",
                    gap: 2,
                    cursor: "pointer",
                  },
                }}
              >
                <Text as="label" fontWeight="medium">
                  <Switch
                    isChecked={dataFormat === "percentage"}
                    onChange={() => {
                      setAnimate(true);
                      setDataFormat((prevValue) =>
                        prevValue === "count" ? "percentage" : "count",
                      );
                    }}
                  />
                  Percentage
                </Text>
              </Row>
            )}
          </Column>
        )}
      </Column>
    </ChakraListItem>
  );
};

const TooltipContent = ({ label, payload }: TooltipProps<number, string>) => {
  const [position, setPosition] = useState<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });

  useEffect(() => {
    const handleWindowMouseMove = (event: MouseEvent) => {
      if (!payload?.[0]) {
        return null;
      }

      return setPosition({
        x: event.pageX,
        y: event.pageY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, [payload?.length]);

  if (!payload?.[0]) {
    return null;
  }

  const {
    payload: { count, percentage, value },
  } = payload[0];

  return (
    <Portal>
      <Box
        position="absolute"
        top={`${position.y === null ? 0 : position.y + 20}px`}
        left={`${position.x === null ? 0 : position.x + 20}px`}
        mr={3}
        display={position.x === null ? "none" : "visible"}
        bg="white"
        p={4}
        border="small"
        borderRadius="md"
        zIndex="var(--chakra-zIndices-popover)"
        boxShadow="sm"
      >
        <Column gap={1}>
          <Text fontWeight="medium">{label ?? value}</Text>
          <Text>
            Count: {count} or {(percentage * 100).toFixed(2)}%
          </Text>
        </Column>
      </Box>
    </Portal>
  );
};
