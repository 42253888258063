import { FC } from "react";

import {
  Column,
  InformationIcon,
  NumberInput,
  Row,
  SectionHeading,
  Select,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { IntervalUnit } from "@hightouch/lib/query/visual/types";

import { TIME_OPTIONS } from "src/pages/metrics/constants";

import { ConversionCriteriaData, ConversionWindow } from "./types";
import { InputGroup } from "./input-group";

type ConversionCriteriaProps = {
  funnelConversionWindow: ConversionWindow;
  onChange: (data: Partial<ConversionCriteriaData>) => void;
};

export const ConversionCriteria: FC<ConversionCriteriaProps> = ({
  funnelConversionWindow,
  onChange,
}) => {
  return (
    <Column gap={2}>
      <SectionHeading>Conversion criteria</SectionHeading>
      <Column
        flex={1}
        minWidth={0}
        p={2}
        gap={2}
        bg="white"
        border="1px solid"
        borderColor="base.border"
        borderRadius="md"
        boxShadow="xs"
      >
        <Row align="center" justify="space-between" gap={2}>
          <Row
            as={Text}
            align="center"
            gap={1}
            color="text.secondary"
            size="sm"
          >
            Conversion window
            <Tooltip message="The window of time a user has to complete all steps once they enter the funnel">
              <InformationIcon />
            </Tooltip>
          </Row>
          <InputGroup
            content={
              <Row flex={1} minWidth={0} gap={2}>
                <NumberInput
                  width="auto"
                  value={funnelConversionWindow.quantity}
                  onChange={(value) =>
                    onChange({
                      funnelConversionWindow: {
                        ...funnelConversionWindow,
                        quantity: value ?? 0,
                      },
                    })
                  }
                />
                <Select
                  options={TIME_OPTIONS}
                  value={funnelConversionWindow.unit}
                  width="auto"
                  onChange={(value) =>
                    onChange({
                      funnelConversionWindow: {
                        ...funnelConversionWindow,
                        unit: value ?? IntervalUnit.Day,
                      },
                    })
                  }
                />
              </Row>
            }
          >
            <Text color="primary.base" size="sm">
              {funnelConversionWindow.quantity}{" "}
              {
                TIME_OPTIONS.find(
                  ({ value }) => value === funnelConversionWindow.unit,
                )?.label
              }
            </Text>
          </InputGroup>
        </Row>
      </Column>
    </Column>
  );
};
