import { useState } from "react";

import * as Sentry from "@sentry/react";
import {
  Button,
  ButtonGroup,
  ConfirmationDialog,
  DeleteIcon,
  Paragraph,
  Row,
  SearchInput,
  Text,
  useToast,
} from "@hightouchio/ui";
import { useNavigate } from "src/router";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionedLinkButton } from "src/components/permission";
import {
  AttributionMethodsOrderBy,
  OrderBy,
  useDeleteAttributionMethodsMutation,
  useListAttributionMethodsQuery,
} from "src/graphql";
import {
  Pagination,
  SortOption,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { openUrl } from "src/utils/urls";

import { WeightingOptionMap } from "./constants";
import { useRowSelect } from "src/ui/table/use-row-select";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import pluralize from "pluralize";

const initialSort: SortOption<keyof AttributionMethodsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof AttributionMethodsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "parent_model", direction: OrderBy.Asc, label: "Parent model A -> Z" },
  {
    key: "parent_model",
    direction: OrderBy.Desc,
    label: "Parent model Z -> A",
  },
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

export const AttributionMethods = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [search, setSearch] = useState("");
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  const { limit, offset, page, setPage } =
    useTableConfig<AttributionMethodsOrderBy>();
  const orderBy = useTableSort<AttributionMethodsOrderBy>(
    initialSort,
    sortOptions,
  );
  const { selectedRows, onRowSelect } = useRowSelect();
  const { isPermitted: hasDeletePermissions } = useResourcePermission({
    v1: { resource: "audience_schema", grant: "delete" },
  });

  const deleteAttributionMethodsMutation =
    useDeleteAttributionMethodsMutation();

  const { data, isLoading } = useListAttributionMethodsQuery(
    {
      filters: { name: { _ilike: `%${search}%` } },
      offset,
      limit,
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const bulkDeleteAttributionMethods = async () => {
    if (!hasDeletePermissions) {
      toast({
        id: "bulk-delete-attribution-methods",
        title: "You don't have permissions to delete attribution methods",
        variant: "error",
      });
      return;
    }

    try {
      await deleteAttributionMethodsMutation.mutateAsync({
        ids: selectedRows.map(String),
      });

      toast({
        id: "bulk-delete-attribution-methods",
        title: "Selected attribution methods were deleted",
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-attribution-methods",
        title: `Failed to delete ${pluralize(
          "attribution method",
          selectedRows.length,
          true,
        )}`,
        message: error.message,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const columns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name }) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      name: "Parent model",
      cell: ({ parent_model }) => (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon
            src={parent_model?.connection?.definition?.icon}
            name={parent_model?.connection?.definition?.name}
          />
          <Text isTruncated>{parent_model?.name ?? "Private model"}</Text>
        </Row>
      ),
    },
    {
      name: "Weighting",
      cell: ({ type }) => <Text isTruncated>{WeightingOptionMap[type]}</Text>,
    },
    { ...LastUpdatedColumn, breakpoint: "md" },
  ];

  return (
    <>
      <Row justify="space-between" my={8}>
        <SearchInput
          placeholder="Search by name..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />

        <ButtonGroup>
          {hasDeletePermissions && selectedRows.length > 0 && (
            <Button
              icon={DeleteIcon}
              isDisabled={deleteAttributionMethodsMutation.isLoading}
              variant="warning"
              onClick={() => {
                setConfirmDeletion(true);
              }}
            >
              Delete
            </Button>
          )}
          <PermissionedLinkButton
            permission={{
              v1: {
                resource: "audience_schema",
                grant: "create",
              },
            }}
            href="/metrics/attributions/new"
            variant="primary"
          >
            Add attribution method
          </PermissionedLinkButton>
        </ButtonGroup>
      </Row>

      <Table
        columns={columns}
        data={data?.attribution_methods || []}
        loading={isLoading}
        placeholder={{
          image: searchPlaceholder,
          title: "No attribution methods",
          error: "Attribution methods failed to load, please try again",
        }}
        sortOptions={sortOptions}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
        onRowClick={({ id }, event) =>
          openUrl(`/metrics/attributions/${id}`, navigate, event)
        }
      />
      <Row justify="flex-end" width="100%" mt={4} px={4}>
        <Pagination
          count={data?.attribution_methods_aggregate.aggregate?.count || 0}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>

      <ConfirmationDialog
        isOpen={confirmDeletion}
        title={`Delete ${pluralize(
          "attribution method",
          selectedRows.length,
          true,
        )}`}
        confirmButtonText={`Delete ${pluralize(
          "attribution method",
          selectedRows.length,
        )}`}
        variant="danger"
        onClose={() => setConfirmDeletion(false)}
        onConfirm={bulkDeleteAttributionMethods}
      >
        <Paragraph>
          Attribution method deletion is not reversible. Are you sure you want
          to delete the selected attribution methods?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
