import {
  AudienceIcon,
  Box,
  CheckIcon,
  Column,
  ExternalLinkIcon,
  Heading,
  IgnoreIcon,
  MailIcon,
  Row,
  StatsItemTitle,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { InsightsContext } from ".";
import { abbreviateNumber } from "src/utils/numbers";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import { InsightsOutcomes } from "./components/outcomes";

export const InsightsOverview = () => {
  const { flow } = useOutletContext<InsightsContext>();

  const treatment = 1 - flow.config.holdout;

  return (
    <Column p={6} gap={6}>
      <Row gap={6} flexWrap="wrap">
        <Card gap={4} flex={1} minW="xs" p={6}>
          <Row align="center" gap={4}>
            <Row
              align="center"
              justify="center"
              p={2}
              borderRadius="50%"
              bg="gray.100"
            >
              <Box as={AudienceIcon} boxSize={5} color="text.secondary" />
            </Row>

            <Heading>Audience</Heading>
          </Row>
          <Column>
            <StatsItemTitle>Size</StatsItemTitle>
            <Box
              fontWeight="semibold"
              fontSize="4xl"
              fontFamily="sharpSansDisplayNo1"
            >
              {abbreviateNumber(flow.audience?.last_run_size ?? 1_000_000)}
            </Box>
          </Column>
          <Column gap={1}>
            <Row gap={2} color="text.secondary">
              <CheckIcon />
              <Box width="80px">Treatment</Box>
              <Text fontWeight="medium">
                {`${treatment * 100}% ${
                  flow.audience?.last_run_size
                    ? abbreviateNumber(flow.audience.last_run_size * treatment)
                    : ""
                }`}
              </Text>
            </Row>
            <Row gap={2} color="text.secondary">
              <IgnoreIcon />
              <Box width="80px">Holdout</Box>
              <Text fontWeight="medium">
                {`${flow.config.holdout * 100}% ${
                  flow.audience?.last_run_size
                    ? abbreviateNumber(
                        flow.audience.last_run_size * flow.config.holdout,
                      )
                    : ""
                }`}
              </Text>
            </Row>
          </Column>
          <Link href={`/audiences/${flow.audience?.id}`}>
            {flow.audience?.name} <ExternalLinkIcon />
          </Link>
        </Card>
        <Card gap={4} flex={1} minW="xs" p={6}>
          <Row align="center" gap={4}>
            <Row
              align="center"
              justify="center"
              p={2}
              borderRadius="50%"
              bg="gray.100"
            >
              <Box as={MailIcon} boxSize={5} color="text.secondary" />
            </Row>
            <Heading>Messages</Heading>
          </Row>
          <Row gap={4} justify="space-between">
            <Column>
              <StatsItemTitle>Past 24H</StatsItemTitle>
              <Box
                fontWeight="semibold"
                fontSize="4xl"
                fontFamily="sharpSansDisplayNo1"
              >
                {abbreviateNumber(Math.round(Math.random() * 10_000))}
              </Box>
            </Column>
            <Column>
              <StatsItemTitle>Next 24H</StatsItemTitle>
              <Box
                fontWeight="semibold"
                fontSize="4xl"
                fontFamily="sharpSansDisplayNo1"
              >
                {abbreviateNumber(Math.round(Math.random() * 10_000))}
              </Box>
            </Column>
            <Column>
              <StatsItemTitle>All time</StatsItemTitle>
              <Box
                fontWeight="semibold"
                fontSize="4xl"
                fontFamily="sharpSansDisplayNo1"
              >
                {abbreviateNumber(
                  Math.round(500_000 + Math.random() * 1_000_000),
                )}
              </Box>
            </Column>
          </Row>
          <ResponsiveContainer>
            <LineChart
              data={[
                { value: 200 },
                { value: 300 },
                { value: 200 },
                { value: 278 },
                { value: 489 },
                { value: 239 },
                { value: 349 },
                { value: 300 },
                { value: 578 },
                { value: 689 },
                { value: 739 },
                { value: 949 },
              ]}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            >
              <Line
                dot={false}
                type="monotone"
                dataKey="value"
                strokeWidth={3}
                stroke="var(--chakra-colors-grass-500)"
              />
            </LineChart>
          </ResponsiveContainer>
          <Link href="/ai/inspector">
            View messages <ExternalLinkIcon />
          </Link>
        </Card>
      </Row>
      <Card>
        <InsightsOutcomes outcomes={flow.outcomes} />
      </Card>
    </Column>
  );
};
