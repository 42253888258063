import { FC, useEffect, useState } from "react";
import { FormkitTable } from "./table";
import { TableColumnProps, TableProps } from "@hightouch/formkit";
import { useTunnelsQuery } from "src/graphql";

type Props = TableProps & {
  tunnelColumnIndex?: number;
  onChange: any;
};

/**
 * TunnelTable will append the TunnelSelect component at the provided tunnelColumnIndex, or as the last column
 * with the columnKey tunnelId
 */
export const TunnelTable: FC<Readonly<Props>> = ({
  addRow,
  addRowLabel,
  columns,
  value,
  onChange,
  tunnelColumnIndex,
}) => {
  const tunnelColumn: TableColumnProps = {
    header: "Tunnel",
    columnKey: "tunnelId",
    componentType: "select",
    options: [], // Options get populated below to avoid extra renders
    placeholder: "Select a tunnel...",
  };

  const { data, isFetching } = useTunnelsQuery();
  const [tableColumns, setTableColumns] = useState(
    tunnelColumnIndex
      ? columns.toSpliced(tunnelColumnIndex, 0, tunnelColumn)
      : columns.concat(tunnelColumn),
  );

  useEffect(() => {
    let tunnelOptions;
    if (data) {
      tunnelOptions =
        data.getTunnels?.map(({ tunnel: { name, id } }) => ({
          label: name ?? "",
          value: id ?? "",
        })) ?? [];

      setTableColumns(
        tableColumns.map((tcol) => {
          if (tcol.columnKey === tunnelColumn.columnKey) {
            return {
              ...tcol,
              options: tunnelOptions,
            };
          }
          return tcol;
        }),
      );
    }
  }, [isFetching]);

  return (
    <FormkitTable
      columns={tableColumns}
      addRow={addRow}
      addRowLabel={addRowLabel}
      value={value}
      onChange={onChange}
    />
  );
};
