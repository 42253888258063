import { Syncs } from "src/components/models/syncs";
import { useNavigate } from "src/router";
import { OutletContext } from ".";

export const ModelSyncs = ({
  context: { model },
}: {
  context: OutletContext;
}) => {
  const navigate = useNavigate();

  return (
    <Syncs
      modelMatchboostingEnabled={model.matchboosting_enabled}
      syncs={model.syncs}
      onAdd={() => {
        navigate(`/syncs/new?model=${model.id}`);
      }}
    />
  );
};
