import { Text, Row, Column, CheckIcon, Box } from "@hightouchio/ui";
import { FC, ReactNode } from "react";

export const Step: FC<{
  isChecked?: boolean;
  heading: string;
  description: string;
  children: ReactNode;
}> = ({ isChecked, heading, description, children }) => (
  <Row align="start" gap={4}>
    <StepIcon isChecked={isChecked} />
    <Column align="flex-start">
      <Text fontWeight="medium">{heading}</Text>
      <Text color="text.secondary">{description}</Text>
      <Column mt={2} gap={2}>
        {children}
      </Column>
    </Column>
  </Row>
);

const StepIcon: FC<{ isChecked?: boolean }> = ({ isChecked }) => (
  <Column
    flexShrink={0}
    background={isChecked ? "primary.base" : "white"}
    border={isChecked ? "none" : "1px solid"}
    borderColor={isChecked ? undefined : "text.placeholder"}
    borderRadius="full"
    boxSize={4}
    color="white"
    align="center"
    justify="center"
    mt={1}
  >
    {isChecked && <Box as={CheckIcon} fontSize="13px" />}
  </Column>
);
