import { FormField, TextInput } from "@hightouchio/ui";
import { FC } from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import validator from "validator";

export const SmsRecipientForm: FC = () => {
  return (
    <>
      <Controller
        name="config.name"
        rules={{
          required: "Name is required",
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormField
            label="Recipient name"
            error={error?.message}
            description={`Give this phone number a descriptive name, e.g. "Jane's cell"`}
          >
            <TextInput
              width="100%"
              type="text"
              autoComplete="false"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </FormField>
        )}
      />

      <Controller
        name="config.phoneNumber"
        rules={{
          required: "Phone number is required",
          validate: (value) =>
            validator.isMobilePhone(value) ? true : "Phone number is invalid",
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormField error={error?.message} label="Phone number">
            <PhoneInput
              width="100%"
              autoComplete="false"
              defaultCountry="US"
              inputComponent={TextInput}
              placeholder="Enter a phone number..."
              sx={{ width: "300px", ml: 1 }}
              value={value}
              onChange={(phone) => {
                onChange(phone);
              }}
            />
          </FormField>
        )}
      />
    </>
  );
};
