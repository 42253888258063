import { noop } from "ts-essentials";

import { AggregationOption } from "src/pages/metrics/constants";
import { AnalyticsFrequency, IntervalUnit } from "src/types/visual";

import {
  AnalyticsActions,
  AnalyticsState,
  ConversionWindow,
  FunnelStep,
  GraphType,
  GroupByColumn,
  MeasurementType,
  MetricSelection,
  SelectedAudience,
  URLData,
} from "../types";
import { CumulativeIcon, LinearIcon } from "@hightouchio/ui";

export const SECONDS_IN_ONE_DAY = 86400;

export enum TimeOptions {
  SevenDays = "7-days",
  ThirtyDays = "30-days",
  SixtyDays = "60-days",
  NinetyDays = "90-days",
  Custom = "custom",
}

export const TimeMap: Record<
  | TimeOptions.SevenDays
  | TimeOptions.ThirtyDays
  | TimeOptions.SixtyDays
  | TimeOptions.NinetyDays,
  number
> = {
  [TimeOptions.SevenDays]: SECONDS_IN_ONE_DAY * 7,
  [TimeOptions.ThirtyDays]: SECONDS_IN_ONE_DAY * 30,
  [TimeOptions.SixtyDays]: SECONDS_IN_ONE_DAY * 60,
  [TimeOptions.NinetyDays]: SECONDS_IN_ONE_DAY * 90,
};

export const SecondsToTimeOptions = {
  [SECONDS_IN_ONE_DAY * 7]: TimeOptions.SevenDays,
  [SECONDS_IN_ONE_DAY * 30]: TimeOptions.ThirtyDays,
  [SECONDS_IN_ONE_DAY * 60]: TimeOptions.SixtyDays,
  [SECONDS_IN_ONE_DAY * 90]: TimeOptions.NinetyDays,
};

export const LookbackOptions = [
  { label: "7d", value: TimeOptions.SevenDays },
  { label: "30d", value: TimeOptions.ThirtyDays },
  { label: "60d", value: TimeOptions.SixtyDays },
  { label: "90d", value: TimeOptions.NinetyDays },
];

export const AccumulationTypes = [
  {
    label: "Over time",
    description: "Metric is charted over time",
    value: false,
    icon: LinearIcon,
  },
  {
    label: "Cumulative",
    description: "Running total of your metric",
    value: true,
    icon: CumulativeIcon,
  },
];

export const RollupFrequencies = [
  { label: "Daily", value: AnalyticsFrequency.Daily },
  { label: "Weekly", value: AnalyticsFrequency.Weekly },
  { label: "Monthly", value: AnalyticsFrequency.Monthly },
];

export const STATIC_ARRAY = [];
export const PLACEHOLDER_AUDIENCE: SelectedAudience = {
  id: -1,
  name: "",
  splits: [],
};
export const DEFAULT_METRIC_SELECTION: MetricSelection[] = [
  {
    id: "",
    name: "",
    eventModelId: null,
    description: null,
    aggregationMethod: AggregationOption.Count,
    conditions: [],
  },
];

export const DEFAULT_SELECTED_AUDIENCES: SelectedAudience[] = [];
export const DEFAULT_FUNNEL_STEPS: FunnelStep[] = [
  { eventModelId: "", relationshipId: "", subconditions: [] },
];
export const DEFAULT_GROUP_BY_COLUMNS: (GroupByColumn | undefined)[] = [
  undefined,
];
export const DEFAULT_SELECTED_DATES: string[] = [];

export const DEFAULT_FUNNEL_CONVERSION_WINDOW: ConversionWindow = {
  quantity: 1,
  unit: IntervalUnit.Day,
};

export const defaultState: AnalyticsState = {
  audiences: STATIC_ARRAY,
  audiencesAndMetricsLoading: false,
  cumulative: false,
  events: STATIC_ARRAY,
  funnelConversionWindow: DEFAULT_FUNNEL_CONVERSION_WINDOW,
  funnelSteps: DEFAULT_FUNNEL_STEPS,
  graphType: GraphType.Performance,
  groupByColumns: DEFAULT_GROUP_BY_COLUMNS,
  measuringType: MeasurementType.TotalConversion,
  metrics: STATIC_ARRAY,
  metricSelection: DEFAULT_METRIC_SELECTION,
  parent: null,
  parentModelId: null,
  parentModelLoading: false,
  rollupFrequency: AnalyticsFrequency.Daily,
  selectedAudiences: STATIC_ARRAY,
  selectedDates: [],
  timeValue: TimeOptions.SevenDays,
};

export const defaultActions: AnalyticsActions = {
  addAudience: noop,
  addGroupByColumn: noop,
  addGroupByColumns: noop,
  addMetric: noop,
  removeAudienceAtIndex: noop,
  removeGroupByColumns: noop,
  removeMetricAtIndex: noop,
  resetMetricAtIndex: noop,
  resetView: noop,
  selectParentModel: noop,
  setCumulative: noop,
  setFunnelConversionCriteria: noop,
  setFunnelSteps: noop,
  setGraphType: noop,
  setLookbackWindow: noop,
  setRollupFrequency: noop,
  setSelectedDates: noop,
  updateAudienceAtIndex: noop,
  updateMetricAtIndex: noop,
};

export const defaultPerformanceUrlState: Partial<URLData> = {
  audiences: [],
  cumulative: false,
  frequency: AnalyticsFrequency.Daily,
  groupBy: DEFAULT_GROUP_BY_COLUMNS,
  metrics: DEFAULT_METRIC_SELECTION,
  selectedDates: DEFAULT_SELECTED_DATES,
  timeValue: TimeOptions.SevenDays,
};

export const defaultFunnelUrlState: Partial<URLData> = {
  audiences: [],
  funnelConversionWindow: DEFAULT_FUNNEL_CONVERSION_WINDOW,
  funnelSteps: DEFAULT_FUNNEL_STEPS,
  groupBy: DEFAULT_GROUP_BY_COLUMNS,
  measuringType: MeasurementType.TotalConversion,
  selectedDates: DEFAULT_SELECTED_DATES,
  timeValue: TimeOptions.SevenDays,
};
