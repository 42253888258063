import { FC, ReactNode } from "react";
import { QueryType } from "src/types/models";
import { Alert } from "@hightouchio/ui";

type Props = {
  type: QueryType.JourneyNode | QueryType.DecisionEngine;
  actions?: ReactNode;
};

export const ManagedQuery: FC<Readonly<Props>> = ({ actions, type }) => {
  return (
    <Alert
      actions={actions}
      title={`Managed ${type} query`}
      message={`This query is managed by Hightouch and associated with a ${type}`}
      variant="inline"
      type="info"
    />
  );
};
