import { useState, FC } from "react";

import Helmet from "react-helmet";
import { useNavigate } from "src/router";

import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import { PageSpinner } from "src/components/loading";
import { Wizard } from "src/components/wizard";

import { DraftOperation, ResourceToPermission } from "src/graphql";
import { useCreateSyncWizard } from "./use-create-sync-wizard";

export const CreateSync: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useUser();
  const [submitDraftModalOpen, setSubmitDraftModalOpen] =
    useState<boolean>(false);

  const goToSync = (id: string) => {
    navigate(`/syncs/${id}`);
  };

  const {
    createSync,
    setStep,
    step,
    steps,
    loading,
    selectedModelId,
    selectedDestinationId,
  } = useCreateSyncWizard({});

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>New sync</title>
      </Helmet>

      <DraftSubmissionModal
        draft={{
          _set: {
            draft: false, // the draft change is to set the column `draft` to false.
          },
        }}
        getResourceId={async () => {
          const sync = await createSync();
          return sync?.id;
        }}
        dependentResource={
          selectedModelId
            ? {
                resourceId: selectedModelId,
                resourceType: ResourceToPermission.Model,
              }
            : undefined
        }
        approverOptions={{
          type: "sync",
          modelId: selectedModelId?.toString(),
          destinationId: selectedDestinationId?.toString(),
        }}
        permission={{
          v2: {
            resource: "sync",
            grant: "can_approve",
            creationOptions: {
              modelId: selectedModelId?.toString(),
              destinationId: selectedDestinationId?.toString(),
            },
          },
        }}
        open={submitDraftModalOpen}
        operation={DraftOperation.Create}
        resource={ResourceToPermission.Sync}
        onClose={() => setSubmitDraftModalOpen(false)}
        onSubmit={(id) => {
          goToSync(id);
        }}
      />

      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New sync"
        onCancel={() => {
          navigate("/syncs");
        }}
        onSubmit={async () => {
          if (workspace?.approvals_required) {
            setSubmitDraftModalOpen(true);
          } else {
            const sync = await createSync();
            if (sync?.id) {
              goToSync(sync?.id);
            }
          }
        }}
      />
    </>
  );
};
