import {
  Column,
  InformationIcon,
  Row,
  SectionHeading,
  Switch,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { FC, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomJsonEditor } from "src/events/contracts/contract/event-schema/fields/custom-json-editor";
import { SimpleContractForm } from "src/events/contracts/contract/event-schema/fields/simple-contract-form";
import {
  CheckConversionResult,
  ConversionFailure,
  canJsonSchemaBeConvertedToContractProperties,
  convertContractPropertiesToJsonSchema,
  convertJsonSchemaToContractProperties,
  editableProperties,
} from "src/events/contracts/contract/event-schema/transformation";
import JSON5 from "json5";
import { getDefaultProperty } from "src/events/contracts/utils";
import { EventSchemaFormState } from "src/events/contracts/types";
import { capitalize } from "lodash";

const getErrorMessage = ({ error }: ConversionFailure): string => {
  return error
    ? `Visual builder does not support schema. Reason: ${error}`
    : "The visual builder does not support the schema that is currently defined.";
};

export const SchemaEditor: FC = () => {
  const form = useFormContext<EventSchemaFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Circular reference for ContractProperty type
  const json = form.watch("editorState.json");
  const isJson = form.watch("editorState.isJson");
  const eventType = form.watch("eventType");

  const checkConvertResult: CheckConversionResult = useMemo(() => {
    // Simple form will always translate to a 'translatable' schema
    if (!isJson) return { canConvert: true };

    try {
      return canJsonSchemaBeConvertedToContractProperties(
        JSON5.parse(json),
        eventType,
      );
    } catch {
      return { canConvert: false };
    }
  }, [isJson, json, eventType]);

  const changeViews = () => {
    if (checkConvertResult.canConvert) {
      const {
        eventType,
        editorState: { isJson, json, properties },
      } = form.getValues();

      // set the other view's value
      if (isJson) {
        form.setValue(
          "editorState.properties",
          json
            ? convertJsonSchemaToContractProperties(
                JSON5.parse(json),
                eventType,
              ) ?? [getDefaultProperty()]
            : [getDefaultProperty()],
        );
      } else {
        form.setValue(
          "editorState.json",
          JSON.stringify(
            convertContractPropertiesToJsonSchema(properties, eventType),
            null,
            4,
          ),
        );
      }

      form.clearErrors("editorState");
      form.setValue("editorState.isJson", !isJson, { shouldDirty: true });
    }
  };

  const visualEditorHeading = capitalize(editableProperties[eventType]);

  const header = (
    <Row justifyContent="space-between" align="center">
      <Column>
        <SectionHeading>
          {isJson ? (
            "JSON editor"
          ) : (
            <Row gap={1} align="center">
              {visualEditorHeading}{" "}
              <Tooltip
                message={`Define the expected attributes within the ${visualEditorHeading} object.`}
              >
                <InformationIcon color="text.secondary" />
              </Tooltip>
            </Row>
          )}
        </SectionHeading>
      </Column>
      <Row alignItems="center" gap={2}>
        <Controller
          name="editorState.isJson"
          control={form.control}
          render={({ field }) => (
            <Tooltip
              isDisabled={checkConvertResult.canConvert}
              message={getErrorMessage(checkConvertResult as ConversionFailure)}
            >
              <Switch
                isDisabled={!checkConvertResult.canConvert}
                isChecked={field.value}
                onChange={changeViews}
              />
            </Tooltip>
          )}
        />
        <Text fontWeight="medium">Edit as JSON</Text>
      </Row>
    </Row>
  );

  return (
    <Column>
      {isJson ? (
        <CustomJsonEditor header={header} />
      ) : (
        <SimpleContractForm header={header} />
      )}
    </Column>
  );
};
