/* eslint-disable no-restricted-imports */
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useParams,
  useLocation,
  useOutletContext,
  useSearchParams,
  Outlet,
  Route,
  Routes,
  createSearchParams,
  NavigateProps,
  Link as RRLink,
  useNavigate as RRuseNavigate,
  Navigate as RRNavigate,
  LinkProps as RRLinkProps,
  MemoryRouter,
  generatePath,
  useNavigation,
  RouteObject,
  LoaderFunction,
  useHref,
} from "react-router-dom";
import { forwardRef, useCallback, useEffect } from "react";
import {
  Link as HTLink,
  LinkButton as HTLinkButton,
  LinkButtonProps as HTLinkButtonProps,
  LinkProps as HTLinkProps,
} from "@hightouchio/ui";
import { preloadRoute } from "./utils";

// Modify paths to include workspace slug when available
const modifyToWithWorkspace = (
  to: To | number,
  root_workspace_slug: string | undefined,
): To | number => {
  if (typeof to === "number") {
    return to;
  }
  if (!root_workspace_slug) {
    return to;
  }
  if (typeof to === "string") {
    if (to.startsWith("/")) {
      return `/${root_workspace_slug}${to}`;
    }
    return to;
  }
  if (to?.pathname?.startsWith("/")) {
    return { ...to, pathname: `/${root_workspace_slug}${to.pathname}` };
  }
  return to;
};

const useNavigate = () => {
  const navigate = RRuseNavigate();
  const { root_workspace_slug } = useParams<{ root_workspace_slug: string }>();

  const navigateWithWorkspace: NavigateFunction = useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      const processedTo = modifyToWithWorkspace(to, root_workspace_slug);
      if (typeof processedTo === "number") {
        return navigate(processedTo);
      }
      return navigate(processedTo, options);
    },
    [navigate, root_workspace_slug],
  );

  return navigateWithWorkspace;
};

const Navigate = ({ to, ...props }: NavigateProps) => {
  const { root_workspace_slug } = useParams<{ root_workspace_slug: string }>();
  return (
    <RRNavigate
      to={modifyToWithWorkspace(to, root_workspace_slug) as To}
      {...props}
    />
  );
};

const RouterLink = forwardRef<
  HTMLAnchorElement,
  RRLinkProps & { preload?: "mount" | "intent" }
>(({ to, preload, ...props }, ref) => {
  const { root_workspace_slug } = useParams<{
    root_workspace_slug: string;
  }>();
  const href = useHref(to);

  useEffect(() => {
    if (preload === "mount" && href) {
      preloadRoute(href);
    }
  }, [href, preload]);

  return (
    <RRLink
      ref={ref}
      to={modifyToWithWorkspace(to, root_workspace_slug) as To}
      {...props}
    />
  );
});

RouterLink.displayName = "RouterLink";

type AnchorProps = {
  target?: string;
  rel?: string;
  download?: string;
};

const LinkButton = forwardRef<
  HTMLAnchorElement,
  HTLinkButtonProps & AnchorProps
>(({ href, ...props }, ref) => {
  // @ts-expect-error to is RouterLink prop
  return <HTLinkButton as={RouterLink} to={href} {...props} ref={ref} />;
});

LinkButton.displayName = "LinkButton";

const Link = forwardRef<HTMLAnchorElement, HTLinkProps & AnchorProps>(
  ({ href, ...props }, ref) => {
    // @ts-expect-error to is RouterLink prop
    return <HTLink as={RouterLink} to={href} ref={ref} {...props} />;
  },
);

Link.displayName = "Link";

export {
  Route,
  Routes,
  useSearchParams,
  useParams,
  useLocation,
  Outlet,
  useOutletContext,
  createSearchParams,
  MemoryRouter,
  generatePath,
  useNavigation,

  // Modified for workspace slug
  Link,
  LinkButton,
  RouterLink,
  useNavigate,
  Navigate,
};

export type {
  To,
  NavigateOptions,
  NavigateFunction,
  RouteObject,
  LoaderFunction,
};
