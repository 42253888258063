import {
  FormField,
  IconButton,
  RefreshIcon,
  Row,
  Select,
} from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  useSlackChannelsQuery,
  useSlackCredentialsQuery,
  useWorkspaceNotificationChannelsQuery,
} from "../../../graphql";

export const SlackRecipientForm: FC = () => {
  const { data: slackCredentialsData, isLoading: slackCredentialsLoading } =
    useSlackCredentialsQuery();
  const slackCredential = slackCredentialsData?.slack_credentials?.[0];
  const {
    data: slackChannelsData,
    refetch: refetchSlackChannels,
    isRefetching: slackChannelsRefetching,
    isLoading: slackChannelsLoading,
    error: slackChannelsError,
  } = useSlackChannelsQuery(
    {
      id: Number(slackCredential?.id),
    },
    {
      enabled: Boolean(slackCredential),
      select: (data) => data.listSlackChannelsByCredentials,
    },
  );

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      select: (data) => data.workspace_notification_channels,
    },
  );

  const { watch, setValue } = useFormContext();

  const currentConfig = watch("config");

  return (
    <Controller
      rules={{
        required: true,
        validate: (value) =>
          workspaceChannels?.some(
            (c) =>
              c.id !== currentConfig.id && c.config.slackChannelId === value,
          )
            ? "This channel is already in use"
            : true,
      }}
      name="config.slackChannelId"
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormField
            description="If you can't find the channel you're looking for, make sure that the Hightouch Slack app has been added to the channel first. Some organizations may require admin approval before adding the app."
            label="Slack channel"
            error={slackChannelsError?.message || error?.message}
          >
            <Row gap={2}>
              <Select
                width="100%"
                isLoading={
                  slackChannelsLoading ||
                  slackCredentialsLoading ||
                  slackChannelsRefetching
                }
                options={
                  slackChannelsData
                    ?.map((channel) => ({
                      label: channel.name,
                      value: channel.id,
                    }))
                    ?.sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
                }
                value={value}
                placeholder="Select a Slack channel..."
                isDisabled={!slackCredential}
                onChange={(selected) => {
                  if (!selected) return;
                  onChange(selected);
                  setValue(
                    "config.name",
                    slackChannelsData?.find((s) => s.id === selected)?.name ||
                      selected,
                  );
                }}
              />
              <IconButton
                variant="secondary"
                isDisabled={!slackCredential}
                aria-label="refetch channels"
                icon={RefreshIcon}
                onClick={() => refetchSlackChannels()}
              />
            </Row>
          </FormField>
        );
      }}
    />
  );
};
