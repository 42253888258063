import { FC, Fragment, useMemo, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Column,
  EditIcon,
  EmptyState,
  FormField,
  Paragraph,
  Row,
  SectionHeading,
  TagInput,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as y from "yup";
import pluralize from "pluralize";
import { createSearchParams, useNavigate, useSearchParams } from "src/router";
import { useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  InsertDefaultMonitorConditionsMutationVariables,
  SubscribeableResourcesQuery,
  useEventDestinationsQuery,
  useInsertDefaultMonitorConditionsMutation,
  useResourceTypeMonitorConditionsQuery,
  useSubscribeableResourcesQuery,
} from "src/graphql";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { ActionBar } from "src/components/action-bar";
import { DestinationTriggerGlobalOptions } from "./bulk-management-global-options";
import {
  defaultValueForSyncConditionType,
  MonitorConditionForm,
  BulkEditableMonitorConditionTypes,
  valueIsEmpty,
  BulkEditableResourceTypes,
} from "./monitor-condition-forms/monitor-condition-form";

import {
  ConditionFriendlyNames,
  MonitorConditionEvaluationProperties,
  MonitorConditionTypes,
  MonitoredResourceType,
  ParentResourceTypes,
  EventSourceVolumeInterval,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import type { BulkManageMonitorConditionFormState } from "./types";
import { assertedConditionType } from "@hightouch/lib/resource-monitoring/assertions";
import {
  getMinimalCondition,
  isOverride,
  isSupportedConditionForResourceType,
} from "./utils";
import { enumOrFallback, isPresent } from "src/types/utils";
import { EventWarehouseDestinationType } from "src/events/types";

const DestinationRowDecoration: FC<{
  monitorConditionType: MonitorConditionTypes;
  destination: SubscribeableResourcesQuery["destinations"][0];
  overridingSyncsCount: number;
}> = ({ destination, overridingSyncsCount, monitorConditionType }) => {
  const [searchParams, _] = useSearchParams();
  const overrideParams = new URLSearchParams(searchParams);
  overrideParams.set("forDestinationId", destination.id);
  overrideParams.set("forMonitorConditionType", monitorConditionType);

  return (
    <>
      <IntegrationIcon
        name={destination.name || destination.definition.name}
        src={destination.definition.icon}
      />
      <Column>
        <Text fontWeight="medium">
          {destination.name || destination.definition.name}
        </Text>
        <Text size="md" color="gray.700">
          {destination.syncs_aggregate.aggregate?.count}{" "}
          {pluralize("sync", destination.syncs_aggregate.aggregate?.count)}
          {overridingSyncsCount > 0 && (
            <>
              {" "}
              •{" "}
              <Link href={`overrides?${overrideParams.toString()}`}>
                {`${overridingSyncsCount} sync-level ${pluralize(
                  "override",
                  overridingSyncsCount,
                )}`}
              </Link>
            </>
          )}
        </Text>
      </Column>
    </>
  );
};

const ConditionRowHeading: FC<{
  conditionType: BulkEditableMonitorConditionTypes;
  resourceType: BulkEditableResourceTypes;
  setBulkEditingConditionType: (
    conditionType: MonitorConditionTypes | null,
  ) => void;
  bulkEditingConditionType: MonitorConditionTypes | null;
  destinationCount: number;
}> = ({
  conditionType,
  resourceType,
  setBulkEditingConditionType,
  bulkEditingConditionType,
  destinationCount,
}) => {
  const { handleSubmit, setValue } =
    useFormContext<BulkManageMonitorConditionFormState>();

  const submit = async (data: BulkManageMonitorConditionFormState) => {
    const values = data[conditionType];

    const enabled = values.bulk?.enabled ?? false;
    const errorValue = values.bulk?.error_value;
    const warningValue = values.bulk?.warning_value;

    // Only merge in bulk values if something has changed.
    // If we blindly merge values.bulk sometimes the form will be left in an unexpected state.
    const updates = {
      enabled,
      ...(valueIsEmpty(conditionType, errorValue)
        ? {}
        : { error_value: errorValue }),
      ...(valueIsEmpty(conditionType, warningValue)
        ? {}
        : { warning_value: warningValue }),
    };

    setValue<any>(
      `${conditionType}`,
      {
        destinations: Object.entries(values.destinations).reduce(
          (destinations, [id, destination]) => ({
            ...destinations,
            [id]: {
              ...destination,
              ...updates,
            },
          }),
          {},
        ),
      },
      {
        shouldDirty: true,
      },
    );
    setBulkEditingConditionType(null);
  };

  const isEditing = bulkEditingConditionType === conditionType;

  const sx = {
    bg: "base.lightBackground",
    p: 4,
  };

  if (isEditing) {
    return (
      <MonitorConditionForm
        sx={sx}
        title={
          <Text fontWeight="medium">
            {ConditionFriendlyNames[conditionType]}
          </Text>
        }
        action={
          <ButtonGroup>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              onClick={handleSubmit(submit)}
            >
              Apply to {destinationCount}{" "}
              {pluralize("destination", destinationCount)}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setBulkEditingConditionType(null);
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        }
        name="bulk"
        conditionType={conditionType}
        resourceType={resourceType}
      />
    );
  }

  return (
    <>
      <Row sx={sx} gap={2}>
        <Text fontWeight="medium">{ConditionFriendlyNames[conditionType]}</Text>
      </Row>
      <Box sx={sx} gridColumn="span 2">
        {destinationCount > 1 ? (
          <Button
            icon={EditIcon}
            size="sm"
            onClick={() => {
              setBulkEditingConditionType(conditionType);
            }}
          >
            Bulk edit {destinationCount}{" "}
            {pluralize("destination", destinationCount)}
          </Button>
        ) : (
          <Box />
        )}
      </Box>
    </>
  );
};

export const ResourceMonitoringTextButtonStyles = {
  sx: {
    _hover: {
      color: "link.hover",
    },
  },
  color: "link.default",
  cursor: "pointer",
  fontWeight: "medium" as const,
};

export const BulkDestinationAlertTriggerManagement: FC = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const [bulkEditingConditionType, setBulkEditingConditionType] =
    useState<MonitorConditionTypes | null>(null);

  const selectedDestinationIds = useMemo(
    () => search.get("destinationIds")?.split(",") ?? [],
    [search],
  );

  const resourceType = MonitoredResourceType.Sync as const; // Get this from search when we add new resource types: search.get("resourceType");

  const { data } = useResourceTypeMonitorConditionsQuery(
    {
      resourceType: MonitoredResourceType.Sync,
      parentResourceType: ParentResourceTypes.Destination,
      includeTemplates: true,
    },
    { suspense: true },
  );

  const { data: syncsAndDestinations } = useSubscribeableResourcesQuery(
    undefined,
    { suspense: true },
  );

  const { data: eventSyncDestinations } = useEventDestinationsQuery(
    { forwardingTypes: [] },
    {
      suspense: true,
      select: (data) =>
        data.event_warehouse_destinations
          .map((destination) => {
            const destinationType = enumOrFallback(
              EventWarehouseDestinationType,
              undefined,
              false,
            )(destination.type);

            return destinationType
              ? {
                  ...destination,
                  type: destinationType,
                }
              : undefined;
          })
          .filter(isPresent),
    },
  );

  const { mutateAsync: createDefaultMonitorConditions } =
    useInsertDefaultMonitorConditionsMutation();

  const destinations = useMemo(
    () =>
      resourceType === MonitoredResourceType.Sync
        ? syncsAndDestinations?.destinations.map((destination) => ({
            ...destination,
            option: {
              label: destination.name || destination.definition.name,
              value: String(destination.id.toString()),
              icon: destination.definition.icon,
            },
          })) || []
        : [],
    [resourceType, syncsAndDestinations, eventSyncDestinations],
  );

  const filteredDestinations = useMemo(
    () =>
      destinations.filter(
        (destination) =>
          selectedDestinationIds?.includes(destination?.id.toString()),
      ) || [],
    [syncsAndDestinations, selectedDestinationIds],
  );

  const [monitorConditionFormDefaults, defaultOverrides] = useMemo(() => {
    const defaults: BulkManageMonitorConditionFormState = {
      [MonitorConditionTypes.SyncSequentialFailures]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncRejectedRows]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncDuration]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncMissedSchedules]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncThroughput]: {
        destinations: {},
      },
      [MonitorConditionTypes.ModelSize]: {
        destinations: {},
      },
    };

    // Keeps track of the number of syncs overriding a given condition type
    // for a destination
    const overrides: Record<
      BulkEditableMonitorConditionTypes,
      { destinations: Record<string, string[]> }
    > = {
      [MonitorConditionTypes.SyncSequentialFailures]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncRejectedRows]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncDuration]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncMissedSchedules]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncThroughput]: {
        destinations: {},
      },
      [MonitorConditionTypes.ModelSize]: {
        destinations: {},
      },
    };

    const destinationToSyncCount: Record<string, number> = {};

    for (const destination of filteredDestinations) {
      destinationToSyncCount[destination.id.toString()] =
        destination.syncs_aggregate.aggregate?.count ?? 0;
      if (!data) continue;
      const existingDestinationSyncConditions =
        data.monitor_condition_templates?.filter(
          (condition) =>
            condition.parent_resource_id === destination.id.toString() &&
            isSupportedConditionForResourceType(resourceType, condition.type),
        ) || [];

      const destinationId = destination.id.toString();

      for (const conditionType of SupportedConditionsForResourceTypes[
        resourceType
      ]) {
        defaults[conditionType]["destinations"][destinationId] = {
          warning_value: null,
          error_value: defaultValueForSyncConditionType[conditionType],
          enabled: false,
          parent_resource_id: destinationId,
          parent_resource_type: ParentResourceTypes.Destination,
        };
      }

      const destinationSyncIds =
        syncsAndDestinations?.syncs
          ?.filter((sync) => sync?.destination?.id.toString() === destinationId)
          ?.map((sync) => sync.id.toString()) || [];

      if (!existingDestinationSyncConditions.length) continue;

      for (const condition of existingDestinationSyncConditions) {
        const syncsOverridingCondition =
          data.resource_monitor_conditions.filter(
            (rmc) =>
              destinationSyncIds.includes(rmc.resource_id) &&
              rmc.resource_type === MonitoredResourceType.Sync &&
              rmc.type === condition.type &&
              isOverride(
                getMinimalCondition(rmc),
                getMinimalCondition(condition),
              ),
          );
        defaults[assertedConditionType(condition.type)]["destinations"][
          destinationId
        ] = {
          warning_value: condition.warning_value,
          enabled: condition.enabled,
          error_value: condition.error_value,
          parent_resource_id: condition.parent_resource_id,
          parent_resource_type: condition.parent_resource_type,
        };

        overrides[assertedConditionType(condition.type)]["destinations"][
          destinationId
        ] = syncsOverridingCondition.map((rmc) => rmc.resource_id);
      }
    }
    return [defaults, overrides, destinationToSyncCount];
  }, [data, selectedDestinationIds, filteredDestinations]);

  const form = useHightouchForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(monitorConditionSchema(true)),
    errorMessage: "See errors below.",
    values: monitorConditionFormDefaults,
    onSubmit: async (data) => {
      const args: InsertDefaultMonitorConditionsMutationVariables["objects"] =
        [];

      for (const [conditionType, state] of Object.entries(data)) {
        for (const [destinationId, condition] of Object.entries(
          state.destinations,
        )) {
          if (condition.enabled !== undefined) {
            const ConditionType = assertedConditionType(conditionType);
            args.push({
              type: ConditionType,
              parent_resource_id: destinationId,
              parent_resource_type: ParentResourceTypes.Destination,
              error_value: condition.error_value,
              warning_value: condition.warning_value,
              enabled: condition.enabled,
              evaluation_trigger:
                MonitorConditionEvaluationProperties[ConditionType]
                  .EvaluationTrigger,
              evaluation_type:
                MonitorConditionEvaluationProperties[ConditionType]
                  .EvaluationType,
            });
          }
        }
      }
      await createDefaultMonitorConditions({ objects: args });
    },
  });

  return (
    <Form form={form}>
      <Column
        p={6}
        bg="base.lightBackground"
        gap={6}
        borderBottom="1px"
        borderColor="base.border"
      >
        <Column width="80%">
          <SectionHeading>
            Set default alert triggers by destination
          </SectionHeading>
          <Paragraph>
            Triggers are the conditions under which syncs become unhealthy and
            send alerts. In the form below, you can customize the default
            triggers for each destination in this workspace. Default triggers
            are automatically inherited by all syncs to each destination but can
            be overridden at the sync level.
          </Paragraph>
        </Column>

        <Row justify="space-between">
          <Row gap={4}>
            {/* Add back once we have multiple resource types (e.g., event syncs)
            <FormField label="Resource type">
              <Select
                width="3xs"
                options={BulkEditableResourceTypes.map((type) => ({
                  label: capitalize(MonitoredResourceTypeToLabel[type]),
                  value: type,
                }))}
                value={resourceType}
                onChange={(value) => {
                  navigate({
                    pathname: ".",
                    search: createSearchParams({
                      resourceType: value || "",
                      destinationIds: selectedDestinationIds,
                    }).toString(),
                  });
                }}
                placeholder="Select a resource type"
              />
            </FormField> */}

            <FormField label="Destinations">
              <Column gap={1}>
                <TagInput
                  width="md"
                  optionAccessory={(option) => ({
                    type: "image",
                    url: option.icon,
                  })}
                  placeholder="Select one or more destinations..."
                  options={destinations.map(
                    (destination) => destination.option,
                  )}
                  value={selectedDestinationIds}
                  supportsCreatableOptions={false}
                  onChange={(ids) => {
                    if (ids.length) {
                      navigate({
                        pathname: ".",
                        search: createSearchParams({
                          resourceType: resourceType || "",
                          destinationIds: ids.join(","),
                        }).toString(),
                      });
                    } else {
                      navigate(".");
                    }
                  }}
                />
                {selectedDestinationIds?.length > 0 && (
                  <Text color="text.secondary">
                    {selectedDestinationIds?.length} destinations selected
                  </Text>
                )}
              </Column>
            </FormField>
            <Row flexShrink={0} gap={4} pt={8}>
              <Text
                {...ResourceMonitoringTextButtonStyles}
                onClick={() =>
                  navigate({
                    pathname: ".",
                    search: createSearchParams({
                      destinationIds:
                        destinations?.map((d) => d.id.toString()).join(",") ??
                        "",
                    }).toString(),
                  })
                }
              >
                Select all
              </Text>
              <Text
                {...ResourceMonitoringTextButtonStyles}
                onClick={() => navigate(".")}
              >
                Clear
              </Text>
            </Row>
          </Row>
          <DestinationTriggerGlobalOptions
            filteredResources={filteredDestinations}
          />
        </Row>
      </Column>

      <Column flex={1}>
        {filteredDestinations.length > 0 && resourceType ? (
          <BulkManagementTable>
            {SupportedConditionsForResourceTypes[resourceType].map(
              (conditionType) => {
                return (
                  <Fragment key={conditionType}>
                    <ConditionRowHeading
                      conditionType={conditionType}
                      resourceType={resourceType}
                      setBulkEditingConditionType={setBulkEditingConditionType}
                      bulkEditingConditionType={bulkEditingConditionType}
                      destinationCount={filteredDestinations.length}
                    />
                    {filteredDestinations.map((destination, idx) => {
                      return (
                        <MonitorConditionForm
                          isObscured={
                            bulkEditingConditionType === conditionType
                          }
                          conditionType={conditionType}
                          resourceType={resourceType}
                          title={
                            <DestinationRowDecoration
                              monitorConditionType={conditionType}
                              overridingSyncsCount={
                                defaultOverrides[conditionType].destinations[
                                  destination.id.toString()
                                ]?.length || 0
                              }
                              destination={destination}
                            />
                          }
                          key={`${idx}-${destination.id}`}
                          name={`destinations.${destination.id}`}
                        />
                      );
                    })}
                  </Fragment>
                );
              },
            )}
          </BulkManagementTable>
        ) : (
          <EmptyState
            message="Select one or more destinations above to view their default alert triggers"
            m={6}
          />
        )}
      </Column>

      <ActionBar fit>
        <FormActions
          confirmation={{
            title: "Confirm changes",
            message:
              "Updating default triggers will affect the alerting configuration for the syncs associated with the selected destinations.",
          }}
        />
      </ActionBar>
    </Form>
  );
};

const conditionSchema = (
  bulk: boolean,
  valueSchema: y.ObjectSchema | y.NumberSchema,
) =>
  y.lazy((value: any) => {
    if (value) {
      const schema = y
        .object()
        .shape({
          enabled: y.boolean(),
          error_value: valueSchema.nullable().optional(),
          warning_value: valueSchema.nullable().optional(),
        })
        .required();
      if (bulk) {
        return y.object().shape({
          destinations: y.object().shape(
            Object.keys(value.destinations).reduce(
              (acc, destinationId) => ({
                ...acc,
                [destinationId]: schema,
              }),
              {},
            ),
          ),
        });
      } else {
        return schema;
      }
    }
    return y.object();
  });

export const monitorConditionSchema = (
  bulk: boolean,
): y.ObjectSchema<
  y.Shape<
    object | undefined,
    {
      [k in BulkEditableMonitorConditionTypes]: y.Lazy;
    }
  >,
  object
> =>
  y.object().shape({
    [MonitorConditionTypes.SyncRejectedRows]: conditionSchema(
      bulk,
      y.object().shape({
        pctRejectedRows: y.number().min(0).max(100).nullable().optional(),
        rejectedRowCount: y.number().min(0).nullable().optional(),
      }),
    ),
    [MonitorConditionTypes.SyncDuration]: conditionSchema(
      bulk,
      y.number().min(10).max(1440),
    ),
    [MonitorConditionTypes.SyncMissedSchedules]: conditionSchema(
      bulk,
      y.number().min(10).max(1440),
    ),
    [MonitorConditionTypes.SyncSequentialFailures]: conditionSchema(
      bulk,
      y.number().min(0).max(100),
    ),
    [MonitorConditionTypes.SyncThroughput]: conditionSchema(
      bulk,
      y.object().shape({
        count: y.number().min(0).max(1e8),
        sign: y.string().oneOf(["gt", "lt"]),
        interval: y
          .string()
          .oneOf(["7 days", "2 days", "1 day", "4 hours", "1 hour"]),
      }),
    ),
    [MonitorConditionTypes.EventSourceVolume]: conditionSchema(
      bulk,
      y.object().shape({
        sign: y.string().oneOf(["gt", "lt", "gtlt"]).required(),
        interval: y.string().oneOf(EventSourceVolumeInterval).required(),
        count: y
          .number()
          .min(0)
          .max(1e8)
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(),
          }),
        min: y
          .number()
          .min(0)
          .max(1e8)
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        max: y
          .number()
          .min(0)
          .max(1e8)
          .moreThan(y.ref("min"), "x events must be greater than min")
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
    [MonitorConditionTypes.ModelSize]: conditionSchema(
      bulk,
      y.array(
        y.object().shape({
          sign: y.string().oneOf(["gt", "lt"]).required(),
          count: y.number().min(0).max(1e8),
        }),
        // We need to use typebox yup is such a pain to fix
      ) as unknown as y.ObjectSchema,
    ),
  });

export const BulkManagementTable = ({ children }) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 2fr 1fr">
      <Box
        p={4}
        pl={6}
        textTransform="uppercase"
        fontWeight="semibold"
        color="text.secondary"
        borderBottom="1px"
        borderColor="base.border"
      >
        Destination
      </Box>
      <Box
        p={4}
        textTransform="uppercase"
        fontWeight="semibold"
        color="text.secondary"
        borderBottom="1px"
        borderColor="base.border"
      >
        Trigger
      </Box>
      <Box
        p={4}
        textTransform="uppercase"
        fontWeight="semibold"
        color="text.secondary"
        borderBottom="1px"
        borderColor="base.border"
      >
        Action
      </Box>
      {children}
    </Box>
  );
};
