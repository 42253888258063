import { FC, useEffect, useState } from "react";

import {
  Column,
  useToast,
  Heading,
  TextInput,
  FormField,
  Row,
  Text,
  Box,
  SectionHeading,
  Spinner,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import Helmet from "react-helmet";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "src/router";
import * as yup from "yup";

import { EventDestinationForm } from "src/events/destinations/setup";
import {
  eventForwardingDestinationDefinitions,
  eventWarehouseDestinationDefinitions,
  EventWarehouseDestinationType,
} from "src/events/types";
import {
  useCreateDestinationV2Mutation,
  useCreateEventWarehouseDestinationMutation,
} from "src/graphql";
import { TrackView } from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";
import { Wizard, Step } from "src/components/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { SetupForm } from "src/components/destinations/destination-form";
import { useDestinationDefinitions } from "src/hooks/use-destination-definitions";
import { useUser } from "src/contexts/user-context";

type FormState = {
  name: string;
  type: EventWarehouseDestinationType;
};

export const CreateEventWarehouseDestination: FC = () => {
  const { user } = useUser();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useResourceSlug(
    SlugResourceType.EventWarehouseDestinations,
  );
  const [config, setConfig] = useState<Record<string, unknown>>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();

  const createEventWarehouseMutation =
    useCreateEventWarehouseDestinationMutation();

  const createDestinationMutation = useCreateDestinationV2Mutation();

  const form = useForm<FormState>({
    resolver: yupResolver(validationSchema),
  });

  const { data: destinationDefinitions } = useDestinationDefinitions();

  const type = form.watch("type");
  const isForwarding =
    eventForwardingDestinationDefinitions[type] !== undefined;
  const definition =
    eventWarehouseDestinationDefinitions[type] ??
    eventForwardingDestinationDefinitions[type];
  const destinationDefinition = destinationDefinitions?.find(
    (d) => d.type === type,
  );

  const submit = async (data) => {
    try {
      const slug = await getSlug(data.name);
      let id;
      if (isForwarding) {
        const response = await createDestinationMutation.mutateAsync({
          destination: {
            slug,
            name: data.name,
            config,
            setup_complete: true,
            created_by: String(user?.id),
            type: definition?.type,
          },
        });
        id = response.createDestinationWithSecrets.id;
      } else {
        const response = await createEventWarehouseMutation.mutateAsync({
          input: {
            slug,
            type: data.type,
            name: data.name,
            config,
            tunnel_id: tunnelId ?? undefined,
            credential_id: credentialId ?? undefined,
          },
        });
        id = response.createEventWarehouseDestination.id;
      }
      toast({
        id: "create-event-destination",
        title: "Event destination was created",
        variant: "success",
      });

      navigate(`/events/destinations/${id}`);
    } catch (error) {
      captureException(error);
      toast({
        id: "create-event-destination",
        title: "Event destination could not be created",
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Select a destination",
      header: <Heading>Select an event destination</Heading>,
      continue: "Click a destination to continue",
      render: () => (
        <Controller
          name="type"
          render={({ field }) => (
            <Column gap={8}>
              <Column gap={4}>
                <SectionHeading>Event storage</SectionHeading>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(auto-fit, 300px)"
                  gap={4}
                  maxWidth="800px"
                >
                  {Object.entries(eventWarehouseDestinationDefinitions).map(
                    ([type, definition]) => (
                      <Selectable
                        key={type}
                        selected={false}
                        onSelect={() => {
                          field.onChange(type);
                          setStep((step) => step + 1);
                        }}
                      >
                        <Row align="center" p={5} gap={4}>
                          <Box as="img" src={definition.icon} width="36px" />
                          <Text fontWeight="medium" size="lg">
                            {definition.name}
                          </Text>
                        </Row>
                      </Selectable>
                    ),
                  )}
                </Box>
              </Column>
              <Column gap={4}>
                <SectionHeading>Event forwarding</SectionHeading>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(auto-fit, 300px)"
                  gap={4}
                  maxWidth="800px"
                >
                  {Object.entries(eventForwardingDestinationDefinitions).map(
                    ([type, definition]) => (
                      <Selectable
                        key={type}
                        selected={false}
                        onSelect={() => {
                          field.onChange(type);
                          setStep((step) => step + 1);
                        }}
                      >
                        <Row align="center" p={5} gap={4}>
                          <Box as="img" src={definition.icon} width="36px" />
                          <Text fontWeight="medium" size="lg">
                            {definition.name}
                          </Text>
                        </Row>
                      </Selectable>
                    ),
                  )}
                </Box>
              </Column>
            </Column>
          )}
        />
      ),
    },
    {
      title: `Connect destination`,
      continueProps: isForwarding
        ? { form: "destination-form", type: "submit" }
        : { form: "event-destination-form", type: "submit" },
      disabled: tunnelId === null,
      onContinue: () => {},
      header: (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <Heading>{`Connect to ${definition?.name}`}</Heading>
        </Row>
      ),
      render: () => {
        if (!definition) {
          return <Spinner size="lg" m="auto" />;
        }

        if (isForwarding && destinationDefinition) {
          return (
            <Column flex={1} gap={8}>
              <SetupForm
                config={config}
                credentialId={credentialId}
                definition={destinationDefinition}
                isSetup={true}
                setConfig={setConfig}
                setCredentialId={setCredentialId}
                onSubmit={() => {
                  setStep(step + 1);
                  return Promise.resolve();
                }}
              />
            </Column>
          );
        }

        return (
          <Column flex={1} gap={8}>
            <EventDestinationForm
              config={config}
              credentialId={credentialId}
              definition={definition as any}
              disableAuthMethod={true}
              isSetup={true}
              setConfig={setConfig}
              setCredentialId={setCredentialId}
              setTunnelId={setTunnelId}
              tunnelId={tunnelId}
              onConnectClick={() => {}}
              onSubmit={async () => {
                setStep(step + 1);
              }}
            />
          </Column>
        );
      },
    },
    {
      title: "Finalize",
      header: <Heading>Finalize settings</Heading>,
      render: () => {
        return (
          <Column gap={8} maxWidth="576px" width="100%">
            <Controller
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormField label="Name">
                  <TextInput {...field} isInvalid={Boolean(error)} autoFocus />
                </FormField>
              )}
            />
          </Column>
        );
      },
    },
  ];

  useEffect(() => {
    if (type) {
      setConfig({});
    }
  }, [type]);

  return (
    <>
      <Helmet>
        <title>New event destination</title>
      </Helmet>
      <TrackView name="New Event Destination Page" />
      <FormProvider {...form}>
        <Wizard
          setStep={setStep}
          step={step}
          steps={steps}
          title="New event destination"
          onCancel={() => {
            navigate("/events/destinations");
          }}
          onSubmit={form.handleSubmit(submit)}
        />
      </FormProvider>
    </>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
});
