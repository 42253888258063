import { useState } from "react";

import {
  Box,
  Button,
  FormField,
  Textarea,
  TextInput,
  Spinner,
  Text,
  ClipboardButton,
  Tooltip,
  ButtonGroup,
  Column,
  SuccessIcon,
  ErrorIcon,
  RadioGroup,
  Radio,
  Dialog,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { useUser } from "src/contexts/user-context";
import {
  useCreateNormalTunnelMutation,
  useCreateReverseTunnelMutation,
  useTestTunnelQuery,
  CreateNormalTunnelMutation,
  CreateReverseTunnelMutation,
} from "src/graphql";

import { downloadText } from "src/utils/download";
import { getHightouchRegionConfig } from "src/utils/regions";
import { PermissionedButton } from "src/components/permission";

export const ChooseTunnelForm = ({
  onClose,
  onContinue,
  tunnelType,
  setTunnelType,
}: {
  onClose: () => void;
  onContinue: () => void;
  tunnelType: "normal" | "reverse" | undefined;
  setTunnelType: (tunnelType: "normal" | "reverse") => void;
}) => {
  return (
    <Dialog
      isOpen
      variant="form"
      width="lg"
      title="Create an SSH tunnel"
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onContinue}>
            Continue
          </Button>
        </>
      }
      onClose={onClose}
    >
      <FormField
        label="Select tunnel type"
        description={
          <Text>
            Visit{" "}
            <Link href="https://hightouch.com/docs/workspace-management/sso">
              our docs
            </Link>{" "}
            to learn more about these tunneling options.
          </Text>
        }
      >
        <RadioGroup
          mt={3}
          value={tunnelType}
          onChange={(value) => setTunnelType(value as "normal" | "reverse")}
        >
          <Radio
            label="Standard tunnel"
            description="Requires you to run `sshd` on a bastion host accessible from the public internet. Hightouch will first open an SSH connection to your bastion host, then open a port forwarding connection to the source within your private network."
            value="normal"
          />
          <Radio
            label="Reverse tunnel"
            description="Allows you to forward a port by connecting as a client to an SSH server managed by Hightouch. This eliminates the need for a bastion host in your infrastructure but requires you to maintain the connection."
            value="reverse"
          />
        </RadioGroup>
      </FormField>
    </Dialog>
  );
};

export const CreateNormalTunnelForm = ({
  onClose,
  onCreate,
}: {
  onClose: () => void;
  onCreate?: (
    data: CreateNormalTunnelMutation["createNormalTunnel"],
    name: string,
  ) => void | Promise<void>;
}) => {
  const [sshHost, setSSHHost] = useState("");
  const [sshPort, setSSHPort] = useState("");
  const [serviceHost, setServiceHost] = useState("");
  const [servicePort, setServicePort] = useState("");
  const [name, setName] = useState("");

  const {
    isLoading: creating,
    data,
    mutateAsync: createNormalTunnel,
  } = useCreateNormalTunnelMutation();

  const tunnel = data?.createNormalTunnel;

  return (
    <Dialog
      isOpen
      variant="form"
      width="lg"
      title="Create an SSH tunnel"
      actions={
        tunnel ? (
          <Button variant="secondary" onClick={onClose}>
            Done
          </Button>
        ) : (
          <ButtonGroup>
            <Button onClick={onClose}>Close</Button>
            <PermissionedButton
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              isDisabled={Boolean(
                !name ||
                  !sshHost ||
                  !sshPort ||
                  !servicePort ||
                  !serviceHost ||
                  isNaN(Number(sshPort)) ||
                  isNaN(Number(servicePort)),
              )}
              isLoading={creating}
              variant="primary"
              onClick={async () => {
                const data = await createNormalTunnel({
                  input: {
                    name,
                    sshHost: btoa(sshHost),
                    sshPort: Number(sshPort),
                    serviceHost: btoa(serviceHost),
                    servicePort: Number(servicePort),
                  },
                });
                onCreate && (await onCreate(data.createNormalTunnel, name));
              }}
            >
              Create tunnel
            </PermissionedButton>
          </ButtonGroup>
        )
      }
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        {tunnel ? (
          <>
            <FormField
              description="Add this to the `~/.ssh/authorized_keys` file for the `hightouch` user on your bastion server. You can use `ssh-copy-id` to help with this."
              label="Public key"
            >
              <Column gap={3} align="flex-start">
                <Textarea
                  width="100%"
                  isReadOnly
                  resize="none"
                  value={tunnel.publicKey ?? ""}
                />

                <Button
                  variant="primary"
                  onClick={() => {
                    if (tunnel.publicKey) {
                      downloadText(tunnel.publicKey, "key.pem");
                    }
                  }}
                >
                  Download public key
                </Button>
              </Column>
            </FormField>

            <TestTunnel id={tunnel.tunnel?.id} />
          </>
        ) : (
          <>
            <FormField label="Display name">
              <TextInput
                placeholder="Tunnel to marketing database in VPC"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormField>

            <FormField
              label="SSH host"
              tip="This is your public-facing bastion server."
            >
              <TextInput
                placeholder="127.0.0.1"
                value={sshHost}
                onChange={(event) => setSSHHost(event.target.value)}
              />
            </FormField>

            <FormField
              label="SSH port"
              tip="This is your public-facing bastion server."
            >
              <TextInput
                placeholder="22"
                value={sshPort}
                onChange={(event) => setSSHPort(event.target.value)}
              />
            </FormField>

            <FormField
              label="Service host"
              tip="This is the service inside your private network."
            >
              <TextInput
                placeholder="127.0.0.1"
                value={serviceHost}
                onChange={(event) => setServiceHost(event.target.value)}
              />
            </FormField>

            <FormField
              label="Service port"
              tip="This is the service inside your private network."
            >
              <TextInput
                placeholder="5432"
                value={servicePort}
                onChange={(event) => setServicePort(event.target.value)}
              />
            </FormField>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export const CreateReverseTunnelForm = ({
  onClose,
  onCreate,
}: {
  onClose: () => void;
  onCreate?: (
    data: CreateReverseTunnelMutation["createReverseTunnel"],
    name: string,
  ) => void | Promise<void>;
}) => {
  const { region } = useUser();
  const [name, setName] = useState<string>();
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const {
    isLoading: creating,
    data,
    mutateAsync: createReverseTunnel,
  } = useCreateReverseTunnelMutation();

  const tunnel = data?.createReverseTunnel;
  const privateKey = tunnel?.privateKeyBase64
    ? atob(tunnel?.privateKeyBase64)
    : undefined;

  return (
    <Dialog
      isOpen
      variant="form"
      width="lg"
      title="Create a reverse tunnel"
      actions={
        tunnel ? (
          <Tooltip
            isDisabled={downloaded}
            message="Download your private key first"
          >
            <Button
              isDisabled={!downloaded}
              variant="primary"
              onClick={onClose}
            >
              Done
            </Button>
          </Tooltip>
        ) : (
          <ButtonGroup>
            <Button onClick={onClose}>Cancel</Button>
            <PermissionedButton
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              isDisabled={!name}
              isLoading={creating}
              variant="primary"
              onClick={async () => {
                if (name) {
                  const data = await createReverseTunnel({ input: { name } });
                  onCreate && (await onCreate(data.createReverseTunnel, name));
                }
              }}
            >
              Create reverse tunnel
            </PermissionedButton>
          </ButtonGroup>
        )
      }
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        {tunnel ? (
          <>
            <FormField
              description="You will not be able to access your private key again."
              label="Private key"
            >
              <Column>
                <Textarea isReadOnly resize="none" value={privateKey ?? ""} />

                <Box>
                  <Button
                    mt={3}
                    onClick={() => {
                      if (privateKey) {
                        downloadText(privateKey, "key.pem");
                        setDownloaded(true);
                      }
                    }}
                  >
                    Download private key
                  </Button>
                </Box>
              </Column>
            </FormField>

            <FormField
              description="Run the following command to start the tunnel."
              label="Start SSH"
            >
              <Box display="flex" gap={3}>
                <TextInput
                  isReadOnly
                  value={generateTunnelSSHCommand(tunnel, region)}
                />
                <ClipboardButton
                  text={generateTunnelSSHCommand(tunnel, region)}
                />
              </Box>
            </FormField>

            <TestTunnel id={tunnel.tunnel?.id} />
          </>
        ) : (
          <FormField label="Name">
            <TextInput
              placeholder="postgres-prod"
              value={name ?? ""}
              onChange={(event) => setName(event.target.value)}
            />
          </FormField>
        )}
      </Box>
    </Dialog>
  );
};

function generateTunnelSSHCommand(tunnel: any, region: string): string {
  const regionTunnelConfig = getHightouchRegionConfig(region).tunnels;
  const sshUser = tunnel?.tunnel?.id;
  const sshdHost = regionTunnelConfig.reverseTunnelAddress;
  const bindPort = tunnel?.tunnel?.tunnelPort;
  const sshdPort = regionTunnelConfig.useCentralReverseSSHD
    ? 22
    : tunnel?.tunnel?.sshdPort;

  return `ssh -i path/to/key.pem -R 0.0.0.0:${bindPort}:$SERVICE_HOST:$SERVICE_PORT ${sshUser}@${sshdHost} -p ${sshdPort} -o ExitOnForwardFailure=yes`;
}

const TestTunnel = ({ id }) => {
  const { data, isLoading } = useTestTunnelQuery(
    { id },
    { refetchInterval: 5000 },
  );

  return (
    <FormField label="Current tunnel status">
      <Box alignItems="center" display="flex" gap={1.5}>
        {isLoading ? (
          <Spinner />
        ) : data?.checkTunnel?.success ? (
          <>
            <SuccessIcon color="success.base" />
            <Text>Connection successful</Text>
          </>
        ) : (
          <>
            <ErrorIcon color="danger.base" />
            <Text>No connection</Text>
          </>
        )}
      </Box>
    </FormField>
  );
};
