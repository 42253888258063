import { FC, useState } from "react";

import {
  Box,
  Row,
  Column,
  Text,
  Button,
  ChatIcon,
  Dialog,
} from "@hightouchio/ui";
import { useOutletContext } from "src/router";

import {
  IdrRunsOrderBy,
  OrderBy,
  useIdentityResolutionRunsQuery,
} from "src/graphql";
import { IdentityResolutionStatusBadge } from "src/pages/identity-resolution/status-badge";
import { Markdown } from "src/ui/markdown";
import {
  Pagination,
  SortOption,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { commaNumber } from "src/utils/numbers";
import * as time from "src/utils/time";

import { OutletContext, transformLegacyStatistics } from ".";
import { newPylonMessage } from "src/lib/pylon";

const initialSort: SortOption<keyof IdrRunsOrderBy> = {
  key: "created_at",
  direction: OrderBy.Desc,
  label: "Newest",
};

const sortOptions: SortOption<keyof IdrRunsOrderBy>[] = [
  initialSort,
  { key: "status", direction: OrderBy.Asc, label: "Status" },
  {
    key: "finished_at",
    direction: OrderBy.DescNullsLast,
    label: "Finished",
  },
];

export const Runs: FC = () => {
  const { graph } = useOutletContext<OutletContext>();
  const [runError, setError] = useState<string>();

  const { limit, offset, page, setPage } = useTableConfig<IdrRunsOrderBy>();
  const orderBy = useTableSort<IdrRunsOrderBy>(initialSort, sortOptions);

  const { data, isLoading, error } = useIdentityResolutionRunsQuery(
    {
      limit,
      offset,
      orderBy: orderBy ?? { created_at: "desc" },
      filter: { idr_id: { _eq: graph.id } },
    },
    {
      refetchInterval: 5000,
      // Transform statistics from older IDR runs into the newer format so we don't have special
      // case code all over the place.
      select: (data) => {
        const runs = data.idr_runs.map((run) => {
          run.stats = transformLegacyStatistics(run.stats);
          return run;
        });

        return { count: data.idr_runs_aggregate.aggregate?.count, runs };
      },
    },
  );

  const columns: TableColumn[] = [
    {
      name: "Status",
      cell: ({ status }) => {
        return <IdentityResolutionStatusBadge status={status} />;
      },
    },
    {
      name: "Started",
      cell: ({ started_at, finished_at }) => {
        if (!started_at) {
          return <Text fontWeight="medium">--</Text>;
        }
        const duration = time.diff(
          started_at,
          finished_at ?? new Date().toUTCString(),
        );
        return (
          <Column>
            <TextWithTooltip
              message={time.formatDatetime(started_at) ?? ""}
              fontWeight="medium"
              mb="2px"
            >
              {time.formatDatetime(started_at) ?? "--"}
            </TextWithTooltip>
            <Text size="sm" color="text.secondary">
              Duration: {duration}
            </Text>
          </Column>
        );
      },
    },
    {
      name: "Profiles",
      cell: ({ stats }) => {
        return <ProfileStats stats={stats} />;
      },
    },
    {
      name: "Events",
      cell: ({ stats }) => {
        return <EventStats stats={stats} />;
      },
    },
    {
      name: "",
      cell: ({ error }) => {
        if (error?.error) {
          return (
            <Button
              size="sm"
              ml="auto"
              variant="warning"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setError(error.error);
              }}
            >
              View error
            </Button>
          );
        }

        return null;
      },
    },
  ];

  return (
    <>
      <Column gap={4}>
        <Table
          sortOptions={sortOptions}
          columns={columns}
          data={data?.runs ?? []}
          loading={isLoading}
          error={Boolean(error)}
          placeholder={{
            title: "No runs",
            error: "Runs failed to load, please try again.",
          }}
        />
        <Pagination
          count={data?.count ?? 0}
          label="runs"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Column>
      <ErrorModal error={runError} onClose={() => setError(undefined)} />
    </>
  );
};

export const ProfileStats = ({ stats }) => {
  return (
    <Row gap={4}>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.profiles ? commaNumber(stats.profiles.num_ht_ids) : "--"}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Resolved
        </Box>
      </Column>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.profiles ? commaNumber(stats.profiles.source_rows) : "--"}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Total
        </Box>
      </Column>
    </Row>
  );
};

export const EventStats = ({ stats }) => {
  return (
    <Row gap={4}>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.events ? commaNumber(stats.events.num_ht_ids) : "--"}{" "}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Resolved
        </Box>
      </Column>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.events ? commaNumber(stats.events.source_rows) : "--"}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Total
        </Box>
      </Column>
    </Row>
  );
};

const ErrorModal = ({ error, onClose }) => {
  return (
    <Dialog
      isOpen={Boolean(error)}
      variant="info"
      width="xl"
      title="Identity graph error"
      actions={
        <>
          <Button
            icon={ChatIcon}
            size="md"
            variant="secondary"
            onClick={() =>
              newPylonMessage(
                `I'm experiencing an issue with my identity graph and could use some assistance. The error message I'm receiving is: "${error}".
                Here's a link to the graph: <a href="${window.location.href}">${window.location.href}</a>.`,
              )
            }
          >
            Chat with support
          </Button>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </>
      }
      onClose={onClose}
    >
      <Column flex={1} sx={{ overflow: "auto" }}>
        <Column borderBottom="1px" borderColor="base.border" pb={6}>
          <Box
            bg="gray.50"
            p={2}
            borderRadius="md"
            border="1px"
            borderColor="base.border"
          >
            <Markdown>{error}</Markdown>
          </Box>
        </Column>
        <Column mt={6}>
          <Text fontWeight="medium" size="lg">
            Need more help?
          </Text>
          <Text mt={2}>
            If you feel stuck, please reach out! We want to make sure you have
            all the help you need. Our team is available via chat to help you
            troubleshoot this error.
          </Text>
        </Column>
      </Column>
    </Dialog>
  );
};
