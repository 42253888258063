import { FC, PropsWithChildren } from "react";

import { Column, BoxProps } from "@hightouchio/ui";

export const SIZES = {
  small: "818px",
  medium: "1288px",
  large: "1448px",
  xlarge: "1640px",
  page: "1200px",
  full: "unset",
};

export type ContainerSize = keyof typeof SIZES;

export type Props = {
  size?: string;
  center?: boolean;
} & BoxProps;

export const Container: FC<PropsWithChildren<Props>> = ({
  children,
  center = true,
  size = "large",
  ...props
}) => {
  return (
    <Column
      width="100%"
      mx={center ? "auto" : 0}
      flex={1}
      maxWidth={SIZES[size] ?? size}
      {...props}
    >
      {children}
    </Column>
  );
};
