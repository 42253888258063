import {
  Box,
  CloseIcon,
  Column,
  GroupedCombobox,
  IconButton,
  PlusIcon,
  Row,
  SectionHeading,
  Text,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";
import { isPresent } from "ts-extras";

import { RelationshipFragment } from "src/graphql";
import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { useAnalyticsContext } from "./state";
import {
  GraphType,
  GroupByColumn,
  GroupByOptionColumnReference,
  GroupByOption,
  ParentModel,
  GroupByValue,
} from "./types";
import { isEqual, uniqBy } from "lodash";
import {
  getEventById,
  getMetricById,
  getUnsupportedMetricNamesForGroupByColumn,
  isGroupByColumnRelatedToParent,
} from "./utils";

// Group the shared event properties by name into one since we will treat this
// as one groupBy in the graph
export const transformGroupByColumns = (
  groupByColumns: (GroupByColumn | undefined)[],
  parent: ParentModel | null,
): GroupByValue => {
  if (!groupByColumns || !groupByColumns.length) return [];

  // Make a map of the column name -> column references (name can point to
  // multiple columns for shared event properties). Note that this can
  // be undefined to represent an empty GroupBy to be filled out
  const columnsByNameMap = new Map<string, GroupByColumn[] | undefined>();
  for (const gb of groupByColumns) {
    // Don't group event columns with parent columns
    const columnModelId = getModelIdFromColumn(gb);
    const colNamePrefix = isGroupByColumnRelatedToParent(parent, gb)
      ? `${columnModelId}-`
      : "";

    const columnName = colNamePrefix + getPropertyNameFromColumn(gb);

    if (!gb) {
      columnsByNameMap.set(columnName, undefined);
    } else {
      const columns = columnsByNameMap.get(columnName) ?? [];
      columns.push(gb);
      columnsByNameMap.set(columnName, columns);
    }
  }

  const groupBys: GroupByValue = [];
  for (const [_name, columns] of columnsByNameMap) {
    if (!columns || !columns.length) {
      groupBys.push(undefined);
    } else {
      const groupBy = columns.length === 1 ? columns[0] : columns;
      if (groupBy) groupBys.push(groupBy);
    }
  }

  return groupBys;
};

export const getSameNameEventColumnsOptions = (
  events: RelationshipFragment[],
): GroupByOption[] => {
  if (events.length === 1) {
    return (events[0]?.to_model?.filterable_audience_columns ?? []).map(
      (column) => ({
        groupLabel: null,
        name: column.alias ?? column.name,
        columnReference: column.column_reference,
      }),
    );
  }

  const columnsByNameMap = new Map<string, GroupByColumn[]>();
  for (const event of events) {
    for (const col of event.to_model.filterable_audience_columns ?? []) {
      if (!col) continue;

      const columnName = getPropertyNameFromColumn(col.column_reference);
      if (!columnName) continue;

      const columns = columnsByNameMap.get(columnName) ?? [];
      columns.push(col.column_reference);
      columnsByNameMap.set(columnName, columns);
    }
  }

  const groupByOptions: GroupByOption[] = [];
  for (const [name, columns] of columnsByNameMap) {
    if (columns.length === events.length) {
      groupByOptions.push({
        groupLabel: null,
        name,
        columnReference: columns,
      });
    }
  }

  return groupByOptions;
};

const convertValueToArray = (
  value: Array<unknown> | unknown | undefined,
): Array<unknown> => {
  return Array.isArray(value) ? value : [value];
};

// XXX: Current groupByColumn options contain the transformed index while the
// state's groupByColumn is a flattened array of GroupBys. We need to make sure
// we update the state groupByColumn at the correct index and remove any flattened
// shared events (i.e. multiple groupByColumns that are grouped together in the
// the graph by name)
export const getColumnsAndIndexToUpdate = ({
  value,
  optionIndex,
  groupByColumns,
  groupByValues,
}: {
  value: GroupByOptionColumnReference | undefined;
  optionIndex: number;
  groupByColumns: (GroupByColumn | undefined)[];
  groupByValues: GroupByValue;
}): { columns: (GroupByColumn | undefined)[]; startIndex: number } => {
  const currentGroupByValue = convertValueToArray(groupByValues[optionIndex]);
  const firstGroupByIndex = groupByColumns.findIndex((gb) =>
    isEqual(gb, currentGroupByValue[0]),
  );

  // Default to end of the list if we are not replacing existing groupByColumns
  const startIndex =
    firstGroupByIndex !== -1 ? firstGroupByIndex : groupByColumns.length - 1;

  const arrayValue = convertValueToArray(value) as (
    | GroupByColumn
    | undefined
  )[];

  // Grab the rest of the array so we can easily remove the current GroupByColumns
  // in the case there are multiple underlying columns
  const restOfGroupBys = groupByColumns
    .slice(firstGroupByIndex + currentGroupByValue.length)
    .filter(
      // Filter out undefined unless we know there's an empty one in the next option
      (gb) => groupByValues[optionIndex + 1] != undefined && isPresent(gb),
    ) as (GroupByColumn | undefined)[];

  return {
    columns: arrayValue.concat(restOfGroupBys),
    startIndex,
  };
};

export const GroupBy = () => {
  const {
    addGroupByColumn,
    addGroupByColumns,
    removeGroupByColumns,
    groupByColumns,
    parent,
    graphType,
    metricSelection,
    metrics,
    events,
  } = useAnalyticsContext();

  const isFunnelGraphType = graphType === GraphType.Funnel;

  // Events are derived from the selected metrics.
  // It's possible that multiple metrics reference the same event, so we need to dedupe the models.
  const selectedEvents = uniqBy(
    metricSelection
      .map((selectedMetric) => {
        const metricDefinition = getMetricById(metrics, selectedMetric.id);
        const eventModelId =
          selectedMetric.eventModelId ?? metricDefinition?.config.eventModelId;

        return getEventById(events, {
          eventModelId,
          relationshipId:
            metricDefinition?.config.relationshipId ?? selectedMetric?.id,
        });
      })
      .filter(isPresent),
    (relationship) => relationship.id,
  );

  const groupByValues = transformGroupByColumns(groupByColumns, parent);

  const groupByOptions: { label: string; options: GroupByOption[] }[] = [
    {
      label: "User properties",
      options:
        parent?.filterable_audience_columns
          .filter(
            ({ column_reference }) =>
              !isFunnelGraphType ||
              // Not supporting merge columns for funnel graphs right now
              (isFunnelGraphType && column_reference.type !== "related"),
          )
          .map((column) => ({
            groupLabel:
              column.model_id === parent.id?.toString()
                ? null
                : column.model_name,
            name: column.alias ?? column.name,
            columnReference: column.column_reference,
          })) ?? [],
    },
    !isFunnelGraphType && selectedEvents.length === 1
      ? {
          label: "Event properties",
          options: selectedEvents.flatMap(({ to_model }) => {
            return to_model.filterable_audience_columns.map((column) => ({
              groupLabel: column.model_id == to_model.id ? null : to_model.name,
              name: column.alias ?? column.name,
              columnReference: column.column_reference,
            }));
          }),
        }
      : // When there are multiple metrics, only show columns of the same name and
        // group these together to represent one groupBy and one column in the graph
        !isFunnelGraphType && selectedEvents.length > 1
        ? {
            label: "Shared events properties",
            options: getSameNameEventColumnsOptions(selectedEvents),
          }
        : null,
  ].filter(isPresent);

  const handleChange = (
    value: GroupByOptionColumnReference | undefined,
    optionIndex: number,
  ) => {
    const { columns, startIndex } = getColumnsAndIndexToUpdate({
      value,
      optionIndex,
      groupByColumns,
      groupByValues,
    });

    addGroupByColumns(columns, startIndex);
  };

  const addEmptyGroupBy = () => {
    addGroupByColumn(undefined, groupByColumns.length);
  };

  const removeGroup = (optionIndex: number) => {
    const currentGroupByValue = convertValueToArray(groupByValues[optionIndex]);
    const firstGroupByIndex = groupByColumns.findIndex((gb) =>
      isEqual(gb, currentGroupByValue[0]),
    );

    // Default to end of the list if we are not removing existing groupByColumns
    const startIndex =
      firstGroupByIndex !== -1 ? firstGroupByIndex : groupByColumns.length - 1;
    const endIndex = startIndex + currentGroupByValue.length - 1;

    removeGroupByColumns(startIndex, endIndex);
  };

  return (
    <>
      <Row align="center" justify="space-between" minHeight="32px">
        <SectionHeading>Group by</SectionHeading>
        {((!isFunnelGraphType && groupByValues.length < 2) ||
          (isFunnelGraphType && groupByValues.length < 1)) && (
          <Tooltip message="Add group by">
            <IconButton
              icon={PlusIcon}
              aria-label="Add group by"
              onClick={addEmptyGroupBy}
            />
          </Tooltip>
        )}
      </Row>
      {groupByValues.map((groupByColumn, index) => {
        // TODO: @jenn-chan update function to handle multiple groupBys value
        const unsupportedMetricNames = !Array.isArray(groupByColumn)
          ? getUnsupportedMetricNamesForGroupByColumn({
              parent,
              groupByColumn,
              metricSelection,
              metrics,
              events: selectedEvents,
            })
          : [];

        return (
          <Column
            key={index}
            bg="white"
            border="1px solid #dbe1e8"
            borderRadius="md"
            gap={2}
            p={2}
          >
            <Row
              gap={2}
              flex={1}
              minWidth={0}
              sx={{
                input: {
                  width: "100%",
                  boxSizing: "border-box",
                },
              }}
            >
              <GroupedCombobox
                optionLabel={({ groupLabel, name }) =>
                  groupLabel ? `${groupLabel} -> ${name}` : name
                }
                optionValue={(column) => column.columnReference}
                optionGroups={groupByOptions}
                placeholder="Select a column to group by..."
                value={groupByColumn}
                variant="heavy"
                onChange={(column) => handleChange(column, index)}
              />
              {(isPresent(groupByColumn) || groupByValues.length > 1) && (
                <Tooltip message="Remove group by column">
                  <IconButton
                    icon={CloseIcon}
                    aria-label="Remove group by column"
                    variant="tertiary"
                    onClick={() => removeGroup(index)}
                  />
                </Tooltip>
              )}
            </Row>
            {unsupportedMetricNames.length > 0 && (
              <Box
                display="grid"
                gridTemplateColumns="28px 1fr"
                alignItems="center"
                color="warning.base"
                fontSize="20px"
                gap={2}
              >
                <WarningIcon ml={2} />
                <Text color="warning.base" size="sm">
                  This “Group by” does not apply to{" "}
                  {unsupportedMetricNames.join(", ")}
                </Text>
              </Box>
            )}
          </Column>
        );
      })}
    </>
  );
};
