import { Alert, Column, FormField, Select, TextInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { WizardDrawer } from "src/components/drawer";
import { Form, useHightouchForm } from "src/components/form";
import { useCreateDecisionEngineMessageMutation } from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { ChannelOptionsMap } from "src/pages/decision-engines/utils";
import { useNavigate, useOutletContext, useParams } from "src/router";
import { getKeyValueObject } from "src/utils/key-value";
import { CollectionSelect } from "./message/components/collection-select";
import { SendLimit } from "./message/components/send-limit";
import { Tags } from "./message/components/tags";
import { TemplateSelect } from "./message/components/template-select";
import { Timeline } from "./message/components/timeline";
import { Variables } from "./message/components/variables";

export const AddMessage = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const createMessage = useCreateDecisionEngineMessageMutation();

  const enabledChannels = engine.channels.filter((channel) => channel.enabled);

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const decision_engine_channel_id = channel?.id;
      const response = await createMessage.mutateAsync({
        flowId: flowId!,
        message: {
          decision_engine_channel_id,
          name: data.name,
          config: data.config,
          variables: data.variables,
          tags: getKeyValueObject(data.tags),
          collections: {
            data: data.collections
              .filter(({ enabled }) => enabled)
              .map(({ id, item_count }) => ({
                decision_engine_collection_id: id,
                item_count,
              })),
          },
        },
        flowMessageConfig: data.flowMessageConfig,
      });
      if (
        response.createDecisionEngineMessage.__typename ===
        "DecisionEngineError"
      ) {
        throw new Error(response.createDecisionEngineMessage.error);
      } else {
        navigate(
          `/ai/flows/${flowId}/messages/${response.createDecisionEngineMessage}`,
        );
      }
    },
    defaultValues: {
      decision_engine_channel_id: enabledChannels?.[0]?.id,
      name: "",
      config: {
        baseMessageId: "",
      },
      flowMessageConfig: {},
      variables: [],
      collections:
        engine.collections?.map((collection) => ({
          id: collection.id,
          enabled: false,
          name: collection.collection.name,
          item_count: 1,
        })) ?? [],
      tags: [],
    },
  });

  const onClose = () => {
    navigate(`/ai/flows/${flowId}`);
  };

  const { watch } = form;
  const channelId = watch("decision_engine_channel_id");
  const channel = engine.channels.find((channel) => channel.id === channelId);

  return (
    <Form form={form}>
      <WizardDrawer
        isOpen
        steps={[
          {
            label: "Define message",
            render: () => {
              return (
                <Column gap={6} maxW="lg">
                  {enabledChannels.length > 1 && (
                    <Controller
                      name="decision_engine_channel_id"
                      render={({ field }) => (
                        <FormField label="Channel">
                          <Select
                            {...field}
                            options={engine.channels}
                            optionValue={(option) => option.id}
                            optionLabel={(option) =>
                              ChannelOptionsMap[option.type]?.label ?? ""
                            }
                          />
                        </FormField>
                      )}
                    />
                  )}
                  <TemplateSelect
                    destination={channel!.destination}
                    channel={channel!}
                  />
                </Column>
              );
            },
          },
          {
            label: "Configure",
            render: () => {
              return (
                <Column gap={6} pb={12}>
                  <Column gap={4} align="flex-start">
                    <Variables />
                  </Column>
                  <CollectionSelect />
                  <Tags />
                  <SendLimit />
                  <Timeline />
                </Column>
              );
            },
          },
          {
            label: "Finalize",
            continueLabel: "Add message",
            render: () => {
              const channelDefinition = getChannelDefinition(channel!.type);

              return (
                <Column gap={6}>
                  {channelDefinition.campaignSetup(channel!.config) ===
                    "event-triggered" && (
                    <Alert
                      type="info"
                      title="Event triggered setup"
                      message={`${channel?.destination.definition.name} is an event triggered channel. After this message is created Hightouch will create a new event with the message variables as properties. Please create a Flow triggered by this event and set it as the destination resource for this message.`}
                    />
                  )}
                  <FormField label="Name">
                    <Controller
                      name="name"
                      render={({ field }) => <TextInput {...field} />}
                    />
                  </FormField>
                  {createMessage.data?.createDecisionEngineMessage
                    .__typename === "DecisionEngineError" && (
                    <Alert
                      title="Unable to create message"
                      variant="inline"
                      type="error"
                      message={
                        createMessage.data.createDecisionEngineMessage.error
                      }
                    />
                  )}
                </Column>
              );
            },
          },
        ].filter(Boolean)}
        title="Add message"
        onClose={onClose}
        onSubmit={form.submit}
        size="xl"
      />
    </Form>
  );
};
