import {
  MonitorConditionTypes,
  MonitorStatus,
} from "@hightouch/lib/resource-monitoring/types";
import { subDays, subHours, subMinutes } from "date-fns";
import { SplashPage } from "src/components/splash-page";
import { SyncHealthAlerts } from "src/pages/syncs/sync/components/sync-health-alerts";
import { AlertingIcon } from "src/ui/icons";
import { SyncHealthStatus } from "src/utils/syncs";
import bottomRightGradient from "src/assets/backgrounds/bottom-right-green-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-green-gradient.svg";
import {
  Box, // Column,
  // Heading,
  Row, // Text,
  // Badge,
  // SparkleIcon,
  ArrowRightIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
// import * as analytics from "src/lib/analytics";
// import { ReactNode } from "react";
// import { Card } from "../card";
import { useSearchParam } from "src/components/routeable-tabs/utils";
import { useUser } from "src/contexts/user-context";
import { useUpdateWorkspaceMutation } from "src/graphql";
import { PermissionedButton } from "src/components/permission";

export const AdvancedAlertingSplashPage = () => {
  const [_, setMigrating] = useSearchParam("migrating");

  const { mutateAsync: enableAlertingMutation } = useUpdateWorkspaceMutation();
  const { workspace } = useUser();

  return (
    <>
      <SplashPage
        hideLogos
        icon={<AlertingIcon />}
        eyebrow="Advanced Alerting"
        heading="New alerting features are available for early access"
        description="Detect, triage, and resolve issues with your syncs before they impact customers and internal teams. With advanced alerting, you can receive actionable alerts based on custom monitors for sync throughput, row-level errors, and other metrics. Reduce alert fatigue and set custom routing rules to loop in the relevant teams when things break. Now in beta."
        visual={
          <SyncHealthAlerts
            health={SyncHealthStatus.Unhealthy}
            healthChangedAt=""
            statuses={[
              {
                conditionType: MonitorConditionTypes.SyncRejectedRows,
                id: "throughput",
                eventTime: subMinutes(new Date(), 15).toISOString(),
                text: "More than 10% of rows were rejected during a sync run",
                status: MonitorStatus.Unhealthy,
              },
              {
                conditionType: MonitorConditionTypes.SyncDuration,
                id: "slow",
                eventTime: subHours(new Date(), 8).toISOString(),
                text: "Sync has been running for more than 2 hours",
                status: MonitorStatus.Warning,
              },
              {
                conditionType: MonitorConditionTypes.SyncSequentialFailures,
                id: "rejected",
                eventTime: subDays(new Date(), 2).toISOString(),
                text: "3 or more consecutive sync runs experienced fatal errors",
                status: MonitorStatus.Unhealthy,
              },
            ]}
          />
        }
        backgroundGradient={
          <>
            <Box
              as="img"
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
            />
            <Box
              as="img"
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
            />
          </>
        }
        actions={
          <Row alignItems="baseline" gap={4}>
            <PermissionedButton
              unauthorizedTooltip="You must be an admin to enable beta features"
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              variant="primary"
              onClick={async () => {
                await enableAlertingMutation({
                  id: workspace?.id,
                  input: {
                    alerting_v2_enabled: true,
                  },
                });
                setMigrating("true");
              }}
            >
              Enable beta features
            </PermissionedButton>

            <Link href="/extensions/alerting/configuration">
              Continue to alerting settings <ArrowRightIcon />
            </Link>
          </Row>
        }
        // infoSection={
        //   <Grid gap={14} columns={2} mt={12}>
        //     <Column gap={8}>
        //       <Row>
        //         <ExtraInfoCard
        //           title="Define custom alerts based on sync throughput, model size,
        //             rejected rows, and other metrics"
        //           body="Quickly detect and fix upstream data problems in your
        //             warehouse, as well as destination-specific issues like
        //             expired credentials, duplicate records, and API errors."
        //         />
        //       </Row>
        //       <Row>
        //         <ExtraInfoCard
        //           title="Route alerts to specific teams and stakeholders"
        //           body="Bring in the relevant team members to help triage and
        //             resolve incidents. Send alerts to different channels based
        //             on affected destinations and other attributes."
        //         />
        //       </Row>
        //       <Row>
        //         <ExtraInfoCard
        //           title="Assign severity levels to different alert types"
        //           body="Define separate thresholds for warnings and critical alerts.
        //             Create escalation flows that suppress alerts for minor
        //             issues while amplifying errors affecting business-critical
        //             pipelines."
        //         />
        //       </Row>
        //     </Column>
        //     <Column gap={8}>
        //       <Row>
        //         <ExtraInfoCard
        //           title="Filter out the noise by ignoring transient errors"
        //           body="Prevent alert fatigue for your team and configure your
        //               alerts to be sent only after several retries."
        //         />
        //       </Row>
        //       <Row>
        //         <ExtraInfoCard
        //           title={
        //             <>
        //               <Badge icon={SparkleIcon} variant="upsell" mb={2}>
        //                 Coming soon
        //               </Badge>
        //               Analyze sync performance with interactive charts
        //             </>
        //           }
        //           body="Correlate metrics and gain deeper visibility into your
        //             pipelines, all the way from source to destination. Find
        //             anomalies in historical metrics and jump directly into the
        //             debugger by clicking on a chart."
        //         />
        //       </Row>
        //       <Row>
        //         <ExtraInfoCard
        //           title={
        //             <>
        //               <Badge icon={SparkleIcon} variant="upsell" mb={2}>
        //                 Coming soon
        //               </Badge>
        //               Monitor syncs using anomaly detection
        //             </>
        //           }
        //           body="Watch out for unusual changes in metrics like model size,
        //             rejected row rate, and sync throughput. Receive proactive
        //             alerts about anomalous behavior, taking into account
        //             seasonal patterns like day-of-week or time-of-day."
        //         />
        //       </Row>
        //     </Column>
        //   </Grid>
        // }
      />
    </>
  );
};

// const ExtraInfoCard = ({
//   title,
//   body,
// }: {
//   title: ReactNode;
//   body: ReactNode;
// }) => (
//   <Card>
//     <Column gap={2}>
//       <Heading>{title}</Heading>
//       <Text>{body}</Text>
//     </Column>
//   </Card>
// );
