import { FC } from "react";

import { get } from "lodash";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Section } from "src/ui/section";
import {
  OBJECT_LABEL,
  DEFAULT_OBJECT_LABEL,
  OBJECT_DESCRIPTION,
  DEFAULT_OBJECT_DESCRIPTION,
  OBJECT_RELOAD_TOOLTIP,
  DEFAULT_OBJECT_RELOAD_TOOLTIP,
} from "src/utils/destinations";
import {
  Combobox,
  FormField,
  IconButton,
  RefreshIcon,
  Row,
  Tooltip,
} from "@hightouchio/ui";

type Props = {
  path?: string[];
  value?: any;
  options?: any;
  error?: any;
  onChange?: (object: any) => void;
  reload?: () => void;
  loading?: boolean;
  placeholder?: string;
  isCreatable?: boolean;
};

export const ObjectField: FC<Readonly<Props>> = ({
  value: propValue,
  error: propError,
  options,
  path,
  onChange,
  reload,
  loading,
  placeholder,
  isCreatable,
}) => {
  const { slug, errors, config, setConfig } = useDestinationForm();

  const value = propValue || config?.object;
  const error = propError || errors?.object;

  return (
    <Section>
      <FormField
        description={
          (path
            ? get(OBJECT_DESCRIPTION[slug ?? ""], path)
            : OBJECT_DESCRIPTION[slug ?? ""]) || DEFAULT_OBJECT_DESCRIPTION
        }
        error={error}
        label={
          (path
            ? get(OBJECT_LABEL[slug ?? ""], path)
            : OBJECT_LABEL[slug ?? ""]) || DEFAULT_OBJECT_LABEL
        }
      >
        <Row align="center" gap={2}>
          <Combobox
            supportsCreatableOptions={isCreatable}
            isInvalid={Boolean(error)}
            isLoading={loading}
            options={options ?? []}
            placeholder={
              placeholder ?? isCreatable
                ? "Select or add an object..."
                : "Select an object..."
            }
            value={value}
            onChange={(value) => {
              if (onChange) {
                onChange(value);
              } else {
                setConfig({ object: value });
              }
            }}
            onCreateOption={(newValue) => {
              if (onChange) {
                onChange(newValue);
              } else {
                setConfig({ object: newValue });
              }
            }}
          />
          {reload && (
            <Tooltip
              message={
                (path
                  ? get(OBJECT_RELOAD_TOOLTIP[slug ?? ""], path)
                  : OBJECT_RELOAD_TOOLTIP[slug ?? ""]) ||
                DEFAULT_OBJECT_RELOAD_TOOLTIP
              }
            >
              <IconButton
                aria-label="Reload options"
                isLoading={loading}
                icon={RefreshIcon}
                onClick={() => reload}
              />
            </Tooltip>
          )}
        </Row>
      </FormField>
    </Section>
  );
};
