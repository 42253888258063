import { ConditionType, PredefinedMetric } from "src/types/visual";
import { AudienceFilter } from "./types";

export const MAX_SELECTED_AUDIENCES_ALLOWED = 4;
export const MAX_LINES = 30;
export const placeholderContentWidthPx = "576px";

export const DEFAULT_FILTER: AudienceFilter = {
  type: ConditionType.And,
  conditions: [],
};

export enum MetricType {
  Goal = "Goal",
  Event = "Event",
}

export const PREDEFINED_METRIC_OPTIONS = [
  {
    id: PredefinedMetric.AudienceSize as string,
    name: "Audience size",
    eventModelId: null,
    description: null,
  },
];

export const graphColors = [
  { color: "#4FC26B", bg: "#ECFEF5" },
  { color: "#AC61FF", bg: "#F1E7FC" },
  { color: "#E49A38", bg: "#FEF9EC" },
  { color: "#349AC6", bg: "#EBFCFF" },
  { color: "#D25046", bg: "#FFECEB" },
];
