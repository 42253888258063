import { FC, useEffect, useState } from "react";

import {
  Avatar,
  ConfirmationDialog,
  DeleteIcon,
  EditIcon,
  Menu,
  MenuActionsButton,
  MenuList,
  Paragraph,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { LoaderFunction, Outlet, useNavigate, useParams } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isPresent } from "ts-extras";

import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { DetailPage } from "src/components/layout";
import {
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import {
  useDeleteDestinationMutation,
  useDestinationQuery,
  useSupportsAnalyticsIngestionQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { PageSpinner } from "src/components/loading";
import { formatDate } from "src/utils/time";
import { useQueryString } from "src/utils/use-query-string";
import { queryClient } from "src/utils/query-client";

export const loader: LoaderFunction = async ({ params }) => {
  const data = await queryClient.fetchQuery({
    queryKey: useDestinationQuery.getKey({ id: String(params.destination_id) }),
    queryFn: useDestinationQuery.fetcher({ id: String(params.destination_id) }),
  });
  if (!data?.destinations_by_pk) {
    throw new Response("Not Found", { status: 404 });
  }
  return null;
};

export const Element: FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { analyticsAudienceReportIngestion } = useFlags();

  // `user.id` should be a string but fixing that is a larger change
  const userId = user?.id != null ? String(user?.id) : undefined;

  const { destination_id: id } = useParams<{ destination_id?: string }>();

  const {
    data: { reauthorized },
  } = useQueryString();

  const { toast } = useToast();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);

  const { mutateAsync: updateDestination } = useUpdateDestinationV2Mutation();
  const { mutateAsync: deleteDestination } = useDeleteDestinationMutation();

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "destination", grant: "can_update", id: id! },
  });

  const {
    data: destination,
    isLoading: destinationLoading,
    error,
  } = useDestinationQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.destinations_by_pk,
    },
  );

  const { data: supportsAnalyticsIngestion } =
    useSupportsAnalyticsIngestionQuery(
      {
        destinationType: destination?.type ?? "",
      },
      {
        enabled: Boolean(destination?.slug),
        select: (data) => data.supportsAnalyticsIngestion,
      },
    );

  const showAnalyticsIngestionTab =
    analyticsAudienceReportIngestion && supportsAnalyticsIngestion;

  const definition = destination?.definition;
  const updatedByUsername =
    destination?.updated_by_user?.name || destination?.created_by_user?.name;

  const labels = destination?.tags ?? {};
  const labelKeys = Object.keys(labels);

  const updateName = async (name: string) => {
    await updateDestination({
      id: id ?? "",
      destination: {
        name,
        updated_by: userId,
      },
    });
    toast({
      id: "update-destination",
      title: "Destination was updated",
      variant: "success",
    });
  };

  const title = destination?.name || definition?.name || "Unnamed destination";

  useEffect(() => {
    if (reauthorized) {
      toast({
        id: "reauthorized-destination",
        title: "Destination reauthorized",
        variant: "success",
      });
    }
  }, [reauthorized]);

  if (!id || destinationLoading) {
    return <PageSpinner />;
  }

  if (error) {
    return (
      <Warning
        subtitle="Please try again later"
        title="Destination failed to load"
      />
    );
  }

  if (!destination) {
    return <Warning title="Destination not found" />;
  }

  return (
    <>
      <PermissionProvider
        permission={{
          v2: {
            resource: "destination",
            grant: "can_update",
            id,
          },
        }}
      >
        <DetailPage
          tabs={[
            { path: "configuration", title: "Configuration" },
            hasUpdatePermission ? { path: "linking", title: "Linking" } : null,
            {
              title: "Syncs",
              path: "syncs",
            },
            user?.permissions_v2_enabled
              ? { path: "grants", title: "Grants" }
              : null,
            showAnalyticsIngestionTab
              ? { path: "data-extraction", title: "Data Extraction" }
              : null,
          ].filter(isPresent)}
          crumbs={[{ label: "All destinations", link: "/destinations" }]}
          title={`${title} - Destinations`}
          hasBottomPadding
          header={
            <>
              <Row gap={4} justify="space-between" align="center" width="100%">
                <Row align="center" gap={2}>
                  <PermissionedEditableHeading
                    permission={{
                      v2: {
                        resource: "destination",
                        grant: "can_update",
                        id,
                      },
                    }}
                    size="lg"
                    value={title}
                    onChange={updateName}
                  />
                </Row>

                <Menu>
                  <MenuActionsButton variant="secondary" />
                  <MenuList>
                    <PermissionedMenuItem
                      permission={{
                        v2: {
                          resource: "destination",
                          grant: "can_update",
                          id: id!,
                        },
                      }}
                      icon={EditIcon}
                      onClick={() => {
                        setIsEditLabelModalOpen(true);
                      }}
                    >
                      Edit labels
                    </PermissionedMenuItem>
                    <PermissionedMenuItem
                      permission={{
                        v2: {
                          resource: "destination",
                          grant: "can_delete",
                          id,
                        },
                      }}
                      icon={DeleteIcon}
                      variant="danger"
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    >
                      Delete
                    </PermissionedMenuItem>
                  </MenuList>
                </Menu>
              </Row>

              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon
                    src={definition?.icon}
                    name={definition?.name ?? ""}
                  />
                  <Text fontWeight="medium">{definition?.name}</Text>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate(
                      (destination.updated_at || destination.created_at)!,
                    )}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Slug:</Text>
                  <DisplaySlug currentSlug={destination.slug} />
                </Row>
                {labelKeys.length > 0 && <Labels labels={labels} />}
              </DetailBar>
            </>
          }
        >
          <Outlet />
        </DetailPage>
      </PermissionProvider>

      <ConfirmationDialog
        confirmButtonText="Delete destination"
        isOpen={deleteModal}
        title="Delete destination"
        variant="danger"
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={async () => {
          const { delete_destinations_by_pk } = await deleteDestination({
            id,
            stringId: id.toString(),
          }); // Typing :(

          if (delete_destinations_by_pk) {
            analytics.track("Destination Deleted", {
              destination_id: destination?.id,
              destination_name: destination?.name,
              destination_type: definition?.name,
            });

            toast({
              id: "delete-destination",
              title: `Destination ${
                destination?.name || definition?.name
              } was deleted`,
              variant: "success",
            });

            navigate("/destinations");
          } else {
            toast({
              id: "delete-destination",
              title: "Couldn't delete this destination",
              variant: "error",
            });
          }
        }}
      >
        <Paragraph>
          Are you sure you want to delete this destination? You won't be able to
          undo this.
        </Paragraph>
      </ConfirmationDialog>

      <EditLabelModal
        isOpen={isEditLabelModalOpen}
        labels={labels ?? {}}
        resourceType={ResourceType.Destination}
        onClose={() => setIsEditLabelModalOpen(false)}
        onSubmit={(labels) =>
          updateDestination({
            id,
            destination: {
              tags: labels,
            },
          })
        }
      />
    </>
  );
};
