import {
  formatDistanceStrict,
  format,
  parseISO,
  intervalToDuration,
  formatDuration as dateFnsFormatDuration,
  type Duration,
  differenceInMinutes,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const diff = (
  start: string,
  end: string,
  { units = "long" }: Partial<DurationFormatOptions> = {},
): string | undefined => {
  if (start && end) {
    const distance = formatDistanceStrict(new Date(end), new Date(start), {
      roundingMethod: "floor",
    });

    return units === "short" ? convertToShortUnits(distance) : distance;
  }

  return undefined;
};

type DurationFormatOptions = {
  units: "short" | "long";
};

// Helper specifically for formatting potentially long durations (eg. sync run times)
// This is to reduce precision for long durations (eg. hour long syncs don't need to show seconds),
// and to make it easy to see longer durations from a quick scan.
// >= 1 day: 1d 2h 3m
// >= 1 hour: 1h 2m
// >= 1 minute: 1m
// <1 minute: <1m
export const formatPotentiallyLongDuration = (
  start: string,
  end: string,
  { units = "long" }: Partial<DurationFormatOptions> = {},
): string | undefined => {
  if (start && end) {
    const duration = intervalToDuration({
      start: new Date(start),
      end: new Date(end),
    });

    const formatted =
      !duration.years &&
      !duration.months &&
      !duration.weeks &&
      !duration.days &&
      !duration.hours &&
      !duration.minutes
        ? "<1 minute"
        : dateFnsFormatDuration(truncateLongDuration(duration), { zero: true });

    return units === "short" ? convertToShortUnits(formatted) : formatted;
  }

  return undefined;
};

// Cleans up long durations to specific units depending on the biggest units.
// Also adds zeros to units we want to show even if they're 0.
const truncateLongDuration = (duration: Duration): Duration => {
  if (duration.days) {
    return {
      days: duration.days,
      hours: duration.hours ?? 0,
      minutes: duration.minutes ?? 0,
    };
  }

  if (duration.hours) {
    return {
      hours: duration.hours,
      minutes: duration.minutes ?? 0,
    };
  }

  if (duration.minutes) {
    return {
      minutes: duration.minutes,
    };
  }

  // Undefined if duration doesn't fit patterns above,
  // just return the original duration.
  return duration;
};

const convertToShortUnits = (formatted: string): string =>
  formatted
    .replace(/\s+day(s?)\b/g, "d")
    .replace(/\s+hour(s?)\b/g, "h")
    .replace(/\s+minute(s?)\b/g, "m")
    .replace(/\s+second(s?)\b/g, "s");

export const formatDatetime = (
  timestamp: string,
  pattern = "MM/dd/yy 'at' p",
) => {
  try {
    return format(parseISO(timestamp), pattern);
  } catch (e) {
    return null;
  }
};

export const formatDate = (timestamp: string) => {
  try {
    return format(parseISO(timestamp), "MM/dd/yy");
  } catch (e) {
    return null;
  }
};

export const formatTime = (timestamp: string) => {
  try {
    return format(parseISO(timestamp), "p");
  } catch (e) {
    return null;
  }
};

export const formatDateOrDatetime = (
  timestamp: string,
  includeTime: boolean,
) => {
  return includeTime ? formatDatetime(timestamp) : formatDate(timestamp);
};

export const formatUTC = (timestamp: number) => {
  try {
    return format(toZonedTime(timestamp, "UTC"), "MM/dd/yy 'at' p");
  } catch (e) {
    return null;
  }
};

export const formatFriendlyDistanceToNow = (
  timestampString: string,
  nowString?: string,
) => {
  try {
    const timestamp = parseISO(timestampString);
    const now = nowString ? parseISO(nowString) : new Date();

    return differenceInMinutes(now, timestamp) < 1 &&
      now.getTime() >= timestamp.getTime()
      ? "Just now"
      : formatDistanceStrict(timestamp, now, {
          addSuffix: true,
        });
  } catch (e) {
    return "";
  }
};
