import { FC } from "react";

import { useIncidentBanner } from "src/hooks/use-incident-banner";

import { IncidentBanner } from "./incident-banner";
import { OverageBanner } from "./overage-banner";
import { TrialBanner } from "./trial-banner";

/**
 * Incident banners are large. To save space, hide other secondary banners when it's enabeld.
 *
 * Render the following banners, if applicable, in order of precedence:
 *  1. Incident Banner
 *  2. Overage Banner, Trial Banner, Duplicate Key Banner, Daylight Savings Banner
 */
export const SecondaryBanners: FC = () => {
  const { loading, incident, onDismiss } = useIncidentBanner();

  if (!loading && incident?.id) {
    return <IncidentBanner {...incident} onDismiss={onDismiss} />;
  }

  return (
    <>
      <OverageBanner />
      <TrialBanner />
      {/* 
        Add back a few days before November 3, 2024
        <DaylightSavingsBanner />
      */}
    </>
  );
};
