import { FC } from "react";

import { AddBoxIcon, Column, Row } from "@hightouchio/ui";
import immutableUpdate from "immutability-helper";

import { toSingleCondition } from "src/components/audiences/utils";
import {
  initialPropertyCondition,
  PropertyCondition,
  isRelatedColumn,
  ConditionType,
  TraitCondition,
  initialRootPropertyCondition,
  InlineTraitCondition,
} from "src/types/visual";

import { useQueryBuilderContext } from "src/components/explore/context/query-builder-context";
import { FilterProps } from "./condition";
import { DetailButton } from "./condition-buttons";
import { NestedPropertyFilter } from "./nested-property-filter";

export type TraitFilterProps = FilterProps<
  TraitCondition | InlineTraitCondition
>;

export const TraitFilter: FC<Readonly<TraitFilterProps>> = (props) => {
  // Transform condition from the legacy format to the new format
  // where there is a SINGLE top-level AND/OR condition
  const condition = transformTraitConditions(props.condition);

  const { relationships: relationshipsOverride, onChange } = props;

  const { relationships: allRelationships } = useQueryBuilderContext();

  const relationships = relationshipsOverride ?? allRelationships;

  const columns = relationships?.find(
    ({ id }) =>
      isRelatedColumn(condition.property) && id === condition.property.path[0],
  )?.to_model.filterable_audience_columns;

  const addRootCondition = () => {
    onChange(
      immutableUpdate(condition, {
        property: {
          column: { conditions: { $push: [initialRootPropertyCondition] } },
        },
      }),
    );
  };

  const addCondition = () => {
    onChange(
      immutableUpdate(condition, {
        property: {
          column: {
            conditions: {
              0: { conditions: { $push: [initialPropertyCondition] } },
            },
          },
        },
      }),
    );
  };

  const updateCondition =
    (index: number) => (updates: Partial<PropertyCondition>) => {
      onChange(
        immutableUpdate(condition, {
          property: {
            column: {
              conditions: {
                0: { conditions: { [index]: { $merge: updates } } },
              },
            },
          },
        }),
      );
    };

  const removeCondition = (index: number) => () => {
    onChange(
      immutableUpdate(condition, {
        property: {
          column: {
            conditions: { 0: { conditions: { $splice: [[index, 1]] } } },
          },
        },
      }),
    );
  };

  const onToggleWrappingConditionType = (newConditionType) => {
    onChange(
      immutableUpdate(condition, {
        property: {
          column: { conditions: { 0: { type: { $set: newConditionType } } } },
        },
      }),
    );
  };

  const rootConditions =
    "conditions" in condition.property.column
      ? condition.property.column.conditions
      : [];

  if (rootConditions?.length === 0) {
    return <AddConditionButton onClick={addRootCondition} />;
  }

  return (
    <>
      {rootConditions.map((topLevelCondition, index: number) => (
        <Column key={index} gap={4}>
          {topLevelCondition.conditions.map(
            (subcondition, subindex: number) => (
              <>
                <Row key={subindex} align="flex-start" width="100%">
                  <NestedPropertyFilter
                    {...props}
                    isFirstCondition={subindex === 0}
                    showSecondaryFilter
                    columns={columns}
                    condition={subcondition}
                    isWrappedWithAndCondition={
                      topLevelCondition.type === ConditionType.And
                    }
                    traits={[]}
                    onChange={updateCondition(subindex)}
                    onRemove={removeCondition(subindex)}
                    onToggleWrappingConditionType={
                      onToggleWrappingConditionType
                    }
                  />
                </Row>
              </>
            ),
          )}
          <AddConditionButton onClick={addCondition} />
        </Column>
      ))}
    </>
  );
};

type AddConditionButtonProps = {
  onClick: () => void;
};

const AddConditionButton: FC<Readonly<AddConditionButtonProps>> = ({
  onClick,
}) => (
  <DetailButton icon={AddBoxIcon} size="sm" onClick={onClick}>
    Where...
  </DetailButton>
);

const transformTraitConditions = (
  condition: TraitCondition | InlineTraitCondition,
): TraitCondition | InlineTraitCondition => {
  // Transform condition to AND/OR
  const transformedCondition = immutableUpdate(condition, {
    property: {
      column: {
        // Trait conditions
        conditions: {
          $apply: (input) => {
            const rootConditions = toSingleCondition(input);
            return rootConditions ? rootConditions : input;
          },
        },
      },
    },
  });

  return transformedCondition;
};
