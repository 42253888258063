import { FC, useMemo } from "react";

import { Column, Row } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { useOrganizationMembersQuery } from "src/graphql";
import { useOutletContext } from "src/router";
import { PermissionedSwitch } from "src/components/permission";
import { useOrganizationPermissions } from "src/components/permission/use-organization-permissions";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Table } from "src/ui/table";
import { useDebounce } from "use-debounce";
import { OutletContext } from "src/pages/organizations/roles/role";

export type Member = {
  user_id: number;
  is_group_manager?: boolean | null;
  is_member?: boolean | null;
  is_member_via_sso?: boolean | null;
  user: {
    name: string;
    email: string;
    auth0_auth_id: string | null;
    google_auth_id: string | null;
    azure_ad_auth_id: string | null;
  } | null;
};

export type Props = {
  organizationId: string;
  members?: Array<Member>;
  isMemberDisabled?: (member: Member) => boolean;
  exclude?: Array<string>;
  search?: string;
  onSelect?: (id: number) => void;
  selectedRows?: Array<any>;
};

export const Members: FC<Readonly<Props>> = (props) => {
  const [search] = useDebounce(props.search, 500);
  const { isGroupManager } = useOrganizationPermissions();

  const context = useOutletContext<OutletContext>();

  const { data, isFetching } = useOrganizationMembersQuery(
    {
      search: search ? `%${search}%` : undefined,
      organizationId: props.organizationId,
    },
    {
      enabled: !props.members,
      keepPreviousData: true,
    },
  );

  const members: Array<Member> = useMemo(() => {
    if (props.members) {
      return props.members.filter((member) => {
        if (search) {
          const s = search.toLowerCase();
          return (
            member.user?.name?.toLowerCase().includes(s) ||
            member.user?.email?.toLowerCase().includes(s)
          );
        }
        return true;
      });
    }
    return (
      data?.organization_members_distinct?.filter((member) => {
        return !props.exclude?.includes(member.user_id);
      }) ?? []
    );
  }, [
    search,
    props.members,
    data?.organization_members_distinct,
    props.exclude,
  ]);

  return (
    <Controller
      name="members"
      render={({ field }) => {
        return (
          <Table
            disabled={(m) =>
              !isGroupManager(context.userGroup?.id) ||
              Boolean(props.isMemberDisabled?.(m))
            }
            loading={isFetching}
            primaryKey="user_id"
            onSelect={props.onSelect}
            selectedRows={props.selectedRows}
            data={members}
            placeholder={{
              title: "No users found",
              body: props.search
                ? "Try changing your search query"
                : props.exclude
                  ? "All users are already assigned"
                  : undefined,
            }}
            columns={[
              {
                name: "Name",
                cell: ({ user, is_member, is_member_via_sso }) => (
                  <Column>
                    <TextWithTooltip fontWeight="medium">
                      <>
                        {user?.name}{" "}
                        {is_member_via_sso && !is_member
                          ? "(assigned via SSO)"
                          : null}
                      </>
                    </TextWithTooltip>
                    <TextWithTooltip color="text.secondary">
                      {user?.email}
                    </TextWithTooltip>
                  </Column>
                ),
              },
              {
                name: "Auth method",
                cell: ({ user }) => {
                  let authMethod = "Email (magic link)";

                  if (user?.google_auth_id) {
                    authMethod = "Google";
                  } else if (user?.azure_ad_auth_id) {
                    authMethod = "Microsoft";
                  } else if (user?.auth0_auth_id) {
                    authMethod = "Single sign-on";
                  }

                  return <TextWithTooltip>{authMethod}</TextWithTooltip>;
                },
              },
              {
                name: "Group manager",
                max: "max-content",
                cell: ({ user_id }) => {
                  const value = field.value?.[user_id];
                  return (
                    <Row justify="center" flex={1}>
                      <PermissionedSwitch
                        size="sm"
                        organizationPermission={{
                          resource: "group",
                          id: context.userGroup?.id,
                        }}
                        isDisabled={!value}
                        isChecked={value?.is_group_manager}
                        onChange={(value) => {
                          field.onChange({
                            ...field.value,
                            [user_id]: {
                              ...field.value?.[user_id],
                              user_id: user_id,
                              is_group_manager: value,
                            },
                          });
                        }}
                      />
                    </Row>
                  );
                },
              },
            ]}
          />
        );
      }}
    />
  );
};
