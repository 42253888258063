import { DecisionEngineChannelsInsertInput } from "@hightouch/core/server/graphql/types";
import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";
import { Column, Heading } from "@hightouchio/ui";
import { useOutletContext } from "src/router";
import * as yup from "yup";

import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Page } from "src/components/layout";
import { OutletContext } from "..";
import { Channels, formatChannel, channelsSchema } from "./components/channels";
import { Timing, timingSchema } from "./components/timing";
import { Targeting } from "./components/targeting";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateDecisionEngineWithChannelsMutation } from "src/graphql";

const schema = yup.object().shape({
  channels: channelsSchema,
  config: yup.object().shape({
    timing: timingSchema,
  }),
});

export const Configuration = () => {
  const { engine } = useOutletContext<OutletContext>();

  const update = useUpdateDecisionEngineWithChannelsMutation();

  const form = useHightouchForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      const channels = Object.values(data.channels)
        .filter((channel) => channel.id || channel.enabled)
        .map((channel) => ({
          ...channel,
          decision_engine_id: engine.id,
        }));

      await update.mutateAsync({
        id: engine.id,
        input: {
          config: {
            ...engine.config,
            ...data.config,
          },
          segment_id: data.segment_id,
        },
        channels,
      });
    },
    values: {
      segment_id: engine.segment.id,
      config: {
        timing: engine.config?.timing ?? { frequency: [], days: [], times: [] },
      },
      channels: {
        email: formatChannel(
          engine.channels.find((c) => c.type === "email"),
          "email",
        ),
        sms: formatChannel(
          engine.channels.find((c) => c.type === "sms"),
          "sms",
        ),
        push: formatChannel(
          engine.channels.find((c) => c.type === "push"),
          "push",
        ),
        raw: formatChannel(
          engine.channels.find((c) => c.type === "raw"),
          "raw",
        ),
      } as Record<DecisionEngineChannelType, DecisionEngineChannelsInsertInput>,
    },
  });

  return (
    <Page title="Configuration">
      <Form form={form}>
        <Column gap={6} maxW="xl" pb={12}>
          <Heading isTruncated size="xl">
            Configuration
          </Heading>
          <Targeting />
          <Timing />
          <Channels />
        </Column>
        <ActionBar>
          <FormActions />
        </ActionBar>
      </Form>
    </Page>
  );
};
