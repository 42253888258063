import { FormField, NumberInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

export const SendLimit = () => {
  return (
    <Controller
      name="flowMessageConfig.maxSendsPerUser"
      render={({ field }) => (
        <FormField
          isOptional
          label="Send limit"
          description="The maximum number of times this message can be sent to the same user."
        >
          <NumberInput {...field} width="xs" placeholder="Send limit..." />
        </FormField>
      )}
    />
  );
};
