export const NumericFontStyles = {
  fontFamily: "Inter",
  fontVariantNumeric: "lining-nums tabular-nums slashed-zero",
};

export const BreakdownChartViews = [
  { label: "Bar chart", value: "bar" },
  { label: "Pie chart", value: "pie" },
  { label: "Table", value: "table" },
];

const purpleColor = "#E7ECFE";
const greenColor = "#E1F9FE";
const salmonColor = "#FFE8E3";
const fuschiaColor = "#F6E3FF";
const blueColor = "#E3FAFF";
const yellowColor = "#FEFCE7";
const redColor = "#FEE7E7";
const orangeColor = "#fef3e7";
const tealColor = "#e7fef7";
const grayColor = "#F2F2F2";
const greenerColor = "#CCFFCC";

const purpleHoverColor = "#728ff9";
const greenHoverColor = "#6de1fa";
const salmonHoverColor = "#ff856a";
const fuschiaHoverColor = "#cf6aff";
const blueHoverColor = "#6ae4ff";
const yellowHoverColor = "#f9ed72";
const redHoverColor = "#f97272";
const orangeHoverColor = "#f9b872";
const tealHoverColor = "#72f9d0";
const grayHoverColor = "#b5b5b5";
const greenerHoverColor = "#00e500";

export const HexColors = [
  { base: purpleColor, hover: purpleHoverColor },
  { base: greenColor, hover: greenHoverColor },
  { base: salmonColor, hover: salmonHoverColor },
  { base: fuschiaColor, hover: fuschiaHoverColor },
  { base: blueColor, hover: blueHoverColor },
  { base: yellowColor, hover: yellowHoverColor },
  { base: redColor, hover: redHoverColor },
  { base: orangeColor, hover: orangeHoverColor },
  { base: tealColor, hover: tealHoverColor },
  { base: grayColor, hover: grayHoverColor },
  { base: greenerColor, hover: greenerHoverColor },
];
