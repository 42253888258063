import { FC } from "react";
import { formatDistanceToNowStrict } from "date-fns";
import { Column, Row, Text } from "@hightouchio/ui";

import { InteractionRow } from "./messages";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";

type Props = {
  onClick: () => void;
  isSelected: boolean;
  interaction: InteractionRow;
  flowMessages: Array<{
    message: {
      id: string;
      name: string;
    };
  }>;
  px?: number;
};

export const MessageRow: FC<Readonly<Props>> = ({
  onClick,
  isSelected,
  interaction,
  flowMessages,
  px = 6,
}) => {
  const name = flowMessages.find(
    (m) => m.message.id === interaction.action_features.message,
  )?.message.name;
  return (
    <Row
      h="52px"
      flexShrink={0}
      px={px}
      gap={2}
      align="center"
      justify="space-between"
      key={interaction.interaction_id}
      borderBottom="1px"
      borderColor="base.border"
      cursor="pointer"
      _hover={{
        bg: "gray.100",
      }}
      bg={isSelected ? "primary.background" : undefined}
      onClick={onClick}
    >
      <Row gap={2} align="center">
        <ChannelIcon
          type={
            interaction.action_features.channel as DecisionEngineChannelType
          }
        />
        <Column overflow="hidden">
          <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
          {interaction.user_primary_label && (
            <TextWithTooltip color="text.secondary" size="sm">
              {interaction.user_primary_label}
            </TextWithTooltip>
          )}
        </Column>
      </Row>
      <Text color="text.secondary" whiteSpace="nowrap" size="sm">
        {formatDistanceToNowStrict(new Date(interaction.send_at), {
          addSuffix: true,
        })}
      </Text>
    </Row>
  );
};
