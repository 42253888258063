import {
  Column,
  Row,
  SearchInput,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import { IntegrationIcon } from "../integrations/integration-icon";
import { useSubscribedResources } from "./use-subscribed-resources";
import { Table } from "src/ui/table";

export const ResourceSubscriptionFormTable: FC = () => {
  const [search, setSearch] = useState("");

  const { availableResources } = useSubscribedResources({});

  const allDestinations = availableResources?.destinations ?? [];

  const filteredDestinations = allDestinations.filter((destination) => {
    if (!destination) {
      return false;
    }
    if (search) {
      return destination.name?.toLowerCase().includes(search.toLowerCase());
    }
    return true;
  });

  return (
    <Column gap={6} minH={0}>
      <Row justify="space-between">
        <Column>
          <SectionHeading>Assign recipient to destinations</SectionHeading>
          <Text color="text.secondary">
            Subscribe to alerts for specific sync destinations in this
            workspace.
          </Text>
        </Column>
        <SearchInput
          placeholder="Search destinations..."
          value={search ?? ""}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </Row>
      <Controller
        name="resourceIdsToSubscribe.destinations"
        render={({ field }) => {
          const subscribedDestinations = Object.entries(field.value ?? {})
            .filter(([_, value]) => (value as any).subscribed)
            .map(([id]) => Number(id));

          return (
            <Table
              onSelect={(selection) => {
                if (Array.isArray(selection)) {
                  const newValue = { ...field.value };
                  if (selection.length === 0) {
                    for (const id of Object.keys(field.value)) {
                      newValue[id] = {
                        ...field.value[id],
                        subscribed: false,
                      };
                    }
                  } else {
                    for (const id of selection) {
                      const existing = field.value[id];
                      newValue[id] = {
                        ...(existing ?? {}),
                        subscribed: true,
                      };
                    }
                  }
                  field.onChange(newValue);
                } else {
                  const currentValue = field.value[selection];
                  field.onChange({
                    ...field.value,
                    [selection]: {
                      ...currentValue,
                      subscribed: !currentValue.subscribed,
                    },
                  });
                }
              }}
              selectedRows={subscribedDestinations}
              columns={[
                {
                  cell: (destination) => {
                    return (
                      <Row gap={2} align="center">
                        <IntegrationIcon
                          src={destination.definition.icon}
                          name={destination.name || destination.definition.name}
                        />
                        <Column>
                          <Text fontWeight="medium">
                            {destination.name || destination.definition.name}
                          </Text>
                        </Column>
                      </Row>
                    );
                  },
                  name: "Destination",
                },
              ]}
              data={filteredDestinations}
            />
          );
        }}
      />
    </Column>
  );
};
