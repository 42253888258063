import { Alert, FormField, Combobox, TextInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { useDecisionEngineBaseEmailsQuery } from "src/graphql";
import { isPresent } from "ts-extras";
import { FC } from "react";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";

export const TemplateSelect: FC<
  Readonly<{
    destination: { type: string; id: string };
    channel: { config: DecisionEngineChannelConfig };
  }>
> = ({ destination, channel }) => {
  const channelDefinition = getChannelDefinition(destination?.type);
  const campaignSetup = channelDefinition.campaignSetup(channel.config);
  const { data, error, isLoading } = useDecisionEngineBaseEmailsQuery(
    {
      destinationId: destination?.id,
    },
    {
      enabled: destination && campaignSetup === "template",
      select: (data) => data.getEmailBaseMessages,
    },
  );

  if (!destination) {
    return null;
  }

  if (error) {
    return (
      <Alert
        variant="inline"
        type="error"
        title="Error"
        message={error?.message}
      />
    );
  }

  if (campaignSetup === "freeform" || campaignSetup === "event-triggered") {
    return (
      <Controller
        name="config.baseMessageId"
        render={({ field }) => (
          <FormField
            label="Identifier"
            description="A unique identifier used to reference this message."
          >
            <TextInput {...field} placeholder="Identifier..." />
          </FormField>
        )}
      />
    );
  }

  return (
    <Controller
      name="config.baseMessageId"
      render={({ field }) => (
        <FormField
          label="Template"
          description="Choose a template that you’ve created in your downstream customer engagement platform. If you haven’t created it yet, you can come back and select one later."
        >
          <Combobox
            isClearable
            width="100%"
            value={field.value?.toString()}
            onChange={(value) => {
              field.onChange(value ?? "");
            }}
            isLoading={isLoading}
            options={(data ?? []).filter(isPresent)}
            optionValue={(o) => String(o?.id)}
            optionLabel={(o) => String(o?.name)}
            optionDescription={(o) => o?.type ?? ""}
          />
        </FormField>
      )}
    />
  );
};
