import { FC, useMemo } from "react";

import { Alert, Box } from "@hightouchio/ui";

import {
  AuthorizeConnection,
  ReauthorizeConnection,
} from "src/components/authorize-connection";
import { Form as FormkitForm } from "src/formkit/components/form";
import { processFormNode } from "src/formkit/formkit";
import { SourceDefinition } from "src/graphql";

export type OAuthMethodProps = {
  definition: SourceDefinition;
  config: Record<string, unknown> | undefined;
  setupMethods: Record<string, any>;
  sourceId: string | undefined;
  onConnectClick?(defintion: SourceDefinition): void;
  isSetup: boolean;
};

export const OAuthMethod: FC<Readonly<OAuthMethodProps>> = ({
  definition,
  config,
  setupMethods,
  sourceId,
  onConnectClick,
  isSetup,
}) => {
  const matchingSource = setupMethods?.find((o) => o.key === config?.methodKey);

  const Form = useMemo(
    () =>
      matchingSource?.form && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingSource.form)}
        </FormkitForm>
      ),
    [matchingSource?.key],
  );

  if (!sourceId) {
    return (
      <AuthorizeConnection
        href={`${import.meta.env.VITE_API_BASE_URL}${matchingSource.url}/${
          definition.type
        }/${config?.methodKey}`}
        icon={definition.icon}
        name={definition.name}
        onAuthorize={() => {
          onConnectClick && onConnectClick(definition);
        }}
      />
    );
  }

  return (
    <>
      {isSetup && (
        <Alert
          mb={4}
          variant="inline"
          message={`Your ${definition.name} account was successfully connected to Hightouch.`}
          title="Authorization successful"
          type="success"
        />
      )}
      {matchingSource?.form && Form}
      {!isSetup && (
        <Box mt={6}>
          <ReauthorizeConnection
            permission={{
              v2: {
                resource: "source",
                grant: "can_update",
                id: sourceId,
              },
            }}
            href={`${import.meta.env.VITE_API_BASE_URL}${matchingSource.url}/${
              definition.type
            }/${config?.methodKey}/${sourceId}`}
            icon={definition.icon}
            name={definition.name}
            onAuthorize={() => {
              onConnectClick && onConnectClick(definition);
            }}
          />
        </Box>
      )}
    </>
  );
};
