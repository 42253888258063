import { FC } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  FormField,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { Controller, useForm } from "react-hook-form";

import { useResourcePermission } from "src/components/permission/use-resource-permission";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: { name: string; private: boolean }) => Promise<void>;
  loading: boolean;
};

export const CreateViewModal: FC<Readonly<Props>> = ({
  isOpen,
  onClose,
  onSave,
  loading,
}) => {
  const { toast } = useToast();
  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: { name: "", private: true },
  });

  const name = watch("name");

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  return (
    <Dialog
      isOpen={isOpen}
      variant="form"
      title="Save view"
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            isDisabled={!name}
            isLoading={loading}
            onClick={handleSubmit(async (data) => {
              await onSave(data);
              reset();
              onClose();

              toast({
                id: "create-view",
                title: `View "${data.name}" was created`,
                variant: "success",
              });
            })}
          >
            Save view
          </Button>
        </>
      }
      onClose={onClose}
    >
      <FormField label="View name">
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextInput
              autoFocus
              width="100%"
              {...field}
              placeholder="Enter a name..."
            />
          )}
        />
      </FormField>

      <Controller
        control={control}
        name="private"
        render={({ field }) => (
          <Checkbox
            isChecked={!field.value}
            isDisabled={!hasUpdatePermission}
            label="Share view with workspace members"
            mt={4}
            onChange={(event) => field.onChange(!event.target.checked)}
          />
        )}
      />
    </Dialog>
  );
};
