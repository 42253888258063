import { TraitDefinitionsBoolExp } from "src/graphql";

import { TraitType } from "./traits";

export const getTraitsFilter = ({
  search,
  type,
}: {
  search?: string;
  type: TraitType;
}): TraitDefinitionsBoolExp => {
  const baseFilter: TraitDefinitionsBoolExp = search
    ? {
        _or: [
          { name: { _ilike: `%${search}%` } },
          { segment: { name: { _ilike: `%${search}%` } } },
          { relationship: { to_model: { name: { _ilike: `%${search}%` } } } },
          { type: { _ilike: `%${search}%` } },
        ],
      }
    : {};

  switch (type) {
    case TraitType.Active:
      return {
        ...baseFilter,
        is_template: { _eq: false },
        archived_at: { _is_null: true },
      };
    case TraitType.Templates:
      return {
        ...baseFilter,
        is_template: { _eq: true },
        archived_at: { _is_null: true },
      };
    case TraitType.Archived:
      return { ...baseFilter, archived_at: { _is_null: false } };
    default:
      return baseFilter;
  }
};
