import { FC, useEffect, useMemo } from "react";

import {
  Box,
  Column,
  EditIcon,
  GroupedCombobox,
  Text,
  TraitIcon,
} from "@hightouchio/ui";
import immutableUpdate, { Spec } from "immutability-helper";
import { uniq } from "lodash";
import { isPresent } from "ts-extras";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { useColumnSuggestionsQuery } from "src/graphql";
import {
  isRelatedColumn,
  DefaultOperators,
  isColumnReference,
  PropertyCondition,
  isTraitCondition,
  TraitType,
  isInlineAggregatedTraitCondition,
} from "src/types/visual";

import { useQueryBuilderContext } from "src/components/explore/context/query-builder-context";
import { FilterPopover } from "src/components/explore/filter-popover";
import { FilterProps, HStack } from "./condition";
import { DetailButton, XButton } from "./condition-buttons";
import { ConditionTextWrapper } from "./condition-text-wrapper";
import { validatePropertyCondition } from "./condition-validation";
import { ErrorMessage } from "./error-message";
import { InlineTrait } from "./inline-trait";
import { PropertyConditions } from "./property-conditions";
import { PropertyOperatorValueFilter } from "./property-operator-value-filter";
import { TraitFilter } from "./trait-filter";
import {
  getColumnFromValue,
  getColumnOptions,
  getModelIdFromColumn,
  getModelIdFromTrait,
  getPropertyNameFromColumn,
  getPropertyNameFromTrait,
  getTraitOptions,
} from "./utils";

export type PropertyFilterProps = FilterProps<PropertyCondition> & {
  prefix?: boolean;
  showSecondaryFilter?: boolean;
  showParameterizeCheckbox?: boolean;
  isNested?: boolean;
};

export const usePropertyFilterValidation = (condition: PropertyCondition) => {
  const filterId = useMemo<string>(uuidv4, []);
  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const operatorError = filterErrors?.operator;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validatePropertyCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [
    condition.property,
    condition.operator,
    condition.value,
    filterId,
    condition.propertyOptions,
  ]);

  return {
    filterErrors,
    propertyError,
    operatorError,
    valueError,
  };
};

export const PropertyFilter: FC<Readonly<PropertyFilterProps>> = ({
  showSecondaryFilter = false,
  showParameterizeCheckbox = false,
  isNested = false,
  ...props
}) => {
  const {
    columns: columnsOverride,
    traits: traitsOverride,
    condition,
    onChange,
    onRemove,
    audience,
    parent,
  } = props;

  const { columns: allColumns, traits: allTraits } = useQueryBuilderContext();
  const columns = columnsOverride ?? allColumns;
  const traits = traitsOverride ?? allTraits;

  const { propertyError, operatorError, valueError } =
    usePropertyFilterValidation(condition);

  const isDeprecatedPropertyCondition = !isColumnReference(condition.property);

  const isTrait = isTraitCondition(condition);

  const trait = isTrait
    ? traits.find(
        ({ id }) => id === condition.property.column.traitDefinitionId,
      )
    : null;

  const modelId = isColumnReference(condition.property)
    ? isTrait
      ? getModelIdFromTrait(trait)
      : getModelIdFromColumn(condition.property)
    : parent?.id;
  const columnName = isColumnReference(condition.property)
    ? isTrait
      ? getPropertyNameFromTrait(trait)
      : getPropertyNameFromColumn(condition.property)
    : condition.property;

  const shouldFetchSuggestions = useMemo(() => {
    const validInputs = isPresent(modelId) && isPresent(columnName);
    if (isTrait) {
      return (
        validInputs &&
        [
          TraitType.First,
          TraitType.Last,
          TraitType.LeastFrequent,
          TraitType.MostFrequent,
        ].includes(trait?.type as TraitType)
      );
    }
    return validInputs;
  }, [modelId, columnName, isTrait, trait?.type]);

  const { data: columnSuggestionsData, isLoading: loadingSuggestions } =
    useColumnSuggestionsQuery(
      {
        modelIds: [String(modelId)],
        columnNames: [columnName].filter(isPresent),
      },
      {
        enabled: shouldFetchSuggestions,
      },
    );

  const suggestions = columnSuggestionsData?.getTopK?.columns?.find?.(
    (column) =>
      column.modelId === String(modelId) && column.name === columnName,
  )?.values;

  const mergedModels = uniq([
    ...(columns?.map(({ model_name }) => model_name) || []),
    ...(traits?.map((trait) => trait.relationship?.to_model.name) || []),
  ]);

  const propertyOptions =
    mergedModels.length > 1
      ? mergedModels.map((model) => {
          const modelColumns = columns?.filter(
            ({ model_name }) => model_name === model,
          );
          const traitsForModel = traits?.filter(
            (trait) => trait.relationship?.to_model.name === model,
          );
          const traitOptions = getTraitOptions(
            condition.property,
            traitsForModel,
          );

          return {
            label: model === parent?.name ? "Properties" : model ?? "No model",
            options: [
              ...traitOptions,
              ...getColumnOptions(
                modelColumns ?? [],
                isDeprecatedPropertyCondition,
              ),
            ],
          };
        })
      : [
          {
            label: "Properties",
            options: [
              ...getTraitOptions(condition.property, traits),
              ...getColumnOptions(columns ?? [], isDeprecatedPropertyCondition),
            ],
          },
        ];

  // Convert condition's property from a TraitColumn to an InlineColumn
  const onEditTrait = () => {
    if (!trait) return;

    onChange(
      immutableUpdate(condition, {
        property: {
          type: { $set: "related" },
          column: {
            $set: {
              type: "inline_trait",
              traitType: trait?.type,
              traitConfig: trait?.config,
              conditions: trait?.config.conditions ?? [],
              relationshipId: trait?.relationship?.id,
            },
          },
        },
        propertyOptions: {
          $merge: {
            traitType: "inline_trait",
          },
        },
      } as Spec<PropertyCondition>),
    );
  };

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        {showSecondaryFilter ? (
          <Column sx={{ input: { fontWeight: "medium" } }}>
            <GroupedCombobox
              isDisabled={loadingSuggestions}
              isInvalid={Boolean(propertyError)}
              isLoading={loadingSuggestions}
              optionGroups={propertyOptions}
              optionDescription={(option) => option.description || ""}
              placeholder="Select a property"
              popoverWidth={isNested ? "xs" : undefined}
              width="2xs"
              value={condition.property ?? undefined}
              onChange={(value) => {
                const column = getColumnFromValue(
                  propertyOptions.flatMap(({ options }) => options),
                  value,
                );

                if (!column) {
                  return;
                }

                onChange({
                  propertyType: column.type,
                  property: value,
                  propertyOptions: {
                    caseSensitive:
                      "case_sensitive" in column
                        ? column.case_sensitive ?? undefined
                        : undefined,
                  },
                  operator: column.type ? DefaultOperators[column.type] : null,
                  value: null,
                });
              }}
            />
            {propertyError && <ErrorMessage>{propertyError}</ErrorMessage>}
          </Column>
        ) : (
          <>
            {condition?.propertyOptions?.traitType === "inline_trait" ? (
              <>
                {isRelatedColumn(condition?.property) && (
                  <Column>
                    <ConditionTextWrapper>
                      <Text color="text.secondary">Has</Text>
                      <Box
                        as={TraitIcon}
                        bg="white"
                        color="warning.border"
                        borderRadius="sm"
                        borderColor="warning.border"
                        borderWidth={1}
                        height={5}
                        width={5}
                        p={0.5}
                      />
                      <Text color="text.secondary" fontWeight="medium">
                        Trait calculation
                      </Text>
                    </ConditionTextWrapper>

                    {parent &&
                      isRelatedColumn(condition.property) &&
                      isInlineAggregatedTraitCondition(condition) && (
                        <InlineTrait
                          audience={audience}
                          condition={condition}
                          loadingSuggestions={loadingSuggestions}
                          operatorError={operatorError}
                          parent={parent}
                          propertyError={propertyError}
                          suggestions={suggestions}
                          valueError={valueError}
                          onChange={onChange}
                        />
                      )}
                  </Column>
                )}
              </>
            ) : (
              <>
                <ConditionTextWrapper>
                  <Text color="text.secondary">
                    {isTrait ? "Has custom trait" : "Has property"}
                  </Text>
                </ConditionTextWrapper>
                <FilterPopover
                  {...props}
                  condition={condition}
                  isDisabled={loadingSuggestions}
                  hasError={Boolean(propertyError)}
                  onChange={onChange}
                />
              </>
            )}
          </>
        )}

        {condition.property &&
          condition?.propertyOptions?.traitType !== "inline_trait" && (
            <PropertyOperatorValueFilter
              condition={condition}
              loadingSuggestions={loadingSuggestions}
              operatorError={operatorError}
              parent={parent}
              showParameterizeCheckbox={showParameterizeCheckbox}
              suggestions={suggestions}
              valueError={valueError}
              onChange={onChange}
              columns={columns}
            />
          )}

        {onRemove && <XButton onRemove={onRemove} />}
      </HStack>
      {isTrait && (
        <Column pl={10} gap={2}>
          {condition.propertyOptions?.traitType === "trait" ? (
            <>
              <PropertyConditions
                conditions={condition.property.column.conditions ?? []}
              />
              <DetailButton icon={EditIcon} size="sm" onClick={onEditTrait}>
                Edit trait
              </DetailButton>
            </>
          ) : (
            <TraitFilter {...props} condition={condition} />
          )}
        </Column>
      )}
    </>
  );
};
