import {
  Alert,
  Column,
  DrawerFooter,
  EmptyState,
  Pill,
  PlusIcon,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { FC, useState } from "react";

import { useNavigate, useParams } from "src/router";
import placeholderIcon from "src/assets/placeholders/generic.svg";
import { PageSpinner } from "src/components/loading";
import {
  PermissionedButton,
  PermissionedLinkButton,
  PermissionedSwitch,
} from "src/components/permission";
import { useIsPermissionsV2Enabled } from "src/components/permission/utils";
import {
  useSubsetCategoryQuery,
  useUpdateSubsetCategoryMutation,
} from "src/graphql";
import { useListAudiencesWithoutSubsetCategoryQuery } from "src/graphql/types";
import { CategoryDrawerHeader } from "src/pages/governance/subsets/category/category-drawer-header";
import { SubsetPreview } from "src/pages/governance/subsets/subset-preview";
import { getGrantType } from "src/pages/governance/subsets/util";
import { BackfillRequiredSubsetsModal } from "./backfill-required-subsets-modal";
import { DeleteSubsetCategoryModal } from "./delete-category-modal";

export const ViewCategory: FC = () => {
  const { subset_category_id } = useParams();

  const navigate = useNavigate();
  const { toast } = useToast();

  const permissionsV2Enabled = useIsPermissionsV2Enabled();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [
    isBackfillRequiredSubsetsModalOpen,
    setIsBackfillRequiredSubsetsModalOpen,
  ] = useState(false);

  const { data, isLoading } = useSubsetCategoryQuery({
    id: subset_category_id ?? "",
    permissionsV2Enabled,
  });
  const subsetCategory = data?.audience_subset_groups_by_pk;
  const parentModel = subsetCategory?.parent_model;
  const parentModelId = parentModel?.id;

  const { data: audiencesWithoutSubsetCategory } =
    useListAudiencesWithoutSubsetCategoryQuery(
      {
        parentModelId: parentModelId ?? "",
        subsetCategoryId: subset_category_id ?? "",
      },
      {
        enabled: Boolean(parentModelId) && Boolean(subset_category_id),
        select: (data) =>
          data?.segments_by_pk?.audiences_for_parent_model ?? [],
      },
    );

  const updateSubsetCategory = useUpdateSubsetCategoryMutation();

  const updateRequirementProperty = async (isRequired: boolean) => {
    try {
      await updateSubsetCategory.mutateAsync({
        id: subset_category_id ?? "",
        input: { is_required: isRequired },
      });
      toast({
        id: "update-is-required",
        title: "Successfully updated requirement property.",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-is-required",
        title: "Could not update requirement property. Please try again.",
        variant: "error",
      });
      captureException(error);
    }
  };

  const onClose = () => navigate("../");

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Column height="100vh" position="relative">
        {subsetCategory?.is_required &&
          subsetCategory.subset_values.every(
            ({ access_grants_aggregate }) =>
              access_grants_aggregate?.aggregate?.count === 0,
          ) && (
            <Column position="sticky" top={0} zIndex={1}>
              <Alert
                title="Audience building is blocked"
                message={`No ${getGrantType(
                  permissionsV2Enabled,
                )} have access to any subsets in this category which blocks all ${getGrantType(
                  permissionsV2Enabled,
                )} from creating or editing audiences. Grant access to ${getGrantType(
                  permissionsV2Enabled,
                )} by clicking into each subset below.`}
                type="error"
                variant="banner"
              />
            </Column>
          )}
        <CategoryDrawerHeader
          subsetCategoryId={subsetCategory?.id ?? ""}
          name={subsetCategory?.name ?? ""}
          description={subsetCategory?.description ?? ""}
          parentModel={parentModel}
          onClose={onClose}
        />
        <Column height="100%" p={6} gap={6} overflow="auto">
          <Column gap={0.5}>
            <Row align="center" gap={2}>
              <Text fontWeight="medium">Required</Text>
              <PermissionedSwitch
                permission={{
                  v1: { resource: "audience_schema", grant: "update" },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: parentModelId ?? "",
                  },
                }}
                isChecked={Boolean(subsetCategory?.is_required)}
                isDisabled={subsetCategory?.subset_values.length === 0}
                tooltip={
                  subsetCategory?.subset_values.length === 0
                    ? "You must add a subset before making this category required."
                    : undefined
                }
                onChange={(value) => {
                  if (
                    value &&
                    audiencesWithoutSubsetCategory &&
                    audiencesWithoutSubsetCategory.length > 0
                  ) {
                    setIsBackfillRequiredSubsetsModalOpen(true);
                  } else {
                    updateRequirementProperty(value);
                  }
                }}
              />
            </Row>
            <Text color="text.secondary">
              Require a subset selection for this category whenever an audience
              is being created.
            </Text>
          </Column>

          <Column gap={2}>
            <Row alignItems="center" justifyContent="space-between">
              <Row alignItems="center" gap={2}>
                <Text fontWeight="medium">Subsets</Text>
                <Pill>{subsetCategory?.subset_values.length}</Pill>
              </Row>
              <PermissionedLinkButton
                permission={{
                  v1: { resource: "audience_schema", grant: "update" },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: parentModelId ?? "",
                  },
                }}
                icon={PlusIcon}
                href={`subsets/new${window.location.search}`}
              >
                Add subset
              </PermissionedLinkButton>
            </Row>
            {subsetCategory?.subset_values.length === 0 ? (
              <EmptyState
                imageUrl={placeholderIcon}
                title="No subsets"
                message="Add a subset to get started"
              />
            ) : (
              <Column gap={2}>
                {subsetCategory?.subset_values.map((subset) => (
                  <SubsetPreview
                    key={subset.id}
                    id={subset.id}
                    name={subset.name}
                    categoryName={subsetCategory.name}
                    parentModelId={parentModelId?.toString()}
                    userCount={
                      permissionsV2Enabled
                        ? subset.user_group_subset_grants_aggregate?.aggregate
                            ?.count ?? 0
                        : subset.access_grants_aggregate?.aggregate?.count ?? 0
                    }
                    audiencesSubsets={subset.audiences_subsets}
                  />
                ))}
              </Column>
            )}
          </Column>
        </Column>

        <DrawerFooter>
          <Row justifyContent="right" width="100%">
            <PermissionedButton
              permission={{
                v1: { resource: "audience_schema", grant: "update" },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: parentModelId ?? "",
                },
              }}
              size="lg"
              variant="warning"
              onClick={() => setIsDeleteConfirmationOpen(true)}
            >
              Delete subset category
            </PermissionedButton>
          </Row>
        </DrawerFooter>

        {subsetCategory && (
          <>
            <DeleteSubsetCategoryModal
              isOpen={isDeleteConfirmationOpen}
              subsetCategory={subsetCategory}
              onClose={() => setIsDeleteConfirmationOpen(false)}
            />
            <BackfillRequiredSubsetsModal
              audiencesWithoutSubsetCategory={
                audiencesWithoutSubsetCategory ?? []
              }
              subsetCategoryName={subsetCategory.name}
              subsetValues={subsetCategory.subset_values}
              isOpen={isBackfillRequiredSubsetsModalOpen}
              onClose={() => setIsBackfillRequiredSubsetsModalOpen(false)}
            />
          </>
        )}
      </Column>
    </>
  );
};
