import { FC, Suspense, useEffect } from "react";

import { Routes, Route, Outlet, useNavigate } from "src/router";
import { FunctionsOverview } from "./functions";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FunctionRouter } from "./function";
import { PageSpinner } from "src/components/loading";
import { CreateFunction } from "./function/create";

const Loader: FC = () => {
  const navigate = useNavigate();
  const { functionsForEventsEnabled } = useFlags();

  useEffect(() => {
    if (!functionsForEventsEnabled) {
      navigate("/home");
    }
  }, [functionsForEventsEnabled]);

  return (
    <Suspense fallback={<PageSpinner />}>
      <Outlet />
    </Suspense>
  );
};

export const FunctionsRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route path="/" element={<FunctionsOverview />} />
        <Route path="new" element={<CreateFunction />} />
        <Route path=":id/*" element={<FunctionRouter />} />
      </Route>
    </Routes>
  );
};
