import { useEffect } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  EditableDescription,
  EditableHeading,
  IconButton,
  Row,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "src/router";

import {
  TraitDefinitionsConstraint,
  TraitDefinitionsInsertInput,
  useCreateTraitMutation,
  useTraitQuery,
} from "src/graphql";
import { NotFound } from "src/pages/not-found";

import { Drawer } from "src/components/drawer";
import { Metadata } from "./metadata";
import { TraitCalculationForm } from "./trait-calculation-form";
import {
  CalculationMethod,
  formatTraitConfig,
  traitTypeToCalculationMethod,
  validateConfig,
} from "./utils";

type DuplicateFormProps = {
  name: string;
  description: string | null;
  parent_model_id: string;
  relationship_id: string;
  calculation_method: CalculationMethod;
  type: TraitType;
  config: TraitConfig;
};

export const DuplicateTrait = () => {
  const { traitId } = useParams();

  const navigate = useNavigate();
  const { toast } = useToast();
  const createTrait = useCreateTraitMutation();

  const form = useForm<DuplicateFormProps>();
  const { control, handleSubmit, reset, watch } = form;

  const { data, isLoading } = useTraitQuery(
    { id: traitId ?? "" },
    { enabled: traitId != null },
  );
  const trait = data?.trait_definitions_by_pk;

  // Set initial form data
  useEffect(() => {
    if (!trait) return;

    const initialFormValues = {
      name: `${trait.name} (Duplicate)`,
      description: trait.description,
      parent_model_id: trait.parent_model.id,
      relationship_id: trait.relationship?.id,
      calculation_method: traitTypeToCalculationMethod[trait.type],
      type: trait.type as TraitType,
      config: trait.config as TraitConfig,
    };
    reset(initialFormValues, { keepDefaultValues: false });
  }, [reset, trait]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types on Column
  const type = watch("type");
  const config = watch("config");

  const onCancel = () => navigate(`/traits/active/${traitId}`);
  const onCloseDrawer = () => navigate("/traits/active");

  const onSubmit = async (formData: DuplicateFormProps) => {
    try {
      const input: TraitDefinitionsInsertInput = {
        name: formData.name,
        description: formData.description,
        parent_model_id: formData.parent_model_id,
        relationship_id: formData.relationship_id,
        type: formData.type,
        config: formatTraitConfig(
          formData.type,
          formData.config,
          trait?.parent_model,
        ),
        is_template: false,
      };

      const newTrait = await createTrait.mutateAsync({
        input,
      });

      toast({
        id: "duplicate-trait",
        title: "Trait was created successfully",
        variant: "success",
      });

      const newTraitId = newTrait.insert_trait_definitions_one?.id;
      navigate(`/traits/active/${newTraitId}`);
    } catch (error) {
      toast({
        id: "duplicate-trait",
        title: "Trait failed to be created",
        message: error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? `There is an existing trait named "${formData.name}" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const isCreateDisabled =
    createTrait.isLoading || !validateConfig(type, config);

  return (
    <Drawer isLoading={isLoading} isOpen size="xl" onClose={onCloseDrawer}>
      {!trait ? (
        <DrawerBody>
          <NotFound />
        </DrawerBody>
      ) : (
        <FormProvider {...form}>
          <Row
            justifyContent="space-between"
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore - no circular types on Column */}
            <Column>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <EditableHeading
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <EditableDescription
                    value={field.value ?? ""}
                    onChange={field.onChange}
                  />
                )}
              />
              <Metadata
                parentModel={trait.parent_model}
                isTemplate={trait.is_template}
                isArchived={Boolean(trait.archived_at)}
              />
            </Column>
            <IconButton
              icon={CloseIcon}
              aria-label="Close"
              onClick={onCloseDrawer}
            />
          </Row>

          <DrawerBody>
            <Column overflowY="auto" flex={1}>
              <TraitCalculationForm parentModel={trait.parent_model} />
            </Column>
          </DrawerBody>

          <DrawerFooter>
            <ButtonGroup>
              <Button
                isDisabled={isCreateDisabled}
                size="lg"
                variant="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Save changes
              </Button>
              <Button size="lg" onClick={onCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </FormProvider>
      )}
    </Drawer>
  );
};
