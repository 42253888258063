import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import { Column, Row, Spinner, Text, useToast } from "@hightouchio/ui";
import { compare } from "fast-json-patch";
import { isEqual, omitBy } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { JsonEditor } from "src/components/destinations/json-editor";
import { TestSync } from "src/components/destinations/test-sync";
import { SidebarForm } from "src/components/page";
import { DestinationFormProvider } from "src/contexts/destination-form-context";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import { Form } from "src/formkit/components/form";
import {
  DraftChange,
  ExternalSegment,
  FormkitDestination,
  FormkitModel,
  FormkitProvider,
  FormkitSync,
} from "src/formkit/components/formkit-context";
import { processFormNode } from "src/formkit/formkit";
import {
  DestinationDefinition,
  DraftOperation,
  FormkitSyncDefinitionQuery,
  SourceDefinitionFragment as SourceDefinition,
  useDestinationQuery,
  useFormkitSyncDefinitionQuery,
  useFormkitSyncValidationQuery,
  useMigrateConfigQuery,
  useSupportsMatchboostingQuery,
} from "src/graphql";
import { useDraftMerger } from "src/hooks/use-draft-merger";
import * as analytics from "src/lib/analytics";
import { validate as oldValidate } from "src/utils/destinations";

import { PermissionedButton } from "src/components/permission";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import ActiveCampaignDestination from "./forms/active-campaign";
import CustomDestination from "./forms/custom";
import HeapDestination from "./forms/heap";
import HubspotLegacy from "./forms/hubspot-legacy";
import OneSignalDestination from "./forms/onesignal";
import OrbitForm from "./forms/orbit";
import PartnerstackDestination from "./forms/partnerstack";
import ReplyioDestination from "./forms/replyio";
import RudderStackDestination from "./forms/rudderstack";
import SalesloftDestination from "./forms/salesloft";
import SendgridDestination from "./forms/sendgrid";
import SfmcFileDropDestination from "./forms/sfmc-file-drop";
import TotangoDestination from "./forms/totango";
import VeroDestination from "./forms/vero";
import { cleanConfig } from "./utils";
import { DefaultSidebarTopDisplacement } from "../page/sidebar";

type FormkitDefinition = FormkitSyncDefinitionQuery["formkitSyncDefinition"];

interface DestinationFormContext {
  sync: FormkitSync | undefined;
  slug: string | undefined | null;
  model: FormkitModel | undefined;
  isModelDraft: boolean;
  draftChanges: DraftChange[];
  destination: FormkitDestination | undefined;
  destinationDefinition: DestinationDefinition;
  sourceDefinition: SourceDefinition | undefined;
  externalSegment: ExternalSegment | undefined;
}

/**
 * XXX: Destinations moved to formkit must be removed from here.
 */
export const DESTINATION_FORMS = {
  activeCampaign: ActiveCampaignDestination,
  rudderstack: RudderStackDestination,
  onesignal: OneSignalDestination,
  hubspotLegacy: HubspotLegacy,
  totango: TotangoDestination,
  sfmcFileDrop: SfmcFileDropDestination,
  sendgrid: SendgridDestination,
  partnerstack: PartnerstackDestination,
  vero: VeroDestination,
  salesloft: SalesloftDestination,
  orbit: OrbitForm,
  custom: CustomDestination,
  replyio: ReplyioDestination,
  heap: HeapDestination,
};

type Props = {
  model?: FormkitModel;
  destination?: FormkitDestination;
  sync?: FormkitSync;
  syncConfig?: any;
  destinationDefinition: DestinationDefinition;
  sourceDefinition: SourceDefinition | undefined;
  externalSegment?: ExternalSegment;
  slug: string | undefined;
  sidebarTop?: string;
  onSubmit: (config: any) => Promise<void>;
  hideSave?: boolean;
  hideSidebar?: boolean;
  hideSidebarDocs?: boolean;
  disableRowTesting?: boolean;
  permission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
  testPermission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
};

export interface FormRef {
  submit: () => Promise<void>;
}

export const DestinationForm = forwardRef<FormRef, Props>(
  function DestinationForm(
    {
      sync,
      syncConfig,
      model,
      destination,
      destinationDefinition,
      sourceDefinition,
      externalSegment,
      slug,
      sidebarTop,
      onSubmit,
      hideSave,
      hideSidebar,
      hideSidebarDocs,
      permission,
      testPermission,
      disableRowTesting = false,
    },
    ref,
  ) {
    const { editingDraft, draft } = useDraft();
    const deprecatedForm = slug ? DESTINATION_FORMS[slug] : undefined;

    const {
      error: formkitDefinitionError,
      data,
      isLoading: formkitDefinitionLoading,
    } = useFormkitSyncDefinitionQuery(
      { type: destination?.type ?? "" },
      {
        enabled: Boolean(destination?.type) && !deprecatedForm,
      },
    );

    const { data: destinationConfig, isLoading: destinationConfigLoading } =
      useDestinationQuery(
        { id: String(destination?.id) },
        {
          enabled: Boolean(destination?.id),
          select: (data) => data.destinations_by_pk?.config,
        },
      );

    const { draft: draftModel, mergeResourceWithDraft } = useDraftMerger({
      resourceId: model?.id ?? "",
      resourceType: "model",
    });

    const { data: migrateData } = useMigrateConfigQuery(
      { draftId: draft?.id ?? "" },
      {
        enabled:
          draft?.operation !== DraftOperation.Create && Boolean(draft?.id),
      },
    );

    const draftConfig = migrateData?.migrateConfig;

    const formkitDefinition = data?.formkitSyncDefinition;
    const context = {
      sync,
      slug,
      model:
        model && draftModel
          ? (mergeResourceWithDraft(model) as FormkitModel)
          : model,
      destination,
      destinationConfig,
      destinationDefinition,
      sourceDefinition,
      externalSegment,
      isModelDraft: Boolean(draftModel),
      draftChanges:
        editingDraft &&
        draft?.operation === DraftOperation.Update &&
        draftConfig
          ? compare(syncConfig || sync?.config, draftConfig)
              .map((o) => {
                if (o.op === "add" || o.op === "replace") {
                  return {
                    key: o.path.split("/").filter(Boolean).join("."),
                    op: o.op,
                  };
                } else return null;
              })
              .filter<DraftChange>((v): v is DraftChange => Boolean(v))
          : [],
    };

    if (!destination || !model || !sourceDefinition) {
      return (
        <Text>
          You do not have access to the underlying model, source or destination
          needed to edit this configuration.
        </Text>
      );
    }

    // XXX: added `!deprecatedForm` here so we dont have to re-render when loading.
    // Re-rendering when loading is causing the deprecated form to loose state.
    if (
      (!deprecatedForm &&
        !formkitDefinitionError &&
        formkitDefinitionLoading) ||
      destinationConfigLoading
    ) {
      return <Spinner size="lg" m="auto" />;
    }

    const formSyncConfig =
      editingDraft && draftConfig ? draftConfig : syncConfig || sync?.config;

    return (
      <DestForm
        ref={ref}
        sidebarTop={sidebarTop}
        context={context}
        deprecatedForm={deprecatedForm}
        formkitDefinition={formkitDefinition}
        hideSave={hideSave}
        syncConfig={formSyncConfig}
        onSubmit={onSubmit}
        hideSidebar={hideSidebar}
        hideSidebarDocs={hideSidebarDocs}
        permission={permission}
        testPermission={testPermission}
        disableRowTesting={disableRowTesting}
      />
    );
  },
);

const NullComponent = () => null;

interface DestFormProps {
  disableRowTesting?: boolean;
  syncConfig: Record<string, unknown>;
  context: DestinationFormContext;
  formkitDefinition: FormkitDefinition;
  deprecatedForm: { form: FC; validation: Yup.ObjectSchema };
  sidebarTop?: string;
  onSubmit: (config: any) => void;
  hideSave: boolean | undefined;
  hideSidebar?: boolean;
  hideSidebarDocs?: boolean;
  permission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
  testPermission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
}

const DestForm = forwardRef<FormRef, DestFormProps>(function DestForm(
  {
    syncConfig,
    disableRowTesting = false,
    context,
    formkitDefinition,
    deprecatedForm,
    sidebarTop,
    onSubmit,
    hideSave,
    hideSidebar,
    hideSidebarDocs,
    permission,
    testPermission,
  },
  ref,
) {
  const client = useQueryClient();
  const { workspace } = useUser();
  const { toast } = useToast();

  const [customValidation, setCustomValidation] = useState<{
    validate: (config: any) => Promise<{ yupError?: any; otherError?: any }>;
  }>();
  const [editingJson, setEditingJson] = useState(false);
  const [initialConfig, setConfig] = useState<any>(syncConfig || {});
  const [saving, setSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const [isInitialized, setIsInitialized] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  const methods = useForm();

  const DeprecatedForm = deprecatedForm?.form ?? NullComponent;

  const formkitValidate = async (config) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitSyncValidationQuery.fetcher({
        type: context.destination?.type ?? "",
        config,
      }),
      queryKey: useFormkitSyncValidationQuery.getKey(config),
    });

    return response.formkitSyncValidation;
  };

  const formkit = useMemo(() => {
    if (!formkitDefinition) {
      return null;
    }
    return processFormNode(formkitDefinition, 0, { ...context });
  }, [formkitDefinition]);

  // XXX: Existing state should be set via reset without setting the default values
  // This is because default values should be set by the components only
  useEffect(() => {
    if (syncConfig && Object.keys(syncConfig).length) {
      methods.reset(syncConfig, { keepDefaultValues: true });
      setConfig(syncConfig || {});
    }
    setIsInitialized(true);
  }, [syncConfig]);

  const config = formkitDefinition
    ? methods.getValues()
    : deprecatedForm?.validation?.cast(initialConfig, { assert: false });

  const isConfigChanged = !isEqual(
    syncConfig,
    omitBy(config, (v) => v === undefined),
  );

  const validate = async (config) => {
    if (formkitDefinition) {
      const cleanedConfig = cleanConfig(config);
      const errors = await formkitValidate(cleanedConfig);
      if (typeof errors === "object" && Object.keys(errors).length) {
        return errors;
      }
    } else {
      return oldValidate(config, deprecatedForm.validation, customValidation);
    }
  };

  const handleSubmit = async () => {
    setSaving(true);
    if (formkitDefinition) {
      // XXX: temporary fix to clear form errors before submit handler is validated so that the handleSubmit can
      // re-validate the form when theres an error. The proper fix should be to refactor the code to only use
      // "setErrors" only and not a combination of "setErrors" and "useForm.setError" within the formkit code
      methods.clearErrors();
      await methods.handleSubmit(async (data) => {
        const cleanedConfig = cleanConfig(data);
        const errors = await formkitValidate(cleanedConfig);
        if (typeof errors === "object" && Object.keys(errors).length) {
          Object.entries(errors).forEach(([key, message]) => {
            methods.setError(key, { message: String(message) });
          });
          analytics.track("Destination Config Validation Error");

          toast({
            id: "save-sync-config",
            title: "Couldn't save the sync configuration",
            variant: "error",
          });

          setErrors(errors);
        } else {
          await onSubmit(cleanedConfig);
          setErrors(null);
        }
      })();
    } else {
      const errors = await oldValidate(
        config,
        deprecatedForm.validation,
        customValidation,
      );
      if (errors) {
        analytics.track("Destination Config Validation Error");

        toast({
          id: "save-sync-config",
          title: "Couldn't save the sync configuration",
          variant: "error",
        });

        setErrors(errors);
      } else {
        await onSubmit(config);
        setErrors(null);
      }
    }
    setSaving(false);
  };

  useEffect(() => {
    if (editingJson) {
      analytics.track("Sync JSON Editor Opened", {
        sync_id: context.sync?.id,
        sync_slug: context.slug,
        model_name: context.model?.name,
        query_type: context.model?.query_type,
        destination_type: context.destination?.type,
        source_type: context.sourceDefinition?.type,
      });
    }
  }, [editingJson]);

  useEffect(() => {
    if (errors) {
      analytics.track("Sync Destination Configuration Error", {
        sync_id: context.sync?.id,
        sync_slug: context.slug,
        model_name: context.model?.name,
        query_type: context.model?.query_type,
        destination_type: context.destination?.type,
        source_type: context.sourceDefinition?.type,
        errors,
      });
    }
  }, [errors]);

  const { data: supportsMatchboosting } = useSupportsMatchboostingQuery(
    {
      config,
      destinationType: context.destination?.type ?? "",
    },
    {
      enabled: !!context.destination?.slug,
      keepPreviousData: true,
    },
  );

  if (!isInitialized) {
    return null;
  }

  return (
    <FormkitProvider
      {...context}
      validate={validate}
      supportsMatchboosting={supportsMatchboosting?.supportsMatchboosting}
    >
      <DestinationFormProvider
        config={config}
        errors={errors}
        setConfig={setConfig}
        setCustomValidation={setCustomValidation}
        setErrors={setErrors}
      >
        <FormProvider {...methods}>
          <Row align="flex-start" flex={1}>
            <Column flex={1} minWidth={0}>
              {formkitDefinition ? (
                <Form>{formkit}</Form>
              ) : (
                <Column
                  gap={12}
                  sx={{
                    flex: 1,
                    "& > div:not(:last-child)": {
                      pb: 12,
                      borderBottom: "1px solid",
                      borderColor: "base.border",
                    },
                  }}
                >
                  <DeprecatedForm />
                </Column>
              )}
              <form
                hidden
                id="destination-form"
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              ></form>
            </Column>
            {hideSidebar && hideSave ? null : (
              <SidebarForm
                top={
                  sidebarTop ??
                  (hideSidebar
                    ? "0px"
                    : hideSave
                      ? // Account for missing save button
                        `${DefaultSidebarTopDisplacement - 32}px`
                      : undefined)
                }
                hideInviteTeammate={hideSidebar}
                hideSendMessage={hideSidebar}
                buttons={[
                  <>
                    {!hideSave && (
                      <PermissionedButton
                        variant="primary"
                        permission={permission}
                        isDisabled={!isConfigChanged}
                        isLoading={saving}
                        onClick={handleSubmit}
                      >
                        {workspace?.approvals_required ? "Save draft" : "Save"}
                      </PermissionedButton>
                    )}

                    {!disableRowTesting && (
                      <TestSync formkit={formkit} permission={testPermission} />
                    )}
                    <PermissionedButton
                      permission={permission}
                      onClick={() => {
                        setEditingJson(true);
                      }}
                    >
                      Edit as JSON
                    </PermissionedButton>
                  </>,
                ]}
                docsUrl={
                  hideSidebarDocs
                    ? undefined
                    : `${import.meta.env.VITE_DOCS_URL}/${
                        context.destinationDefinition.docs
                      }`
                }
                invite="If you need help setting up this sync"
                name={context.destinationDefinition.name}
              />
            )}
          </Row>
          {editingJson && (
            <JsonEditor
              formkit={Boolean(formkitDefinition)}
              onClose={() => {
                setEditingJson(false);
              }}
            />
          )}
        </FormProvider>
      </DestinationFormProvider>
    </FormkitProvider>
  );
});
