import { FC, useEffect, useState } from "react";

import {
  Box,
  ChakraListItem,
  ChakraUnorderedList,
  CloseIcon,
  Column,
  Combobox,
  IconButton,
  InformationIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import { OverlaySpinner } from "src/components/loading";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { AudienceComparisonResult } from "src/graphql";
import { accurateCommaNumber } from "src/utils/numbers";

import {
  leftCircleBaseColor,
  overlapBaseColor,
  rightCircleBaseColor,
  VennDiagram,
} from "./venn-diagram";
import { NumericFontStyles } from "./constants";

type Props = {
  audienceId: number;
  audienceName: string;
  audiences: {
    id: number;
    name: string;
    description: string | null;
    parent: { id: number } | null;
  }[];
  comparedAudienceIds: string[];
  comparisonData?: AudienceComparisonResult;
  loading?: boolean;
  parentModelId: number;
  onAddComparison(audienceId: string[]): void;
  onRemoveComparison(id: string): void;
  onClearComparisons(): void;
};

export const AudienceOverlap: FC<Readonly<Props>> = ({
  audienceId,
  audienceName,
  audiences,
  comparedAudienceIds,
  comparisonData,
  loading,
  parentModelId,
  onAddComparison,
  onRemoveComparison,
}) => {
  const [toCompare, setToCompare] = useState<number | null>(null);

  const { audienceSize, allAudiencesOverlapSize, comparisons } =
    comparisonData ?? {};

  const filteredAudienceOptions = audiences.filter(
    (option) =>
      audienceId !== option.id &&
      !comparedAudienceIds.includes(option.id.toString()) &&
      parentModelId === option?.parent?.id,
  );
  const dropdownOptions = filteredAudienceOptions.map(
    ({ name, id, description }) => ({
      label: name,
      value: id,
      description,
    }),
  );

  const audienceDictionary = {};
  for (const entry of audiences) {
    audienceDictionary[entry.id] = entry;
  }

  const resetForm = () => {
    setToCompare(null);
  };

  useEffect(() => {
    resetForm();
  }, [comparisons?.length]);

  const hasComparisons = comparisons && comparisons.length > 0;

  return (
    <Column gap={2}>
      <Column gap={4}>
        {!hasComparisons && (
          <Column
            alignItems="center"
            justifyContent="center"
            height="100%"
            px={2}
            overflowY="auto"
          >
            <VennDiagram disableHover baseCircleSize={100} sx={{ my: 8 }} />
            <Box as={Text} color="text.secondary" textAlign="center">
              Compare member overlap between this audience and others you select
            </Box>
          </Column>
        )}

        {hasComparisons && (
          <Column position="relative" flex={1} minHeight={0} overflowY="auto">
            <Box position="relative">
              {loading && comparisons.length > 0 && (
                <OverlaySpinner sx={{ zIndex: 3 }} />
              )}
              <ChakraUnorderedList m={0} p={0}>
                {comparisons.map(
                  (
                    {
                      audienceId,
                      audienceSize: comparedAudienceSize,
                      overlapSize,
                    },
                    index,
                  ) => {
                    const overlapPercentage =
                      audienceSize && audienceSize > 0
                        ? overlapSize / audienceSize
                        : 0;

                    return (
                      <ChakraListItem
                        key={index}
                        position="relative"
                        display="flex"
                        flexDirection="column"
                        mb={6}
                        borderBottom="small"
                        sx={{
                          listStyle: "none",

                          ":last-child": {
                            mb: 0,
                            borderBottom: 0,
                          },
                        }}
                      >
                        <Row
                          alignItems="center"
                          justifyContent="space-between"
                          gap={4}
                        >
                          <TextWithTooltip
                            fontWeight="medium"
                            size="lg"
                            message={audienceDictionary[audienceId]?.name}
                          >
                            {audienceDictionary[audienceId]?.name}
                          </TextWithTooltip>

                          <Box
                            as={IconButton}
                            aria-label="Remove overlap"
                            bg="transparent"
                            border="none"
                            icon={CloseIcon}
                            onClick={() => onRemoveComparison(audienceId)}
                          />
                        </Row>

                        <Row alignItems="center" justifyContent="center" py={4}>
                          <VennDiagram
                            baseCircleSize={85}
                            comparedAudienceSize={comparedAudienceSize}
                            currentAudienceSize={audienceSize}
                            sharedMembers={overlapSize}
                          />
                        </Row>

                        <Column gap={2}>
                          <Row alignItems="center" gap={2}>
                            <Box
                              flexShrink={0}
                              height="12px"
                              width="12px"
                              borderRadius="50%"
                              bg={leftCircleBaseColor}
                            />
                            <TextWithTooltip
                              color="text.secondary"
                              message={audienceName}
                            >
                              {audienceName}
                            </TextWithTooltip>
                            <Box
                              as={Text}
                              fontWeight="medium"
                              textAlign="right"
                              sx={NumericFontStyles}
                            >
                              {typeof audienceSize === "number"
                                ? accurateCommaNumber(audienceSize)
                                : "unknown"}
                            </Box>
                          </Row>

                          <Row alignItems="center" gap={2}>
                            <Box
                              flexShrink={0}
                              height="12px"
                              width="12px"
                              borderRadius="50%"
                              bg={rightCircleBaseColor}
                            />

                            <TextWithTooltip
                              color="text.secondary"
                              message={audienceDictionary[audienceId]?.name}
                            >
                              {audienceDictionary[audienceId]?.name}
                            </TextWithTooltip>

                            <Box
                              as={Text}
                              fontWeight="medium"
                              textAlign="right"
                              sx={NumericFontStyles}
                            >
                              {accurateCommaNumber(comparedAudienceSize)}
                            </Box>
                          </Row>

                          <Row alignItems="center" gap={2}>
                            <Box
                              flexShrink={0}
                              height="12px"
                              width="12px"
                              borderRadius="50%"
                              bg={overlapBaseColor}
                            />

                            <TextWithTooltip
                              color="text.secondary"
                              message="Member overlap"
                            >
                              Member overlap
                            </TextWithTooltip>

                            <Row
                              justify="end"
                              gap={1}
                              flexWrap="nowrap"
                              whiteSpace="nowrap"
                              sx={{ span: NumericFontStyles }}
                            >
                              <Text fontWeight="medium">
                                {accurateCommaNumber(overlapSize)}
                              </Text>
                              <Text color="text.secondary">
                                ({(overlapPercentage * 100).toFixed(2)}%)
                              </Text>
                            </Row>
                          </Row>
                        </Column>
                      </ChakraListItem>
                    );
                  },
                )}
              </ChakraUnorderedList>

              {comparisons.length > 1 && (
                <Row
                  alignItems="center"
                  justifyContent="space-between"
                  py={4}
                  my={2}
                  mx={4}
                  borderTop="small"
                >
                  <Row alignItems="center">
                    <TextWithTooltip
                      mr={2}
                      color="gray.600"
                      fontWeight="medium"
                      message="Combined overlap"
                    >
                      Combined overlap
                    </TextWithTooltip>
                    <Tooltip message="This is the total amount of overlap between members of all currently compared audiences.">
                      <Row color="text.placeholder" fontSize="xl">
                        <InformationIcon />
                      </Row>
                    </Tooltip>
                  </Row>
                  <Row justify="end">
                    <Text color="text.secondary" fontWeight="medium">
                      {accurateCommaNumber(allAudiencesOverlapSize ?? 0)}{" "}
                      members
                    </Text>
                  </Row>
                </Row>
              )}
            </Box>
          </Column>
        )}
      </Column>

      <Row pb={6}>
        <Combobox
          isLoading={loading}
          options={dropdownOptions}
          placeholder={`Select ${
            hasComparisons ? "another" : "an"
          } audience to compare`}
          value={toCompare ?? undefined}
          optionDescription={({ description }) => description ?? ""}
          width="100%"
          onChange={(value) => {
            if (value) {
              setToCompare(value);
              onAddComparison([...comparedAudienceIds, value.toString()]);
            }
          }}
        />
      </Row>
    </Column>
  );
};
