import { FC, useEffect, useMemo, useState } from "react";

import {
  Box,
  ChakraTooltip,
  ChevronLeftIcon,
  ChevronRightIcon,
  Column,
  IconButton,
  Pill,
  Row,
  SearchInput,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import orderBy from "lodash/orderBy";
import { isPresent } from "ts-extras";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import {
  GraphSeries,
  GroupColumn,
} from "src/components/analytics/cross-audience-graph/types";
import {
  formatMetricValue,
  getSeriesName,
} from "src/components/analytics/cross-audience-graph/utils";
import { GraphTooltip } from "src/components/analytics/graph-tooltip";
import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { AggregationOption } from "src/pages/metrics/constants";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { accurateCommaNumber } from "src/utils/numbers";
import { ColumnReference } from "src/types/visual";

import { GroupByColumn, ParentModel } from "./types";
import {
  getMaxBreakdownValue,
  getNumberOfUniqueValues,
  isGroupByColumnRelatedToParent,
} from "./utils";
import { useAnalyticsContext } from "./state";

type BreakdownTableProps = {
  data: GraphSeries[];
  groupByColumns?: GroupByColumn[];
  isLoading?: boolean;
};

const metricNameKey = "metricName";
const audienceNameKey = "audienceName";
const splitNameKey = "splitName";

const getTdStyles = ({
  isLastColumn,
  isFirstOfGroup,
  isLastOfGroup,
}: {
  isLastColumn: boolean;
  isFirstOfGroup: boolean;
  isLastOfGroup: boolean;
}) => {
  if (isLastColumn) {
    return { display: "flex", alignItems: "center", px: 4 };
  }

  return {
    bg: "base.background",
    px: 4,
    mx: 1,
    ":first-of-type": {
      ml: 0,
    },
    display: "flex",
    alignItems: "center",
    minHeight: "36px",

    span: {
      visibility: "hidden",
    },
    ...(isFirstOfGroup && {
      borderTopRadius: "sm",
      mt: 1,
      span: { visibility: "visible" },
    }),
    ...(isLastOfGroup && { borderBottomRadius: "sm" }),
  };
};

const move = (
  array: string[],
  columnName: string,
  direction: "left" | "right",
) => {
  const index = array.findIndex((name) => name === columnName);
  const newArray = [...array];
  const item = newArray.splice(index, 1)[0];

  if (item) {
    if (direction === "left") {
      newArray.splice(index - 1, 0, item);
    } else if (direction === "right") {
      newArray.splice(index + 1, 0, item);
    }
    return newArray;
  }

  return array;
};

// Include model id to avoid name collisions between parent and event groupBy columns
const getGroupingKey = (
  column: ColumnReference,
  parent: ParentModel | null,
) => {
  const columnModelId = getModelIdFromColumn(column);
  const columnName = getPropertyNameFromColumn(column) ?? "--";

  return isGroupByColumnRelatedToParent(parent, column as GroupByColumn)
    ? `${columnModelId}-${columnName}`
    : columnName;
};

export const BreakdownTable: FC<BreakdownTableProps> = ({
  data,
  groupByColumns = [],
  isLoading = false,
}) => {
  const numberOfMetrics = getNumberOfUniqueValues(data, metricNameKey);
  const hasSplits = data.some(({ splitName }) => Boolean(splitName));

  const { parent } = useAnalyticsContext();
  const { page, limit, offset, setPage } = useTableConfig();
  const [search, setSearch] = useState("");
  const [columnKeys, setColumnKeys] = useState(
    [audienceNameKey, metricNameKey, hasSplits ? splitNameKey : null].filter(
      isPresent,
    ),
  );

  const groupByColumnPaths = useMemo(
    () =>
      (data?.[0]?.grouping || groupByColumns)
        .map((_, groupingIndex) => `grouping.${groupingIndex}.value`)
        .filter(isPresent),
    [data, groupByColumns],
  );

  useEffect(() => {
    const newKeys = [audienceNameKey];
    if (numberOfMetrics > 1) {
      newKeys.unshift(metricNameKey);
    }

    if (hasSplits) {
      newKeys.push(splitNameKey);
    }

    if (groupByColumnPaths.length > 0) {
      newKeys.push(...groupByColumnPaths);
    }

    setColumnKeys(newKeys);
  }, [numberOfMetrics, groupByColumnPaths]);

  const filteredData = useMemo(() => {
    const result = data.filter(
      ({ metricName, splitName, name, grouping, data }) => {
        const groupMetadata =
          grouping?.flatMap(({ column, value }) => [
            getPropertyNameFromColumn(column)?.toLowerCase() ?? "",
            value?.toLowerCase() ?? "--",
          ]) ?? [];
        const lowerCasedSearch = search.toLowerCase().trim();

        const formattedMetricValue =
          data?.[0]?.metricValue !== undefined
            ? accurateCommaNumber(data?.[0]?.metricValue)
            : undefined;

        return (
          metricName.toLowerCase().trim().includes(lowerCasedSearch) ||
          name.toLowerCase().trim().includes(lowerCasedSearch) ||
          splitName?.toLowerCase().includes(lowerCasedSearch) ||
          groupMetadata.filter((value) => value.includes(lowerCasedSearch))
            .length > 0 ||
          (formattedMetricValue &&
            (formattedMetricValue.includes(lowerCasedSearch) ||
              formattedMetricValue.replace(",", "").includes(lowerCasedSearch)))
        );
      },
    );

    const filterKeys = (columnKeys as string[])
      .slice(0, columnKeys.length - 1)
      .concat(["data.0.metricValue"]);
    const directionArray = Array(filterKeys.length - 1)
      .fill("asc")
      // Last column is a number (value) so it'll be sorted in descending order
      .concat("desc");

    return orderBy(result, filterKeys, directionArray);
  }, [columnKeys, data, search]);

  const pageData = filteredData.slice(offset, offset + limit);

  const maxValue = useMemo(() => getMaxBreakdownValue(data), [data]);
  const groupings = useMemo(
    () =>
      filteredData.flatMap(
        ({ grouping }) =>
          grouping?.map(({ column, value }) => ({
            [getGroupingKey(column, parent)]: value,
          })),
      ),
    [filteredData],
  );

  const columnsByKey = useMemo(() => {
    const map: Record<string, TableColumn> = {
      metricName: {
        headerSx: { pl: "0 !important" },
        header: () => (
          <Row align="center" gap={2}>
            <Text color="text.secondary">Metric </Text>
            <Box>
              <Pill>{getNumberOfUniqueValues(data, metricNameKey)}</Pill>
            </Box>
            <Box>
              <Tooltip message="Move column to the left" openSpeed="slow">
                <IconButton
                  aria-label="Move metric name column to the left."
                  icon={ChevronLeftIcon}
                  isDisabled={columnKeys[0] === metricNameKey}
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, metricNameKey, "left"))
                  }
                />
              </Tooltip>
              <Tooltip message="Move column to the right" openSpeed="slow">
                <IconButton
                  aria-label="Move metric name column to the right."
                  icon={ChevronRightIcon}
                  isDisabled={
                    columnKeys[columnKeys.length - 1] === metricNameKey
                  }
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, metricNameKey, "right"))
                  }
                />
              </Tooltip>
            </Box>
          </Row>
        ),
        min: "240px",
        max: "240px",
        cellSx: {
          display: "contents",
        },
        cell: ({ metricName }, index) => {
          const isFirstOfGroup =
            pageData?.[index - 1]?.metricName !== metricName;
          const isLastOfGroup =
            pageData?.[index + 1]?.metricName !== metricName;
          const isLastColumn =
            columnKeys.indexOf("metricName") === columnKeys.length - 1;

          return (
            <Box
              sx={getTdStyles({ isFirstOfGroup, isLastColumn, isLastOfGroup })}
            >
              <TextWithTooltip
                color="text.secondary"
                message={metricName}
                size="sm"
              >
                {metricName}
              </TextWithTooltip>
            </Box>
          );
        },
      },
      splitName: {
        headerSx: { pl: "0 !important" },
        header: () => (
          <Row align="center" gap={2}>
            <Text color="text.secondary">Split</Text>{" "}
            <Box>
              <Pill>
                {getNumberOfUniqueValues(data, splitNameKey, [undefined])}
              </Pill>
            </Box>
            <Box>
              <Tooltip message="Move column to the left" openSpeed="slow">
                <IconButton
                  aria-label="Move split name column to the left."
                  icon={ChevronLeftIcon}
                  isDisabled={columnKeys[0] === splitNameKey}
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, splitNameKey, "left"))
                  }
                />
              </Tooltip>
              <Tooltip message="Move column to the right" openSpeed="slow">
                <IconButton
                  aria-label="Move split name column to the right."
                  icon={ChevronRightIcon}
                  isDisabled={
                    columnKeys[columnKeys.length - 1] === splitNameKey
                  }
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, splitNameKey, "right"))
                  }
                />
              </Tooltip>
            </Box>
          </Row>
        ),
        min: "168px",
        max: "168px",
        cellSx: {
          display: "contents",
        },
        cell: ({ splitName }: GraphSeries, index) => {
          const isFirstOfGroup = pageData?.[index - 1]?.splitName !== splitName;
          const isLastOfGroup = pageData?.[index + 1]?.splitName !== splitName;
          const isLastColumn =
            columnKeys.indexOf("splitName") === columnKeys.length - 1;

          return (
            <Box
              sx={getTdStyles({ isFirstOfGroup, isLastColumn, isLastOfGroup })}
            >
              <TextWithTooltip
                color="text.secondary"
                message={splitName ?? "--"}
                size="sm"
              >
                {splitName ?? "--"}
              </TextWithTooltip>
            </Box>
          );
        },
      },
      value: {
        min: "160px",
        cell: ({
          color = "electric.500",
          name: audienceName,
          splitName,
          grouping,
          metricName,
          aggregation,
          data,
        }: GraphSeries) => {
          const metricValue = data?.[0]?.metricValue;

          if (maxValue === undefined || metricValue === undefined) {
            return <Text color="text.secondary">Not available</Text>;
          }

          const value = formatMetricValue(
            metricValue,
            aggregation === AggregationOption.PercentOfAudience,
          );
          const fillWidth = 100 * (metricValue / maxValue);
          const fullName = getSeriesName({
            audienceName,
            splitName,
            groupByColumns: grouping,
          });

          return (
            <Row align="center" width="100%" gap={1}>
              <ChakraTooltip
                bg="text.primary"
                p={3}
                placement="left"
                label={
                  <GraphTooltip
                    color={color}
                    title={metricName}
                    subtitles={[fullName]}
                    value={[{ value }]}
                  />
                }
              >
                <Box
                  bg={color}
                  borderRadius="sm"
                  height="16px"
                  minWidth={value === "0" ? 0 : "1px"}
                  width={`${fillWidth}%`}
                />
              </ChakraTooltip>

              <Text size="sm">{value}</Text>
            </Row>
          );
        },
      },
    };

    // Only show audience column if > 1 audience selected except if there are no group bys
    const numberOfUniqueAudiences = getNumberOfUniqueValues(data, "name");
    if (!groupByColumns.length || numberOfUniqueAudiences > 1) {
      map[audienceNameKey] = {
        headerSx: { pl: "0 !important" },
        disabled: () => true,
        header: () => (
          <Row align="center" gap={2}>
            <Text color="text.secondary">Audience</Text>
            <Box>
              <Pill>{numberOfUniqueAudiences}</Pill>
            </Box>
            <Box>
              <Tooltip message="Move column to the left" openSpeed="slow">
                <IconButton
                  aria-label="Move audience name column to the left."
                  icon={ChevronLeftIcon}
                  isDisabled={columnKeys[0] === audienceNameKey}
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, audienceNameKey, "left"))
                  }
                />
              </Tooltip>
              <Tooltip message="Move column to the right" openSpeed="slow">
                <IconButton
                  aria-label="Move audience name column to the right."
                  icon={ChevronRightIcon}
                  isDisabled={
                    columnKeys[columnKeys.length - 1] === audienceNameKey
                  }
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, audienceNameKey, "right"))
                  }
                />
              </Tooltip>
            </Box>
          </Row>
        ),
        min: "240px",
        max: "240px",
        cellSx: {
          display: "contents",
        },
        cell: ({ name }, index) => {
          const isFirstOfGroup = pageData?.[index - 1]?.name !== name;
          const isLastOfGroup = pageData?.[index + 1]?.name !== name;
          const isLastColumn =
            columnKeys.indexOf("audienceName") === columnKeys.length - 1;

          return (
            <Box
              sx={getTdStyles({ isFirstOfGroup, isLastColumn, isLastOfGroup })}
            >
              <TextWithTooltip color="text.secondary" message={name} size="sm">
                {name}
              </TextWithTooltip>
            </Box>
          );
        },
      };
    }

    (data?.[0]?.grouping || groupByColumns).forEach(
      (column: GroupByColumn | GroupColumn, groupingIndex) => {
        const groupByColumn = (
          "column" in column ? column.column : column
        ) as GroupByColumn;

        const columnName = getPropertyNameFromColumn(groupByColumn);
        const modelId = getModelIdFromColumn(groupByColumn);
        const groupingPath = `grouping.${groupingIndex}.value`;

        if (columnName) {
          map[groupingPath] = {
            headerSx: { pl: "0 !important" },
            header: () => {
              return (
                <Row align="center" gap={2}>
                  <Text color="text.secondary">
                    {("alias" in column && column.alias) || columnName}
                  </Text>
                  <Box>
                    <Pill>
                      {getNumberOfUniqueValues(
                        groupings,
                        getGroupingKey(groupByColumn, parent),
                        [undefined],
                      )}
                    </Pill>
                  </Box>
                  <Box>
                    <Tooltip message="Move column to the left" openSpeed="slow">
                      <IconButton
                        aria-label="Move audience name column to the left."
                        icon={ChevronLeftIcon}
                        isDisabled={columnKeys[0] === groupingPath}
                        size="sm"
                        onClick={() =>
                          setColumnKeys(move(columnKeys, groupingPath, "left"))
                        }
                      />
                    </Tooltip>
                    <Tooltip
                      message="Move column to the right"
                      openSpeed="slow"
                    >
                      <IconButton
                        aria-label="Move audience name column to the right."
                        icon={ChevronRightIcon}
                        isDisabled={
                          columnKeys[columnKeys.length - 1] === groupingPath
                        }
                        size="sm"
                        onClick={() =>
                          setColumnKeys(move(columnKeys, groupingPath, "right"))
                        }
                      />
                    </Tooltip>
                  </Box>
                </Row>
              );
            },
            cellSx: {
              display: "contents",
            },
            cell: ({ grouping }: GraphSeries, index) => {
              const value = grouping?.find(({ column: groupingColumn }) => {
                const groupingColumnName =
                  getPropertyNameFromColumn(groupingColumn);
                const groupingColumnModelId =
                  getModelIdFromColumn(groupingColumn);

                // We want to group event columns of the same name but we don't
                // want to group it with the parent model columns so make sure
                // to check the groupByColumn modelId when the groupBy is related
                // the parent model
                if (isGroupByColumnRelatedToParent(parent, groupByColumn)) {
                  return (
                    groupingColumnName === columnName &&
                    groupingColumnModelId === modelId
                  );
                }

                return (
                  groupingColumnName === columnName &&
                  groupingColumnModelId !== parent?.id?.toString()
                );
              });

              if (!value) {
                return (
                  <Text color="text.secondary" size="sm">
                    --
                  </Text>
                );
              }

              const previousRowValue = pageData?.[index - 1]?.grouping?.find(
                ({ column: groupingColumn }) =>
                  getPropertyNameFromColumn(groupingColumn) === columnName,
              );
              const nextRowValue = pageData?.[index + 1]?.grouping?.find(
                ({ column: groupingColumn }) =>
                  getPropertyNameFromColumn(groupingColumn) === columnName,
              );
              const isFirstOfGroup = previousRowValue?.value !== value.value;
              const isLastOfGroup = nextRowValue?.value !== value.value;
              const isLastColumn =
                columnKeys.indexOf(groupingPath) === columnKeys.length - 1;

              return (
                <Box
                  sx={getTdStyles({
                    isFirstOfGroup,
                    isLastColumn,
                    isLastOfGroup,
                  })}
                >
                  <TextWithTooltip
                    color="text.secondary"
                    size="sm"
                    message={value.value ?? "--"}
                  >
                    {value.value ?? "--"}
                  </TextWithTooltip>
                </Box>
              );
            },
          };
        }
      },
    );

    return map;
  }, [data, groupByColumns, groupings, page]);

  const columns = (columnKeys as string[])
    .concat(["value"])
    .map((key) => columnsByKey[key])
    .filter(isPresent);

  return (
    <Column flex={1} minHeight={0} gap={4}>
      {data.length > 0 && (
        <SearchInput
          placeholder="Search series..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      )}

      <Column height="100%" overflow="auto" flex={1} gap={2}>
        <Table
          defaultMin="min-content"
          columns={columns}
          data={pageData}
          loading={isLoading}
          rowHeight="40px"
          placeholder={{
            image: searchPlaceholder,
            title: "No breakdowns found",
            error:
              "Breakdowns failed to load. Please check your configuration and try again.",
          }}
        />

        <Box py={4}>
          <Pagination
            page={page}
            setPage={setPage}
            rowsPerPage={limit}
            count={filteredData.length}
          />
        </Box>
      </Column>
    </Column>
  );
};
