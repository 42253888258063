import { FC, useEffect, useRef, useState } from "react";

import {
  AudienceIcon,
  Box,
  BreakdownIcon,
  Button,
  ButtonGroup,
  Checkbox,
  CloseIcon,
  Column,
  EditIcon,
  ExternalLinkIcon,
  FilterIcon,
  FunnelIcon,
  GroupedCombobox,
  IconButton,
  PerformanceIcon,
  PlusIcon,
  RefreshIcon,
  Row,
  SectionHeading,
  Select,
  SettingsIcon,
  TableIcon,
  Text,
  Tooltip,
  WarningIcon,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { noop } from "ts-essentials";
import { isPresent } from "ts-extras";

import * as analytics from "src/lib/analytics";
import { AudienceColumn } from "src/components/explore/filter-popover/constants";
import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";
import { IconBox } from "src/components/icon-box";
import { PageSidebar, SidebarKey } from "src/components/layout/page-sidebar";
import {
  PermissionedButton,
  PermissionedLinkButton,
} from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import {
  AggregationOption,
  AggregationOptionsWithColumns,
} from "src/pages/metrics/constants";
import { mapAggregationConfigurationToConfigurationOption } from "src/pages/metrics/utils";
import {
  ConditionType,
  PredefinedMetric,
  PropertyCondition,
} from "src/types/visual";

import {
  MAX_SELECTED_AUDIENCES_ALLOWED,
  PREDEFINED_METRIC_OPTIONS,
} from "./constants";
import { ConversionCriteria } from "./conversion-criteria";
import { GroupBy } from "./group-by";
import { MetricBox } from "./metric-box";
import { ParentModelFilter } from "./parent-model-filter";
import { PLACEHOLDER_AUDIENCE, useAnalyticsContext } from "./state";
import { Steps } from "./steps";
import {
  AdHocAudience,
  GraphType,
  MetricSelection,
  ParentModelOption,
  isAdHocAudience,
} from "./types";
import { getMetricSelectionFromMetric, isInitialMetric } from "./utils";

const BlueAudienceIcon: FC = () => (
  <IconBox
    bg={AudienceColumn.color}
    boxSize="20px"
    icon={AudienceColumn.icon}
    iconSize="14px"
  />
);

const InvertedAudienceIcon: FC = () => (
  <IconBox
    boxSize="20px"
    icon={<AudienceIcon />}
    iconSize="14px"
    iconSx={{ color: "space.600" }}
  />
);

type AnalyticsSidebarProps = {
  funnelsErrors: Record<string, string>;
  isLoading?: boolean;
  isSavedView: boolean;
  metricSeriesErrors: Record<string, string>;
  parentModelOptions: ParentModelOption[];
  onSaveMetric?: (metricData: MetricSelection) => void;
};

export const AnalyticsSidebar: FC<AnalyticsSidebarProps> = ({
  parentModelOptions,
  funnelsErrors,
  isSavedView,
  metricSeriesErrors,
  onSaveMetric,
}) => {
  const { toast } = useToast();

  const {
    audiences,
    audiencesAndMetricsLoading,
    funnelConversionWindow,
    funnelSteps,
    graphType,
    events,
    metrics,
    metricSelection,
    parentModelId,
    parent,
    parentModelLoading,
    selectedAudiences,

    addAudience,
    addMetric,
    removeAudienceAtIndex,
    removeMetricAtIndex,
    resetMetricAtIndex,
    resetView,
    selectParentModel,
    setCumulative,
    setFunnelConversionCriteria,
    setFunnelSteps,
    setGraphType,
    updateAudienceAtIndex,
    updateMetricAtIndex,
  } = useAnalyticsContext();

  const [showParentModelFilter, setShowParentModelFilter] = useState(false);
  const [selectedAudienceToFilter, setSelectedAudienceToFilter] = useState<
    null | number
  >(null);

  // We use the audience preview permission because we're effectively
  // running queries on an audience with additional filtering
  const { isPermitted: hasPreviewPermission } = useResourcePermission({
    v1: { resource: "audience", grant: "preview" },
  });

  const graphOptions = [
    {
      label: "Performance",
      value: GraphType.Performance,
      icon: (props) => <PerformanceIcon {...props} color="text.secondary" />,
    },
    {
      label: "Breakdown",
      value: GraphType.Breakdown,
      icon: (props) => <BreakdownIcon {...props} color="text.secondary" />,
    },
    {
      label: "Funnel",
      value: GraphType.Funnel,
      icon: (props) => <FunnelIcon {...props} color="text.secondary" />,
    },
    {
      label: "Table",
      value: GraphType.Table,
      icon: (props) => <TableIcon {...props} color="text.secondary" />,
    },
  ];

  const selectedNonParentAudienceIds = new Set(
    selectedAudiences
      ?.filter(({ id }) => parent?.id !== id)
      ?.map(({ id }) => id) ?? [],
  );

  const parentModelNotSelected =
    parent &&
    !selectedAudiences?.some((a) => a.id === parent.id && !isAdHocAudience(a));

  const audiencesRef = useRef(selectedAudiences);
  audiencesRef.current = selectedAudiences;

  const metricOptions = [
    {
      label: "Saved metrics",
      options: [
        ...PREDEFINED_METRIC_OPTIONS,
        ...metrics.map((goal) => ({
          id: goal.id,
          eventModelId: null,
          name: goal.name,
          description: goal.description,
        })),
      ],
    },
    {
      label: "Events",
      options: events.map((event) => ({
        id: event.id,
        eventModelId: event.to_model.id,
        name: event.to_model?.name ?? event.name,
        description: event?.to_model?.description,
      })),
    },
  ].filter(({ options }) => options.length > 0);

  const selectMetric = (index: number) => (metricId: string) => {
    const data = getMetricSelectionFromMetric({ metricId, events, metrics });

    if (data) {
      analytics.track("New Metric Selected", {
        chart_type: graphType,
        event_model_id: data.eventModelId,
        is_event: data.eventModelId !== null,
        metric_id: data.id,
        metric_name: data.name,
      });
      updateMetricAtIndex(index, data);
    }
  };

  const disableAccumulationSelection = metricSelection.some(
    ({ id }) => id === PredefinedMetric.AudienceSize,
  );
  useEffect(() => {
    if (disableAccumulationSelection) {
      setCumulative(false);
    }
  }, [disableAccumulationSelection]);

  return (
    <>
      <PageSidebar
        stateKey={SidebarKey.Analytics}
        width="346px"
        contentStyles={{
          height: "100%",
          p: 0,
        }}
        header={
          <Column gap={2} width="100%">
            <Select
              isLoading={parentModelLoading}
              isDisabled={!hasPreviewPermission || parentModelLoading}
              optionAccessory={(option) => ({
                type: "image",
                url: option.connection?.definition.icon ?? "",
              })}
              placeholder="Select a parent model..."
              onChange={(value) => {
                if (value === undefined) {
                  return;
                }

                const option = parentModelOptions.find(
                  (option) => option.value === value,
                );

                analytics.track("Parent Model Selected", {
                  parent_model_id: value,
                  parent_model_name: option?.label,
                  parent_model_source_name: option?.connection?.name,
                  parent_model_source_type: option?.connection?.definition.name,
                });

                selectParentModel(Number(value));
              }}
              options={parentModelOptions}
              value={parentModelId ?? undefined}
            />

            <Select
              value={graphType}
              optionAccessory={(option) => ({
                type: "icon",
                icon: option.icon,
              })}
              options={graphOptions}
              onChange={(value) => setGraphType(value ?? GraphType.Performance)}
            />
          </Column>
        }
      >
        <Column justify="space-between" height="100%">
          <Column gap={4} p={4} overflow="auto" flex={1} minHeight={0}>
            {graphType === GraphType.Funnel && (
              <>
                <Column gap={2}>
                  <Row align="center" justify="space-between">
                    <SectionHeading>Steps</SectionHeading>
                    <Tooltip message="Add step">
                      <IconButton
                        icon={PlusIcon}
                        aria-label="Add funnel step input"
                        onClick={() => {
                          if (!funnelSteps.find((step) => !step.eventModelId)) {
                            setFunnelSteps([
                              ...funnelSteps,
                              {
                                eventModelId: "",
                                relationshipId: "",
                                subconditions: [],
                              },
                            ]);
                          }
                        }}
                      />
                    </Tooltip>
                  </Row>
                  <Steps steps={funnelSteps} onChange={setFunnelSteps} />
                </Column>

                <ConversionCriteria
                  funnelConversionWindow={funnelConversionWindow}
                  onChange={setFunnelConversionCriteria}
                />
              </>
            )}

            {graphType !== GraphType.Funnel && (
              <Column gap={2}>
                <Row align="center" justifyContent="space-between">
                  <SectionHeading>Metrics</SectionHeading>
                  <Tooltip message="Add metric">
                    <IconButton
                      aria-label="Add metric."
                      icon={PlusIcon}
                      onClick={addMetric}
                    />
                  </Tooltip>
                </Row>

                {metricSelection?.map((metric, index) => {
                  const {
                    aggregationMethod,
                    conditions = [],
                    attributionWindow,
                    column,
                    ...selectedMetric
                  } = metric;
                  const metricDefinition = metrics.find(
                    ({ id }) => id === selectedMetric.id,
                  );

                  let metricColumn = column;
                  const metricDefinitionAggregationMethod = metricDefinition
                    ? mapAggregationConfigurationToConfigurationOption(
                        metricDefinition,
                      )
                    : AggregationOption.Count;

                  if (
                    !metricColumn &&
                    metricDefinition &&
                    metricDefinitionAggregationMethod &&
                    AggregationOptionsWithColumns.includes(
                      metricDefinitionAggregationMethod,
                    )
                  ) {
                    const columnReference =
                      metricDefinition && "column" in metricDefinition.config
                        ? metricDefinition?.config.column
                        : null;
                    const event = events.find(
                      ({ id }) =>
                        metricDefinition.config.relationshipId.toString() ===
                        id.toString(),
                    );
                    metricColumn =
                      event?.to_model.filterable_audience_columns.find(
                        ({ name }) =>
                          name === getPropertyNameFromColumn(columnReference),
                      );
                  }

                  // Conditions will always be wrapped in an and/or condition.
                  let propertyConditions: PropertyCondition[] = [];

                  if (conditions?.[0]?.type === ConditionType.And) {
                    // Pulling subconditions out doesn't resolve the type, since it is recursive.
                    propertyConditions = conditions?.[0]
                      ?.conditions as PropertyCondition[];
                  }

                  return (
                    <MetricBox
                      key={index}
                      aggregationMethod={aggregationMethod}
                      attributionWindow={attributionWindow}
                      column={metricColumn}
                      conditions={propertyConditions}
                      error={metricSeriesErrors[metric.id]}
                      isDirty={
                        metricDefinition &&
                        !isInitialMetric(metricDefinition, {
                          conditions,
                          attributionWindow,
                        })
                      }
                      isLoading={audiencesAndMetricsLoading}
                      metric={selectedMetric}
                      metricOptions={metricOptions}
                      onRemove={
                        metricSelection.length > 1
                          ? () => removeMetricAtIndex(index)
                          : undefined
                      }
                      onResetForm={() => resetMetricAtIndex(index)}
                      onSave={onSaveMetric}
                      onSelectNewMetric={selectMetric(index)}
                      onUpdate={(updates) => {
                        analytics.track("Updating Live Metric", {
                          chart_type: graphType,
                          event_model_id: updates.eventModelId,
                          is_event: updates.eventModelId !== null,
                          metric_id: updates.id,
                          metric_name: updates.name,
                        });

                        updateMetricAtIndex(index, updates);
                      }}
                    />
                  );
                })}
              </Column>
            )}

            <Column gap={2}>
              <Row align="center" justifyContent="space-between">
                <SectionHeading>Segment by</SectionHeading>
                {(selectedAudiences?.length ?? 0) <=
                  MAX_SELECTED_AUDIENCES_ALLOWED && (
                  <ButtonGroup>
                    {parent && (
                      <Tooltip
                        message={`Filter ${parent?.name ?? "Parent model"}`}
                      >
                        <IconButton
                          icon={FilterIcon}
                          aria-label="Filter parent model"
                          onClick={() => {
                            setSelectedAudienceToFilter(
                              selectedAudiences?.length ?? 0,
                            );
                            setShowParentModelFilter(true);
                          }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip message="Add audience">
                      <IconButton
                        icon={PlusIcon}
                        aria-label="Add audience input"
                        onClick={() => addAudience()}
                      />
                    </Tooltip>
                  </ButtonGroup>
                )}
              </Row>

              <Column gap={2}>
                {selectedAudiences?.map((selectedAudience, index) => {
                  const { splits = [], ...audience } = selectedAudience;

                  // Display a static box for ad hoc audiences bc we don't want
                  // to combine them with the persisted audience dropdown so it's
                  // clearer that these audiences are temporary
                  if (isAdHocAudience(selectedAudience)) {
                    return (
                      <Column
                        key={`${audience?.id}-${audience?.name}-${index}`}
                        bg="white"
                        gap={2}
                        p={2}
                        border="1px solid #dbe1e8"
                        borderRadius="md"
                      >
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr max-content"
                          gap={2}
                          sx={{
                            input: {
                              width: "100%",
                              boxSizing: "border-box",
                            },
                          }}
                        >
                          <Tooltip message="Filtered parent model">
                            <Box
                              display="inline-flex"
                              alignItems="center"
                              gap={1}
                              paddingInline={2}
                              backgroundColor="base.lightBackground"
                              boxShadow="xs"
                              borderWidth="1px"
                              borderColor="base.border"
                              borderRadius="md"
                            >
                              <Box as={InvertedAudienceIcon} />
                              <Text>{audience.name}</Text>
                            </Box>
                          </Tooltip>

                          {(audience.name || selectedAudiences.length > 1) && (
                            <Row alignItems="center" width="fit-content">
                              {audience.name && (
                                <Tooltip message="Edit filter">
                                  <IconButton
                                    icon={EditIcon}
                                    aria-label="Edit audience filter"
                                    variant="tertiary"
                                    onClick={() => {
                                      analytics.track(
                                        "Editing Audience Filter",
                                        {
                                          chart_type: graphType,
                                          audience_id: audience.id,
                                          audience_name: audience.name,
                                        },
                                      );

                                      setSelectedAudienceToFilter(index);
                                      setShowParentModelFilter(true);
                                    }}
                                  />
                                </Tooltip>
                              )}
                              <Tooltip message="Remove">
                                <IconButton
                                  icon={CloseIcon}
                                  aria-label="Remove audience selection"
                                  variant="tertiary"
                                  onClick={() => removeAudienceAtIndex(index)}
                                />
                              </Tooltip>
                            </Row>
                          )}
                        </Box>
                      </Column>
                    );
                  }

                  const isParentModel = audience.id === parent?.id;

                  return (
                    <Column
                      key={`${audience?.id}-${audience?.name}-${index}`}
                      bg="white"
                      gap={2}
                      p={2}
                      border="1px solid #dbe1e8"
                      borderRadius="md"
                    >
                      <Box
                        display="grid"
                        gridTemplateColumns="1fr max-content"
                        gap={2}
                        sx={{
                          input: {
                            width: "100%",
                            boxSizing: "border-box",
                          },
                        }}
                      >
                        <GroupedCombobox
                          optionGroups={[
                            parentModelNotSelected || audience.id === parent?.id
                              ? {
                                  label: "Parent model",
                                  options: [
                                    {
                                      id: parent.id,
                                      name: parent.name,
                                      splits: [],
                                    },
                                  ],
                                }
                              : null,
                            {
                              label: "Audiences",
                              options:
                                audiences?.filter(
                                  ({ id }) =>
                                    !selectedNonParentAudienceIds.has(id) ||
                                    audience.id === id,
                                ) ?? [],
                            },
                          ]
                            .filter(isPresent) // Throws a type error unless null is filtered out separately
                            .filter((group) => group.options.length > 0)}
                          optionAccessory={({ id }) => {
                            if (id === parent?.id) {
                              return {
                                type: "image",
                                url: parent.connection?.definition.icon ?? "",
                              };
                            }
                            return {
                              type: "icon",
                              icon: BlueAudienceIcon,
                            };
                          }}
                          optionLabel={(option) => option.name}
                          optionValue={(option) => option.id}
                          placeholder="Select an audience..."
                          value={
                            audience.id !== PLACEHOLDER_AUDIENCE.id
                              ? audience.id
                              : undefined
                          }
                          variant="heavy"
                          width="100%"
                          onChange={(newValue) => {
                            if (!newValue) {
                              return;
                            }

                            if (newValue === parent?.id) {
                              analytics.track(
                                "Selecting Parent Model in Analytics Chart",
                                {
                                  chart_type: graphType,
                                  audience_id: newValue,
                                  parent_model_name: parent?.name,
                                  parent_model_source_name:
                                    parent?.connection?.name,
                                  parent_model_source_type:
                                    parent?.connection?.definition.name,
                                },
                              );

                              updateAudienceAtIndex(index, {
                                id: parent.id,
                                name: parent.name,
                                splits: [],
                              });
                            } else {
                              const audienceToSelect = audiences?.find(
                                ({ id }) => id === newValue,
                              );

                              analytics.track(
                                "Selecting Audience in Analytics Chart",
                                {
                                  chart_type: graphType,
                                  audience_id: newValue,
                                  audience_name: audienceToSelect?.name,
                                  has_splits: Boolean(
                                    audienceToSelect?.splits.length,
                                  ),
                                },
                              );

                              if (audienceToSelect) {
                                updateAudienceAtIndex(index, audienceToSelect);
                              }
                            }
                          }}
                        />

                        {(audience.name || selectedAudiences.length > 1) && (
                          <Row alignItems="center" width="fit-content">
                            {audience.name && (
                              <Link
                                href={
                                  isParentModel
                                    ? parent?.connection
                                      ? `/schema-v2/view/query?source=${parent.connection.id}&id=${audience.id}`
                                      : "/schema-v2/view/query"
                                    : `/audiences/${audience.id}`
                                }
                                isExternal
                              >
                                <Tooltip message="View audience">
                                  <IconButton
                                    icon={ExternalLinkIcon}
                                    aria-label="Link to selected audience"
                                    variant="tertiary"
                                    onClick={noop}
                                  />
                                </Tooltip>
                              </Link>
                            )}
                            <Tooltip message="Remove">
                              <IconButton
                                icon={CloseIcon}
                                aria-label="Remove audience selection"
                                variant="tertiary"
                                onClick={() => removeAudienceAtIndex(index)}
                              />
                            </Tooltip>
                          </Row>
                        )}
                      </Box>

                      {/* TODO(samuel): when splits are enabled for funnels, add this back in */}
                      {graphType !== GraphType.Funnel && splits.length > 0 && (
                        <Column pl={2} gap={2}>
                          {splits.map(({ id, name, enabled }, splitIndex) => (
                            <Checkbox
                              key={id}
                              label={name}
                              isChecked={enabled}
                              onChange={(event) => {
                                const newSplits = splits.slice();
                                newSplits[splitIndex] = {
                                  id,
                                  name,
                                  enabled: event.target.checked,
                                };

                                updateAudienceAtIndex(index, {
                                  ...audience,
                                  splits: newSplits,
                                });
                              }}
                            />
                          ))}
                        </Column>
                      )}

                      {graphType !== GraphType.Funnel &&
                        audience.name &&
                        metricSeriesErrors[audience.id] && (
                          <Box
                            alignItems="center"
                            display="grid"
                            gridTemplateColumns="28px 1fr 32px"
                            color="danger.base"
                            fontSize="20px"
                            gap={2}
                          >
                            <WarningIcon ml={2} />
                            <Text color="danger.base" size="sm">
                              {metricSeriesErrors[audience.id]}
                            </Text>
                          </Box>
                        )}

                      {graphType === GraphType.Funnel &&
                        audience.name &&
                        funnelsErrors[audience.id] && (
                          <Box
                            alignItems="center"
                            display="grid"
                            gridTemplateColumns="28px 1fr 32px"
                            color="danger.base"
                            fontSize="20px"
                            gap={2}
                          >
                            <WarningIcon ml={2} />
                            <Text color="danger.base" size="sm">
                              {funnelsErrors[audience.id]}
                            </Text>
                          </Box>
                        )}
                    </Column>
                  );
                })}
              </Column>
            </Column>

            <Column gap={2}>
              <GroupBy />
            </Column>
          </Column>

          <Column borderTop="1px solid" borderColor="base.border" px={6} py={4}>
            <Row align="center" justify="space-between">
              <PermissionedLinkButton
                permission={{
                  v1: { resource: "audience", grant: "preview" },
                  v2: {
                    resource: "model",
                    grant: "can_preview",
                    id: parentModelId ?? "",
                  },
                }}
                href="/metrics"
                icon={SettingsIcon}
                variant="tertiary"
              >
                Manage metrics
              </PermissionedLinkButton>
              {/* TODO(samuel): figure out what to do here to make more generic */}
              {isSavedView ? (
                <Link href="/analytics" isExternal>
                  <Button icon={PlusIcon} variant="tertiary">
                    New chart
                  </Button>
                </Link>
              ) : (
                <PermissionedButton
                  permission={{
                    v1: { resource: "audience", grant: "preview" },
                    v2: {
                      resource: "model",
                      grant: "can_preview",
                      id: parentModelId ?? "",
                    },
                  }}
                  icon={RefreshIcon}
                  variant="tertiary"
                  onClick={() => {
                    resetView(graphType);
                    toast({
                      id: "metric-form-reset",
                      title: "View reset",
                      variant: "success",
                    });
                  }}
                >
                  Reset view
                </PermissionedButton>
              )}
            </Row>
          </Column>
        </Column>
      </PageSidebar>

      {parent && selectedAudienceToFilter != null && (
        <ParentModelFilter
          parent={parent}
          selectedAudience={
            selectedAudiences?.[selectedAudienceToFilter] ?? {
              id: parent.id,
              name: "Audience X",
              splits: [],
            }
          }
          isOpen={showParentModelFilter}
          onClose={() => {
            setShowParentModelFilter(false);
            setSelectedAudienceToFilter(null);
          }}
          addFilter={(audience: AdHocAudience) =>
            selectedAudienceToFilter < (selectedAudiences?.length ?? 0)
              ? updateAudienceAtIndex(selectedAudienceToFilter, audience)
              : addAudience(audience)
          }
        />
      )}
    </>
  );
};
