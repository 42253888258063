import {
  ChangeEvent,
  memo,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";

import {
  Box,
  Checkbox,
  Spinner,
  SystemStyleObject,
  useSize,
} from "@hightouchio/ui";

import { Private } from "src/components/private";
import { SortMenu } from "src/components/sort-menu";
import { usePermissionContext } from "src/components/permission/permission-context";
import { OrderBy } from "src/graphql";
import { SortOption } from "src/ui/table/use-table-config";

import { HeaderCell, TableCell } from "./cells";
import { Placeholder, PlaceholderContent } from "./placeholder";
import { Row } from "./row";

export type TableColumn<RowData extends Record<string, any> = any> = {
  name?: string;
  header?: () => ReactElement;
  headerSx?: SystemStyleObject;
  cell: (
    row: RowData,
    index: number,
    selected: boolean,
  ) => ReactNode | ReactElement;
  cellSx?: SystemStyleObject;
  sortKey?: string;
  min?: string;
  max?: string;
  whitespace?: "unset" | undefined;
  divider?: boolean;
  disabled?: (row: RowData) => boolean;
  sortDirection?: OrderBy | null;
  onClick?(): void;
  breakpoint?: "lg" | "md" | "sm";
};

export type RowClickHandler<RowData extends Record<string, any>> = (
  row: RowData,
  event: MouseEvent,
) => unknown;

export type TableProps<RowData extends Record<string, any>> = {
  columns: Array<TableColumn<RowData>>;
  data: Array<RowData> | undefined;
  onRowClick?: RowClickHandler<RowData>;
  onSelect?: (key: any) => void;
  selectedRows?: Array<any>;
  width?: string;
  defaultMin?: string;
  defaultMax?: string;
  error?: boolean;
  placeholder?: PlaceholderContent;
  primaryKey?: keyof RowData;
  disabled?: (row: RowData) => boolean;
  headerHeight?: string;
  rowHeight?: string;
  allowWrap?: boolean;
  highlight?: number | string;
  loading?: boolean;
  showHeaders?: boolean;
  sortingEnabled?: boolean;
  scrollable?: boolean;
  top?: number | string;
  sortOptions?: SortOption<any>[];
  isDense?: boolean;
  isPrivate?: boolean;
  alignFirstHeader?: boolean;
  backgroundColor?: string;
};

export function Table<RowData extends Record<string, any>>({
  primaryKey = "id",
  columns: propColumns,
  data,
  onRowClick,
  onSelect,
  selectedRows,
  defaultMin = "0",
  defaultMax = "1fr",
  placeholder,
  error,
  headerHeight = "40px",
  rowHeight = "60px",
  allowWrap,
  disabled,
  highlight,
  loading,
  sortingEnabled = true,
  showHeaders = true,
  scrollable = false,
  isDense = false,
  top,
  sortOptions,
  isPrivate = false,
  alignFirstHeader = false,
  backgroundColor = "white",
}: Readonly<TableProps<RowData>>) {
  const isEmpty = !loading && data?.length === 0;
  const permission = usePermissionContext();

  const supportsSelection =
    Boolean(selectedRows && onSelect) && !permission?.unauthorized;
  const tableRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useSize(tableRef);
  const sortable = Boolean(sortOptions && sortOptions.length > 0);

  const columns = useMemo(() => {
    return propColumns.filter((column) => {
      if (column.breakpoint && dimensions) {
        if (column.breakpoint === "lg") {
          return dimensions.width > 1400;
        }
        if (column.breakpoint === "md") {
          return dimensions.width > 1000;
        }
        if (column.breakpoint === "sm") {
          return dimensions.width > 600;
        }
      }
      return true;
    });
  }, [propColumns, dimensions]);

  const rows = useMemo(
    () =>
      data?.map((row, index: number) => {
        return (
          <MemoizedTableRow
            key={row[primaryKey] as string}
            columns={columns as TableColumn<Record<string, any>>[]}
            disabled={
              disabled as ((row: Record<string, any>) => boolean) | undefined
            }
            height={isDense ? "auto" : rowHeight}
            index={index}
            primaryKey={primaryKey as any}
            row={row}
            selected={
              selectedRows?.includes(row[primaryKey]) ||
              (typeof highlight !== "undefined" &&
                highlight === row[primaryKey])
            }
            onClick={onRowClick as any}
            onSelect={
              supportsSelection && onSelect ? (onSelect as any) : undefined
            }
            sortable={sortable}
            isDense={isDense}
          />
        );
      }),
    [
      data,
      columns,
      highlight,
      onRowClick,
      onSelect,
      disabled,
      selectedRows,
      primaryKey,
      rowHeight,
      supportsSelection,
      isDense,
    ],
  );

  const headerCells = useMemo(() => {
    return columns.map(
      (
        { header, headerSx, name, sortDirection, onClick }: TableColumn,
        index,
      ) => {
        const key = name ? name : `header-column-${index}-cell`;
        const sortingProps = sortingEnabled
          ? {
              sortDirection,
              onClick,
            }
          : {};

        return (
          <HeaderCell
            key={key}
            top={top}
            isDense={isDense}
            {...sortingProps}
            alignFirstHeader={alignFirstHeader}
            backgroundColor={backgroundColor}
            sx={headerSx}
          >
            {header ? header() : name}
          </HeaderCell>
        );
      },
    );
  }, [backgroundColor, columns, top, isDense]);

  const onSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof onSelect !== "function") {
        return;
      }

      const selectedDisabledRows =
        data?.filter(
          (row) => disabled?.(row) && selectedRows?.includes(row[primaryKey]),
        ) ?? [];

      if (event.target.checked) {
        onSelect(
          [
            ...(data?.filter((row) => (disabled ? !disabled(row) : true)) ??
              []),
            ...selectedDisabledRows,
          ].map((row) => row[primaryKey]),
        );
      } else {
        onSelect(selectedDisabledRows.map((row) => row[primaryKey]));
      }
    },
    [data, onSelect, primaryKey, disabled],
  );

  const isPlaceholder = isEmpty || error || loading;

  const enabledRowCount =
    data?.filter((row) => (disabled ? !disabled(row) : true))?.length ?? 0;

  const enabledSelectedRowCount =
    data?.filter(
      (row) =>
        selectedRows?.includes(row[primaryKey]) &&
        (disabled ? !disabled(row) : true),
    )?.length ?? 0;

  return (
    <Private isDisabled={!isDense || !isPrivate}>
      <Box
        ref={tableRef as any}
        flex={isPlaceholder ? 1 : undefined}
        height={isPlaceholder ? "100%" : undefined}
        as="table"
        overflow={isPlaceholder ? "hidden" : undefined}
        bg={backgroundColor}
        display={isPlaceholder ? "flex" : "grid"}
        p={isPlaceholder ? placeholder?.p : undefined}
        flexDirection="column"
        gridTemplateColumns={getGridTemplateColumns(
          [
            supportsSelection && { max: "max-content" },
            ...columns,
            sortable && { max: "max-content" },
          ].filter(Boolean),
          scrollable ? "min-content" : defaultMin,
          defaultMax,
        )}
        gridTemplateRows={
          allowWrap || isDense
            ? undefined
            : `${headerHeight} repeat(auto-fill, minmax(${rowHeight}, max-content))`
        }
        width="100%"
        overflowX={scrollable ? "auto" : undefined}
        sx={{
          [`td:nth-child(1)`]: { pl: isDense ? undefined : 8 },
          [`td:nth-child(${columns.length + 1}n)`]: {
            pr: isDense ? undefined : 8,
          },
        }}
      >
        {showHeaders && !isPlaceholder && (
          <Box as="thead" display="contents">
            <Box as="tr" display="contents">
              {supportsSelection && (
                <HeaderCell top={top}>
                  <Checkbox
                    isChecked={Boolean(
                      data &&
                        data?.length > 0 &&
                        enabledSelectedRowCount === enabledRowCount,
                    )}
                    isIndeterminate={Boolean(
                      data &&
                        data?.length > 0 &&
                        (selectedRows?.length ?? 0) > 0 &&
                        enabledSelectedRowCount < enabledRowCount,
                    )}
                    onChange={onSelectAll}
                  />
                </HeaderCell>
              )}
              {headerCells}
              {sortable && (
                <HeaderCell top={top} isSortMenu>
                  <SortMenu options={sortOptions} />
                </HeaderCell>
              )}
            </Box>
          </Box>
        )}

        {loading ? (
          <Spinner size="lg" m="auto" />
        ) : isEmpty || error ? (
          <Placeholder content={placeholder} error={Boolean(error)} />
        ) : (
          <Box as="tbody" display="contents" position="relative">
            {rows}
          </Box>
        )}
      </Box>
    </Private>
  );
}

const getGridTemplateColumns = (columns, defaultMin, defaultMax) =>
  columns
    .map(({ min, max }) => `minmax(${min || defaultMin}, ${max || defaultMax})`)
    .join(" ");

type TableRowProps<RowData extends Record<string, any>> = {
  row: RowData;
  primaryKey: any;
  selected: boolean;
  disabled?: (row: RowData) => boolean;
  height?: string;
  onClick?: RowClickHandler<RowData>;
  onSelect?: (row: RowData) => unknown;
  columns: TableColumn<RowData>[];
  index: number;
  sortable: boolean;
  isDense: boolean;
};

function TableRow<RowData extends Record<string, any>>({
  row,
  disabled,
  selected,
  height,
  columns,
  onClick,
  onSelect,
  sortable,
  isDense,
  index: rowIndex,
  primaryKey,
}: TableRowProps<RowData>) {
  const isDisabled = disabled ? disabled(row) : false;
  return (
    <Row
      primaryKey={primaryKey}
      clickable={Boolean(onClick && !isDisabled)}
      disabled={isDisabled}
      row={row}
      selected={selected}
      onClick={!isDisabled ? onClick : undefined}
      onSelect={onSelect}
      sortable={sortable}
    >
      {columns.map((column: TableColumn, index) => (
        <TableCell
          isDense={isDense}
          key={column.name ? column.name : `column-${index}-cell`}
          rowIndex={rowIndex}
          column={column}
          height={height}
          selected={selected}
          row={row}
        />
      ))}
    </Row>
  );
}

const MemoizedTableRow = memo(TableRow);
