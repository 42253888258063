import { FC, useState } from "react";

import {
  Column,
  EmptyState,
  GroupedCombobox,
  Paragraph,
  Row,
  Text,
  useToast,
  DeleteIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { capitalize } from "lodash";

import {
  LinkableWorkspaceResourcesQuery,
  DeploymentResourceType,
  useDeleteResourceLinkMutation,
  useLinkableWorkspaceResourcesQuery,
  useLinkResourcesMutation,
  useLinkingResourceDefinitionsQuery,
} from "src/graphql";
import {
  PermissionedButton,
  PermissionedIconButton,
} from "src/components/permission";

interface FullResourceLinkFormProps {
  resourceType: "connections" | "destinations";
  sourceResourceId: string;
}

export const FullResourceLinkForm: FC<FullResourceLinkFormProps> = ({
  sourceResourceId,
  resourceType,
}) => {
  const { isLoading: definitionsLoading, data: resourceDefinitions } =
    useLinkingResourceDefinitionsQuery(
      {
        sources: resourceType === "connections",
        destinations: resourceType === "destinations",
      },
      {
        select: (data) => {
          if (resourceType === "connections") {
            return data.getSourceDefinitions || [];
          }
          return data.getDestinationDefinitions || [];
        },
      },
    );

  const { isLoading: linkableResourcesLoading, data: linkableResources } =
    useLinkableWorkspaceResourcesQuery(
      {
        sourceResourceId,
        resourceType,
      },
      {
        select: (data) => {
          if (!resourceDefinitions) return data.getLinkableResources;
          return data.getLinkableResources.map((resource) => {
            if (!resource.name) {
              // This map statement is needed as ts really does not like union gql array types
              const definition = resourceDefinitions
                .map(({ name, type }) => ({ name, type }))
                .find(
                  (d: { type: string; name?: string }) =>
                    d.type === resource.type,
                );
              if (definition) {
                resource.name = definition.name;
              }
            }
            return resource;
          });
        },
        enabled: !definitionsLoading,
      },
    );

  const resources = linkableResources || [];
  const linkedResources = resources.filter((r) => r.existing_link);
  const unlinkedResources = resources.filter((r) => !r.existing_link);

  const resourceName =
    resourceType === "connections" ? "source" : "destination";

  return (
    <Column gap={8}>
      <LinkedResources
        resourceType={resourceType}
        resourceName={resourceName}
        linkedResources={linkedResources}
      />
      <Column gap={2}>
        <Text size="lg" fontWeight="medium">
          Link a new {resourceName}
        </Text>
        <ResourceLinkForm
          targetResources={unlinkedResources}
          sourceResourceId={sourceResourceId}
          loadingResources={linkableResourcesLoading || definitionsLoading}
          resourceName={resourceName}
          resourceType={resourceType}
        />
      </Column>
    </Column>
  );
};

const LinkedResource: FC<{
  resourceName: string;
  resourceType: DeploymentResourceType;
  linkedResource: LinkableWorkspaceResourcesQuery["getLinkableResources"][0];
}> = ({ resourceName, linkedResource, resourceType }) => {
  const { mutate: deleteLink } = useDeleteResourceLinkMutation();
  const { toast } = useToast();
  return (
    <Row
      bg="white"
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      alignItems="center"
      justifyContent="space-between"
      width="500px"
      p={2}
      marginY={2}
    >
      {/* We need to use an absolute link here unfortunately */}
      <Row gap={1}>
        <Link
          href={`${import.meta.env.VITE_APP_BASE_URL}/${linkedResource
            .existing_link?.workspace.slug}/${resourceName}s/${
            linkedResource.id
          }`}
        >
          {linkedResource.name}
        </Link>
        <Text>in workspace </Text>
        <Link
          href={`${import.meta.env.VITE_APP_BASE_URL}/${
            linkedResource.workspace.slug
          }`}
        >
          {linkedResource.workspace.name}
        </Link>
      </Row>

      <PermissionedIconButton
        permission={{
          v2: {
            resource: resourceType === "connections" ? "source" : "destination",
            grant: "can_create",
          },
        }}
        tooltip={`Unlink this ${resourceName}`}
        variant="danger"
        icon={DeleteIcon}
        aria-label={`Delete linked ${resourceName}`}
        onClick={() => {
          try {
            deleteLink({
              resourceType,
              linkId: linkedResource?.existing_link?.id,
            });
            toast({
              title: `${capitalize(resourceName)} unlinked`,
              variant: "success",
              id: "delete-linked-resource",
            });
          } catch (e) {
            toast({
              title: `Error deleting linked ${resourceName}`,
              variant: "error",
              id: "delete-linked-resource",
            });
          }
        }}
      />
    </Row>
  );
};

interface LinkedResourcesProps {
  resourceName: string;
  linkedResources: LinkableWorkspaceResourcesQuery["getLinkableResources"];
  resourceType: DeploymentResourceType;
}

export const LinkedResources: FC<LinkedResourcesProps> = ({
  resourceName,
  linkedResources,
  resourceType,
}) => {
  const docsLink = `${
    import.meta.env.VITE_DOCS_URL
  }/workspace-management/environments`;

  return (
    <Column gap={2}>
      <Text size="lg" fontWeight="medium">
        Linked {resourceName + "s"}
      </Text>

      {linkedResources.length > 0 ? (
        linkedResources.map((linkedResource, idx) => (
          <LinkedResource
            key={idx}
            resourceName={resourceName}
            linkedResource={linkedResource}
            resourceType={resourceType}
          />
        ))
      ) : (
        <EmptyState
          title={`No linked ${resourceName}s`}
          message={
            resourceType === "connections"
              ? "Syncs that use this source also use linked sources in any deployed workspaces."
              : "Syncs that use this destination also use linked destinations in any deployed workspaces."
          }
          actions={[
            <Link key={1} href={docsLink}>
              Learn more about environments and deployments
            </Link>,
          ]}
        />
      )}
    </Column>
  );
};

type ResourceLinkFormProps = {
  resourceType: "connections" | "destinations";
  resourceName: string;
  sourceResourceId: string;
  targetResources: LinkableWorkspaceResourcesQuery["getLinkableResources"];
  loadingResources: boolean;

  // If we want to provide an existing workspace for options
  targetWorkspace?: Omit<
    LinkableWorkspaceResourcesQuery["getLinkableResources"][0]["workspace"],
    "slug"
  >;
};

/**
 * Used to link together sources or destinations in different workspaces
 */
export const ResourceLinkForm: FC<Readonly<ResourceLinkFormProps>> = ({
  resourceType,
  sourceResourceId,
  targetResources,
  loadingResources,
  resourceName,
  targetWorkspace,
}) => {
  const { mutateAsync: linkResource, isLoading: saving } =
    useLinkResourcesMutation();
  const { toast } = useToast();

  const [value, setValue] = useState<{ id: string; workspaceId: string }>();

  const save = async () => {
    if (!value) return;
    try {
      await linkResource({
        source: resourceType === "connections",
        destination: resourceType === "destinations",
        sourceResourceId: sourceResourceId,
        targetResourceId: value.id,
        targetWorkspaceId: value.workspaceId,
      });
      toast({
        title: `${capitalize(resourceType)} linked successfully`,
        variant: "success",
        id: "link-resources",
      });
    } catch (e) {
      toast({
        title: `Error linking ${resourceType}`,
        variant: "error",
        id: "link-resources",
      });
    }
    setValue(undefined);
  };

  const transformedResources: {
    [workspaceId: string]: {
      label: string;
      options: { label: string; value: { id: string; workspaceId: string } }[];
    };
  } = targetResources.reduce((acc, resource) => {
    const workspaceId = resource.workspace.id;
    if (!acc[workspaceId]) {
      acc[workspaceId] = { label: resource.workspace.name, options: [] };
    }
    acc[workspaceId].options.push({
      label: resource.name,
      value: { id: resource.id, workspaceId },
    });

    return acc;
  }, {});

  const options =
    Object.entries(transformedResources)
      .filter(
        ([w, _]) =>
          !targetWorkspace || String(targetWorkspace.id) === String(w),
      )
      .map(([_, resource]) => resource) ?? [];

  return (
    <Column gap={2}>
      <Paragraph>
        Select a {resourceName} of the same type from
        {targetWorkspace
          ? " workspace " + targetWorkspace.name
          : " another workspace you are a member of"}
      </Paragraph>
      <Row gap={2}>
        <GroupedCombobox
          isDisabled={loadingResources || saving}
          value={value}
          placeholder={`Select a ${resourceName}...`}
          optionGroups={options}
          isLoading={loadingResources || saving}
          onChange={(v) => setValue(v)}
        />

        <PermissionedButton
          permission={{
            v2: {
              resource:
                resourceType === "connections" ? "source" : "destination",
              grant: "can_create",
            },
          }}
          variant={targetWorkspace ? "secondary" : "primary"}
          isDisabled={!value || saving}
          isLoading={saving || loadingResources}
          onClick={save}
        >
          Link
        </PermissionedButton>
      </Row>
    </Column>
  );
};
