import { useFlags } from "launchdarkly-react-client-sdk";

import { Outlet } from "src/router";
import { PartnerDashboard } from "src/partner/dashboard";
import { RunDebug } from "src/pages/syncs/sync/run/debug";
import { CreateSource } from "./create-source";
import { CreateSync } from "./create-sync";
import { SyncRoutes } from "./sync";
import {
  Brand,
  CreateSyncConfig,
  CreateSourceConfig,
  ViewSourceConfig,
  ConfigType,
} from "./types";
import { SyncRun } from "./sync/run";
import { NotFound } from "src/pages/not-found";
import { AuthenticatedRoute } from "src/components/router/authenticated-route";
import { Layout } from "./layout";

export type Config = {
  brand: Brand;
  config: CreateSyncConfig | ViewSourceConfig | CreateSourceConfig;
};

// Use this for initial state for easy testing
export const exampleCreateSyncConfig: Config = {
  brand: {
    logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
    name: "Iterable",
  },
  config: {
    type: ConfigType.CreateSync,
    destinationId: "11111",
    sourceId: "12345",
    allowedSourceTypes: ["snowflake"],
    allowedModelingMethods: [],
  },
};

export const exampleCreateSourceConfig: Config = {
  brand: {
    logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
    name: "Iterable",
  },
  config: {
    type: ConfigType.CreateSource,
    allowedSourceTypes: ["snowflake"],
  },
};

const PartnerIndex = () => {
  const { partnerUser } = useFlags();
  return partnerUser ? <PartnerDashboard /> : <NotFound />;
};

export const routes = {
  path: "/partner/*",
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      index: true,
      element: <PartnerIndex />,
    },
    {
      element: <Layout />,
      children: [
        {
          path: "sources/new",
          element: <CreateSource />,
        },
        {
          path: "syncs/new",
          element: <CreateSync />,
        },
        {
          path: "syncs/:id/*",
          element: <SyncRoutes />,
        },
        {
          path: "syncs/:sync_id/runs/:run_id",
          element: <SyncRun />,
          children: [
            {
              path: "debug/:row_id",
              element: <RunDebug />,
            },
          ],
        },
      ],
    },
  ],
};
