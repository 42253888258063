import { FC, ReactNode, useState } from "react";

import {
  Box,
  Column,
  Button,
  Row,
  Heading,
  Tooltip,
  Text,
  CheckIcon,
} from "@hightouchio/ui";

import { Watermark } from "src/partner/watermark";

import { FOOTER_HEIGHT } from "./utils";

export type Step = {
  label: string;
  description: string;
  next?: ReactNode;
  tooltip?: string;
  isDisabled?: boolean;
  render: () => ReactNode;
  onNext?: () => Promise<unknown>;
};

type Props = {
  step: number;
  steps: Step[];
  onStepChange: (step: number) => void;
  onSubmit: () => Promise<unknown>;
};

const CONTAINER_MAX_WIDTH = "1200px";
const SIDEBAR_WIDTH = "280px";

export const PartnerWizard: FC<Readonly<Props>> = ({
  steps,
  step,
  onSubmit,
  onStepChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { render, next, label, tooltip, onNext, isDisabled } = steps[step]!;

  const isFirstStep = step === 0;
  const isLastStep = step === steps.length - 1;

  return (
    <Column width="100vw" height="100vh">
      <Column
        overflowY="auto"
        flex={1}
        maxWidth="100%"
        bg="base.lightBackground"
        px={4}
        sx={{ scrollbarGutter: "stable" }}
      >
        <Row
          maxW={CONTAINER_MAX_WIDTH}
          mx="auto"
          width="100%"
          gap={{ base: 8, md: 16, lg: 24, xl: 32 }}
          pb={12}
        >
          <Column
            pos="sticky"
            pt={8}
            top={0}
            gap={10}
            width={{ base: "auto", lg: SIDEBAR_WIDTH }}
            maxW={SIDEBAR_WIDTH}
            height="max-content"
            flexShrink={0}
          >
            <Column as="ol" p={0} gap={1}>
              {steps.map((s, index) => (
                <Row gap={4} as="li" key={s.label}>
                  <Column>
                    {step > index ? (
                      <Row
                        flexShrink={0}
                        fontSize="lg"
                        bg="base.background"
                        borderRadius="50%"
                        w="21px"
                        h="21px"
                        align="center"
                        justify="center"
                      >
                        <CheckIcon color="primary.base" />
                      </Row>
                    ) : index > step ? (
                      <Row
                        flexShrink={0}
                        borderRadius="50%"
                        w="21px"
                        h="21px"
                        border="1px"
                        borderColor="base.border"
                      />
                    ) : (
                      <Row
                        flexShrink={0}
                        w="21px"
                        h="21px"
                        align="center"
                        justify="center"
                        border="2px"
                        borderColor="primary.base"
                        borderRadius="50%"
                      >
                        <Row
                          flexShrink={0}
                          w="9px"
                          h="9px"
                          borderRadius="50%"
                          bg="primary.base"
                        />
                      </Row>
                    )}
                    {index < steps.length - 1 && (
                      <Box
                        mt="4px"
                        w="1px"
                        height="48px"
                        ml="10px"
                        bg="base.divider"
                      />
                    )}
                  </Column>
                  <Column gap={1} display={{ base: "none", lg: "flex" }}>
                    <Text fontWeight="medium">{s.label}</Text>
                    <Text size="sm" color="text.secondary">
                      {s.description}
                    </Text>
                  </Column>
                </Row>
              ))}
            </Column>
          </Column>
          <Column
            flex={1}
            minW={0}
            // Add zIndex for web screens so it sits on top of the heading
            zIndex={{ base: undefined, lg: 1 }}
          >
            <Row
              width="100%"
              maxW={CONTAINER_MAX_WIDTH}
              mx="auto"
              pos="sticky"
              top={0}
              align="center"
              flexShrink={0}
              bg="base.lightBackground"
              py={8}
              zIndex={1}
            >
              <Heading size="xl">{label}</Heading>
            </Row>
            {render()}
          </Column>
        </Row>
      </Column>

      <Row
        align="center"
        borderTop="1px"
        borderColor="base.border"
        height={FOOTER_HEIGHT}
        py={3}
        px={4}
      >
        <Row
          gap={{ base: 6, lg: 24, xl: 32 }}
          flexWrap={{ base: "wrap-reverse", md: "nowrap" }}
          justify="space-between"
          width="100%"
          maxW={CONTAINER_MAX_WIDTH}
          mx="auto"
        >
          <Watermark
            display="flex"
            alignItems="center"
            justifyContent={{ base: "center", md: "start" }}
            minW={{ base: "auto", lg: SIDEBAR_WIDTH }}
            w={{ base: "auto", lg: SIDEBAR_WIDTH }}
            py={0}
          />
          <Row
            gap={3}
            align="center"
            justify={{ base: "end", lg: "space-between" }}
            w={{ base: "auto", lg: "100%" }}
            ml="auto"
            sx={{ scrollbarGutter: "stable" }}
          >
            {!isFirstStep && (
              <Button
                size="lg"
                onClick={() => {
                  onStepChange(step - 1);
                }}
              >
                Back
              </Button>
            )}
            <Box ml="auto">
              {next ?? (
                <Tooltip isDisabled={!tooltip} message={tooltip ?? ""}>
                  <Button
                    size="lg"
                    variant="primary"
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    onClick={async () => {
                      setIsLoading(true);
                      if (isLastStep) {
                        await onSubmit();
                      } else if (onNext) {
                        await onNext();
                      }
                      setIsLoading(false);
                    }}
                  >
                    {isLastStep ? "Finish" : "Continue"}
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Row>
        </Row>
      </Row>
    </Column>
  );
};
