import { FC } from "react";

import {
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalBody,
  Row,
  Button,
  Paragraph,
  Heading,
  WarningIcon,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import noop from "lodash/noop";

import { useUser } from "src/contexts/user-context";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";

export const OverageModal: FC = () => {
  const { data: entitlementsData } = useEntitlements(true);
  const isOpen = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO once more overages are added, determine the text based on the active overage.
  const { workspace } = useUser();

  const messagePylon = () => {
    analytics.track("SS Overage Modal Lockout Live Chat Clicked", {
      workspace_id: workspace?.id,
    });
    newPylonMessage(
      `Hi, ${overageText.replace(
        /This/,
        "my",
      )} I would like to learn more about upgrading.`,
    );
  };

  return (
    <ChakraModal isCentered isOpen={Boolean(isOpen)} size="xl" onClose={noop}>
      <ChakraModalOverlay />
      <ChakraModalContent p={0} maxHeight="85%" overflowY="auto" my="auto">
        <ChakraModalHeader p={4}>
          <Row gap={3} align="center">
            <WarningIcon color="red" />
            <Heading>Billing Alert</Heading>
          </Row>
        </ChakraModalHeader>
        <ChakraModalBody overflowY="auto" p={0} mt={0}>
          <Paragraph>{overageText}</Paragraph>
        </ChakraModalBody>
        <ChakraModalFooter
          p={4}
          mt={0}
          borderTop="1px solid"
          borderColor="base.border"
          boxShadow="xs"
        >
          <Button onClick={() => messagePylon()}>Talk to us</Button>
          <LinkButton variant="primary" href="/settings/billing">
            Go to billing
          </LinkButton>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
