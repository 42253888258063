import { AggregationOption } from "src/pages/metrics/constants";
import type {
  FilterableColumn,
  PropertyCondition,
  AndOrCondition,
  RawColumn,
  RelatedColumn,
  AnalyticsFrequency,
  Relationship,
  PerUserAggregationType,
  AudienceAggregationType,
  AttributionWindow,
  UserDefinedMetricConfig,
  IntervalUnit,
  AndCondition,
  OrCondition,
} from "src/types/visual";

import { TimeOptions } from "./state";

export enum GraphType {
  Performance = "performance",
  Breakdown = "breakdown",
  Funnel = "funnel",
  Table = "table",
}

export type BaseAudience = {
  id: number;
  name: string;
  splits: { id: string; name: string; enabled: boolean }[];
};

export type AudienceFilter = AndOrCondition<AndCondition | OrCondition>;

// Temp audiences that filters the parent model that are meant to just exist in the chart
export type AdHocAudience = BaseAudience & {
  filter: AudienceFilter;
};

export type SelectedAudience = AdHocAudience | BaseAudience;

export const isAdHocAudience = (
  audience: SelectedAudience,
): audience is AdHocAudience => {
  return "filter" in audience && audience.filter.conditions?.length > 0;
};

export type ParentModelOption = {
  label: string;
  value: number;
  connection: {
    __typename?: "connections";
    name: string;
    definition: {
      __typename?: "SourceDefinition";
      name: string;
      icon: string;
    };
  } | null;
};

export type EventOrMetricOption = {
  id: string; // this is relationship id for events
  eventModelId: any;
  name: string;
  description: string | null;
};

export type MetricSelection = {
  id: string; // this is relationship id for events
  eventModelId: string | null;
  name: string;
  description: string | null;
  aggregationMethod: AggregationOption;
  conditions: AndOrCondition<PropertyCondition>[];
  attributionWindow?: AttributionWindow;
  column?: FilterableColumn;
};

const requiredMetricSelectionKeys = [
  "aggregationMethod",
  "conditions",
  "eventModelId",
  "id",
  "name",
];

export const isMetricSelection = (
  metricSelection: MetricSelection,
): metricSelection is MetricSelection => {
  let isMetricSelection = true;

  const keys = new Set(Object.keys(metricSelection));

  requiredMetricSelectionKeys.forEach((key) => {
    if (!keys.has(key)) {
      isMetricSelection = false;
    }
  });

  return isMetricSelection;
};

export type SavedChartFunnelStage = {
  event_model_id: string;
  relationship_id: string;
  funnel_stage_definition?: {
    subconditions: AndOrCondition<PropertyCondition>[];
  };
};

export type FunnelStep = {
  eventModelId: string;
  subconditions: AndOrCondition<PropertyCondition>[];
  relationshipId: string;
};

export type FunnelStepGraphDataPoint = {
  /* The grouping value */
  groupByValue: string | number | undefined;

  /* The amount of users in the stage */
  count: number;

  /* The conversion value between the previous and current step */
  conversion: number;

  /* 
    The size of the conversion bar to show in the bar chart.
    May not be the same value as the conversion
  */
  conversionBarSize: number;
  /*
    The size of the drop off bar to show in the bar chart.
    This bar will be stacked on top of conversionBar.
  */
  dropOffBarSize: number;
};

export type FunnelStepGraphData = {
  stageName: string;
  eventModelId: string;
  relationshipId: string;
  subconditions: AndOrCondition<PropertyCondition>[];
  index: number; // redundant but necessary for recharts graph
  data: Record<string, FunnelStepGraphDataPoint>;
};

export type FunnelTableData = {
  seriesName: string;
  conversion: number;
  steps: {
    stageName: string;
    numberOfUsers: number;
    conversion: number;
  }[];
};

export enum MeasurementType {
  UniqueConversion = "uniqueConversion",
  TotalConversion = "totalConversion",
}

export type HoveredSection = {
  dataKey: string | null;
  seriesName: string | null;
  section: "conversion" | "dropOff";
};

export type ConversionWindow = {
  quantity: number;
  unit: IntervalUnit.Day | IntervalUnit.Week | IntervalUnit.Month;
};

export type ConversionCriteriaData = {
  measuringType: MeasurementType;
  funnelConversionWindow: ConversionWindow;
};

export type GroupByColumn = RawColumn | RelatedColumn;

export type GroupByOptionColumnReference =
  | (RawColumn | RelatedColumn)[]
  | RawColumn
  | RelatedColumn;

export type GroupByOption = {
  groupLabel: string | null;
  name: string;
  columnReference: GroupByOptionColumnReference;
};

export type GroupByValue = Array<GroupByColumn | GroupByColumn[] | undefined>;

export type URLData = {
  audiences?: SelectedAudience[];
  cumulative?: boolean;
  frequency: AnalyticsFrequency;
  funnelConversionWindow: ConversionWindow;
  funnelSteps: FunnelStep[];
  graph: GraphType;
  groupBy: (GroupByColumn | undefined)[];
  measuringType: MeasurementType;
  metrics: MetricSelection[];
  parentModelId: number;
  selectedDates: string[];
  timeValue: TimeOptions;
};

export type ParentModel = {
  id: number;
  name: string;
  filterable_audience_columns: FilterableColumn[];
  relationships: Relationship[];
  connection?: {
    id: string;
    name: string;
    definition: { icon: string; type: string; name: string };
  } | null;
};

export type Metric = {
  id: string;
  name: string;
  description: string | null;
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
  attribution_window: AttributionWindow;
  // The analytics page assumes that only "User-defined" metrics are being queried
  // as opposed to "Predefined" metrics which have a different config defiition.
  config: UserDefinedMetricConfig;
  audiences: {
    enabled: boolean;
    segment: {
      id: any;
      name: string;
    };
  }[];
};

export type AnalyticsState = {
  audiences: SelectedAudience[] | undefined;
  audiencesAndMetricsLoading: boolean;
  cumulative: boolean;
  events: Relationship[];
  funnelConversionWindow: ConversionWindow;
  funnelSteps: FunnelStep[];
  graphType: GraphType;
  groupByColumns: (GroupByColumn | undefined)[];
  measuringType: MeasurementType;
  metrics: Metric[];
  metricSelection: MetricSelection[];
  parent: ParentModel | null;
  parentModelId: number | null;
  parentModelLoading: boolean;
  rollupFrequency: AnalyticsFrequency;
  selectedAudiences: SelectedAudience[] | undefined;
  selectedDates: string[]; // Dates get serialized as ISO strings due to URL encoding
  timeValue: TimeOptions;
};

export type AnalyticsActions = {
  addAudience: (audience?: SelectedAudience) => void;
  addGroupByColumn: (column: GroupByColumn | undefined, index: number) => void;
  addGroupByColumns: (
    column: (GroupByColumn | undefined)[],
    fromIndex: number,
  ) => void;
  addMetric: () => void;
  removeAudienceAtIndex: (index: number) => void;
  removeGroupByColumns: (startIndex: number, endIndex?: number) => void;
  removeMetricAtIndex: (indexToRemove: number) => void;
  resetMetricAtIndex: (index: number) => void;
  resetView: (view: GraphType) => void;
  selectParentModel: (id: number) => void;
  setFunnelConversionCriteria: (data: Partial<ConversionCriteriaData>) => void;
  setCumulative: (cumulative: boolean) => void;
  setFunnelSteps: (steps: FunnelStep[]) => void;
  setGraphType: (graphType: GraphType) => void;
  setRollupFrequency: (rollupFrequency: AnalyticsFrequency) => void;
  setSelectedDates: (dates: Date[]) => void;
  setLookbackWindow: (time: TimeOptions) => void;
  updateAudienceAtIndex: (index: number, updates: SelectedAudience) => void;
  updateMetricAtIndex: (
    index: number,
    updates: Partial<MetricSelection>,
  ) => void;
};

export type AnalyticsContextType = AnalyticsState & AnalyticsActions;
