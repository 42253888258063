// eslint-disable-next-line no-restricted-imports
import { matchRoutes } from "react-router-dom";

import { routes } from "src/components/router";

export const preloadRoute = (pathname: string) => {
  console.log(routes, pathname);
  const nextMatches = matchRoutes(routes, { pathname });
  if (nextMatches) {
    // This will call the loader for all matching routes (i.e. parent routes as well)
    for (const match of nextMatches) {
      if (match.route.loader) {
        (match.route.loader as any)({
          params: match.params,
        });
      }
    }
  }
};
