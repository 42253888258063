import { Controller, useFormContext } from "react-hook-form";
import { Box, NumberInput } from "@hightouchio/ui";

export const NumberInputField = ({ name }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Box width="90px">
          <NumberInput
            isInvalid={Boolean(error)}
            size="sm"
            value={value}
            onChange={onChange}
          />
        </Box>
      )}
    />
  );
};
