import { FormField, TextInput } from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import validator from "validator";

import { useWorkspaceNotificationChannelsQuery } from "../../../graphql";

export const EmailRecipientForm: FC = () => {
  const { watch } = useFormContext();

  const channelId = watch("channelId");

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      select: (data) => data.workspace_notification_channels,
    },
  );

  return (
    <Controller
      name="config.emailAddress"
      rules={{
        required: "Email address is required",
        validate: (value) =>
          validator.isEmail(value) &&
          !workspaceChannels?.some(
            (c) => c.id !== channelId && c.config.emailAddress === value,
          ),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormField label="Email address" error={error?.message}>
          <TextInput
            width="100%"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            autoComplete="false"
            type="email"
          />
        </FormField>
      )}
    />
  );
};
