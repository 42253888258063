import { ChangeEventHandler, useEffect, useMemo, useState } from "react";

import { sortBy } from "lodash";

import {
  ColumnType,
  Condition,
  ConditionType,
  getInitialTraitColumn,
  isRelatedColumn,
  isTraitColumn,
  RawColumn,
  RelatedColumn,
} from "src/types/visual";

import { useQueryBuilderContext } from "src/components/explore/context/query-builder-context";
import { getTraitPropertyType } from "src/components/explore/visual/utils";
import { FilterOption } from "./constants";
import {
  EventFilterOption,
  FilterColumnOption,
  InlineTraitFilterOption,
  NumberOfFilterOption,
  PropertyFilterOption,
  SegmentSetFilterOption,
  TraitFilterOption,
} from "./types";
import {
  getSelectedColumn,
  buildConditionFromColumn,
  getRelationshipLabel,
  countRelationsByModel,
} from "./utils";
import { isTransformedColumn } from "@hightouch/lib/query/visual/types";

export interface UseConditionSelect<TCondition> {
  condition?: TCondition;
  initialTab?: FilterOption;
}

const customTraitOption: InlineTraitFilterOption = {
  type: FilterOption.InlineTrait,
  label: "Create a custom trait",
  value: null,
  description: undefined,
};

export const useConditionSelect = <TCondition extends Condition>({
  condition,
  initialTab = FilterOption.All,
}: UseConditionSelect<TCondition>) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<FilterOption>(initialTab);
  const [refreshSelectedTab, setRefreshSelectedTab] = useState(true);

  const { columns, events, relationships, traits, relatedAudiences } =
    useQueryBuilderContext();

  const updateSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };

  const changeFilter = (filterType: FilterOption) => {
    setFilter(filterType);
  };

  const reset = () => {
    setFilter(FilterOption.All);
    setSearch("");
  };

  const propertyOptions: PropertyFilterOption[] = useMemo(
    () =>
      sortBy(
        columns.map(
          ({
            alias,
            name,
            column_reference,
            custom_type,
            description,
            type,
            model_name,
            case_sensitive,
          }) => ({
            case_sensitive,
            description,
            label: alias || name,
            modelName: model_name,
            propertyType: (custom_type ?? type) as ColumnType,
            type: FilterOption.Property,
            value: column_reference as RawColumn | RelatedColumn,
          }),
        ),
        (column) => column.label.toLowerCase(),
      ),
    [columns],
  );

  const traitOptions: TraitFilterOption[] = useMemo(() => {
    const options: TraitFilterOption[] = traits
      .filter((trait) => trait.type)
      .map((trait) => ({
        label: trait.name,
        value:
          condition?.type === ConditionType.Property &&
          (isRelatedColumn(condition.property) ||
            isTransformedColumn(condition.property)) &&
          isTraitColumn(condition.property.column) &&
          condition?.property?.column.traitDefinitionId === trait.id
            ? { ...condition.property, something: [] }
            : getInitialTraitColumn(trait),
        description: trait.description,
        propertyType: getTraitPropertyType(trait),
        type: trait.is_template
          ? FilterOption.TraitTemplate
          : FilterOption.Trait,
        parentModel: trait.parentModel,
        relatedModel: trait.relationship?.to_model,
      }));

    return sortBy(options, (option) => option.label.toLowerCase());
  }, [traits]);

  const eventOptions: EventFilterOption[] = useMemo(() => {
    const grouped = countRelationsByModel(events);

    return sortBy(
      events?.map(
        ({
          id: relationshipId,
          name: relationshipName,
          to_model: { id: eventModelId, name: eventModelName, description },
        }) => ({
          label:
            (grouped[eventModelName] || 0) >= 2
              ? getRelationshipLabel(relationshipName, eventModelName)
              : eventModelName,
          description,
          value: { eventModelId, relationshipId },
          type: FilterOption.Event,
        }),
      ),
      (column) => column.label.toLowerCase(),
    );
  }, [events]);

  // Don't show related events under the options. Users should use the
  // EventConditionFilter for creating queries based on events.
  const relatedModelOptions: NumberOfFilterOption[] = useMemo(() => {
    const grouped = countRelationsByModel(relationships);

    return sortBy(
      relationships
        ?.filter(
          ({ to_model: { event }, cardinality, merge_columns }) =>
            // 1:1 relationships that are merged will be shown here as well
            !event &&
            (!merge_columns || (merge_columns && cardinality === "one")),
        )
        ?.map(
          ({
            id,
            name: relationshipName,
            to_model: { name: relatedModelName, description },
            cardinality,
            merge_columns,
          }) => ({
            value: { relationshipId: id },
            label:
              (grouped[relatedModelName] || 0) >= 2
                ? getRelationshipLabel(relationshipName, relatedModelName)
                : relatedModelName,
            description,
            type: FilterOption.Relation,
            disabledText:
              merge_columns && cardinality === "one"
                ? "This related model has 1:1 merged columns meaning you can find the values as properties."
                : undefined,
          }),
        ),
      (column) => column.label.toLowerCase(),
    );
  }, [relationships]);

  const audienceOptions: SegmentSetFilterOption[] = useMemo(
    () =>
      sortBy(
        relatedAudiences?.map(({ value, ...data }) => ({
          ...data,
          value: { modelId: value },
          type: FilterOption.Audience,
        })),
        (column) => column.label.toLowerCase(),
      ),
    [relatedAudiences],
  );

  const allColumns = useMemo(
    () =>
      sortBy(
        [
          ...propertyOptions,
          ...traitOptions,
          ...eventOptions,
          ...relatedModelOptions,
          ...audienceOptions,
        ],
        (columns) => columns.label.toLowerCase(),
      ),
    [
      propertyOptions,
      traitOptions,
      eventOptions,
      relatedModelOptions,
      audienceOptions,
    ],
  );

  const optionsByFilter = useMemo(
    () => ({
      [FilterOption.All]: allColumns,
      [FilterOption.Property]: propertyOptions,
      [FilterOption.Event]: eventOptions,
      [FilterOption.Relation]: relatedModelOptions,
      [FilterOption.Audience]: audienceOptions,
      [FilterOption.Trait]: [customTraitOption, ...traitOptions],
      [FilterOption.TraitTemplate]: [customTraitOption, ...traitOptions],
    }),
    [
      allColumns,
      propertyOptions,
      eventOptions,
      relatedModelOptions,
      audienceOptions,
      traitOptions,
    ],
  );

  const popoverOptions: FilterColumnOption[] = useMemo(() => {
    const columns = optionsByFilter[filter].filter(
      ({ label, description }) =>
        label?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        description?.toLowerCase().includes(search.trim().toLowerCase()),
    );

    return columns;
  }, [optionsByFilter, filter, search]);

  const selectedColumn = useMemo(() => {
    if (!condition) {
      return undefined;
    }

    return getSelectedColumn(condition, allColumns);
  }, [condition, allColumns]);

  // Popover stays mounted, so may need to be triggered externally.
  useEffect(() => {
    if (refreshSelectedTab) {
      const newFilter = getSelectedColumn(condition, allColumns)?.type;

      if (newFilter) {
        setFilter(newFilter);
      }

      setRefreshSelectedTab(false);
    }
  }, [allColumns, condition, refreshSelectedTab]);

  return {
    columns: popoverOptions,
    filter,
    search,
    selectedColumn,
    onResetSelectedTab: () => setRefreshSelectedTab(true),
    onFilterChange: changeFilter,
    onReset: reset,
    onSearch: updateSearch,
    onSelectColumn: (column: FilterColumnOption) =>
      buildConditionFromColumn(column),
  };
};
