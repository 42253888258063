import ELK, { ElkNode } from "elkjs/lib/elk.bundled.js";
import { sortBy } from "lodash";
import { Node, Edge } from "reactflow";

import { SchemaModelType } from "src/types/schema";

import { UnreachableCaseError } from "ts-essentials";
import {
  NODE_HEIGHT,
  NODE_WIDTH,
  NODE_SPACING,
} from "src/pages/schema/graph/utils";
import { CHILD_NODE_HEIGHT } from "./utils";

export const getNodeHeight = (node: Node, expandedGroupNodeId?: string) => {
  if (node.type === SchemaModelType.Group) {
    const countChildren = (node.data.children || []).length;

    const childrenToRender =
      expandedGroupNodeId === node.id
        ? countChildren
        : Math.min(countChildren, 5);

    return NODE_HEIGHT + childrenToRender * CHILD_NODE_HEIGHT;
  }

  return NODE_HEIGHT;
};

type RepositionParams = {
  nodes: Node[];
  edges: Edge[];
  expandedGroupNodeId: string | undefined;
  ephemeralNode: Node | undefined;
  selectedId: string | undefined;
};

export const reposition = async ({
  nodes,
  edges,
  expandedGroupNodeId,
  ephemeralNode,
  selectedId,
}: RepositionParams): Promise<Node[]> => {
  const elk = new ELK();
  const elkNodes: ElkNode[] = sortBy(nodes, [
    (n) => {
      const type = n.type as SchemaModelType;
      switch (type) {
        case SchemaModelType.Parent:
          return 1;
        case SchemaModelType.Group:
          return 2;
        case SchemaModelType.Related:
          return 3;
        case SchemaModelType.Event:
          return 4;
        case SchemaModelType.Catalog:
          return 5;
        case SchemaModelType.Interaction:
          return 6;
        case SchemaModelType.Asset:
          return 7;
        case SchemaModelType.AdStats:
          return 8;
        default:
          throw new UnreachableCaseError(type);
      }
    },
  ]).map((node) => {
    return {
      id: node.id,
      width: NODE_WIDTH,
      height: getNodeHeight(node, expandedGroupNodeId || undefined),
    };
  });

  const elkEdges = edges.map((edge) => {
    return {
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    };
  });
  const graph: ElkNode = {
    id: "root",
    // https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
    layoutOptions: {
      "elk.layered.layering.strategy": "NETWORK_SIMPLEX",
      "elk.debugMode": "true",
      "elk.algorithm": "layered",
      "elk.direction": "RIGHT",
      "elk.layered.spacing.nodeNodeBetweenLayers": `${NODE_SPACING}`,
      "elk.layered.spacing.nodeNode": "30",
      "nodePlacement.strategy": "LINEAR_SEGMENTS",
    },
    children: elkNodes,
    edges: elkEdges,
  };
  const layout = await elk.layout(graph);

  const repositionedNodes: Node[] = [];

  for (const node of nodes) {
    const repositionedNode = layout.children?.find((n) => n.id === node.id);
    repositionedNodes.push({
      ...node,
      position: { x: repositionedNode?.x || 0, y: repositionedNode?.y || 0 },
    });
  }

  const layoutedNodes = repositionedNodes.map((node) => {
    const isChildOfGroup = (node.data.children ?? []).find(
      (child) => child.id === selectedId,
    );
    const isSelected = ephemeralNode
      ? node.id === ephemeralNode.id
      : node.id === selectedId || isChildOfGroup;

    return {
      ...node,
      data: {
        ...(node.data || {}),
        isSelected,
        isSource: Boolean(edges.find((edge) => edge.source === node.id)),
        isTarget: Boolean(edges.find((edge) => edge.target === node.id)),
        isExpanded: expandedGroupNodeId === node.id,
      },
    };
  });

  return layoutedNodes;
};
