import { FC, useState } from "react";

import {
  Column,
  useToast,
  Heading,
  TextInput,
  FormField,
  Row,
  Text,
  Box,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import Helmet from "react-helmet";
import {
  Controller,
  SubmitHandler,
  FormProvider,
  useForm,
} from "react-hook-form";
import { useNavigate } from "src/router";
import * as yup from "yup";

import {
  EventSourceDefinition,
  eventSourceDefinitions,
} from "src/events/types";
import { useCreateEventSourceMutation } from "src/graphql";
import { TrackView } from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";
import { Wizard, Step } from "src/components/wizard";
import { SidebarForm } from "src/components/page";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { getEventSourceForm } from "./source/form";

const eventSourceSchema = yup.object().shape({
  name: yup.string().required("Source name is required"),
});

type EventSourceFormState = {
  name: string;
};

export const CreateEventSource: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useResourceSlug(SlugResourceType.EventSources);

  const createMutation = useCreateEventSourceMutation();

  const form = useForm<EventSourceFormState>({
    resolver: yupResolver(eventSourceSchema),
    defaultValues: {
      name: "",
    },
  });

  const sourceDefinitions = Object.values(eventSourceDefinitions);

  const [sourceDefinition, setSourceDefinition] =
    useState<EventSourceDefinition>();

  const SourceForm = sourceDefinition
    ? getEventSourceForm(sourceDefinition)
    : undefined;

  const [config, setConfig] = useState<Record<string, any>>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string | null>();

  const submit: SubmitHandler<EventSourceFormState> = async ({ name }) => {
    try {
      const slug = await getSlug(name);
      const source = await createMutation.mutateAsync({
        input: {
          slug,
          type: sourceDefinition!.type,
          name,
          config,
          tunnel_id: tunnelId || null,
          credential_id: credentialId || null,
        },
      });
      toast({
        id: "create-event-source",
        title: "Event source was created",
        variant: "success",
      });
      navigate(`/events/sources/${source.createEventSource.id}`);
    } catch (error) {
      captureException(error);
      toast({
        id: "create-event-source",
        title: "Event source could not be created",
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Select a source",
      header: <Heading>Select an event source</Heading>,
      continue: "Click a source to continue",
      render: () => (
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
          gap={4}
          maxWidth="800px"
        >
          {sourceDefinitions.map((definition) => (
            <Selectable
              key={definition.type}
              selected={false}
              onSelect={() => {
                setSourceDefinition(definition);
                setStep((step) => step + 1);
              }}
            >
              <Row align="center" p={5} gap={4}>
                <Box as="img" src={definition.icon} width="36px" />
                <Text fontWeight="medium" size="lg">
                  {definition.name}
                </Text>
              </Row>
            </Selectable>
          ))}
        </Box>
      ),
    },
  ];

  if (SourceForm) {
    const sourceFormId = "event-source-form";
    steps.push({
      title: "Configure",
      header: <Heading>Configure settings</Heading>,
      continueProps: {
        form: sourceFormId,
        type: "submit",
      },
      onContinue: () => {}, // defer to SourceForm.onSubmit
      render: () => {
        return (
          <Row gap={8}>
            <Column width="100%">
              <SourceForm
                id={sourceFormId}
                mode="create"
                source={{
                  name: sourceDefinition!.name,
                  config,
                  tunnelId,
                  credentialId,
                }}
                definition={sourceDefinition!}
                onSubmit={({ config, tunnelId, credentialId }) => {
                  setConfig(config);
                  setTunnelId(tunnelId);
                  setCredentialId(credentialId);

                  setStep((step) => step + 1);
                }}
              />
            </Column>
            <SidebarForm name={sourceDefinition!.name} />
          </Row>
        );
      },
    });
  }

  steps.push({
    title: "Finalize",
    header: <Heading>Finalize settings</Heading>,
    render: () => {
      return (
        <FormProvider {...form}>
          <Column gap={8} maxWidth="576px" width="100%">
            <Controller
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormField label="Name" error={error?.message}>
                  <TextInput {...field} isInvalid={Boolean(error)} autoFocus />
                </FormField>
              )}
            />
          </Column>
        </FormProvider>
      );
    },
  });

  return (
    <>
      <Helmet>
        <title>New event source</title>
      </Helmet>
      <TrackView name="New Event Source Page" />
      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New event source"
        onCancel={() => {
          navigate("/events/sources");
        }}
        onSubmit={form.handleSubmit(submit)}
      />
    </>
  );
};
