import { FC, ReactNode } from "react";
import { RouterLink } from "src/router";

import { Box, BoxProps, LinkProps, Row, PaddingProps } from "@hightouchio/ui";

export type CardProps = Omit<BoxProps, keyof PaddingProps> & {
  children: ReactNode;
  p?: number;
  footer?: ReactNode;
  href?: LinkProps["href"];
};

const defaultPadding = 6;

export const Card: FC<Readonly<CardProps>> = ({
  children,
  footer,
  onClick,
  href,
  p = defaultPadding,
  ...props
}) => {
  const isClickable = Boolean(onClick || href);
  return (
    <Box
      as={href ? RouterLink : "div"}
      to={href}
      bg="white"
      display="flex"
      flexDirection="column"
      {...props}
      p={p}
      onClick={onClick}
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      cursor={isClickable ? "pointer" : "auto"}
      transition="border-color 100ms, background-color 100ms"
      _hover={{
        borderColor: isClickable ? "gray.400" : undefined,
        bg: isClickable ? "gray.50" : undefined,
      }}
    >
      {children}
      {footer && (
        <>
          <Box mt="auto" />
          <Row
            pt={4}
            mt={p}
            borderTop="1px"
            borderColor="base.border"
            px={p}
            mx={-p}
          >
            {footer}
          </Row>
        </>
      )}
    </Box>
  );
};
