import { Column, SectionHeading } from "@hightouchio/ui";
import { FC } from "react";
import { useNavigate } from "src/router";

import {
  getSchemaModelType,
  getSchemaModelTypeNames,
} from "src/components/audiences/utils";
import { Query } from "src/components/models/query";
import { PermissionedButton } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";

import { AssetForm } from "src/pages/schema/components/asset";
import { CatalogForm } from "src/pages/schema/components/catalog";
import {
  InteractionForm,
  MissingAssetModelWarning,
} from "src/pages/schema/components/interaction";
import { ParentForm } from "src/pages/schema/components/parent";
import { TimestampForm } from "src/pages/schema/components/timestamp-field";
import { EventTimestampModelTypes } from "src/pages/schema/graph/utils";
import { SchemaObjectType } from "src/pages/schema/types";
import { SchemaModelType } from "src/types/schema";
import {
  shouldShowConfigurationSection,
  showMissingModelWarning,
} from "./utils";
import { AdStatsForm } from "../components/ad-stats-fields";

export const QueryTab: FC<Readonly<{ object: SchemaObjectType }>> = ({
  object,
}) => {
  const navigate = useNavigate();
  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: object?.id },
    v1: { resource: "audience_schema", grant: "update", id: object?.id },
  });
  const type = getSchemaModelType(object);
  const { typePath } = getSchemaModelTypeNames(type);

  return (
    <Column gap={8} p={6} overflow="auto" height="100%">
      {showMissingModelWarning(object) && (
        <MissingAssetModelWarning objectId={object.id} />
      )}

      <Column maxHeight="40vh" overflow="hidden" flexShrink={0}>
        <Query
          model={object}
          source={object.connection}
          actions={
            <PermissionedButton
              permission={{
                v1: {
                  resource: "audience_schema",
                  grant: "update",
                },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: object.id,
                },
              }}
              onClick={() =>
                navigate(`/schema/${typePath}/${object.id}/query?v2=true`)
              }
            >
              Edit
            </PermissionedButton>
          }
        />
      </Column>

      {shouldShowConfigurationSection(type) && (
        <Column gap={6}>
          <SectionHeading>Configuration</SectionHeading>
          {EventTimestampModelTypes.includes(type) && (
            <TimestampForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.Catalog && (
            <CatalogForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.Parent && (
            <ParentForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.Interaction && (
            <InteractionForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.Asset && (
            <AssetForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.AdStats && (
            <AdStatsForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
        </Column>
      )}
    </Column>
  );
};
