import { MouseEventHandler, useMemo, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  DeleteIcon,
  EditableDescription,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, useNavigate, useParams } from "src/router";
import { isPresent } from "ts-extras";

import { ActionBar } from "src/components/action-bar";
import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { PageSpinner } from "src/components/loading";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import {
  UpdateGoalMutationVariables,
  useDeleteGoalMutation,
  useGoalQuery,
  useParentModelForMetricsViewsQuery,
  useUpdateGoalAttributionMethodsMutation,
  useUpdateGoalMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import {
  AudienceAggregationType,
  ConditionType,
  Goal,
  PerUserAggregationType,
} from "src/types/visual";
import { formatDate } from "src/utils/time";

import { PermissionedEditableHeading } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { AggregationOption } from "./constants";
import { MetricForm } from "./metric-form";
import { MetricFormData, useMetricForm } from "./use-metric-form";
import {
  getAggregationConfiguration,
  mapAggregationConfigurationToConfigurationOption,
} from "./utils";

function getAggregationType(goal: {
  aggregation: string;
  audience_aggregation: string;
}): AggregationOption {
  if (!goal?.aggregation) {
    return AggregationOption.Count;
  }
  return (
    mapAggregationConfigurationToConfigurationOption({
      aggregation: goal?.aggregation as PerUserAggregationType,
      audienceAggregation:
        goal?.audience_aggregation as AudienceAggregationType,
    }) ?? AggregationOption.Count
  );
}

export const Metric = () => {
  const { metric_id: id } = useParams<{ metric_id?: string }>();
  const { toast } = useToast();
  const { schemaV2 } = useFlags();
  const navigate = useNavigate();

  const {
    isLoading,
    data: goal,
    refetch,
  } = useGoalQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id), select: (data) => data?.goals_by_pk },
  );

  const updateGoalMutation = useUpdateGoalMutation();
  const updateGoalsAttributionMethods =
    useUpdateGoalAttributionMethodsMutation();
  const deleteGoalMutation = useDeleteGoalMutation();
  const { isPermitted: hasDeletePermissions } = useResourcePermission({
    v1: { resource: "audience_schema", grant: "delete" },
  });

  const parentModelForQueryBuilderQuery = useParentModelForMetricsViewsQuery(
    {
      parentModelId: goal?.parent_model_id ?? "",
    },
    {
      enabled: Boolean(goal?.parent_model_id),
      select: (data) => data.segments_by_pk,
    },
  );

  const parentModel = parentModelForQueryBuilderQuery.data;
  const isParentLoading = parentModelForQueryBuilderQuery.isLoading;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const metricData: MetricFormData | undefined = useMemo(() => {
    if (!goal) return undefined;

    return {
      aggregationType: getAggregationType(goal),
      attributionWindow: goal?.attribution_window,
      column: goal?.config?.column,
      config: {
        type: ConditionType.Event,
        eventModelId: goal?.config?.eventModelId,
        relationshipId: goal?.config?.relationshipId,
        filter: {
          subconditions: goal?.config?.filter?.subconditions,
        },
      },
      attributionMethods: goal.goals_attribution_methods.map(
        ({ attribution_method }) => ({
          id: attribution_method.id,
        }),
      ),
    };
  }, [goal]);

  const { isPermitted: userCanUpdate } = useResourcePermission({
    v1: { resource: "audience_schema", grant: "update" },
  });

  const { handleSubmit, ...metricFormProps } = useMetricForm(metricData);

  const {
    formState: { isDirty },
    reset,
  } = metricFormProps;

  const trackUpdate = () => {
    analytics.track("Metric Updated", {
      metric_id: id,
      metric_name: goal?.name,
      parent_model_id: goal?.parent_model_id,
    });
  };

  if (!id || isLoading || isParentLoading) {
    return <PageSpinner />;
  }

  if (!goal) {
    return (
      <Warning subtitle="It may have been deleted" title="Source not found" />
    );
  }

  const updatedAt =
    goal.updated_at || goal.created_at
      ? formatDate(goal.updated_at || goal.created_at)
      : "--";
  const updatedBy = goal.updated_by_user?.name || goal.created_by_user?.name;

  const saveMetricInfo = async (
    input: UpdateGoalMutationVariables["input"],
  ) => {
    try {
      await updateGoalMutation.mutateAsync({
        id,
        input,
      });

      toast({
        id: "update-metric",
        title: "Metric was updated",
        variant: "success",
      });

      trackUpdate();
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "update-metric",
        title: "Failed to update this metric",
        message: "There was a problem updating this metric. Please try again.",
        variant: "error",
      });
    }
  };

  const updateMetric = async (data: MetricFormData) => {
    // Need to make sure the data is formatted correctly for the backend
    const {
      attributionWindow,
      aggregationType,
      column,
      attributionMethods,
      ...restData
    } = data;
    const { aggregation, audienceAggregation } =
      getAggregationConfiguration(aggregationType)!;

    const formattedData: Goal = {
      ...restData,
      aggregation,
      audience_aggregation: audienceAggregation,
      attribution_window: attributionWindow,
      config: {
        ...restData.config,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore graphql thinks this is a string but it's a NUMBER
        eventModelId: isPresent(restData.config?.eventModelId)
          ? Number(restData.config?.eventModelId)
          : null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore graphql thinks this is a string but it's a NUMBER
        relationshipId: isPresent(restData.config?.relationshipId)
          ? Number(restData.config?.relationshipId)
          : null,
      },
    };

    if (column) {
      formattedData.config["column"] = column;
    }

    try {
      await updateGoalMutation.mutateAsync({
        id,
        input: { ...formattedData },
      });

      // TODO: add conditional case here based on dirtyFields to only update attribution methods if changed
      await updateGoalsAttributionMethods.mutateAsync({
        goalId: id,
        attribution_methods: attributionMethods.map((attributionMethod) => ({
          attribution_method_id: attributionMethod.id,
          goal_id: id,
        })),
      });

      toast({
        id: "update-metric",
        title: "Metric was updated",
        variant: "success",
      });

      refetch();

      trackUpdate();
    } catch (error) {
      toast({
        id: "update-metric",
        title: "Failed to update this metric",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const saveMetric: MouseEventHandler<HTMLButtonElement> = (event) => {
    handleSubmit(updateMetric, () => {
      toast({
        id: "metrics-form-toast",
        title: "There is an error in the form",
        message: "Check the form and try again.",
        variant: "error",
      });
    })(event);
  };

  const deleteMetric = async () => {
    if (!hasDeletePermissions) {
      toast({
        id: "delete-metric",
        title: "You don't have permissions to delete metrics",
        variant: "error",
      });

      return;
    }

    try {
      await deleteGoalMutation.mutateAsync({ id });

      analytics.track("Metric Deleted", {
        metric_id: id,
        metric_name: goal?.name,
        parent_model_id: goal?.parent_model_id,
      });

      navigate("/metrics");
    } catch (error) {
      toast({
        id: "delete-metric",
        title: "Failed to delete this metric",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return (
    <>
      <DetailPage
        crumbs={[
          {
            label: "All metrics",
            link: "/metrics",
          },
        ]}
        header={
          <Column flex={1} minWidth={0}>
            <Row flex={1} justify="space-between" minWidth={0} pt={1}>
              <PermissionedEditableHeading
                permission={{
                  v1: { resource: "audience_schema", grant: "update" },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: parentModel?.id,
                  },
                }}
                size="lg"
                value={goal?.name ?? ""}
                onChange={(name) => saveMetricInfo({ name })}
              />
              <Row align="center">
                <Menu>
                  <MenuActionsButton variant="secondary" />

                  <MenuList>
                    <MenuItem
                      isDisabled={
                        !hasDeletePermissions || deleteGoalMutation.isLoading
                      }
                      icon={DeleteIcon}
                      variant="danger"
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Row>
            </Row>
            <Row>
              <EditableDescription
                value={goal?.description ?? ""}
                onChange={(description) => saveMetricInfo({ description })}
              />
            </Row>
            <DetailBar>
              <Row align="center" gap={2} flexShrink={0}>
                <IntegrationIcon
                  src={parentModel?.connection?.definition?.icon}
                  name={parentModel?.connection?.definition?.name ?? ""}
                />
                <Link
                  href={
                    schemaV2
                      ? `/schema-v2/view?source=${parentModel?.connection?.id}&id=${parentModel?.id}`
                      : `/schema/parent-models/${parentModel?.id}`
                  }
                >
                  <Text isTruncated fontWeight="medium" color="inherit">
                    {parentModel?.name}
                  </Text>
                </Link>
              </Row>
              <Row align="center" flexShrink={0}>
                <Text>
                  Updated:
                  <Text fontWeight="semibold" ml={1}>
                    {updatedAt}
                  </Text>{" "}
                  {updatedBy && `by ${updatedBy}`}
                </Text>
              </Row>
            </DetailBar>
          </Column>
        }
        title={`${goal.name} metric`}
      >
        <Column mb={32} gap={4}>
          <Text fontWeight="medium" size="lg">
            Metric definition
          </Text>
          <MetricForm
            metricFormProps={metricFormProps}
            parentModel={parentModel}
          />
        </Column>

        <ActionBar>
          <ButtonGroup>
            <Button
              isDisabled={!userCanUpdate || !isDirty}
              isLoading={updateGoalMutation.isLoading}
              size="lg"
              variant="primary"
              onClick={saveMetric}
            >
              Save metric
            </Button>
            <Button
              isDisabled={
                !userCanUpdate ||
                updateGoalMutation.isLoading ||
                !metricFormProps.formState.isDirty
              }
              size="lg"
              onClick={() => {
                reset();
              }}
            >
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      </DetailPage>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        label="metric"
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onDelete={deleteMetric}
      />
    </>
  );
};
