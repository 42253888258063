import {
  IconButton,
  CloseIcon,
  Row,
  Text,
  Column,
  Skeleton,
  SkeletonBox,
} from "@hightouchio/ui";
import { FC, useEffect, useState } from "react";
import { Card } from "src/components/card";
import {
  useInspectorMessagesBackgroundQuery,
  useRunSqlResultQuery,
} from "src/graphql";
import type { Flow } from "src/pages/decision-engines/flows";
import { InteractionRow } from "src/pages/decision-engines/inspector/components/messages";
import { parseIfString } from "src/utils/object";
import { MessageRow } from "src/pages/decision-engines/inspector/components/message-row";

import {
  Interaction,
  InteractionHeader,
} from "src/pages/decision-engines/inspector/components/interaction";
import { Drawer } from "src/components/drawer";

export const MessageFeed = ({ flow }: { flow: Flow }) => {
  const [selectedInteraction, setSelectedInteraction] =
    useState<InteractionRow>();
  const [shouldPoll, setShouldPoll] = useState(false);
  const [interactions, setInteractions] = useState<InteractionRow[]>([]);
  const flowMessages = flow.messages;

  const { data: jobId, isLoading: loadingJob } =
    useInspectorMessagesBackgroundQuery(
      {
        input: {
          flowId: flow.id,
          direction: "previous",
          primaryKeys: [],
        },
      },
      {
        select: (data) => data.inspectorMessagesBackground,
      },
    );

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename ===
          "SuccessfulQueryResponse"
        ) {
          setInteractions(
            data.backgroundPreviewQueryResult.rows.map((row) => ({
              ...row,
              sync_metadata: parseIfString(row.sync_metadata),
              action_features: parseIfString(row.action_features),
            })),
          );
        }
        setShouldPoll(false);
      },
    },
  );

  const isLoading = loadingJob || shouldPoll;

  return (
    <Card
      p={0}
      overflow="auto"
      flex="0 1 20%"
      h="100%"
      display={["none", "none", "none", "none", "flex"]}
    >
      {isLoading ? (
        <>
          <Skeleton isLoading>
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
          </Skeleton>
        </>
      ) : !interactions?.length ? (
        // eslint-disable-next-line react/jsx-no-undef
        <Text color="text.placeholder" m="auto">
          No messages
        </Text>
      ) : (
        interactions.map((interaction) => (
          <MessageRow
            px={2}
            key={interaction.interaction_id}
            interaction={interaction}
            flowMessages={flowMessages}
            isSelected={
              selectedInteraction?.interaction_id === interaction.interaction_id
            }
            onClick={() => {
              setSelectedInteraction(interaction);
            }}
          />
        ))
      )}
      {selectedInteraction && (
        <InteractionDrawer
          flow={flow}
          onClose={() => setSelectedInteraction(undefined)}
          interaction={selectedInteraction}
        />
      )}
    </Card>
  );
};

export const InteractionDrawer: FC<
  Readonly<{
    onClose: () => void;
    interaction: InteractionRow;
    flow: Flow;
  }>
> = ({ onClose, interaction, flow }) => {
  const flowMessage = flow.messages.find(
    (m) => m.message.id === interaction.action_features.message,
  );
  return (
    <Drawer isOpen onClose={onClose} size="lg">
      <Row
        gap={2}
        align="center"
        justify="space-between"
        p={6}
        borderBottom="1px"
        borderColor="base.border"
      >
        <InteractionHeader interaction={interaction} message={flowMessage} />
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      </Row>
      <Column flex={1} py={6} overflow="auto">
        <Interaction
          message={flowMessage!}
          usersByPrimaryKey={{}}
          interaction={interaction}
          error=""
        />
      </Column>
    </Drawer>
  );
};
