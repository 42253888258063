import { FC } from "react";
import { Member, Props as MemberProps, Members } from "./members";
import { useFormContext } from "react-hook-form";

type Props = Omit<MemberProps, "onSelect" | "selectedRows">;

export const SelectMembers: FC<Readonly<Props>> = (props) => {
  const form = useFormContext<{ members: Record<string, Member> }>();
  const members = form.watch("members");
  const selected = Object.values(members)
    .filter(Boolean)
    .map(({ user_id }) => user_id);

  return (
    <Members
      {...props}
      selectedRows={selected}
      onSelect={(value) => {
        if (Array.isArray(value)) {
          form.setValue(
            "members",
            value.reduce((acc, id) => {
              return {
                ...acc,
                [id]: { user_id: id, is_group_manager: false },
              };
            }, {}),
            { shouldDirty: true },
          );
        } else {
          form.setValue(
            "members",
            {
              ...members,
              [value]: selected.includes(value)
                ? null
                : { user_id: value, is_group_manager: false },
            },
            { shouldDirty: true },
          );
        }
      }}
    />
  );
};
