import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  Button,
  CheckIcon,
  Combobox,
  FormField,
  useToast,
  WarningIcon,
} from "@hightouchio/ui";
import { capitalize } from "lodash";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  useTestNewNotificationChannelQuery,
  useTestNotificationChannelQuery,
} from "src/graphql";
import { ChannelDefinition } from "./channel-definitions";

export const ChannelConfigWrapper: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { toast } = useToast();
  const {
    watch,
    formState: { isDirty },
  } = useFormContext();

  const channelType = watch("channelType");
  const channelId = watch("channelId");
  const config = watch("config");

  const {
    refetch: testChannel,
    isLoading: testingExistingChannel,
    data: existingChannelTestResult,
  } = useTestNotificationChannelQuery(
    {
      channelId: channelId || "",
    },
    {
      enabled: false,

      select(data) {
        return data.testWorkspaceNotificationChannel;
      },
      onSuccess(data) {
        if (data?.success) {
          toast({
            title: "Success",
            variant: "success",
            id: "workspace-notifications",
          });
        } else {
          toast({
            title: "Error",
            message: data?.error?.message,
            variant: "error",
            id: "workspace-notifications",
          });
        }
      },
    },
  );

  const {
    refetch: testNewChannel,
    isLoading: testingNewChannel,
    data: newChannelTestResult,
  } = useTestNewNotificationChannelQuery(
    {
      config,
      channelType: channelType || "",
    },
    {
      enabled: false,
      select(data) {
        return data.testNewWorkspaceNotificationChannel;
      },
      onSuccess(data) {
        if (data?.success) {
          toast({
            title: "Success",
            variant: "success",
            id: "workspace-notifications",
          });
        } else {
          toast({
            title: "Error",
            message: data?.error?.message,
            variant: "error",
            id: "workspace-notifications",
          });
        }
      },
    },
  );

  const isTesting = testingExistingChannel || testingNewChannel;
  const testResult = newChannelTestResult || existingChannelTestResult;

  const { control } = useFormContext();

  const definition = channelType && ChannelDefinition[channelType];

  return (
    <>
      <Controller
        control={control}
        name="channelType"
        render={({ field: { onChange, value } }) => (
          <FormField label="Recipient type">
            <Combobox
              width="100%"
              optionAccessory={(option) => ({
                type: "icon",
                icon: () => (
                  <Box sx={{ svg: { width: "18px", height: "18px" } }}>
                    <option.icon />
                  </Box>
                ),
              })}
              options={Object.values(ChannelType).map((channelType) => {
                const definition = ChannelDefinition[channelType];
                return {
                  label: capitalize(channelType),
                  value: channelType,
                  icon: definition.icon,
                };
              })}
              onChange={onChange}
              value={value}
              isDisabled={!!channelId}
            />
          </FormField>
        )}
      />
      {children && children}
      {definition?.testable && (channelId || config) ? (
        <FormField
          label="Test this recipient"
          description={definition?.testText}
          error={testResult?.error?.message}
        >
          <Button
            isLoading={isTesting}
            onClick={() => {
              toast({
                title: `Testing ${channelType} notifications`,
                variant: "info",
                id: "workspace-notifications",
              });
              channelId && !isDirty ? testChannel() : testNewChannel();
            }}
            directionIcon={
              testResult?.success === true
                ? CheckIcon
                : testResult?.success === false
                  ? WarningIcon
                  : undefined
            }
          >
            Send a test alert now
          </Button>
        </FormField>
      ) : null}
    </>
  );
};
