import { SchemaModelType } from "src/types/schema";

export function getNameFieldTooltip(type: SchemaModelType) {
  if (type === SchemaModelType.Interaction) {
    return "Something simple like “Email opens”";
  } else if (type === SchemaModelType.Asset) {
    return "Something simple like “Emails” or “Ads”";
  } else if (type === SchemaModelType.AdStats) {
    return "Something simple like “Ad stats”";
  }
  return "Something simple like “Users”";
}
